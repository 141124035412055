import api, {ExternalSpace} from 'src/api';
import {LoadAction, PayloadAction} from '../types';
import {Errors, Loadings, Success, Actions} from '../constants';

export type LoadExternalAccountAction = LoadAction<Actions.LOAD_EXTERNAL_SPACES>;

export type LoadExternalSpacesSuccessAction = PayloadAction<Actions.LOAD_EXTERNAL_SPACES_SUCCESS, ExternalSpace[]>;

export type SetExternalAccountAction = PayloadAction<Actions.SET_EXTERNAL_SPACE, ExternalSpace>;
export type SetChosenExternalAccountAction = PayloadAction<Actions.SET_CHOSEN_EXTERNAL_SPACE, ExternalSpace>;
export type RelinkCardAction = PayloadAction<Actions.RELINK_CARD, ExternalSpace>;
export type CancelRelinkCardAction = PayloadAction<Actions.CANCEL_RELINK_CARD, ExternalSpace>;

export const loadExternalAccounts = (accountSpaceId: number): LoadExternalAccountAction => ({
	type: Actions.LOAD_EXTERNAL_SPACES,
	load: api.externalAccounts.loadExternalSpaces(accountSpaceId),
	loading: Loadings.loadExternalSpacesLoading,
	error: Errors.loadExternalSpaces,
	success: Success.isExternalAccountsLoaded
});

export const setExternalAccount = (externalAccount: ExternalSpace): SetExternalAccountAction => ({
	type: Actions.SET_EXTERNAL_SPACE,
	payload: externalAccount
});

export const setChosenExternalAccount = (externalAccount: ExternalSpace): SetChosenExternalAccountAction => ({
	type: Actions.SET_CHOSEN_EXTERNAL_SPACE,
	payload: externalAccount
});

export const relinkCard = (card: ExternalSpace): RelinkCardAction => ({
	type: Actions.RELINK_CARD,
	payload: card
});

export const cancelRelinkCard = (card: ExternalSpace): CancelRelinkCardAction => ({
	type: Actions.CANCEL_RELINK_CARD,
	payload: card
});
