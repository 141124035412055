import {makeStyles} from '@material-ui/styles';

import theme from 'src/theme';

export const useStyles = makeStyles(() => ({
	cover: {
		position: 'fixed',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		zIndex: 1,
		background: 'rgba(0, 0, 0, 0.3)',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backdropFilter: 'blur(4px)'
	},
	container: {
		width: '320px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		background: theme.colors.white,
		padding: '50px 40px',
		borderRadius: '10px',
		boxShadow: '0px 0px 26px rgb(0 0 0 / 15%)'
	},
	message: {
		margin: '12px 0 24px 0'
	},
	button: {
		marginTop: 12
	},
	buttonCancel: {
		marginTop: 12,
		background: theme.colors.white,
		color: theme.colors.black,
		fontWeight: 600
	}
}));
