// TEMPORARY OBJECT
export default [
	'BY',
	'MM',
	'CI',
	'CU',
	'CG',
	'IR',
	'IQ',
	'LR',
	'KP',
	'SD',
	'SY',
	'ZW',
	'Albania',
	'BA',
	'HR',
	'XK',
	'ME',
	'MK',
	'RS',
	'SI'
];
