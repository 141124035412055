import React, {FC} from 'react';
import NumberFormat from 'react-number-format';
import {TextField} from 'src/components/@shared';
import {CustomInputProps, NumberFieldPropsBase} from 'src/types';

const CustomInput: FC<CustomInputProps> = ({...props}) => <TextField type="number" {...props} />;

export interface ExpirationDateFieldProps extends NumberFieldPropsBase {
	onChange?: (expiredDate: string) => void;
}

const cardExpiry = (val: string) => {
	const month = val.slice(0, 2);
	const year = val.slice(2, 4);
	return month + (year.length > 0 ? `/${year}` : '');
};

export const ExpirationDateField: FC<ExpirationDateFieldProps> = ({value, onChange, ...props}) => (
	<NumberFormat
		{...props}
		format={cardExpiry}
		customInput={CustomInput}
		defaultValue={value}
		onValueChange={(values) => {
			onChange?.(values.value);
		}}
	/>
);
