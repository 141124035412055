import {makeStyles} from '@material-ui/styles';

import {S} from 'src/styles';

export const useStyles = makeStyles(() => ({
	content: S.screenContent,
	title: S.screenTitle,
	subtitle: (supportUrl) => ({
		...S.screenSubtitle,
		textDecoration: supportUrl ? 'underline' : 'none',
		cursor: supportUrl ? 'pointer' : 'none',
		pointerEvents: supportUrl ? 'visible' : 'none',
		'&:hover': {
			color: 'inherit'
		},
		'&:visited': {
			color: 'inherit'
		}
	}),
	noUrlMessage: {
		margin: 0
	}
}));
