import {Actions, Errors, Loadings, Success} from 'src/store/constants';
import api from 'src/api';
import {LoadAction, PayloadAction} from '../types';

export type LoadNftMediaAction = LoadAction<Actions.LOAD_NFT_MEDIA>;
export type LoadNftMediaSuccessAction = PayloadAction<Actions.LOAD_NFT_MEDIA_SUCCESS, any>;
export type LoadNftMediaErrorsAction = PayloadAction<Actions.LOAD_NFT_MEDIA_ERROR, any>;

export const loadNftMedia = (): LoadNftMediaAction => ({
	type: Actions.LOAD_NFT_MEDIA,
	load: api.nft.getMedia(),
	loading: Loadings.isNftMediaLoading,
	error: Errors.loadNftMediaError,
	success: Success.isNftMediaLoadedSuccessfully
});
