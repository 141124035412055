import {makeStyles} from '@material-ui/styles';

import {S} from 'src/styles';
import theme from 'src/theme';

export const useStyles = makeStyles(() => ({
	wrapper: {
		background: theme.colors.gray98,
		height: '100vh'
	},
	header: {
		background: `linear-gradient(90deg, #F82507 0%, #7622A9 69.27%, #7622A9 100%)`,
		height: 75,
		...S.flexCentered
	},
	logo: {
		height: 28,
		width: 59
	},
	main: (isScrolling) => ({
		height: 'calc(100vh - 75px)',
		overflow: `hidden overlay`,
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		'&::-webkit-scrollbar': {
			backgroundColor: isScrolling ? theme.colors.white : 'transparent'
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: isScrolling ? theme.colors.black20 : 'transparent'
		}
	}),
	footer: {
		background: `linear-gradient(90deg, #F82507 0%, #7622A9 69.27%, #7622A9 100%)`,
		padding: '40px 0',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	footer__text: {
		color: theme.colors.white,
		fontFamily: 'Sofia Pro',
		letterSpacing: 0.5,
		fontSize: 10,
		opacity: 0.7
	}
}));
