export enum Actions {
	LOAD_PAYMENT_INFO = 'LOAD_PAYMENT_INFO',
	LOAD_PAYMENT_INFO_SUCCESS = 'LOAD_PAYMENT_INFO_SUCCESS',
	LOAD_NFT_MEDIA = 'LOAD_NFT_MEDIA',
	LOAD_NFT_MEDIA_SUCCESS = 'LOAD_NFT_MEDIA_SUCCESS',
	LOAD_NFT_MEDIA_ERROR = 'LOAD_NFT_MEDIA_ERROR',
	SET_LOADING = 'SET_LOADING',
	REMOVE_LOADING = 'REMOVE_LOADING',
	CLEAR_LOADINGS = 'CLEAR_LOADINGS',
	ACCEPT_PAYMENT = 'ACCEPT_PAYMENT',
	DECLINE_PAYMENT = 'DECLINE_PAYMENT',
	DECLINE_PAYMENT_SUCCESS = 'DECLINE_PAYMENT_SUCCESS',
	DECLINE_PAYMENT_ERROR = 'DECLINE_PAYMENT_ERROR',
	ACCEPT_PAYMENT_SUCCESS = 'ACCEPT_PAYMENT_SUCCESS',
	ACCEPT_PAYMENT_ERROR = 'ACCEPT_PAYMENT_ERROR',
	SET_ERROR = 'SET_ERROR',
	REMOVE_ERROR = 'REMOVE_ERROR',
	CLEAR_ERRORS = 'CLEAR_ERRORS',
	SET_BANQS = 'SET_BANQS',
	LOAD_BANQ_BY_PAY_ID = 'LOAD_BANQ_BY_PAY_ID',
	LOAD_BANQ_BY_PAY_ID_SUCCESS = 'LOAD_BANQ_BY_PAY_ID_SUCCESS',
	LOAD_BANQ = 'LOAD_BANQ',
	LOAD_BANQ_SUCCESS = 'LOAD_BANQ_SUCCESS',
	LOAD_EXTERNAL_SPACES = 'LOAD_EXTERNAL_SPACES',
	SET_EXTERNAL_SPACE = 'SET_EXTERNAL_SPACE',
	RELINK_CARD = 'RELINK_CARD',
	CANCEL_RELINK_CARD = 'CANCEL_RELINK_CARD',
	LOAD_EXTERNAL_SPACES_SUCCESS = 'LOAD_EXTERNAL_SPACES_SUCCESS',
	SET_SUCCESS = 'SET_SUCCESS',
	REMOVE_SUCCESS = 'REMOVE_SUCCESS',
	CLEAR_SUCCESS = 'CLEAR_SUCCESS',
	LOAD_SPACES = 'LOAD_SPACES',
	LOAD_SPACES_SUCCESS = 'LOAD_SPACES_SUCCESS',
	SET_IS_EXPIRED = 'SET_IS_EXPIRED',
	LOAD_PAYMENT_FEES = 'LOAD_PAYMENT_FEES',
	LOAD_PAYMENT_FEES_SUCCESS = 'LOAD_PAYMENT_FEES_SUCCESS',
	ADD_CREDIT_CARD = 'ADD_CREDIT_CARD',
	ADD_CREDIT_CARD_SUCCESS = 'ADD_CREDIT_CARD_SUCCESS',
	ADD_CREDIT_CARD_ERROR = 'ADD_CREDIT_CARD_ERROR',
	SET_TOGGLE_3DS = 'SET_TOGGLE_3DS',
	SET_CHOSEN_EXTERNAL_SPACE = 'SET_CHOSEN_EXTERNAL_SPACE'
}

export enum Loadings {
	isPaymentInfoLoading = 'isPaymentInfoLoading',
	isPaymentAcceptLoading = 'isPaymentAcceptLoading',
	logoutUserLoading = 'logoutUserLoading',
	restoreAuthSessionLoading = 'restoreAuthSessionLoading',
	updatePasswordLoading = 'updatePasswordLoading',
	isBanqByIdLoading = 'isBanqByIdLoading',
	loadExternalSpacesLoading = 'loadExternalSpacesLoading',
	isLoadSpacesLoading = 'isLoadSpacesLoading',
	isNftMediaLoading = 'isNftMediaLoading',
	isPaymentDeclineLoading = 'isPaymentDeclineLoading',
	isPaymentFeeLoading = 'isPaymentFeeLoading',
	isAddCardLoading = 'isAddCardLoading'
}

export enum Errors {
	loadPaymentInfoError = 'loadPaymentInfoError',
	loadPaymentAcceptError = 'loadPaymentAcceptError',
	loadBanqByIdError = 'loadBanqByIdError',
	loadExternalSpaces = 'loadExternalSpaces',
	isLoadSpacesError = 'isLoadSpacesError',
	loadNftMediaError = 'loadNftMediaError',
	DeclinePaymentError = 'DeclinePaymentError',
	loadTipsError = 'loadTipsError',
	snackbar = 'snackbar',
	isAddCardError = 'isAddCardError'
}

export enum Success {
	isPaymentInfoLoadedSuccessfully = 'isPaymentInfoLoadedSuccessfully',
	isPaymentAcceptSuccessfully = 'isPaymentAcceptSuccessfully',
	loadNftError = 'loadNftError',
	loadBanqByIdError = 'loadBanqByIdError',
	loadExternalSpaces = 'loadExternalSpaces',
	isNftLoadedSuccessfully = 'isNftLoadedSuccessfully',
	loadBanqByGuidSuccessful = 'loadBanqByGuidSuccessful',
	isExternalAccountsLoaded = 'isExternalAccountsLoaded',
	isSpacesLoadedSuccessfully = 'isSpacesLoadedSuccessfully',
	isNftMediaLoadedSuccessfully = 'isNftMediaLoadedSuccessfully',
	isPaymentDeclinedSuccessfully = 'isPaymentDeclinedSuccessfully',
	isPaymentFeeLoadedSuccessful = 'isPaymentFeeLoadedSuccessful',
	isCardAddedSuccessfully = 'isCardAddedSuccessfully',
	snackbar = 'snackbar'
}
