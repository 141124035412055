import React from 'react';
import {PaymentOptionsView} from 'src/components/paymentOptions';
import {AddCreditCardView} from 'src/components/addCreditCard';
import {SecurityCheckView} from 'src/components/securityIframe';
import {PopUp} from '../@shared';

export enum PopupPagesEnum {
	PaymentOptionsPage = 'PaymentOptionsPage',
	AddCreditCardPage = 'AddCreditCardPage',
	SecurityPage = 'SecurityPage'
}

interface PaymentOptionsPopupProps {
	isOpen: boolean;
	page?: PopupPagesEnum;
	handleOptionsPopupConfig: (isOpen: boolean, page?: PopupPagesEnum) => void;
}

export const PaymentOptionsPopup = ({isOpen, page, handleOptionsPopupConfig}: PaymentOptionsPopupProps) => {
	const getView = () => {
		switch (page) {
			case PopupPagesEnum.PaymentOptionsPage:
				return <PaymentOptionsView handleOptionsPopupConfig={handleOptionsPopupConfig} />;
			case PopupPagesEnum.AddCreditCardPage:
				return <AddCreditCardView handleOptionsPopupConfig={handleOptionsPopupConfig} />;
			case PopupPagesEnum.SecurityPage:
				return <SecurityCheckView handleOptionsPopupConfig={handleOptionsPopupConfig} />;
			default:
				return;
		}
	};
	if (!isOpen) {
		return <></>;
	}
	return <PopUp>{getView()}</PopUp>;
};
