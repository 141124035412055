import {payment} from './payment';
import banqs from './banqs';
import externalAccounts from './externalAccounts';
import users from './users';
import {nft} from './nft';
import fees from './fees';
export * from './fees';

export * from './types';

const api = {
	banqs,
	externalAccounts,
	payment,
	users,
	nft,
	fees
};

export type API = typeof api;

export default api;
