import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {createBanqSelector} from 'src/store/banqs/selectors';
import {
	createExternalAccountSelector,
	createAreLinkedCreditCardsSelector,
	createIsNoCreditCardRelinkedSelector,
	createCreditCardListSelector,
	createBankListSelector
} from 'src/store/externalAccounts/selectors';
import {createIsUserWithoutPaymentOptionsSelector} from 'src/store/selectors';
import {createLoadingSelector} from 'src/store/@loadings/selectors';
import {createSpaceByBanqIdSelector} from 'src/store/spaces/selectors';
import {Loadings} from 'src/store/constants';
import BanqCard from '../BanqCard/BanqCard';
import {PaymentAccountsAdornment} from 'src/components';
import {AddPaymentOptionPopUp} from '../addPaymentOptionPopUp/AddPaymentOptionPopUp';
interface Props {
	sourceBanqId: number;
	externalAccountId: number;
	onClick: () => void;
	onAddDebitCard: () => void;
}

export const SourceBanqCardContainer = ({sourceBanqId, externalAccountId, onClick, onAddDebitCard}: Props) => {
	const sourceBanq = useSelector(createBanqSelector(sourceBanqId));
	const selectedExternalAccount = useSelector(createExternalAccountSelector(externalAccountId));
	const isBalanceLoading = useSelector(createLoadingSelector(Loadings.isBanqByIdLoading));
	const externalAccount = selectedExternalAccount;
	const isUserWithoutPaymentOptions = useSelector(createIsUserWithoutPaymentOptionsSelector(sourceBanq?.id)); // 172846
	const chosenSpaceId = useSelector(createSpaceByBanqIdSelector(sourceBanqId))?.id;
	const creditCards = useSelector(createCreditCardListSelector(chosenSpaceId));
	const bankAccounts = useSelector(createBankListSelector(chosenSpaceId));
	const areLinkedCreditCards = useSelector(createAreLinkedCreditCardsSelector(chosenSpaceId)); // 162098
	const isNoRelinkedCard = useSelector(createIsNoCreditCardRelinkedSelector(chosenSpaceId)); // 162098

	const [isPaymentMethodPopUpOpen, setAddPaymentMethodPopUpOpen] = useState<boolean>();

	const isSingleCardLinked = creditCards?.length === 1;
	const noPaymentMethodsOtherThanCards = bankAccounts?.length === 0 && !sourceBanq?.balance;

	const handleClick = () => {
		if (isUserWithoutPaymentOptions || (isSingleCardLinked && noPaymentMethodsOtherThanCards && isNoRelinkedCard)) {
			setAddPaymentMethodPopUpOpen(true);
		} else {
			onClick();
		}
	};

	return (
		<>
			<BanqCard
				title="PAY FEES WITH"
				accountName="Credit Card"
				onClick={handleClick}
				endAdornment={
					<PaymentAccountsAdornment
						banqAccount={sourceBanq}
						isBalanceLoading={isBalanceLoading}
						externalAccount={externalAccount}
						areLinkedCreditCards={areLinkedCreditCards}
						isNoRelinkedCard={isNoRelinkedCard}
					/>
				}
			/>
			{isPaymentMethodPopUpOpen && (
				<AddPaymentOptionPopUp
					onAddDebitCard={async () => {
						await setAddPaymentMethodPopUpOpen(false);
						if (sourceBanq?.accountSpaceId) {
							onAddDebitCard();
						}
					}}
					onClose={() => setAddPaymentMethodPopUpOpen(false)}
				/>
			)}
		</>
	);
};
