import {appConfig} from './appConfig';
import http from './http';
import {NftImgSize} from './types';

export const nft = {
	getMedia(imgSize: NftImgSize = NftImgSize.Large) {
		return http
			.get(`/user-tokens/preview/${appConfig.paymentGuid}?resizeDimension=${imgSize}`)
			.then((data) => Promise.resolve(data));
	}
};
