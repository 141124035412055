import React, {ReactNode} from 'react';
import {makeStyles} from '@material-ui/styles';
import ClickAwayListener from 'react-click-away-listener';
import clsx from 'clsx';
import theme from 'src/theme';
import {Backdrop} from '../Backdrop/Backdrop';

const useStyles = makeStyles(() => ({
	root: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		maxWidth: '544px',
		width: '100%',
		border: 'none',
		background: theme.colors.white,
		padding: '40px 48px',
		borderRadius: '10px',
		overflowY: 'scroll',
		boxShadow: theme.shadows.v1,
		[theme.breakpoints.down.sm]: {
			margin: '0 15px'
		}
	}
}));

export interface PopUpProps {
	children: ReactNode;
	className?: string;
	onClose?: () => void;
	isOpen?: boolean;
	blur?: boolean;
}
export const PopUp = ({children, className, onClose, isOpen = true, blur = true}: PopUpProps) => {
	const classes = useStyles();
	if (!isOpen) return <></>;
	return (
		<Backdrop background="shadow" blur={blur}>
			<ClickAwayListener onClickAway={onClose || (() => {})}>
				<div className={clsx(classes.root, className)}>{children}</div>
			</ClickAwayListener>
		</Backdrop>
	);
};
