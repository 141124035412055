import React from 'react';
import {useSelector} from 'react-redux';

import {NFTimageBox, ScreenLayout, ViewTitle} from 'src/components/@shared';
import {selectNftMedia} from 'src/store/nft/selectors';
import {useStyles} from './TransferViewExpired.styles';

export const TransferViewExpired = () => {
	const {supportUrl, marketplace} = useSelector(selectNftMedia);
	const classes = useStyles(supportUrl?.lenght > 0);
	return (
		<ScreenLayout>
			<div className={classes.content}>
				<NFTimageBox />
				<ViewTitle className={classes.title}>The link to this NFT expired after 24 hours.</ViewTitle>
				{supportUrl && marketplace ? (
					<a
						href={supportUrl}
						target="_blank"
						className={classes.subtitle}
						rel="noreferrer">{`Please contact ${marketplace} for support.`}</a>
				) : (
					<p className={classes.noUrlMessage}>Please contact {marketplace || 'marketplace'} for support.</p>
				)}
			</div>
		</ScreenLayout>
	);
};
