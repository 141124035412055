import React from 'react';

import {ScreenLayout} from 'src/components/@shared';
import {useStyles} from './TransferDeclined.styles';

export const TransferDeclined = () => {
	const classes = useStyles();
	return (
		<ScreenLayout className={classes.content}>
			<span>This transfer was declined.</span>
		</ScreenLayout>
	);
};
