import React from 'react';
import {BankDetails, CardDetails, ExternalSpace, PaymentSystem} from 'src/api';
import {formatStringIfLong, mapCardToCardName} from 'src/utils';
import {RightAdornment} from '../RightAdorment/RightAdornment';

interface Props {
	externalAccount: ExternalSpace;
}

const getPaymentMethodLabel = (externalAccount: ExternalSpace) =>
	externalAccount?.accountType === 'card' || externalAccount?.accountType === 'bravaPay'
		? `${
				mapCardToCardName[(externalAccount?.details as CardDetails)?.paymentSystem as PaymentSystem] ||
				mapCardToCardName[PaymentSystem.Other]
		  }`
		: `${(externalAccount?.details as BankDetails)?.bankName}`;

export const PaymentMethodAdornment = ({externalAccount}: Props) => (
	<RightAdornment
		primary={formatStringIfLong(getPaymentMethodLabel(externalAccount), 18)}
		secondary={`...${externalAccount?.last4}`}
	/>
);
