import {State} from 'src/store/reducer';
import {Loadings} from 'src/store/constants';

export const GlobalLoadingList = [
	Loadings.logoutUserLoading,
	Loadings.restoreAuthSessionLoading,
	Loadings.updatePasswordLoading,
	Loadings.isPaymentInfoLoading,
	Loadings.isPaymentAcceptLoading
];

export const selectGlobalLoading = (state: State) => state.loadings.globalLoader;

export const createLoadingSelector = (key: Loadings) => (state: State) => state.loadings[key];

export const selectIsLoading = (state: State) => Object.values(state.loadings).filter(Boolean).length > 0;

export const selectIsAnyFromLoadingListLoading = (state: State) =>
	GlobalLoadingList.map((el) => state.loadings[el]).filter(Boolean).length > 0;
