import axios, {AxiosError, AxiosResponse} from 'axios';
import {setIsExpired} from 'src/store/payment/actions';
import store from 'src/store';
import { env } from 'src/config';
import {appConfig} from './appConfig';

const {dispatch} = store;

const http = axios.create({
	baseURL: `${env.baseURL}/v1`,
	headers: {
		common: {
			'X-Permissions': appConfig.token,
			'Content-Type': 'application/json'
		}
	}
});

const requestParser = <T>(result: AxiosResponse<T>) => result.data;

const errorHandler = <T>(error: AxiosError<T>) => {
	const {response} = error;
	if (response && (response.status >= 400 && response.status < 500)) {
		appConfig.token.length && dispatch(setIsExpired());
	} else {
		return Promise.reject(error.response || 'Something went wrong');
	}
};

http.interceptors.response.use(requestParser, errorHandler);
http.interceptors.request.use((request) => request, errorHandler);

export default http;
