import React, {ReactNode} from 'react';
import {makeStyles} from '@material-ui/styles';
import {IconButton, Typography} from 'src/components/@shared';
import {ReactComponent as ArrowRightIcon} from 'src/assets/svg/arrow-right-purple.svg';
import {ReactComponent as ArrowRightRedIcon} from 'src/assets/svg/arrow-right-red.svg';
import clsx from 'clsx';
import theme from 'src/theme';

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		alignSelf: 'center'
	},
	content: {
		marginTop: 4
	},
	primaryText: {
		textAlign: 'right',
		textTransform: 'capitalize',
		whiteSpace: 'nowrap'
	},
	secondaryItemContainer: {
		display: 'flex',
		justifyContent: 'flex-end'
	},
	secondaryText: {
		marginTop: 8,
		textAlign: 'right',
		color: theme.colors.black50
	},
	arrowButton: {
		opacity: 1,
		alignSelf: 'center',
		'&:hover': {
			transition: 'all 0.15s ease-out',
			transform: 'scale(1.1)'
		}
	},
	redButton: {
		marginLeft: 12
	}
}));

interface Props {
	primaryClassName?: string;
	primary: string;
	secondary?: ReactNode;
	isArrowRed?: boolean;
}

export const RightAdornment = ({primaryClassName, primary, secondary, isArrowRed = false}: Props) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<div className={classes.content}>
				<Typography className={clsx([classes.primaryText, primaryClassName])} size={16} lineHeight={16}>
					{primary}
				</Typography>
				{secondary && (
					<div className={classes.secondaryItemContainer}>
						<Typography className={classes.secondaryText} lineHeight={14} color="black50">
							{secondary}
						</Typography>
					</div>
				)}
			</div>
			<IconButton className={clsx({[classes.arrowButton]: true, [classes.redButton]: isArrowRed})}>
				{isArrowRed ? <ArrowRightRedIcon /> : <ArrowRightIcon />}
			</IconButton>
		</div>
	);
};
