import {makeStyles} from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		height: 550
	},
	title: {
		justifyContent: 'flex-start'
	},
	logo: {
		marginBottom: 34
	}
}));
