import React, {ComponentType, useCallback, useState} from 'react';
import Select, {
	components,
	ControlProps,
	OptionProps,
	DropdownIndicatorProps,
	GroupBase,
	SingleValue,
	StylesConfig
} from 'react-select';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';

import {DropdownIndicator} from 'src/components/@shared/Dropdown/DropdownIndicator';

import theme from 'src/theme';
import {OptionI} from 'src/components/@shared/Dropdown/types/index';

const useStyles = makeStyles(() => ({
	wrapper: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column'
	},
	formFieldLabel: {
		display: 'block',
		fontSize: 16,
		fontFamily: 'Sofia Pro',
		color: theme.colors.black50,
		margin: 0,
		paddingTop: 18,
		position: 'absolute',
		top: 0,
		transition: 'all 0.4s'
	},
	formFieldLabelActive: {
		fontSize: '0.75rem',
		padding: 0,
		transition: 'all 0.4s'
	},
	formFieldLabelFocused: {
		color: theme.colors.purple
	},
	option: {
		textAlign: 'left',
		alignItems: 'center',
		display: 'flex',
		color: theme.colors.black,
		cursor: 'pointer',
		fontFamily: 'Sofia Pro',
		padding: 18,
		fontSize: 16,
		fontWidth: 400,
		backgroundColor: 'transparent',
		'&:hover': {
			background: theme.colors.lightPurple
		}
	},
	optionActive: {
		background: theme.colors.lightPurple
	},
	customControl: {
		display: 'flex',
		width: '100%',
		alignItems: 'center'
	},
	icon: {
		height: 21,
		width: 32,
		marginRight: 15,
		borderRadius: 4
	},
	iconCustom: {
		height: 32
	},
	endAdorment: {
		justifySelf: 'end',
		color: theme.colors.black50,
		marginLeft: 'auto'
	}
}));

const getSelectStyles = (name: string | undefined) => ({
	control: (provided: React.CSSProperties, state: {isSelected: boolean; menuIsOpen: boolean}) => ({
		...provided,
		display: 'flex',
		border: 0,
		color: theme.colors.black,
		background: 'transparent',
		boxShadow: 'none',
		borderBottom: `1px solid ${state.menuIsOpen ? theme.colors.purple : theme.colors.black20}`,
		fontSize: 16,
		fontFamily: theme.fonts.primary,
		borderRadius: 0,
		'&:hover': {
			cursor: 'pointer'
		},
		transition: 'all 0.4s',
		padding: '22px 0 4px 0',
		minHeight: 'none'
	}),
	input: (provided: React.CSSProperties) => ({
		...provided,
		fontWeight: 400,
		margin: 0,
		padding: 0,
		color: theme.colors.black
	}),
	indicatorSeparator: (provided: React.CSSProperties) => ({
		...provided,
		display: 'none'
	}),
	menu: (provided: React.CSSProperties) => ({
		...provided,
		top: 52,
		boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.15)',
		borderRadius: 4,
		backgroundColor: theme.colors.white,
		minWidth: 192,
		marginTop: name === 'state' ? '-2px' : '8px'
	}),
	menuList: (provided: React.CSSProperties) => ({
		'&': {
			...provided,
			maxHeight: 260,
			padding: 0
		},
		'&::-webkit-scrollbar-track': {
			backgroundColor: theme.colors.purple15
		},
		'&::-webkit-scrollbar': {
			width: '4px',
			backgroundColor: theme.colors.purple15
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.colors.purple
		}
	}),
	indicatorsContainer: (provided: React.CSSProperties, state: {selectProps: {menuIsOpen: boolean}}) => ({
		'& div': {
			...provided,
			padding: 0
		},
		'& div:first-child': {
			display: 'none'
		},
		'& div:last-child': {
			transform: state.selectProps.menuIsOpen ? 'initial' : 'rotate(180deg)',
			'& > svg path': {
				fill: state.selectProps.menuIsOpen ? theme.colors.purple : theme.colors.black50
			}
		}
	}),
	valueContainer: (provided: React.CSSProperties) => ({
		...provided,
		padding: 0
	}),
	singleValue: (provided: React.CSSProperties) => ({
		...provided,
		color: theme.colors.black
	}),
	noOptionsMessage: (provided: React.CSSProperties) => ({
		...provided,
		cursor: 'pointer',
		fontFamily: 'Sofia Pro',
		padding: 18,
		fontSize: 16,
		fontWidth: 400,
		color: theme.colors.black50
	})
});

export interface DropdownI {
	onChange: (newValue: SingleValue<OptionI>) => void;
	label?: string;
	name?: string;
	className?: string;
	options: Array<OptionI>;
	value?: OptionI | null;
	placeholder?: string;
	isDisabled?: boolean;
	styles?: StylesConfig<OptionI, false, GroupBase<OptionI>>;
	hasEndAdorment?: boolean;
}

export const Dropdown = ({
	className,
	label,
	onChange,
	options,
	value,
	name,
	styles,
	placeholder,
	isDisabled,
	hasEndAdorment
}: DropdownI): JSX.Element => {
	const [focused, setFocused] = useState<boolean>(false);
	const classes = useStyles();

	const getIndicator = (params: React.PropsWithChildren<DropdownIndicatorProps<OptionI, false, GroupBase<OptionI>>>) =>
		DropdownIndicator({defaultProps: params});

	const getOption = (params: OptionProps<OptionI>) => {
		const optionClass = clsx(classes.option, (params.isSelected || params.isFocused) && classes.optionActive);
		// temporary. TODO: remove library and add all flags as svg at /images
		const iconClass = clsx(
			classes.icon,
			(params.data.value === 'US' || params.data.value === 'UM' || params.data.value === 'GB') && classes.iconCustom
		);

		const clickableProps = {
			role: 'menuitem',
			onKeyPress: params.innerProps.onKeyPress,
			onClick: params.innerProps.onClick
		};

		return (
			<div {...clickableProps} className={optionClass}>
				{params.data.icon && (
					<img className={iconClass} src={params.data.icon} alt={params.data.value} {...clickableProps} />
				)}
				<span {...clickableProps}>{params.data.label}</span>
				{hasEndAdorment && (
					<div className={classes.endAdorment} {...clickableProps}>
						{params.data.value}
					</div>
				)}
			</div>
		);
	};

	const getControl = useCallback(
		(params: ControlProps<OptionI, false>) => {
			const iconClass = clsx(
				classes.icon,
				(value?.value === 'US' || value?.value === 'UM' || value?.value === 'GB') && classes.iconCustom
			);

			setFocused(params.isFocused);
			return (
				<components.Control {...params} className={classes.customControl}>
					{value?.icon && <img className={iconClass} src={value?.icon} alt={value?.value} />}
					{params.children}
				</components.Control>
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[value]
	);

	return (
		<div className={clsx(classes.wrapper, className)}>
			<label
				htmlFor={label}
				className={clsx({
					[classes.formFieldLabel]: true,
					[classes.formFieldLabelActive]: focused || Boolean(value),
					[classes.formFieldLabelFocused]: focused
				})}>
				{label}
			</label>
			<Select
				components={{
					DropdownIndicator: getIndicator,
					Option: getOption as ComponentType<OptionProps<OptionI, false, GroupBase<OptionI>>>,
					Control: getControl
				}}
				styles={
					styles || (getSelectStyles(name) as unknown as StylesConfig<OptionI, false, GroupBase<OptionI>> | undefined)
				}
				options={options}
				name={name}
				value={value}
				onChange={(event) => {
					if (event?.value) {
						onChange(event);
					}
				}}
				isClearable
				placeholder={placeholder || ''}
				isDisabled={isDisabled}
				noOptionsMessage={() => 'No Options'}
			/>
		</div>
	);
};
