import {makeStyles} from '@material-ui/styles';

import theme from 'src/theme';

export const useStyles = makeStyles(() => ({
	root: {
		margin: 0,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginBottom: 30
	},
	caption: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginTop: 16,
		fontFamily: 'Sofia Pro'
	},
	title: {
		fontWeight: 400
	},
	subtitle: {
		fontSize: 12,
		color: theme.colors.black50,
		display: 'inline-block',
		marginTop: 8
	},
	imageBox: {
		width: '246px',
		height: '246px',
		filter: 'drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25))',
		borderRadius: 10,
		position: 'relative'
	},
	image: {
		maxWidth: '246px',
		maxHeight: '246px',
		position: 'absolute',
		top: '50%',
		right: '50%',
		transform: 'translate(50%, -50%)',
		objectFit: 'contain',
		borderRadius: 10
	}
}));
