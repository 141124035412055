import {CardDetails, CardType, ExternalSpace, ExternalSpaceType} from 'src/api';

export enum PaymentMethod {
	CreditCard = 'CreditCard',
	DebitCard = 'DebitCard',
	BankAccount = 'BankAccount'
}

const cardTypeToPaymentMethodMap = {
	[CardType.Credit]: PaymentMethod.CreditCard,
	[CardType.Debit]: PaymentMethod.DebitCard
};

const accountTypesToPaymentMethodsMap = {
	[ExternalSpaceType.Card]: (externalAccount: ExternalSpace) => {
		const {cardType} = externalAccount.details as CardDetails;
		if (!cardType) throw new Error('Wrong externalAccount type');
		return cardTypeToPaymentMethodMap[cardType];
	},
	[ExternalSpaceType.BravaPayCard]: (externalAccount: ExternalSpace) => {
		const {cardType} = externalAccount.details as CardDetails;
		if (!cardType) throw new Error('Wrong externalAccount type');
		return cardTypeToPaymentMethodMap[cardType];
	},
	[ExternalSpaceType.Bank]: () => PaymentMethod.BankAccount
};

export const getPaymentMethod = (externalAccount: ExternalSpace): PaymentMethod =>
	accountTypesToPaymentMethodsMap[externalAccount.accountType](externalAccount);
