import React, {FC, useState} from 'react';
import NumberFormat from 'react-number-format';
import {ReactComponent as VisaCardAvatar} from 'src/assets/svg/visaLogo.svg';
import {ReactComponent as MastercardAvatar} from 'src/assets/svg/mastercardLogo.svg';
import {ReactComponent as AEAvatar} from 'src/assets/svg/aeLogo.svg';
import {ReactComponent as CardAvatar} from 'src/assets/svg/cardLogo.svg';
import {TextField} from 'src/components/@shared';
import {makeStyles} from '@material-ui/styles';
import {CustomInputProps, NumberFieldPropsBase} from 'src/types';

type CardValidationData = {
	format: string;
	validate: RegExp;
	name: string;
	icon?: any;
};

const CustomInput: FC<CustomInputProps> = ({...props}) => <TextField type="number" {...props} />;

export interface CardNumberFieldProps extends NumberFieldPropsBase {
	onChange?: (cardNumber: string) => void;
	showCardIcon?: boolean;
}

const useStyles = makeStyles(() => ({
	iconWrapper: {
		width: 16,
		height: 16
	}
}));

const iconSize = {
	height: '100%',
	width: '100%'
};

// TD: it should be migrated to utils
const cardValidationDataList: CardValidationData[] = [
	{
		format: '#### #### #### #### ####',
		validate: /^\D/,
		name: '',
		icon: <CardAvatar {...iconSize} />
	},
	{
		format: '#### #### #### #### ####',
		validate: /^35\d*/,
		name: 'JCB',
		icon: <CardAvatar {...iconSize} />
	},
	{
		format: '#### #### #### #### ####',
		validate: /^36\d*/,
		name: 'Diners Club',
		icon: <CardAvatar {...iconSize} />
	},
	{
		format: '#### ###### #####',
		validate: /^37\d*/,
		name: 'American Express',
		icon: <AEAvatar {...iconSize} />
	},
	{
		format: '#### #### #### ####',
		validate: /^4\d*/,
		name: 'Visa',
		icon: <VisaCardAvatar {...iconSize} />
	},
	{
		format: '#### #### #### ####',
		validate: /^5\d*/,
		name: 'MasterCard',
		icon: <MastercardAvatar {...iconSize} />
	},
	{
		format: '#### #### #### #### ####',
		validate: /^62217\d*/,
		name: 'LivaKash',
		icon: <CardAvatar {...iconSize} />
	},
	{
		format: '#### #### #### #### ####',
		validate: /^91\d*/,
		name: 'IsraCard',
		icon: <CardAvatar {...iconSize} />
	}
];

const formatCard = (val: string, format: string) => {
	let newValue = '';
	for (let i = 0, j = 0; i < val.length; i += 1, j += 1) {
		if (format[j] === '#') {
			newValue += val[i];
		}
		if (format[j] === ' ') {
			newValue += format[j] + val[i];
			j += 1;
		}
	}
	return newValue;
};

export const CardNumberField: FC<CardNumberFieldProps> = ({
	value,
	onChange,
	label = 'Card Number',
	fullWidth = true,
	showCardIcon = true,
	...props
}) => {
	const [cardValidationData, setCardValidationData] = useState<CardValidationData>(cardValidationDataList[0]);
	const classes = useStyles();

	const selectCardType = (inputValue: string) =>
		setCardValidationData(
			cardValidationDataList.find((el) => el.validate.test(inputValue)) || cardValidationDataList[0]
		);

	return (
		<NumberFormat
			{...props}
			format={(val) => formatCard(val, cardValidationData.format)}
			customInput={CustomInput}
			startAdorment={showCardIcon ? <div className={classes.iconWrapper}>{cardValidationData.icon}</div> : null}
			defaultValue={value}
			label={label}
			fullWidth={fullWidth}
			onValueChange={(values) => {
				selectCardType(values.value);
				if (onChange) {
					onChange(values.value);
				}
			}}
		/>
	);
};
