const screenContent = {
	display: 'flex',
	justifyContent: 'start',
	alignItems: 'center',
	flexDirection: 'column' as const
};

const screenTitle = {
	'& div': {
		fontSize: 20
	}
};

const screenSubtitle = {
	fontSize: 16,
	fontWeight: 400,
	textDecoration: 'underline'
};

const flexCentered = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center'
};

export const S = {
	screenContent,
	flexCentered,
	screenTitle,
	screenSubtitle
};
