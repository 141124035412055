import React from 'react';
import clsx from 'clsx';
import {Spinner} from 'src/components/@shared';
import {formatInUSD} from 'src/utils';
import {BanqAccount, ExternalSpace} from 'src/api';
import {getIsNotBalanceYet} from 'src/utils/getIsNotBalanceYet';
import {makeStyles} from '@material-ui/styles';
import theme from 'src/theme';
import {PaymentMethodAdornment, RightAdornment} from 'src/components';

const useStyles = makeStyles(() => ({
	primaryText: {
		color: theme.colors.red,
		[theme.breakpoints.down.sm]: {
			width: 140,
			whiteSpace: 'normal',
			lineHeight: '20px'
		}
	},
	primaryTextPurple: {
		color: theme.colors.purpleLedger
	}
}));

interface Props {
	externalAccount: ExternalSpace;
	banqAccount: BanqAccount;
	isBalanceLoading: boolean;
	areLinkedCreditCards: boolean;
	isNoRelinkedCard: boolean;
}

export const PaymentAccountsAdornment = ({
	externalAccount,
	banqAccount,
	isBalanceLoading,
	areLinkedCreditCards,
	isNoRelinkedCard
}: Props) => {
	const classes = useStyles();
	const isExternalAccountExists = Boolean(externalAccount?.externalAccountId);
	const balance = banqAccount?.balance;

	const isNoBalanceYet = getIsNotBalanceYet(Boolean(externalAccount?.externalAccountId), balance);

	if (isNoBalanceYet || (areLinkedCreditCards && isNoRelinkedCard))
		return <RightAdornment primaryClassName={classes.primaryText} isArrowRed primary="Add a Payment Method" />;

	if (!areLinkedCreditCards)
		return (
			<RightAdornment primaryClassName={clsx(classes.primaryText, classes.primaryTextPurple)} primary="Link card" />
		);

	if (isExternalAccountExists) return <PaymentMethodAdornment externalAccount={externalAccount} />;

	return (
		<RightAdornment
			primary="USD Balance"
			secondary={isBalanceLoading ? <Spinner size={20} /> : formatInUSD(balance || 0)}
		/>
	);
};
