import omit from 'lodash/omit';
import {Actions} from 'src/store/constants';
import {ClearSuccessAction, RemoveSuccessAction, SetSuccessAction} from './actions';
import {endsWithError, endsWithPending, endsWithSuccess} from '../utils';
import {PayloadAction} from '../types';

type SuccessActions = PayloadAction<Actions, string> | SetSuccessAction | RemoveSuccessAction | ClearSuccessAction;

const initialState: Record<string, unknown> = {};

const messagesReducer = (state = initialState, action: SuccessActions) => {
	switch (action.type) {
		case Actions.SET_SUCCESS:
			return {
				...state,
				[(action as SetSuccessAction).payload.key]: (action as SetSuccessAction).payload.message
			};

		case Actions.REMOVE_SUCCESS:
			return omit(state, action.payload);

		case Actions.CLEAR_SUCCESS:
			return initialState;
	}

	const name = action?.success;

	if (!name) return state;

	if (endsWithSuccess(action.type)) {
		return {...state, [name]: true};
	}

	if (endsWithPending(action.type)) {
		return omit(state, name);
	}

	if (endsWithError(action.type)) {
		return omit(state, name);
	}
	return state;
};

export default messagesReducer;
