import React from 'react';
import {useSelector} from 'react-redux';

import {useStyles} from './NFTimageBox.styles';
import {ReactComponent as DefaultMediaIcon} from 'src/assets/svg/nftMediaError.svg';
import {selectNftMedia} from 'src/store/nft/selectors';
import {getType} from './utils';

export const NFTimageBox = () => {
	const classes = useStyles();
	const {name, mediaType, creatorName, urlImage, isError} = useSelector(selectNftMedia);

	const subtitle = `${getType(mediaType)}${creatorName ? ' by ' + creatorName : ''}`;
	const nftImage = urlImage ? (
		<div className={classes.imageBox}>
			<img src={urlImage} alt="NFT" className={classes.image} />
		</div>
	) : (
		<DefaultMediaIcon />
	);

	return (
		<figure className={classes.root}>
			{isError ? (
				<DefaultMediaIcon />
			) : (
				<>
					{nftImage}
					<figcaption className={classes.caption}>
						{name && <span className={classes.title}>{name}</span>}
						<span className={classes.subtitle}>{subtitle}</span>
					</figcaption>
				</>
			)}
		</figure>
	);
};
