import {combineReducers} from 'redux';
import banqsReducer from 'src/store/banqs/reducer';
import externalAccountsReducer from 'src/store/externalAccounts/reducer';
import messagesReducer from './@success/reducer';
import loadingsReducer from 'src/store/@loadings/reducer';
import paymentReducer from 'src/store/payment/reducer';
import nftReducer from 'src/store/nft/reducer';
import errorsReducer from './@errors/reducer';
import spacesReducer from 'src/store/spaces/reducer';
import feesReducer from 'src/store/cardFees/reducer';
import securityReducer from 'src/store/3ds/reducer';

const reducer = combineReducers({
	loadings: loadingsReducer,
	payment: paymentReducer,
	errors: errorsReducer,
	banqs: banqsReducer,
	externalSpaces: externalAccountsReducer,
	messages: messagesReducer,
	spaces: spacesReducer,
	nft: nftReducer,
	fees: feesReducer,
	security: securityReducer
});

export type State = ReturnType<typeof reducer>;

export default reducer;
