import {createSelector} from 'reselect';
import isEmpty from 'lodash/isEmpty';
import {BanqAccount} from 'src/api';
import {State} from 'src/store/reducer';
import {createBankListSelector, createCreditCardListSelector} from 'src/store/externalAccounts/selectors';
import {createBanqSelector} from 'src/store/banqs/selectors';

export const createIsUserWithoutPaymentOptionsSelector = (sourceBanqId: number) =>
	createSelector(
		(state: State) => state as State,
		(state: State) => createBanqSelector(sourceBanqId)(state),
		(state: State, sourceBanq?: BanqAccount) => {
			const externalSpacesCards = createCreditCardListSelector(sourceBanq?.accountSpaceId)(state);
			const externalSpacesBanks = createBankListSelector(sourceBanq?.accountSpaceId)(state);
			return isEmpty(externalSpacesBanks) && isEmpty(externalSpacesCards) && !sourceBanq?.balance;
		}
	);
