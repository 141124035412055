import React, {MouseEventHandler} from 'react';

import {Button, Typography} from 'src/components/@shared';
import {useStyles} from './DialogConfirm.styles';

interface PropsI {
	onClose: MouseEventHandler<HTMLButtonElement>;
	onConfirm: MouseEventHandler<HTMLButtonElement>;
}

export const DialogConfirm = ({onClose, onConfirm}: PropsI) => {
	const classes = useStyles();
	return (
		<div className={classes.cover}>
			<div className={classes.container}>
				<Typography size={20} lineHeight={28}>
					Are your sure you want to decline this transfer?
				</Typography>
				<Typography size={16} lineHeight={16} color="black50" className={classes.message}>
					The other person will be notified.
				</Typography>
				<Button fullWidth variant="contained" className={classes.button} gradient="popUpButton" onClick={onConfirm}>
					Yes, Decline
				</Button>
				<Button fullWidth className={classes.buttonCancel} onClick={onClose}>
					Never Mind
				</Button>
			</div>
		</div>
	);
};
