import {all, put, takeEvery} from 'redux-saga/effects';
import {setSuccess} from '../@success/actions';
import {setError} from '../@errors/actions';
import {Actions, Success, Errors} from '../constants';
import {AddCardSuccessAction} from './action';

function* AddCardSuccessSaga(action: AddCardSuccessAction) {
	if (!action.payload?.isActive && action.payload?.details?.d3RedirectUrl) {
		yield put({
			type: Actions.SET_TOGGLE_3DS,
			payload: action.payload?.details.d3RedirectUrl
		});
	} else {
		yield put(setSuccess(Success.snackbar, 'Your new card was successfully added!'));
		yield put(setSuccess(Success.isCardAddedSuccessfully, true));
	}
}

function* AddCardErrorSaga(action: AddCardSuccessAction) {
	yield put(setError(Errors.snackbar, 'Something went wrong'));
	yield put(setError(Errors.isAddCardError, true));
}

export default function* cardSaga() {
	yield all([
		takeEvery(Success.isCardAddedSuccessfully, AddCardSuccessSaga),
		takeEvery(Errors.isAddCardError, AddCardErrorSaga)
	]);
}
