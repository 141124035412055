import {BanqAccount, Level, Space, SpaceType} from 'src/api';
import {createSelector} from 'reselect';
import {isEmpty} from 'lodash';
import {State} from '../reducer';
import {createBanqSelector} from '../banqs/selectors';

export const selectSpaces = (state: State) => state.spaces;

export const selectSpacesList = createSelector(
	(state: State) => Object.values(state.spaces),
	(state: State) => Object.values(state.banqs),
	(spacesList, banqsList) =>
		spacesList.map((space) => ({
			...space,
			banqAccounts: banqsList.filter((banq) => banq.accountSpaceId === space.id)
		}))
);

export const selectIsSingleBanq = createSelector(selectSpacesList, (spacesList) => {
	if (isEmpty(spacesList) || isEmpty(spacesList?.[0]?.banqAccounts)) return null;
	return Boolean(spacesList.length === 1 && spacesList[0]?.banqAccounts?.length === 1);
});

export const selectIsSingleBanqWithEmptyBalance = createSelector(
	(state: State) => selectSpacesList(state),
	(state: State) => selectIsSingleBanq(state),
	(spacesList, isSingleBanq) => Boolean(isSingleBanq && spacesList[0]?.banqAccounts?.[0]?.balance === 0)
);

export const selectIsASingleBanqOnHoldStatus = createSelector(
	(state: State) => selectSpacesList(state),
	(state: State) => selectIsSingleBanq(state),
	(spacesList, isSingleBanq) => Boolean(isSingleBanq && spacesList[0]?.isOnHold)
);

export const selectPersonalSpaces = createSelector(selectSpacesList, (spacesList) =>
	spacesList.filter((space) => space.type.includes(SpaceType.Personal))
);
export const selectBusinessSpaces = createSelector(selectSpacesList, (spacesList) =>
	spacesList.filter((space) => space.type.includes(SpaceType.Merchant))
);

export const createSpaceByBanqIdSelector = (banqId: number) =>
	createSelector(createBanqSelector(banqId), selectSpacesList, (banq: BanqAccount, spacesList: Space[]) =>
		spacesList.find((space) => space.id === banq.accountSpaceId)
	);

export const createIsLimitedSpaceSelector = (banqId: number) =>
	createSelector(createSpaceByBanqIdSelector(banqId), (accountSpace) => accountSpace?.level === Level.L0);

export const selectSpaceById = (spaceId: number) =>
	createSelector(selectSpacesList, (spacesList: Space[]) => spacesList.find((space) => space.id === spaceId));
