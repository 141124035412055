import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {CardDetails, ExternalSpace, PaymentSystem, BanqAccount, ExternalSpaceType} from 'src/api';
import {Avatar, RadioButton} from 'src/components/@shared';
import {mapPaymentSystemToAvatar, mapCardToCardName} from 'src/utils';
import {PaymentListItem} from './PaymentListItem';

const useStyles = makeStyles(() => ({
	rightMargin: {
		marginRight: 17
	}
}));

interface Props {
	items: ExternalSpace[];
	banq: BanqAccount;
	selectedExternalAccountId: number;
	onSelect: (card: ExternalSpace) => void;
	defaultAccountId: number;
	shouldShowDefault?: boolean;
	creditCardFee?: string;
	debitCardFee?: string;
}

export const CreditCardsList = ({
	items,
	selectedExternalAccountId,
	onSelect,
	shouldShowDefault = true,
	creditCardFee,
	debitCardFee,
	defaultAccountId
}: Props) => {
	const classes = useStyles();

	return (
		<>
			{items.map(
				(card) =>
					card.accountType === ExternalSpaceType.BravaPayCard && (
						<div key={card.externalAccountId}>
							<PaymentListItem
								onClick={() => onSelect(card)}
								radioButton={
									<RadioButton
										id="id"
										name="paymentOptions"
										value={card.externalAccountId}
										checked={
											card.accountType === ExternalSpaceType.BravaPayCard &&
											card.externalAccountId === Number(selectedExternalAccountId)
										}
										className={classes.rightMargin}
									/>
								}
								primary={
									mapCardToCardName[(card.details as CardDetails).paymentSystem as PaymentSystem] ||
									mapCardToCardName[PaymentSystem.Other]
								}
								secondary={`...${card.last4}`}
								avatar={
									<Avatar
										size={32}
										src={
											mapPaymentSystemToAvatar[(card.details as CardDetails).paymentSystem as PaymentSystem] ||
											mapPaymentSystemToAvatar[PaymentSystem.Other]
										}
									/>
								}
								amountFee={(card.details as CardDetails).cardType === 'credit' ? creditCardFee : debitCardFee}
								isDefault={shouldShowDefault && card.externalAccountId === defaultAccountId}
							/>
						</div>
					)
			)}
		</>
	);
};
