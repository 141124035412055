/* eslint-disable react/jsx-props-no-spreading */
import React, {InputHTMLAttributes, useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';
import theme from 'src/theme';

interface StyleProps {
	fullWidth?: boolean;
	isActive?: boolean;
	isError?: boolean;
	type?: string;
	startAdormentWidth?: number;
}

const useStyles = makeStyles(() => ({
	root: ({fullWidth, isActive, isError, startAdormentWidth}: StyleProps) => ({
		display: 'block',
		filter: 'none',
		border: 0,
		outline: 'none',
		color: theme.colors.black,
		background: theme.colors.white,
		borderBottom: `1px solid ${
			(isError && theme.colors.red) || (isActive ? theme.colors.purple : theme.colors.black20)
		}`,
		paddingTop: 22,
		paddingLeft: startAdormentWidth ? startAdormentWidth + 5 : 0,
		fontSize: 16,
		fontFamily: theme.fonts.primary,
		borderRadius: 0,
		'&:hover': {
			cursor: 'pointer'
		},
		paddingBottom: 4,
		width: fullWidth ? '100%' : 'auto',
		paddingRight: 20
	}),
	autoFillRoot: {
		'&:-webkit-autofill': {
			transitionDelay: '9999s',
			transitionProperty: 'background-color, color',
			animation: '$onAutoFillStart 1s linear'
		},
		'&::-webkit-credentials-auto-fill-button': {
			margin: 0,
			width: 0,
			backgroundColor: 'transparent'
		}
	},
	formField: {
		display: 'block',
		marginBottom: 22
	},
	formFieldLabel: {
		display: 'block',
		fontSize: 16,
		fontFamily: 'Sofia Pro',
		color: theme.colors.black50,
		left: 0,
		margin: 0,
		padding: '18px 0',
		position: 'absolute',
		top: 0,
		transition: 'all 0.4s',
		width: '100%'
	},
	formFieldLabelActive: ({isActive}: StyleProps) => ({
		fontSize: '0.75rem',
		padding: 0,
		transition: 'all 0.4s',
		color: isActive ? theme.colors.purple : theme.colors.black50
	}),
	formFieldLabelFilled: {
		fontSize: '0.75rem',
		padding: 0,
		transition: 'all 0.4s'
	},
	formFieldControl: {
		overflow: 'hidden',
		position: 'relative',
		width: '100%'
	},
	errorMessage: {
		color: `${theme.colors.red} !important`
	},
	helperMessage: {
		'&:first-letter': {
			textTransform: 'uppercase'
		},
		fontFamily: theme.fonts.primary,
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '12px',
		lineHeight: '16px',
		color: theme.colors.dustyGray
	},
	inputWrapper: {
		position: 'relative',
		display: 'flex'
	},
	iconButton: {
		position: 'absolute',
		top: '50%',
		right: '0%'
	},
	startAdorment: {
		position: 'absolute',
		top: 25,
		left: 0
	},
	'@keyframes onAutoFillStart': {},
	'@keyframes onAutoFillCancel': {}
}));

export interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
	fullWidth?: boolean;
	error?: boolean;
	helperText?: string;
	endAdornment?: React.ReactNode;
	startAdorment?: React.ReactNode;
	label: string;
}

export const TextField = ({
	fullWidth,
	label,
	error,
	helperText,
	endAdornment,
	startAdorment,
	...props
}: TextFieldProps) => {
	const [formIsActive, setFormIsActive] = useState(false);
	const [formIsFilled, setFormIsFilled] = useState(false);
	const [formIsAutoFilled, setFormIsAutoFilled] = useState(false);
	const [startAdormentWidth, setLeftPadding] = useState(0);
	const startAdormentRef = useRef<HTMLDivElement>(null);
	const classes = useStyles({
		fullWidth,
		isActive: formIsActive,
		isError: Boolean(error),
		startAdormentWidth
	});

	const setActive = (elValue: string, active: boolean): void => {
		if (active) {
			setFormIsActive(true);
			return;
		}
		if (formIsAutoFilled && !elValue) {
			setFormIsAutoFilled(false);
		}
		setFormIsActive(false);
		setFormIsFilled(Boolean(elValue));
	};

	useEffect(() => {
		setLeftPadding(startAdormentRef.current?.offsetWidth || 0);
	}, [startAdorment, formIsActive]);

	return (
		<div className={classes.formField}>
			<div className={classes.formFieldControl}>
				<div className={classes.inputWrapper}>
					<label
						htmlFor={label}
						className={clsx({
							[classes.formFieldLabel]: true,
							[classes.formFieldLabelActive]: formIsActive || Boolean(props.value),
							[classes.formFieldLabelFilled]: formIsFilled || formIsAutoFilled
						})}>
						{label}
					</label>
					{(formIsActive || formIsFilled) && startAdorment && (
						<div ref={startAdormentRef} className={classes.startAdorment}>
							{startAdorment}
						</div>
					)}
					<input
						id={label}
						{...props}
						type={props.type}
						onAnimationStart={() => setFormIsAutoFilled(true)}
						className={clsx({
							[classes.root]: true,
							[classes.autoFillRoot]: true
						})}
						onBlur={(event) => setActive(event?.target?.value, false)}
						onFocus={(event) => {
							// we need this to set cursor after the last character in the field
							event.currentTarget.setSelectionRange(event.currentTarget.value.length, event.currentTarget.value.length);
							setActive(event?.target?.value, true);
						}}
					/>
					{endAdornment}
				</div>
				{helperText && (
					<p
						className={clsx({
							[classes.helperMessage]: true,
							[classes.errorMessage]: error
						})}>
						{helperText}
					</p>
				)}
			</div>
		</div>
	);
};
