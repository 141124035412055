import {Actions, Errors, Loadings, Success} from 'src/store/constants';
import {LoadAction, PayloadAction} from 'src/store/types';
import api, {BanqAccount} from 'src/api';

export type SetBanqsAction = PayloadAction<Actions.SET_BANQS, BanqAccount[]>;
export type LoadBanqByIdAction = LoadAction<Actions.LOAD_BANQ>;
export type LoadBanqSuccessAction = PayloadAction<Actions.LOAD_BANQ_SUCCESS, BanqAccount>;

export const setBanqs = (banqs: BanqAccount[]): SetBanqsAction => ({
	type: Actions.SET_BANQS,
	payload: banqs
});

export const loadBanqById = (banqId: number): LoadBanqByIdAction => ({
	type: Actions.LOAD_BANQ,
	load: api.banqs.loadByBanqId(banqId),
	loading: Loadings.isBanqByIdLoading,
	error: Errors.loadBanqByIdError,
	success: Success.loadBanqByGuidSuccessful
});
