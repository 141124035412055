import http from './http';
import {AddCardBody, AddCardResponse, ExternalSpace} from './types';

export type AddCheckingAccountRequest = {
	plaidAccountId: number;
	plaidPublicToken: string;
};

const externalAccounts = {
	async loadExternalSpaces(accountSpaceId: number): Promise<ExternalSpace[]> {
		const {data} = await http.get(`account-spaces/${accountSpaceId}/external`);
		return data;
	},
	async addCard(accountSpaceId: number, cardBody: AddCardBody): Promise<AddCardResponse> {
		const {data} = await http.post(`/account-spaces/${accountSpaceId}/external/brava-pay`, cardBody);
		return data;
	},
	async deleteCard(externalAccountId: number): Promise<void> {
		await http.delete(`/external-accounts/${externalAccountId}`);
	}
};

export default externalAccounts;
