import {BanqAccount} from 'src/api';
import {Actions} from 'src/store/constants';
import keyBy from 'lodash/keyBy';
import {LoadBanqSuccessAction, SetBanqsAction} from './actions';

export type BanqActions = SetBanqsAction | LoadBanqSuccessAction;

type BanqState = Record<string, BanqAccount>;

const initialState: BanqState = {};

const banqsReducer = (state = initialState, action: BanqActions) => {
	switch (action.type) {
		case Actions.SET_BANQS:
			return {
				...state,
				...keyBy(action.payload, 'id')
			};

		case Actions.LOAD_BANQ_SUCCESS:
			return {
				...state,
				[action.payload.id]: {
					...state[action.payload.id],
					...action.payload
				}
			};
		default:
			return state;
	}
};

export default banqsReducer;
