/**
 * colors names from https://chir.ag/projects/name-that-color
 */

const colors = {
	shiraz: '#B70B4B',
	scarlet: '#F82507',
	seance: '#7622A9',
	black: '#000',
	white: '#FFF',
	mercury: '#E6E6E6',
	dustyGray: '#999999',
	gallery: '#EEEEEE',
	darkModeGallery: '#333333',
	purple15: '#EADEF2',
	purple: '#7622A9',
	lightPurple: '#F1E9F6',
	darkModePurple: '#A132E4',
	red: '#DF2106',
	lightGrey: '#CFCFCF',
	gray29: '#4A4A4A',
	gray89: '#E3E3E3',
	gray98: '#FAFAFA',
	black90: '#1A1A1A',
	black70: '#4D4D4D',
	black60: '#666666',
	black50: '#808080',
	black40: '#999999',
	black30: '#B3B3B3',
	black20: '#CCCCCC',
	nero: '#2A2A2A',
	shadow: 'rgba(0, 0, 0, 0.2)',
	purpleSpinner: 'rgba(107, 9, 165, 0.75)',
	purpleLedger: '#8C0195',
	violetEggplant: '#B512B0',
	whiteSemiTransparent: 'rgba(255, 255, 255, 0.7)'
};

export enum ThemeModes {
	Light = 'light',
	Dark = 'dark'
}

const shadows = {
	v1: '0px 0.5px 0px rgba(0, 0, 0, 0.0536495), 0px -0.5px 0px rgba(255, 255, 255, 0.276606), 0px 5px 15px rgba(0, 0, 0, 0.143985)',
	v1DarkMode: '0px 10px 30px 0px #FFFFFF0F'
};

const fonts = {
	primary: 'Sofia Pro'
};

const weights = {
	light: 300,
	regular: 400,
	medium: 500,
	'semi-bold': 600,
	bold: 700
};

const gradients = {
	primary: `linear-gradient(135deg, ${colors.scarlet} 0%, ${colors.seance} 65.63%)`,
	button: `linear-gradient(90deg, ${colors.scarlet} 0%, ${colors.seance} 69.27%, #7622A9 100%)`,
	purpleBanner: `linear-gradient(135.46deg, #B512B0 11.31%, #770AB8 85.72%)`,
	popUpButton: `linear-gradient(90deg, ${colors.violetEggplant} 0%, ${colors.purple} 100%)`,
	red: `linear-gradient(135.46deg, #F84A00 11.31%, #D80033 85.72%)`,
	dividerDark: `linear-gradient(270deg, transparent 0%, rgba(255, 255, 255, 0.2) 17.26%, rgba(255, 255, 255, 0.2) 82.34%, transparent 99.27%);`,
	dividerLight: `linear-gradient(270deg, transparent 0%, ${colors.gray89} 17.26%, ${colors.gray89} 82.34%, transparent 99.27%)`
};

const breakpoints = {
	up: {
		sm: '@media (min-width: 448px)'
	},
	down: {
		sm: '@media (max-width: 448px)'
	}
};

const theme = {
	colors,
	gradients,
	shadows,
	weights,
	fonts,
	breakpoints
};

export type Colors = typeof colors;
export type Gradients = typeof gradients;
export type Shadows = typeof shadows;
export type Weights = typeof weights;
export type Theme = typeof theme;
export default theme;
