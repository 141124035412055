import {PaymentInfoErrorI, PaymentInfoI, PaymentSuccessI} from 'src/api/types';
import {Loadings} from 'src/store/constants';
import {State} from 'src/store/reducer';

export const selectIsPaymentInfoLoading = (state: State) => state.loadings[Loadings.isPaymentInfoLoading];
export const selectIsPaymentAcceptLoading = (state: State) => state.loadings[Loadings.isPaymentAcceptLoading];
export const selectIsPaymentDeclinedLoading = (state: State) => state.loadings[Loadings.isPaymentDeclineLoading];

export const selectPaymentInfo = (state: State): PaymentInfoI | PaymentInfoErrorI | PaymentSuccessI => state.payment;
export const selectPaymentStatus = (state: State) => state.payment.paymentStatus;
