import {Actions, Errors, Loadings, Success} from 'src/store/constants';
import {LoadAction, PayloadAction} from 'src/store/types';
import api, {AddCardBody, AddCardResponse} from 'src/api';

export type AddCardAction = LoadAction<Actions.ADD_CREDIT_CARD>;
export type AddCardSuccessAction = PayloadAction<Actions.ADD_CREDIT_CARD_SUCCESS, AddCardResponse>;

export const addCard = (accountSpaceId: number, cardBody: AddCardBody): AddCardAction => ({
	type: Actions.ADD_CREDIT_CARD,
	load: api.externalAccounts.addCard(accountSpaceId, cardBody),
	loading: Loadings.isAddCardLoading,
	error: Errors.isAddCardError,
	success: Success.isCardAddedSuccessfully
});
