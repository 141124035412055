import http from './http';
import {BanqAccount} from './types';

const banqs = {
	async loadByBanqId(banqId: number): Promise<BanqAccount> {
		const response = await http.get(`banq-accounts/${banqId}`);
		return response.data;
	}
};

export default banqs;
