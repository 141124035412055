import React, {useState} from 'react';
import {LoaderWithBackdrop} from 'src/components/@shared';

interface SecurityIframeProps {
	src: string;
	width: string;
	height: string;
	onCheckIsPassed: (isPassed: boolean) => void;
	onResolve?: (res: any) => void;
	onReject?: (err: any) => void;
	className?: string;
}

export const SecurityIframe = ({
	src,
	width,
	height,
	onCheckIsPassed,
	onResolve,
	onReject,
	className
}: SecurityIframeProps) => {
	const [timesRefreshed, setTimesRefreshed] = useState<number>(0);
	const iframeRef = React.useRef<HTMLIFrameElement>(null);

	const handleIframeLoaded = (event: React.SyntheticEvent<HTMLIFrameElement, Event>) => {
		if (
			timesRefreshed > 0 &&
			(event?.target as HTMLIFrameElement)?.contentWindow?.location?.hostname === window.location.hostname
		) {
			onCheckIsPassed(true);
		}
		setTimesRefreshed(timesRefreshed + 1);
	};

	return (
		<>
			{timesRefreshed !== 1 && <LoaderWithBackdrop isLoading />}
			<iframe
				onLoad={(event) => handleIframeLoaded(event)}
				id="securityIframe"
				ref={iframeRef}
				frameBorder="0"
				className={className}
				title="Security"
				width={width}
				height={height}
				src={src}
			/>
		</>
	);
};
