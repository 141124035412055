import http from './http';
import {appConfig} from './appConfig';

export const payment = {
	getInfo() {
		return http.get(`/payments/${appConfig.paymentGuid}`);
	},
	accept(externalAccountId?: number) {
		return http.patch(`/nft-transactions/${appConfig.paymentGuid}/accept/`, {
			externalAccountId: externalAccountId ?? null
		});
	},
	decline() {
		return http.patch(`/nft-transactions/${appConfig.paymentGuid}/decline/`);
	}
};
