import * as React from 'react';
import {components, DropdownIndicatorProps, GroupBase} from 'react-select';

import {OptionI} from 'src/components/@shared/Dropdown/types';
import {ReactComponent as CaretIcon} from 'src/assets/svg/caret.svg';

interface DropdownIndicatorI {
	defaultProps: React.PropsWithChildren<DropdownIndicatorProps<OptionI, false, GroupBase<OptionI>>>;
}

export const DropdownIndicator = ({defaultProps}: DropdownIndicatorI) => (
	<components.DropdownIndicator {...defaultProps}>
		<CaretIcon />
	</components.DropdownIndicator>
);
