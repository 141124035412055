import {Actions} from 'src/store/constants';
import {Toggle3dsAction} from './actions';

export type SecurityState = {
	securityIframeSrc: string;
};

const initialState: SecurityState = {securityIframeSrc: ''};

const securityReducer = (action: Toggle3dsAction, state = initialState) => {
	switch (action?.type) {
		case Actions.SET_TOGGLE_3DS:
			return {
				...state,
				securityIframeSrc: action.payload
			};

		default:
			return state;
	}
};

export default securityReducer;
