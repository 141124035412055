import React, {cloneElement, ReactElement, useEffect, useState} from 'react';
import {AddPaymentOptionPopUp} from '../addPaymentOptionPopUp/AddPaymentOptionPopUp';

interface Props {
	children: ReactElement;
	onAddDebitCard: () => void;
	onTogglePopUp?: (isPaymentOptionPopUpOpen: boolean) => void;
}
export const AddPaymentOptionAction = ({children, onAddDebitCard, onTogglePopUp}: Props) => {
	const [isPaymentOptionPopUpOpen, setIsPaymentOptionPopUpOpen] = useState(false);

	useEffect(() => {
		onTogglePopUp?.(isPaymentOptionPopUpOpen);
	}, [isPaymentOptionPopUpOpen, onTogglePopUp]);

	return (
		<>
			{isPaymentOptionPopUpOpen && (
				<AddPaymentOptionPopUp onAddDebitCard={onAddDebitCard} onClose={() => setIsPaymentOptionPopUpOpen(false)} />
			)}
			{cloneElement(children, {onClick: () => setIsPaymentOptionPopUpOpen(true)})}
		</>
	);
};
