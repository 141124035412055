import React from 'react';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';
import {Typography} from 'src/components/@shared/Typography';
import theme from 'src/theme';

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		height: '100%',
		flexDirection: 'column',
		justifyContent: 'center',
		flexGrow: 1,
		marginLeft: 8
	},
	listItemTextSubtitle: {
		marginTop: 8,
		display: 'flex'
	},
	listItemTextSubtitleText: {
		marginLeft: 8
	},
	title: {
		color: theme.colors.black
	},
	subtitle: {
		color: theme.colors.black50
	}
}));

interface Props {
	className?: string;
	secondary?: string;
	primary?: string;
}

export const ListItemText = ({className, primary, secondary}: Props) => {
	const classes = useStyles();
	return (
		<div className={clsx(classes.root, className)}>
			{primary && (
				<Typography className={classes.title} size={16} lineHeight={19}>
					{primary}
				</Typography>
			)}
			{secondary && (
				<div className={classes.listItemTextSubtitle}>
					<Typography className={classes.subtitle} lineHeight={18}>
						{secondary}
					</Typography>
				</div>
			)}
		</div>
	);
};
