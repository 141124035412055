import {makeStyles} from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	content: {
		'& > div': {
			textAlign: 'center',
			padding: 48,
			fontSize: 20
		}
	}
}));
