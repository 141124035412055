import {Action} from 'redux';
import {Actions} from 'src/store/constants';
import {PayloadAction} from '../types';

export type SetErrorAction = PayloadAction<Actions.SET_ERROR, {key: string; error: unknown}>;
export type RemoveErrorAction = PayloadAction<Actions.REMOVE_ERROR, string>;
export type ClearErrorsAction = Action<Actions.CLEAR_ERRORS>;

export const setError = (key: string, error: unknown = true): SetErrorAction => ({
	type: Actions.SET_ERROR,
	payload: {key, error}
});

export const removeError = (key: string): RemoveErrorAction => ({
	type: Actions.REMOVE_ERROR,
	payload: key
});

export const clearErrors = (): ClearErrorsAction => ({
	type: Actions.CLEAR_ERRORS
});
