import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {ReactComponent as EmptyPersonalAvatar} from 'src/assets/svg/empty-avatar-personal.svg';
import {ReactComponent as EmptyBusinessAvatar} from 'src/assets/svg/empty-avatar-business.svg';
import theme from 'src/theme';

const EmptyAvatarsMap = {
	personal: EmptyPersonalAvatar,
	business: EmptyBusinessAvatar
};

type StylesProps = {
	size: number;
};
const useStyles = makeStyles(() => ({
	avatarWrapper: ({size}: StylesProps) => ({
		width: size - 2,
		height: size - 2,
		backgroundColor: theme.colors.white,
		borderRadius: '12px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		boxShadow: '0px 2px 6px 0px #0000000F'
	}),
	avatar: ({size}: StylesProps) => ({
		width: size,
		height: size,
		boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.06)',
		borderRadius: '14px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	})
}));

export interface AvatarProps {
	src?: string;
	size?: number;
	variant?: 'personal' | 'business';
}

export const Avatar = ({src, variant = 'personal', size = 36}: AvatarProps) => {
	const classes = useStyles({size});

	const EmptyAvatar = EmptyAvatarsMap[variant];

	return (
		<div className={classes.avatarWrapper}>
			{src ? <img className={classes.avatar} src={src} alt="Avatar" /> : <EmptyAvatar />}
		</div>
	);
};
