import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {makeStyles} from '@material-ui/styles';
import {setSuccess} from 'src/store/@success/actions';
import {isMobileDevice} from 'src/utils';
import {Success} from 'src/store/constants';
import {LoaderWithBackdrop, ViewTitle, NavigateIconButton, Typography} from 'src/components/@shared';
import {select3dsIframeSrc} from 'src/store/3ds/selectors';
import {toggle3dsIsOn} from 'src/store/3ds/actions';
import {PopupPagesEnum} from 'src/components/addPaymentOptionPopUp';
import {SecurityIframe} from './SecurityIframe';

interface StyleProps {
	isMobile: boolean;
}

const useStyles = makeStyles(() => ({
	iframe: ({isMobile}: StyleProps) => ({
		marginTop: 42,
		marginLeft: isMobile ? 0 : 20
	})
}));

interface SecurityCheckProps {
	handleOptionsPopupConfig: (isOpen: boolean, page?: PopupPagesEnum) => void;
}

export const SecurityCheckView = ({handleOptionsPopupConfig}: SecurityCheckProps) => {
	const isMobile = isMobileDevice();
	const classes = useStyles({isMobile});
	const dispatch = useDispatch();
	const [isCheckPassed, setIsCheckPassed] = useState<boolean>(false);
	const iframeSrc = useSelector(select3dsIframeSrc);

	const handleIsCheckPassed = (isPassed: boolean) => setIsCheckPassed(isPassed);

	const handleGoBack = () => {
		handleOptionsPopupConfig(true, PopupPagesEnum.AddCreditCardPage);
		dispatch(toggle3dsIsOn(''));
	};

	useEffect(() => {
		if (isCheckPassed) {
			handleOptionsPopupConfig(false);
			dispatch(toggle3dsIsOn(''));
			dispatch(setSuccess(Success.snackbar, 'Your new card was successfully added!'));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isCheckPassed]);

	if (iframeSrc && !isCheckPassed) {
		return (
			<div>
				<ViewTitle startAdornment={<NavigateIconButton variant="back" onClick={handleGoBack} />}>
					Quick Security Check
				</ViewTitle>
				<Typography color="purpleLedger" size={14}>
					Your bank is asking for additional verification. Please complete verification below to continue.
				</Typography>
				<SecurityIframe
					className={classes.iframe}
					src={iframeSrc}
					width={isMobile ? '98%' : '88%'}
					height={isMobile ? '400px' : '520px'}
					onCheckIsPassed={handleIsCheckPassed}
				/>
			</div>
		);
	}
	if (!iframeSrc || isCheckPassed) handleOptionsPopupConfig(false);

	return <LoaderWithBackdrop />;
};
