import React, {ReactNode} from 'react';
import {makeStyles} from '@material-ui/styles';
import {Typography, ListItem, Badge, Spinner} from 'src/components/@shared';
import {formatInFee} from 'src/utils/formatInFee/formatInFee';
import clsx from 'clsx';
import theme from 'src/theme';

const useStyles = makeStyles(() => ({
	avatarHolder: {
		display: 'flex',
		alignItems: 'center'
	},
	adornment: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		alignItems: 'flex-end',
		justifyContent: 'center',
		marginRight: 8
	},
	amountFee: {
		textAlign: 'right'
	},
	listItemText: {
		display: 'flex',
		height: '100%',
		flexDirection: 'column',
		justifyContent: 'center',
		flexGrow: 1,
		marginLeft: 8
	},
	primaryText: {
		marginBottom: 8,
		color: theme.colors.black
	},
	secondaryText: {
		color: theme.colors.black50
	},
	listItemTextSubtitle: {
		display: 'flex',
		opacity: 0.7
	},
	badgeMargin: {
		marginTop: 8
	},
	disable: {
		opacity: 0.3,
		pointerEvents: 'none'
	}
}));

interface Props {
	className?: string;
	avatar: ReactNode;
	radioButton?: ReactNode;
	amountFee?: number | string;
	primary?: string;
	secondary: string;
	isDefault?: boolean;
	disabled?: boolean;
	onClick?: () => void;
	isLoading?: boolean;
	infoMessage?: ReactNode;
}

export const PaymentListItem = ({
	className,
	avatar,
	primary,
	secondary,
	amountFee,
	isDefault,
	radioButton,
	disabled,
	onClick,
	isLoading,
	infoMessage
}: Props) => {
	const classes = useStyles();

	return (
		<ListItem
			className={clsx(className, {
				[classes.disable]: disabled
			})}
			onClick={onClick}>
			{radioButton}
			<div className={classes.avatarHolder}>{avatar}</div>
			<div className={classes.listItemText}>
				<Typography className={classes.primaryText} size={16} lineHeight={16} weight="semi-bold">
					{primary}
				</Typography>
				{secondary && (
					<div className={classes.listItemTextSubtitle}>
						{isLoading ? (
							<Spinner size={20} />
						) : (
							<Typography className={classes.secondaryText} lineHeight={14} size={14} color="black70">
								{secondary}
							</Typography>
						)}
					</div>
				)}
			</div>
			<div className={classes.adornment}>
				{infoMessage}
				<Typography className={classes.amountFee} color="black50" size={13} lineHeight={16}>
					{typeof amountFee === 'number' ? formatInFee(amountFee) : `${amountFee} Fee`}
				</Typography>
				{isDefault && <Badge className={classes.badgeMargin}>Default</Badge>}
			</div>
		</ListItem>
	);
};
