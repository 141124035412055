import {AssetBalance, Owner} from './users';
import {BanqAccount, AccountSpaceType} from './banqs';

export type Address = {
	street1: string;
	street2: string;
	postalCode: string;
	city: string;
	state: string;
	country: string;
};

export enum SpaceType {
	Merchant = 'merchant',
	Personal = 'personal'
}

export enum Level {
	L0 = 'l0',
	L1 = 'l1'
}

export enum UpgradeStatus {
	NotVerified = 'notVerified',
	UpgradePending = 'upgradePending',
	DocumentsRequested = 'documentsRequested',
	VerificationFailed = 'verificationFailed',
	Verified = 'verified'
}

export enum BusinessNature {
	Individual = 'individual',
	Corporation = 'corporation'
}

export type Space = {
	id: number;
	type: AccountSpaceType;
	name: string;
	totalBalance: number;
	adminUsersCount?: number;
	activeInvitesCount?: number;
	personalContact?: Address;
	owner?: Owner;
	businessNature?: BusinessNature;
	assetBalances?: AssetBalance[];
	banqAccountsCount: number | 1;
	banqAccounts?: BanqAccount[];
	level?: Level;
	upgradeStatus?: UpgradeStatus;
	isOnHold?: boolean;
};
