import {Actions} from 'src/store/constants';
import {Space} from 'src/api';
import keyBy from 'lodash/keyBy';
import {LoadSpacesAction, LoadSpacesSuccessAction} from './actions';

export type SpacesAction = LoadSpacesAction | LoadSpacesSuccessAction;

type SpacesState = Record<string, Space>;

const initialState: SpacesState = {};

const spacesReducer = (state = initialState, action: SpacesAction) => {
	switch (action.type) {
		case Actions.LOAD_SPACES_SUCCESS:
			return {
				...state,
				...keyBy(action.payload, 'id')
			};
		default:
			return state;
	}
};

export default spacesReducer;
