import {createSelector} from 'reselect';
import orderBy from 'lodash/orderBy';
import {State} from '../reducer';
import {ExternalSpace, ExternalSpaceType} from '../../api';
import {Success} from '../constants';

export const selectExternalSpaces = (state: State) => state.externalSpaces;

export const selectListOfExternalSpaces = (state: State) => Object.values(state.externalSpaces);

export const selectIsExternalAccountsExist = (state: State) => Object.values(state.externalSpaces).length > 0;

export const createExternalAccountSelector = (externalAccountId: number) =>
	createSelector(selectExternalSpaces, (externalAccounts) => externalAccounts[externalAccountId]);

export const selectLastAddedExternalAccountId = createSelector(selectListOfExternalSpaces, (externalAccounts) => {
	return (orderBy(externalAccounts, ['externalAccountId'], ['desc'])?.[0] as ExternalSpace)?.externalAccountId;
});

export const createBankListSelector = (accountSpaceId: number | undefined) =>
	createSelector(selectExternalSpaces, (spacesList) =>
		Object.values(spacesList).filter(
			(space) =>
				space.accountType.includes(ExternalSpaceType.Bank) &&
				(accountSpaceId ? space.accountSpaceId === accountSpaceId : false)
		)
	);

export const createCreditCardListSelector = (accountSpaceId: number | undefined) =>
	createSelector(selectExternalSpaces, (spacesList) =>
		Object.values(spacesList).filter(
			(space) =>
				(space.accountType.includes(ExternalSpaceType.Card) ||
					space.accountType.includes(ExternalSpaceType.BravaPayCard)) &&
				(accountSpaceId ? space.accountSpaceId === accountSpaceId : false)
		)
	);

export const createAreLinkedCreditCardsSelector = (accountSpaceId?: number) =>
	createSelector(createCreditCardListSelector(accountSpaceId), (creditCardsList) => creditCardsList?.length > 0);

export const createIsNoCreditCardRelinkedSelector = (accountSpaceId?: number) =>
	createSelector(createCreditCardListSelector(accountSpaceId), (creditCardsList): boolean =>
		creditCardsList?.every((card) => card?.accountType !== ExternalSpaceType.BravaPayCard)
	);

export const createAreRelinkedCardsTheOnlyExternalAccountsSelector = (accountSpaceId?: number) =>
	createSelector(
		createCreditCardListSelector(accountSpaceId),
		createBankListSelector(accountSpaceId),
		(creditCardsList, banksList): boolean =>
			creditCardsList?.every((card) => card?.accountType === ExternalSpaceType.BravaPayCard) && banksList?.length === 0
	);

export const createRelinkedCreditCardListSelector = (accountSpaceId?: number) =>
	createSelector(createCreditCardListSelector(accountSpaceId), (creditCardsList) =>
		creditCardsList?.filter((card) => card?.accountType === ExternalSpaceType.BravaPayCard)
	);

export const createLastRelinkedCardSelector = (accountSpaceId?: number) =>
	createSelector(
		createRelinkedCreditCardListSelector(accountSpaceId),
		(bravaPayList) => bravaPayList?.[bravaPayList?.length - 1]
	);

export const createPaymentOptionsListSelector = (accountSpaceId: number | undefined) =>
	createSelector(
		createBankListSelector(accountSpaceId),
		createCreditCardListSelector(accountSpaceId),
		(bankList, creditCardList) => [...bankList, ...creditCardList]
	);

export const selectIsExternalAccountsLoaded = (state: State) =>
	state.messages[Success.isExternalAccountsLoaded] as boolean;

export const createRelinkedCardSelector = () =>
	createSelector(selectExternalSpaces, (externalSpaces) => {
		// transforming the externalSpaces array for further search by value
		const externalSpacesArray = Object.entries(externalSpaces).map((space) => ({[space[0]]: space[1]}));
		// search by property 'relinking'
		const relinkedCard = externalSpacesArray.find((space) => {
			const spaceID = Object.keys(space)[0];
			return (space[spaceID] as ExternalSpace).relinking;
		});

		return relinkedCard;
	});

export const createChosenExternalAccount = (accountSpaceId: number | undefined) =>
	createSelector(createCreditCardListSelector(accountSpaceId), (creditCardList) => {
		return creditCardList.find((card) => card?.chosen)?.externalAccountId;
	});
