export enum ExternalSpaceType {
	Card = 'card',
	BravaPayCard = 'bravaPay',
	Bank = 'bank'
}

export enum PaymentSystem {
	Visa = 'Visa',
	OldPtVisa = 'visa',
	Mastercard = 'Mastercard',
	OldPtMastercard = 'mastercard',
	AmericanExpress = 'Amex',
	Other = 'Other'
}

export enum BankAccountType {
	Saving = 'saving',
	Checking = 'checking'
}

export enum CardType {
	Credit = 'credit',
	Debit = 'debit'
}

export type AddCardBody = {
	cardNumber: string;
	expirationMonth: string;
	expirationYear: string;
	cvvTwo: string;
	cardHolderName: string;
	email: string;
	addressOne: string;
	city: string;
	zipCode: string;
	country: string;
	returnUrl: string;
	addressTwo: string;
	state: string;
};

export type AddCardResponse = {
	creditCardStorageId: string;
	isActive: boolean;
	last4: string;
	details: {
		d3RedirectUrl?: string;
	};
};

export type CardDetails = {
	address?: string;
	bankName?: string;
	cardType?: CardType;
	currencyCode?: string;
	expirationDate?: string;
	fullOwnerName?: string;
	paymentSystem?: PaymentSystem;
};

export type BankDetails = {
	accountName?: string;
	accountNumber?: string;
	accountType?: BankAccountType;
	bankName?: string;
	routingNumber?: string;
};

export type ExternalSpace = {
	externalAccountId: number;
	accountSpaceId: number;
	isDefault: boolean;
	last4: string;
	accountType: ExternalSpaceType;
	type: string;
	details: BankDetails | CardDetails;
	relinking?: boolean;
	restrictedIndustryIdsForPayment: number[];
	chosen?: boolean;
};

export type CardToken = {
	cardResourceId: string;
	resourceTokenHash: string;
};

export type PaymentFees = {
	feeTypeId?: number;
	type?: string;
	fixedValue?: number;
	percentageValue?: number;
};

export enum FeeTypes {
	creditCardPayments = 'creditCardPayments',
	debitCardPayments = 'debitCardPayments'
}
