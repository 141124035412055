import omit from 'lodash/omit';

import {Actions} from 'src/store/constants';
import {ClearLoadingsAction, RemoveLoadingAction, SetLoadingAction} from './actions';
import {endsWithError, endsWithPending, endsWithSuccess} from 'src/store/utils';
import {PayloadAction} from 'src/store/types';

type LoadingsState = Record<string, boolean>;

type LoadingsActions = PayloadAction<Actions, string> | SetLoadingAction | RemoveLoadingAction | ClearLoadingsAction;

const initialState: LoadingsState = {};

const loadingsReducer = (state = initialState, action: LoadingsActions) => {
	switch (action.type) {
		case Actions.SET_LOADING:
			return {
				...state,
				[action.payload]: true
			};
		case Actions.REMOVE_LOADING:
			return omit(state, action.payload);
		case Actions.CLEAR_LOADINGS:
			return initialState;
	}

	const name = action?.loading;

	if (!name) return state;

	if (endsWithPending(action.type)) {
		return {...state, [name]: true};
	}

	if (endsWithSuccess(action.type)) {
		return omit(state, name);
	}

	if (endsWithError(action.type)) {
		return omit(state, name);
	}
	return state;
};

export default loadingsReducer;
