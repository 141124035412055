import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {PopUp} from 'src/components/@shared';
import {AddPaymentOptionsContent} from '../paymentOptions/AddPaymentOptionsContent';

const useStyles = makeStyles(() => ({
	root: {
		maxWidth: 373
	}
}));

interface Props {
	onAddDebitCard: () => void;
	onClose: () => void;
}

// This needs to be refactored
export const AddPaymentOptionPopUp = ({onClose, ...props}: Props) => {
	const classes = useStyles();

	const handleClose = () => onClose();

	return (
		<PopUp className={classes.root} onClose={handleClose}>
			<AddPaymentOptionsContent {...props} onClose={handleClose} />
		</PopUp>
	);
};
