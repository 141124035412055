import React from 'react';
import clsx from 'clsx';

import theme, {Colors} from 'src/theme';
import {StyleProps, useStyles} from './Spinner.styles';

interface Props extends Partial<StyleProps> {
	color?: keyof Colors;
	className?: string;
}

export const Spinner = ({color = 'purpleSpinner', size = 40, className}: Props) => {
	const classes = useStyles({size});
	return (
		<div className={clsx(classes.root, className)}>
			{Array.from({length: 12})
				.fill('')
				.map((_, index) => (
					// Warning: Each child in a list should have a unique "key" prop. And there is no need here for uniq identifier
					// eslint-disable-next-line react/no-array-index-key
					<div className={classes.spinner} style={{backgroundColor: theme.colors[color]}} key={index} />
				))}
		</div>
	);
};
