import {makeStyles} from '@material-ui/styles';

import theme, {Colors, Gradients} from 'src/theme';

type StyleProps = {
	fullWidth?: boolean;
	gradient?: keyof Gradients;
	color?: keyof Colors;
};

export const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontFamily: 'Sofia Pro',
		cursor: 'pointer'
	},
	contained: ({fullWidth, gradient}: StyleProps) => ({
		padding: 16,
		background: (gradient && theme.gradients[gradient]) || theme.gradients.button,
		borderRadius: 80,
		color: theme.colors.white,
		width: fullWidth ? '100%' : 'auto',
		border: 0,
		fontSize: 18,
		'&:focus': {outline: 0},
		'&:hover': {
			transition: 'all 0.25s ease-out',
			boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
			transform: 'scale(1.005)'
		}
	}),
	outlinedGradient: ({fullWidth}: StyleProps) => ({
		padding: 16,
		width: fullWidth ? '100%' : 'auto',
		fontSize: 18,
		borderRadius: '80px',
		backgroundImage: `linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), ${theme.gradients.button}`,
		textFillColor: theme.colors.purple,
		border: 'solid 3px transparent',
		backgroundOrigin: 'border-box',
		'-webkit-background-size': 'cover',
		'-moz-background-size': 'cover',
		'-o-background-size': 'cover',
		'background-size': 'cover',
		boxShadow: `2px 1000px 1px ${theme.colors.white} inset`,
		'&:focus': {outline: 0},
		'&:hover': {
			boxShadow: `2px 1000px 1px`,
			textFillColor: theme.colors.white,
			transform: 'scale(1.01)',
			transition: 'all 0.25s ease-out'
		}
	}),
	outlined: ({fullWidth, color}: StyleProps) => ({
		padding: 13,
		width: fullWidth ? '100%' : 'auto',
		fontSize: 18,
		borderRadius: '80px',
		border: `solid 1px`,
		borderColor: (color && theme.colors[color]) || theme.colors.purple,
		backgroundColor: theme.colors.white,
		textFillColor: (color && theme.colors[color]) || theme.colors.purple,
		boxShadow: `1px 1000px 1px ${theme.colors.white}`,
		'&:focus': {outline: 0},
		'&:hover': {
			boxShadow: `2px 1000px 1px`,
			textFillColor: theme.colors.white,
			backgroundColor: (color && theme.colors[color]) || theme.colors.purple,
			transform: 'scale(1.01)',
			transition: 'all 0.25s ease-out'
		}
	}),
	minimalistic: ({color}: StyleProps) => {
		const themeColor = theme.colors.black70;
		return {
			background: theme.colors.white,
			color: color ? theme.colors[color] : themeColor,
			border: 'none',
			borderBottom: `2px solid ${color ? theme.colors[color] : theme.colors.black70}`,
			padding: 0,
			outline: 'none',
			cursor: 'pointer',
			fontSize: 16,
			'&:hover': {color: theme.colors.black50}
		};
	},
	disabled: {
		opacity: 0.3,
		cursor: 'auto',
		'&:hover': {
			boxShadow: 'none !important' // I'm unable to override hover without important. Looks like this is material ui bug
		}
	}
}));
