import React from 'react';
import {makeStyles} from '@material-ui/styles';
import checkingAccountIcon from 'src/assets/svg/checking-account-icon.svg';
import {ReactComponent as ArrowRight} from 'src/assets/svg/arrow-right-gray.svg';
import debitCardIcon from 'src/assets/svg/debit-card-icon.svg';
import theme from 'src/theme';
import {ReactComponent as BanqLogo} from 'src/assets/svg/banqLogo.svg';
import {Avatar, Button, ListItem, ListItemText, Typography} from 'src/components/@shared';

const useStyles = makeStyles(() => ({
	logo: {
		marginBottom: 36
	},
	list: {
		width: '100%',
		marginTop: 24
	},
	listItem: {
		marginTop: -1, // this allows to merge borders of list items. There is another way to do it. But this seems to be the simplest way
		height: 68
	},
	subtitle: {
		marginTop: 14,
		letterSpacing: '0.25px'
	},
	footer: {
		marginTop: 48,
		marginBottom: 10,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	closeButton: {
		fontSize: 14,
		lineHeight: '20px',
		color: theme.colors.black50,
		borderBottom: 0,
		textDecoration: 'underline'
	}
}));

interface Props {
	onAddDebitCard: () => void;
	onClose: () => void;
}
export const AddPaymentOptionsContent = ({onAddDebitCard, onClose}: Props) => {
	const classes = useStyles();

	return (
		<>
			<header>
				<BanqLogo className={classes.logo} />
				<Typography size={24} lineHeight={32}>
					Add a Payment Method
				</Typography>
				<Typography lineHeight={20} color="black50" className={classes.subtitle}>
					Connect a payment method now, and we’ll remember it for quick payments in the future.
				</Typography>
			</header>
			<div className={classes.list}>
				<ListItem disabled className={classes.listItem}>
					<Avatar size={36} src={checkingAccountIcon} />
					<ListItemText primary="Checking Account" />
					<ArrowRight />
				</ListItem>
				<ListItem className={classes.listItem} onClick={onAddDebitCard}>
					<Avatar size={36} src={debitCardIcon} />
					<ListItemText primary="Debit / Credit Card" />
					<ArrowRight />
				</ListItem>
			</div>
			<footer className={classes.footer}>
				<Button className={classes.closeButton} variant="minimalistic" onClick={onClose}>
					I’ll do this later
				</Button>
			</footer>
		</>
	);
};
