import {Actions, Errors, Loadings, Success} from 'src/store/constants';
import api from 'src/api';
import {LoadAction, PayloadAction, Action} from 'src/store/types';
import {PaymentInfoErrorI, PaymentInfoI} from 'src/api/types';

export type LoadPaymentInfoAction = LoadAction<Actions.LOAD_PAYMENT_INFO>;
export type LoadPaymentInfoSuccessAction = PayloadAction<
	Actions.LOAD_PAYMENT_INFO_SUCCESS,
	PaymentInfoI | PaymentInfoErrorI
>;

export type AcceptPaymentAction = LoadAction<Actions.ACCEPT_PAYMENT>;
export type AcceptPaymentSuccessAction = Action<Actions.ACCEPT_PAYMENT_SUCCESS>;
export type AcceptPaymentErrorAction = Action<Actions.ACCEPT_PAYMENT_ERROR>;

export type DeclinePaymentAction = LoadAction<Actions.DECLINE_PAYMENT>;
export type DeclinePaymentSuccessAction = Action<Actions.DECLINE_PAYMENT_SUCCESS>;
export type DeclinePaymentErrorAction = Action<Actions.DECLINE_PAYMENT_ERROR>;

export type SetIsExpiredAction = Action<Actions.SET_IS_EXPIRED>;

export const loadPaymentInfo = (): LoadPaymentInfoAction => ({
	type: Actions.LOAD_PAYMENT_INFO,
	load: api.payment.getInfo(),
	loading: Loadings.isPaymentInfoLoading,
	error: Errors.loadPaymentInfoError,
	success: Success.isPaymentInfoLoadedSuccessfully
});

export const declinePayment = (): DeclinePaymentAction => ({
	type: Actions.DECLINE_PAYMENT,
	load: api.payment.decline(),
	loading: Loadings.isPaymentDeclineLoading,
	error: Errors.DeclinePaymentError,
	success: Success.isPaymentDeclinedSuccessfully
});

export const acceptPayment = (externalAccountId?: number): AcceptPaymentAction => ({
	type: Actions.ACCEPT_PAYMENT,
	load: api.payment.accept(externalAccountId),
	loading: Loadings.isPaymentAcceptLoading,
	error: Errors.loadPaymentAcceptError,
	success: Success.isPaymentAcceptSuccessfully
});

export const setIsExpired = (): SetIsExpiredAction => ({
	type: Actions.SET_IS_EXPIRED
});
