import React, {ReactNode, useState} from 'react';

import {useStyles} from './Layout.styles';
import {ReactComponent as BanqLogoWhite} from 'src/assets/svg/banqLogoWhite.svg';

interface PropsI {
	children: ReactNode;
}

export const Layout = ({children}: PropsI) => {
	const [isScrolling, setIsScrolling] = useState(false);
	const classes = useStyles(isScrolling);

	let scrollTimer = -1;

	const scrollFinished = () => setIsScrolling(false);
	const onScroll = (): void => {
		setIsScrolling(true);
		if (scrollTimer !== -1) {
			clearTimeout(scrollTimer);
		}
		scrollTimer = window.setTimeout(() => scrollFinished(), 1000);
	};

	return (
		<div className={classes.wrapper}>
			<header className={classes.header}>
				<BanqLogoWhite className={classes.logo} />
			</header>
			<main className={classes.main} onScroll={onScroll}>
				{children}
				<footer className={classes.footer}>
					<span className={classes.footer__text}>©2021 banq Inc. | All Rights Reserved</span>
				</footer>
			</main>
		</div>
	);
};
