import {useSelector} from 'react-redux';
import {isEmpty} from 'lodash';
import {Errors} from 'src/store/constants';
import {setError} from 'src/store/@errors/actions';
import {selectErrors} from 'src/store/@errors/selectors';

export const useSetSomethingWentWrongMessage = () => {
	const shouldShowSomethingWentWrongMessage = !isEmpty(useSelector(selectErrors));

	if (shouldShowSomethingWentWrongMessage) {
		setError(Errors.snackbar, 'Something went wrong');
	}
};
