export default [
	{
		value: 'BAL',
		label: 'Balkh',
		countryLabel: 'Afghanistan',
		countryValue: 'AF'
	},
	{
		value: 'BAM',
		label: 'Bamyan',
		countryLabel: 'Afghanistan',
		countryValue: 'AF'
	},
	{
		value: 'BDG',
		label: 'Badghis',
		countryLabel: 'Afghanistan',
		countryValue: 'AF'
	},
	{
		value: 'BGL',
		label: 'Baghlan',
		countryLabel: 'Afghanistan',
		countryValue: 'AF'
	},
	{
		value: 'DAY',
		label: 'Daykundi',
		countryLabel: 'Afghanistan',
		countryValue: 'AF'
	},
	{
		value: 'FRA',
		label: 'Farah',
		countryLabel: 'Afghanistan',
		countryValue: 'AF'
	},
	{
		value: 'FYB',
		label: 'Faryab',
		countryLabel: 'Afghanistan',
		countryValue: 'AF'
	},
	{
		value: 'GHA',
		label: 'Ghazni',
		countryLabel: 'Afghanistan',
		countryValue: 'AF'
	},
	{
		value: 'GHO',
		label: 'Ghor',
		countryLabel: 'Afghanistan',
		countryValue: 'AF'
	},
	{
		value: 'HEL',
		label: 'Helmand',
		countryLabel: 'Afghanistan',
		countryValue: 'AF'
	},
	{
		value: 'HER',
		label: 'Herat',
		countryLabel: 'Afghanistan',
		countryValue: 'AF'
	},
	{
		value: 'JOW',
		label: 'Jowzjan',
		countryLabel: 'Afghanistan',
		countryValue: 'AF'
	},
	{
		value: 'KAB',
		label: 'Kabul',
		countryLabel: 'Afghanistan',
		countryValue: 'AF'
	},
	{
		value: 'KAN',
		label: 'Kandahar',
		countryLabel: 'Afghanistan',
		countryValue: 'AF'
	},
	{
		value: 'KAP',
		label: 'Kapisa',
		countryLabel: 'Afghanistan',
		countryValue: 'AF'
	},
	{
		value: 'KDZ',
		label: 'Kunduz',
		countryLabel: 'Afghanistan',
		countryValue: 'AF'
	},
	{
		value: 'KHO',
		label: 'Khost',
		countryLabel: 'Afghanistan',
		countryValue: 'AF'
	},
	{
		value: 'LAG',
		label: 'Laghman',
		countryLabel: 'Afghanistan',
		countryValue: 'AF'
	},
	{
		value: 'LOG',
		label: 'Logar',
		countryLabel: 'Afghanistan',
		countryValue: 'AF'
	},
	{
		value: 'NAN',
		label: 'Nangarhar',
		countryLabel: 'Afghanistan',
		countryValue: 'AF'
	},
	{
		value: 'NIM',
		label: 'Nimroz',
		countryLabel: 'Afghanistan',
		countryValue: 'AF'
	},
	{
		value: 'PAR',
		label: 'Parwan',
		countryLabel: 'Afghanistan',
		countryValue: 'AF'
	},
	{
		value: 'PIA',
		label: 'Paktiya',
		countryLabel: 'Afghanistan',
		countryValue: 'AF'
	},
	{
		value: 'SAR',
		label: 'Sar-e Pul',
		countryLabel: 'Afghanistan',
		countryValue: 'AF'
	},
	{
		value: 'TAK',
		label: 'Takhar',
		countryLabel: 'Afghanistan',
		countryValue: 'AF'
	},
	{
		value: 'URU',
		label: 'Uruzgan',
		countryLabel: 'Afghanistan',
		countryValue: 'AF'
	},
	{
		value: '1',
		label: 'Berat',
		countryLabel: 'Albania',
		countryValue: 'AL'
	},
	{
		value: '2',
		label: 'Durres',
		countryLabel: 'Albania',
		countryValue: 'AL'
	},
	{
		value: '3',
		label: 'Elbasan',
		countryLabel: 'Albania',
		countryValue: 'AL'
	},
	{
		value: '4',
		label: 'Fier',
		countryLabel: 'Albania',
		countryValue: 'AL'
	},
	{
		value: '5',
		label: 'Gjirokaster',
		countryLabel: 'Albania',
		countryValue: 'AL'
	},
	{
		value: '6',
		label: 'Korce',
		countryLabel: 'Albania',
		countryValue: 'AL'
	},
	{
		value: '7',
		label: 'Kukes',
		countryLabel: 'Albania',
		countryValue: 'AL'
	},
	{
		value: '8',
		label: 'Lezhe',
		countryLabel: 'Albania',
		countryValue: 'AL'
	},
	{
		value: '9',
		label: 'Diber',
		countryLabel: 'Albania',
		countryValue: 'AL'
	},
	{
		value: '10',
		label: 'Shkoder',
		countryLabel: 'Albania',
		countryValue: 'AL'
	},
	{
		value: '11',
		label: 'Tirane',
		countryLabel: 'Albania',
		countryValue: 'AL'
	},
	{
		value: '12',
		label: 'Vlore',
		countryLabel: 'Albania',
		countryValue: 'AL'
	},
	{
		value: '1',
		label: 'Adrar',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '2',
		label: 'Chlef',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '3',
		label: 'Laghouat',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '4',
		label: 'Oum el Bouaghi',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '5',
		label: 'Batna',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '6',
		label: 'Bejaia',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '7',
		label: 'Biskra',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '8',
		label: 'Bechar',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '9',
		label: 'Blida',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '10',
		label: 'Bouira',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '11',
		label: 'Tamanrasset',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '12',
		label: 'Tebessa',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '13',
		label: 'Tlemcen',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '14',
		label: 'Tiaret',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '15',
		label: 'Tizi Ouzou',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '16',
		label: 'Alger',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '17',
		label: 'Djelfa',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '18',
		label: 'Jijel',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '19',
		label: 'Setif',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '20',
		label: 'Saida',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '21',
		label: 'Skikda',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '22',
		label: 'Sidi Bel Abbes',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '23',
		label: 'Annaba',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '24',
		label: 'Guelma',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '25',
		label: 'Constantine',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '26',
		label: 'Medea',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '27',
		label: 'Mostaganem',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '28',
		label: "M'sila",
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '29',
		label: 'Mascara',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '30',
		label: 'Ouargla',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '31',
		label: 'Oran',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '32',
		label: 'El Bayadh',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '33',
		label: 'Illizi',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '34',
		label: 'Bordj Bou Arreridj',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '35',
		label: 'Boumerdes',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '36',
		label: 'El Tarf',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '37',
		label: 'Tindouf',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '38',
		label: 'Tissemsilt',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '39',
		label: 'El Oued',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '40',
		label: 'Khenchela',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '41',
		label: 'Souk Ahras',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '42',
		label: 'Tipaza',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '43',
		label: 'Mila',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '44',
		label: 'Ain Defla',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '45',
		label: 'Naama',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '46',
		label: 'Ain Temouchent',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '47',
		label: 'Ghardaia',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '48',
		label: 'Relizane',
		countryLabel: 'Algeria',
		countryValue: 'DZ'
	},
	{
		value: '2',
		label: 'Canillo',
		countryLabel: 'Andorra',
		countryValue: 'AD'
	},
	{
		value: '3',
		label: 'Encamp',
		countryLabel: 'Andorra',
		countryValue: 'AD'
	},
	{
		value: '4',
		label: 'La Massana',
		countryLabel: 'Andorra',
		countryValue: 'AD'
	},
	{
		value: '5',
		label: 'Ordino',
		countryLabel: 'Andorra',
		countryValue: 'AD'
	},
	{
		value: '6',
		label: 'Sant Julia de Loria',
		countryLabel: 'Andorra',
		countryValue: 'AD'
	},
	{
		value: '7',
		label: 'Andorra la Vella',
		countryLabel: 'Andorra',
		countryValue: 'AD'
	},
	{
		value: '8',
		label: 'Escaldes-Engordany',
		countryLabel: 'Andorra',
		countryValue: 'AD'
	},
	{
		value: 'BGO',
		label: 'Bengo',
		countryLabel: 'Angola',
		countryValue: 'AO'
	},
	{
		value: 'BGU',
		label: 'Benguela',
		countryLabel: 'Angola',
		countryValue: 'AO'
	},
	{
		value: 'BIE',
		label: 'Bie',
		countryLabel: 'Angola',
		countryValue: 'AO'
	},
	{
		value: 'CAB',
		label: 'Cabinda',
		countryLabel: 'Angola',
		countryValue: 'AO'
	},
	{
		value: 'CCU',
		label: 'Cuando Cubango',
		countryLabel: 'Angola',
		countryValue: 'AO'
	},
	{
		value: 'CNN',
		label: 'Cunene',
		countryLabel: 'Angola',
		countryValue: 'AO'
	},
	{
		value: 'CNO',
		label: 'Cuanza-Norte',
		countryLabel: 'Angola',
		countryValue: 'AO'
	},
	{
		value: 'CUS',
		label: 'Cuanza-Sul',
		countryLabel: 'Angola',
		countryValue: 'AO'
	},
	{
		value: 'HUA',
		label: 'Huambo',
		countryLabel: 'Angola',
		countryValue: 'AO'
	},
	{
		value: 'HUI',
		label: 'Huila',
		countryLabel: 'Angola',
		countryValue: 'AO'
	},
	{
		value: 'LNO',
		label: 'Lunda-Norte',
		countryLabel: 'Angola',
		countryValue: 'AO'
	},
	{
		value: 'LSU',
		label: 'Lunda-Sul',
		countryLabel: 'Angola',
		countryValue: 'AO'
	},
	{
		value: 'LUA',
		label: 'Luanda',
		countryLabel: 'Angola',
		countryValue: 'AO'
	},
	{
		value: 'MAL',
		label: 'Malange',
		countryLabel: 'Angola',
		countryValue: 'AO'
	},
	{
		value: 'MOX',
		label: 'Moxico',
		countryLabel: 'Angola',
		countryValue: 'AO'
	},
	{
		value: 'NAM',
		label: 'Namibe',
		countryLabel: 'Angola',
		countryValue: 'AO'
	},
	{
		value: 'UIG',
		label: 'Uige',
		countryLabel: 'Angola',
		countryValue: 'AO'
	},
	{
		value: 'ZAI',
		label: 'Zaire',
		countryLabel: 'Angola',
		countryValue: 'AO'
	},
	{
		value: '3',
		label: 'Saint George',
		countryLabel: 'Antigua',
		countryValue: 'AG'
	},
	{
		value: '4',
		label: 'Saint John',
		countryLabel: 'Antigua',
		countryValue: 'AG'
	},
	{
		value: '6',
		label: 'Saint Paul',
		countryLabel: 'Antigua',
		countryValue: 'AG'
	},
	{
		value: '7',
		label: 'Saint Peter',
		countryLabel: 'Antigua',
		countryValue: 'AG'
	},
	{
		value: '8',
		label: 'Saint Philip',
		countryLabel: 'Antigua',
		countryValue: 'AG'
	},
	{
		value: '11',
		label: 'Redonda',
		countryLabel: 'Antigua',
		countryValue: 'AG'
	},
	{
		value: 'A',
		label: 'Salta',
		countryLabel: 'Argentina',
		countryValue: 'AR'
	},
	{
		value: 'B',
		label: 'Buenos Aires',
		countryLabel: 'Argentina',
		countryValue: 'AR'
	},
	{
		value: 'C',
		label: 'Ciudad Autonoma de Buenos Aires',
		countryLabel: 'Argentina',
		countryValue: 'AR'
	},
	{
		value: 'D',
		label: 'San Luis',
		countryLabel: 'Argentina',
		countryValue: 'AR'
	},
	{
		value: 'E',
		label: 'Entre Rios',
		countryLabel: 'Argentina',
		countryValue: 'AR'
	},
	{
		value: 'F',
		label: 'La Rioja',
		countryLabel: 'Argentina',
		countryValue: 'AR'
	},
	{
		value: 'G',
		label: 'Santiago del Estero',
		countryLabel: 'Argentina',
		countryValue: 'AR'
	},
	{
		value: 'H',
		label: 'Chaco',
		countryLabel: 'Argentina',
		countryValue: 'AR'
	},
	{
		value: 'J',
		label: 'San Juan',
		countryLabel: 'Argentina',
		countryValue: 'AR'
	},
	{
		value: 'K',
		label: 'Catamarca',
		countryLabel: 'Argentina',
		countryValue: 'AR'
	},
	{
		value: 'L',
		label: 'La Pampa',
		countryLabel: 'Argentina',
		countryValue: 'AR'
	},
	{
		value: 'M',
		label: 'Mendoza',
		countryLabel: 'Argentina',
		countryValue: 'AR'
	},
	{
		value: 'N',
		label: 'Misiones',
		countryLabel: 'Argentina',
		countryValue: 'AR'
	},
	{
		value: 'P',
		label: 'Formosa',
		countryLabel: 'Argentina',
		countryValue: 'AR'
	},
	{
		value: 'Q',
		label: 'Neuquen',
		countryLabel: 'Argentina',
		countryValue: 'AR'
	},
	{
		value: 'R',
		label: 'Rio Negro',
		countryLabel: 'Argentina',
		countryValue: 'AR'
	},
	{
		value: 'S',
		label: 'Santa Fe',
		countryLabel: 'Argentina',
		countryValue: 'AR'
	},
	{
		value: 'T',
		label: 'Tucuman',
		countryLabel: 'Argentina',
		countryValue: 'AR'
	},
	{
		value: 'U',
		label: 'Chubut',
		countryLabel: 'Argentina',
		countryValue: 'AR'
	},
	{
		value: 'V',
		label: 'Tierra del Fuego',
		countryLabel: 'Argentina',
		countryValue: 'AR'
	},
	{
		value: 'W',
		label: 'Corrientes',
		countryLabel: 'Argentina',
		countryValue: 'AR'
	},
	{
		value: 'X',
		label: 'Cordoba',
		countryLabel: 'Argentina',
		countryValue: 'AR'
	},
	{
		value: 'Y',
		label: 'Jujuy',
		countryLabel: 'Argentina',
		countryValue: 'AR'
	},
	{
		value: 'Z',
		label: 'Santa Cruz',
		countryLabel: 'Argentina',
		countryValue: 'AR'
	},
	{
		value: 'AG',
		label: 'Aragacotn',
		countryLabel: 'Armenia',
		countryValue: 'AM'
	},
	{
		value: 'AR',
		label: 'Ararat',
		countryLabel: 'Armenia',
		countryValue: 'AM'
	},
	{
		value: 'AV',
		label: 'Armavir',
		countryLabel: 'Armenia',
		countryValue: 'AM'
	},
	{
		value: 'ER',
		label: 'Erevan',
		countryLabel: 'Armenia',
		countryValue: 'AM'
	},
	{
		value: 'GR',
		label: "Gegark'unik'",
		countryLabel: 'Armenia',
		countryValue: 'AM'
	},
	{
		value: 'KT',
		label: "Kotayk'",
		countryLabel: 'Armenia',
		countryValue: 'AM'
	},
	{
		value: 'LO',
		label: 'Lori',
		countryLabel: 'Armenia',
		countryValue: 'AM'
	},
	{
		value: 'SH',
		label: 'Sirak',
		countryLabel: 'Armenia',
		countryValue: 'AM'
	},
	{
		value: 'SU',
		label: "Syunik'",
		countryLabel: 'Armenia',
		countryValue: 'AM'
	},
	{
		value: 'TV',
		label: 'Tavus',
		countryLabel: 'Armenia',
		countryValue: 'AM'
	},
	{
		value: 'VD',
		label: 'Vayoc Jor',
		countryLabel: 'Armenia',
		countryValue: 'AM'
	},
	{
		value: 'ACT',
		label: 'Australian Capital Territory',
		countryLabel: 'Australia',
		countryValue: 'AU'
	},
	{
		value: 'NSW',
		label: 'New South Wales',
		countryLabel: 'Australia',
		countryValue: 'AU'
	},
	{
		value: 'NT',
		label: 'Northern Territory',
		countryLabel: 'Australia',
		countryValue: 'AU'
	},
	{
		value: 'QLD',
		label: 'Queensland',
		countryLabel: 'Australia',
		countryValue: 'AU'
	},
	{
		value: 'SA',
		label: 'South Australia',
		countryLabel: 'Australia',
		countryValue: 'AU'
	},
	{
		value: 'TAS',
		label: 'Tasmania',
		countryLabel: 'Australia',
		countryValue: 'AU'
	},
	{
		value: 'VIC',
		label: 'Victoria',
		countryLabel: 'Australia',
		countryValue: 'AU'
	},
	{
		value: 'WA',
		label: 'Western Australia',
		countryLabel: 'Australia',
		countryValue: 'AU'
	},
	{
		value: '1',
		label: 'Burgenland',
		countryLabel: 'Austria',
		countryValue: 'AT'
	},
	{
		value: '2',
		label: 'Karnten',
		countryLabel: 'Austria',
		countryValue: 'AT'
	},
	{
		value: '3',
		label: 'Niederosterreich',
		countryLabel: 'Austria',
		countryValue: 'AT'
	},
	{
		value: '4',
		label: 'Oberosterreich',
		countryLabel: 'Austria',
		countryValue: 'AT'
	},
	{
		value: '5',
		label: 'Salzburg',
		countryLabel: 'Austria',
		countryValue: 'AT'
	},
	{
		value: '6',
		label: 'Steiermark',
		countryLabel: 'Austria',
		countryValue: 'AT'
	},
	{
		value: '7',
		label: 'Tirol',
		countryLabel: 'Austria',
		countryValue: 'AT'
	},
	{
		value: '8',
		label: 'Vorarlberg',
		countryLabel: 'Austria',
		countryValue: 'AT'
	},
	{
		value: '9',
		label: 'Wien',
		countryLabel: 'Austria',
		countryValue: 'AT'
	},
	{
		value: 'ABS',
		label: 'Abseron',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'AGA',
		label: 'Agstafa',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'AGC',
		label: 'Agcabadi',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'AGS',
		label: 'Agdas',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'AST',
		label: 'Astara',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'BA',
		label: 'Baki',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'BAL',
		label: 'Balakan',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'BAR',
		label: 'Barda',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'BEY',
		label: 'Beylaqan',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'BIL',
		label: 'Bilasuvar',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'CAL',
		label: 'Calilabad',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'FUZ',
		label: 'Fuzuli',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'GA',
		label: 'Ganca',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'GAD',
		label: 'Gadabay',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'GOR',
		label: 'Goranboy',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'GOY',
		label: 'Goycay',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'GYG',
		label: 'Goygol',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'IMI',
		label: 'Imisli',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'ISM',
		label: 'Ismayilli',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'KUR',
		label: 'Kurdamir',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'LA',
		label: 'Lankaran',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'MAS',
		label: 'Masalli',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'MI',
		label: 'Mingacevir',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'NEF',
		label: 'Neftcala',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'NX',
		label: 'Naxcivan',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'OGU',
		label: 'Oguz',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'QAB',
		label: 'Qabala',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'QAX',
		label: 'Qax',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'QAZ',
		label: 'Qazax',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'QBA',
		label: 'Quba',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'QUS',
		label: 'Qusar',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'SAB',
		label: 'Sabirabad',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'SAK',
		label: 'Saki',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'SAL',
		label: 'Salyan',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'SAT',
		label: 'Saatli',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'SIY',
		label: 'Siyazan',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'SKR',
		label: 'Samkir',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'SM',
		label: 'Sumqayit',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'SMI',
		label: 'Samaxi',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'SMX',
		label: 'Samux',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'SR',
		label: 'Sirvan',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'TAR',
		label: 'Tartar',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'XAC',
		label: 'Xacmaz',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'YAR',
		label: 'Yardimli',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'YEV',
		label: 'Yevlax',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'ZAQ',
		label: 'Zaqatala',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'ZAR',
		label: 'Zardab',
		countryLabel: 'Azerbaijan',
		countryValue: 'AZ'
	},
	{
		value: 'CO',
		label: 'Central Abaco',
		countryLabel: 'Bahamas',
		countryValue: 'BS'
	},
	{
		value: 'EG',
		label: 'East Grand Bahama',
		countryLabel: 'Bahamas',
		countryValue: 'BS'
	},
	{
		value: 'FP',
		label: 'City of Freeport',
		countryLabel: 'Bahamas',
		countryValue: 'BS'
	},
	{
		value: 'HI',
		label: 'Harbour Island',
		countryLabel: 'Bahamas',
		countryValue: 'BS'
	},
	{
		value: 'LI',
		label: 'Long Island',
		countryLabel: 'Bahamas',
		countryValue: 'BS'
	},
	{
		value: 'NE',
		label: 'North Eleuthera',
		countryLabel: 'Bahamas',
		countryValue: 'BS'
	},
	{
		value: 'NO',
		label: 'North Abaco',
		countryLabel: 'Bahamas',
		countryValue: 'BS'
	},
	{
		value: 'NP',
		label: 'New Providence',
		countryLabel: 'Bahamas',
		countryValue: 'BS'
	},
	{
		value: 'NS',
		label: 'North Andros',
		countryLabel: 'Bahamas',
		countryValue: 'BS'
	},
	{
		value: 'SE',
		label: 'South Eleuthera',
		countryLabel: 'Bahamas',
		countryValue: 'BS'
	},
	{
		value: 'WG',
		label: 'West Grand Bahama',
		countryLabel: 'Bahamas',
		countryValue: 'BS'
	},
	{
		value: '13',
		label: "Al 'Asimah",
		countryLabel: 'Bahrain',
		countryValue: 'BH'
	},
	{
		value: '14',
		label: 'Al Janubiyah',
		countryLabel: 'Bahrain',
		countryValue: 'BH'
	},
	{
		value: '15',
		label: 'Al Muharraq',
		countryLabel: 'Bahrain',
		countryValue: 'BH'
	},
	{
		value: '17',
		label: 'Ash Shamaliyah',
		countryLabel: 'Bahrain',
		countryValue: 'BH'
	},
	{
		value: 'A',
		label: 'Barishal',
		countryLabel: 'Bangladesh',
		countryValue: 'BD'
	},
	{
		value: 'B',
		label: 'Chattogram',
		countryLabel: 'Bangladesh',
		countryValue: 'BD'
	},
	{
		value: 'C',
		label: 'Dhaka',
		countryLabel: 'Bangladesh',
		countryValue: 'BD'
	},
	{
		value: 'D',
		label: 'Khulna',
		countryLabel: 'Bangladesh',
		countryValue: 'BD'
	},
	{
		value: 'E',
		label: 'Rajshahi',
		countryLabel: 'Bangladesh',
		countryValue: 'BD'
	},
	{
		value: 'F',
		label: 'Rangpur',
		countryLabel: 'Bangladesh',
		countryValue: 'BD'
	},
	{
		value: 'G',
		label: 'Sylhet',
		countryLabel: 'Bangladesh',
		countryValue: 'BD'
	},
	{
		value: '1',
		label: 'Christ Church',
		countryLabel: 'Barbados',
		countryValue: 'BB'
	},
	{
		value: '2',
		label: 'Saint Andrew',
		countryLabel: 'Barbados',
		countryValue: 'BB'
	},
	{
		value: '3',
		label: 'Saint George',
		countryLabel: 'Barbados',
		countryValue: 'BB'
	},
	{
		value: '4',
		label: 'Saint James',
		countryLabel: 'Barbados',
		countryValue: 'BB'
	},
	{
		value: '5',
		label: 'Saint John',
		countryLabel: 'Barbados',
		countryValue: 'BB'
	},
	{
		value: '7',
		label: 'Saint Lucy',
		countryLabel: 'Barbados',
		countryValue: 'BB'
	},
	{
		value: '8',
		label: 'Saint Michael',
		countryLabel: 'Barbados',
		countryValue: 'BB'
	},
	{
		value: '9',
		label: 'Saint Peter',
		countryLabel: 'Barbados',
		countryValue: 'BB'
	},
	{
		value: '10',
		label: 'Saint Philip',
		countryLabel: 'Barbados',
		countryValue: 'BB'
	},
	{
		value: '11',
		label: 'Saint Thomas',
		countryLabel: 'Barbados',
		countryValue: 'BB'
	},
	{
		value: 'BR',
		label: "Brestskaya voblasts'",
		countryLabel: 'Belarus',
		countryValue: 'BY'
	},
	{
		value: 'HM',
		label: 'Horad Minsk',
		countryLabel: 'Belarus',
		countryValue: 'BY'
	},
	{
		value: 'HO',
		label: "Homyel'skaya voblasts'",
		countryLabel: 'Belarus',
		countryValue: 'BY'
	},
	{
		value: 'HR',
		label: "Hrodzyenskaya voblasts'",
		countryLabel: 'Belarus',
		countryValue: 'BY'
	},
	{
		value: 'MA',
		label: "Mahilyowskaya voblasts'",
		countryLabel: 'Belarus',
		countryValue: 'BY'
	},
	{
		value: 'MI',
		label: "Minskaya voblasts'",
		countryLabel: 'Belarus',
		countryValue: 'BY'
	},
	{
		value: 'VI',
		label: "Vitsyebskaya voblasts'",
		countryLabel: 'Belarus',
		countryValue: 'BY'
	},
	{
		value: 'BRU',
		label: 'Brussels Hoofdstedelijk Gewest',
		countryLabel: 'Belgium',
		countryValue: 'BE'
	},
	{
		value: 'VAN',
		label: 'Antwerpen',
		countryLabel: 'Belgium',
		countryValue: 'BE'
	},
	{
		value: 'VBR',
		label: 'Vlaams-Brabant',
		countryLabel: 'Belgium',
		countryValue: 'BE'
	},
	{
		value: 'VLI',
		label: 'Limburg',
		countryLabel: 'Belgium',
		countryValue: 'BE'
	},
	{
		value: 'VOV',
		label: 'Oost-Vlaanderen',
		countryLabel: 'Belgium',
		countryValue: 'BE'
	},
	{
		value: 'VWV',
		label: 'West-Vlaanderen',
		countryLabel: 'Belgium',
		countryValue: 'BE'
	},
	{
		value: 'WBR',
		label: 'Brabant wallon',
		countryLabel: 'Belgium',
		countryValue: 'BE'
	},
	{
		value: 'WHT',
		label: 'Hainaut',
		countryLabel: 'Belgium',
		countryValue: 'BE'
	},
	{
		value: 'WLG',
		label: 'Liege',
		countryLabel: 'Belgium',
		countryValue: 'BE'
	},
	{
		value: 'WLX',
		label: 'Luxembourg',
		countryLabel: 'Belgium',
		countryValue: 'BE'
	},
	{
		value: 'WNA',
		label: 'Namur',
		countryLabel: 'Belgium',
		countryValue: 'BE'
	},
	{
		value: 'BZ',
		label: 'Belize',
		countryLabel: 'Belize',
		countryValue: 'BZ'
	},
	{
		value: 'CY',
		label: 'Cayo',
		countryLabel: 'Belize',
		countryValue: 'BZ'
	},
	{
		value: 'CZL',
		label: 'Corozal',
		countryLabel: 'Belize',
		countryValue: 'BZ'
	},
	{
		value: 'OW',
		label: 'Orange Walk',
		countryLabel: 'Belize',
		countryValue: 'BZ'
	},
	{
		value: 'SC',
		label: 'Stann Creek',
		countryLabel: 'Belize',
		countryValue: 'BZ'
	},
	{
		value: 'TOL',
		label: 'Toledo',
		countryLabel: 'Belize',
		countryValue: 'BZ'
	},
	{
		value: 'AK',
		label: 'Atacora',
		countryLabel: 'Benin',
		countryValue: 'BJ'
	},
	{
		value: 'AQ',
		label: 'Atlantique',
		countryLabel: 'Benin',
		countryValue: 'BJ'
	},
	{
		value: 'BO',
		label: 'Borgou',
		countryLabel: 'Benin',
		countryValue: 'BJ'
	},
	{
		value: 'DO',
		label: 'Donga',
		countryLabel: 'Benin',
		countryValue: 'BJ'
	},
	{
		value: 'LI',
		label: 'Littoral',
		countryLabel: 'Benin',
		countryValue: 'BJ'
	},
	{
		value: 'MO',
		label: 'Mono',
		countryLabel: 'Benin',
		countryValue: 'BJ'
	},
	{
		value: 'OU',
		label: 'Oueme',
		countryLabel: 'Benin',
		countryValue: 'BJ'
	},
	{
		value: 'PL',
		label: 'Plateau',
		countryLabel: 'Benin',
		countryValue: 'BJ'
	},
	{
		value: 'ZO',
		label: 'Zou',
		countryLabel: 'Benin',
		countryValue: 'BJ'
	},
	{
		value: '11',
		label: 'Paro',
		countryLabel: 'Bhutan',
		countryValue: 'BT'
	},
	{
		value: '12',
		label: 'Chhukha',
		countryLabel: 'Bhutan',
		countryValue: 'BT'
	},
	{
		value: '13',
		label: 'Haa',
		countryLabel: 'Bhutan',
		countryValue: 'BT'
	},
	{
		value: '14',
		label: 'Samtse',
		countryLabel: 'Bhutan',
		countryValue: 'BT'
	},
	{
		value: '15',
		label: 'Thimphu',
		countryLabel: 'Bhutan',
		countryValue: 'BT'
	},
	{
		value: '21',
		label: 'Tsirang',
		countryLabel: 'Bhutan',
		countryValue: 'BT'
	},
	{
		value: '23',
		label: 'Punakha',
		countryLabel: 'Bhutan',
		countryValue: 'BT'
	},
	{
		value: '24',
		label: 'Wangdue Phodrang',
		countryLabel: 'Bhutan',
		countryValue: 'BT'
	},
	{
		value: '31',
		label: 'Sarpang',
		countryLabel: 'Bhutan',
		countryValue: 'BT'
	},
	{
		value: '32',
		label: 'Trongsa',
		countryLabel: 'Bhutan',
		countryValue: 'BT'
	},
	{
		value: '33',
		label: 'Bumthang',
		countryLabel: 'Bhutan',
		countryValue: 'BT'
	},
	{
		value: '41',
		label: 'Trashigang',
		countryLabel: 'Bhutan',
		countryValue: 'BT'
	},
	{
		value: '42',
		label: 'Monggar',
		countryLabel: 'Bhutan',
		countryValue: 'BT'
	},
	{
		value: '43',
		label: 'Pema Gatshel',
		countryLabel: 'Bhutan',
		countryValue: 'BT'
	},
	{
		value: '44',
		label: 'Lhuentse',
		countryLabel: 'Bhutan',
		countryValue: 'BT'
	},
	{
		value: '45',
		label: 'Samdrup Jongkhar',
		countryLabel: 'Bhutan',
		countryValue: 'BT'
	},
	{
		value: 'GA',
		label: 'Gasa',
		countryLabel: 'Bhutan',
		countryValue: 'BT'
	},
	{
		value: 'B',
		label: 'El Beni',
		countryLabel: 'Bolivia',
		countryValue: 'BO'
	},
	{
		value: 'C',
		label: 'Cochabamba',
		countryLabel: 'Bolivia',
		countryValue: 'BO'
	},
	{
		value: 'H',
		label: 'Chuquisaca',
		countryLabel: 'Bolivia',
		countryValue: 'BO'
	},
	{
		value: 'L',
		label: 'La Paz',
		countryLabel: 'Bolivia',
		countryValue: 'BO'
	},
	{
		value: 'N',
		label: 'Pando',
		countryLabel: 'Bolivia',
		countryValue: 'BO'
	},
	{
		value: 'O',
		label: 'Oruro',
		countryLabel: 'Bolivia',
		countryValue: 'BO'
	},
	{
		value: 'P',
		label: 'Potosi',
		countryLabel: 'Bolivia',
		countryValue: 'BO'
	},
	{
		value: 'S',
		label: 'Santa Cruz',
		countryLabel: 'Bolivia',
		countryValue: 'BO'
	},
	{
		value: 'T',
		label: 'Tarija',
		countryLabel: 'Bolivia',
		countryValue: 'BO'
	},
	{
		value: 'BIH',
		label: 'Federacija Bosne i Hercegovine',
		countryLabel: 'Bosniaand Herzegovina',
		countryValue: 'BA'
	},
	{
		value: 'BRC',
		label: 'Brcko distrikt',
		countryLabel: 'Bosniaand Herzegovina',
		countryValue: 'BA'
	},
	{
		value: 'SRP',
		label: 'Republika Srpska',
		countryLabel: 'Bosniaand Herzegovina',
		countryValue: 'BA'
	},
	{
		value: 'CE',
		label: 'Central',
		countryLabel: 'Botswana',
		countryValue: 'BW'
	},
	{
		value: 'CH',
		label: 'Chobe',
		countryLabel: 'Botswana',
		countryValue: 'BW'
	},
	{
		value: 'GH',
		label: 'Ghanzi',
		countryLabel: 'Botswana',
		countryValue: 'BW'
	},
	{
		value: 'KG',
		label: 'Kgalagadi',
		countryLabel: 'Botswana',
		countryValue: 'BW'
	},
	{
		value: 'KL',
		label: 'Kgatleng',
		countryLabel: 'Botswana',
		countryValue: 'BW'
	},
	{
		value: 'KW',
		label: 'Kweneng',
		countryLabel: 'Botswana',
		countryValue: 'BW'
	},
	{
		value: 'NE',
		label: 'North East',
		countryLabel: 'Botswana',
		countryValue: 'BW'
	},
	{
		value: 'NW',
		label: 'North West',
		countryLabel: 'Botswana',
		countryValue: 'BW'
	},
	{
		value: 'SE',
		label: 'South East',
		countryLabel: 'Botswana',
		countryValue: 'BW'
	},
	{
		value: 'SO',
		label: 'Southern',
		countryLabel: 'Botswana',
		countryValue: 'BW'
	},
	{
		value: 'AC',
		label: 'Acre',
		countryLabel: 'Brazil',
		countryValue: 'BR'
	},
	{
		value: 'AL',
		label: 'Alagoas',
		countryLabel: 'Brazil',
		countryValue: 'BR'
	},
	{
		value: 'AM',
		label: 'Amazonas',
		countryLabel: 'Brazil',
		countryValue: 'BR'
	},
	{
		value: 'AP',
		label: 'Amapa',
		countryLabel: 'Brazil',
		countryValue: 'BR'
	},
	{
		value: 'BA',
		label: 'Bahia',
		countryLabel: 'Brazil',
		countryValue: 'BR'
	},
	{
		value: 'CE',
		label: 'Ceara',
		countryLabel: 'Brazil',
		countryValue: 'BR'
	},
	{
		value: 'DF',
		label: 'Distrito Federal',
		countryLabel: 'Brazil',
		countryValue: 'BR'
	},
	{
		value: 'ES',
		label: 'Espirito Santo',
		countryLabel: 'Brazil',
		countryValue: 'BR'
	},
	{
		value: 'GO',
		label: 'Goias',
		countryLabel: 'Brazil',
		countryValue: 'BR'
	},
	{
		value: 'MA',
		label: 'Maranhao',
		countryLabel: 'Brazil',
		countryValue: 'BR'
	},
	{
		value: 'MG',
		label: 'Minas Gerais',
		countryLabel: 'Brazil',
		countryValue: 'BR'
	},
	{
		value: 'MS',
		label: 'Mato Grosso do Sul',
		countryLabel: 'Brazil',
		countryValue: 'BR'
	},
	{
		value: 'MT',
		label: 'Mato Grosso',
		countryLabel: 'Brazil',
		countryValue: 'BR'
	},
	{
		value: 'PA',
		label: 'Para',
		countryLabel: 'Brazil',
		countryValue: 'BR'
	},
	{
		value: 'PB',
		label: 'Paraiba',
		countryLabel: 'Brazil',
		countryValue: 'BR'
	},
	{
		value: 'PE',
		label: 'Pernambuco',
		countryLabel: 'Brazil',
		countryValue: 'BR'
	},
	{
		value: 'PI',
		label: 'Piaui',
		countryLabel: 'Brazil',
		countryValue: 'BR'
	},
	{
		value: 'PR',
		label: 'Parana',
		countryLabel: 'Brazil',
		countryValue: 'BR'
	},
	{
		value: 'RJ',
		label: 'Rio de Janeiro',
		countryLabel: 'Brazil',
		countryValue: 'BR'
	},
	{
		value: 'RN',
		label: 'Rio Grande do Norte',
		countryLabel: 'Brazil',
		countryValue: 'BR'
	},
	{
		value: 'RO',
		label: 'Rondonia',
		countryLabel: 'Brazil',
		countryValue: 'BR'
	},
	{
		value: 'RR',
		label: 'Roraima',
		countryLabel: 'Brazil',
		countryValue: 'BR'
	},
	{
		value: 'RS',
		label: 'Rio Grande do Sul',
		countryLabel: 'Brazil',
		countryValue: 'BR'
	},
	{
		value: 'SC',
		label: 'Santa Catarina',
		countryLabel: 'Brazil',
		countryValue: 'BR'
	},
	{
		value: 'SE',
		label: 'Sergipe',
		countryLabel: 'Brazil',
		countryValue: 'BR'
	},
	{
		value: 'SP',
		label: 'Sao Paulo',
		countryLabel: 'Brazil',
		countryValue: 'BR'
	},
	{
		value: 'TO',
		label: 'Tocantins',
		countryLabel: 'Brazil',
		countryValue: 'BR'
	},
	{
		value: 'BE',
		label: 'Belait',
		countryLabel: 'Brunei',
		countryValue: 'BN'
	},
	{
		value: 'BM',
		label: 'Brunei-Muara',
		countryLabel: 'Brunei',
		countryValue: 'BN'
	},
	{
		value: 'TE',
		label: 'Temburong',
		countryLabel: 'Brunei',
		countryValue: 'BN'
	},
	{
		value: 'TU',
		label: 'Tutong',
		countryLabel: 'Brunei',
		countryValue: 'BN'
	},
	{
		value: '1',
		label: 'Blagoevgrad',
		countryLabel: 'Bulgaria',
		countryValue: 'BG'
	},
	{
		value: '2',
		label: 'Burgas',
		countryLabel: 'Bulgaria',
		countryValue: 'BG'
	},
	{
		value: '3',
		label: 'Varna',
		countryLabel: 'Bulgaria',
		countryValue: 'BG'
	},
	{
		value: '4',
		label: 'Veliko Tarnovo',
		countryLabel: 'Bulgaria',
		countryValue: 'BG'
	},
	{
		value: '5',
		label: 'Vidin',
		countryLabel: 'Bulgaria',
		countryValue: 'BG'
	},
	{
		value: '6',
		label: 'Vratsa',
		countryLabel: 'Bulgaria',
		countryValue: 'BG'
	},
	{
		value: '7',
		label: 'Gabrovo',
		countryLabel: 'Bulgaria',
		countryValue: 'BG'
	},
	{
		value: '8',
		label: 'Dobrich',
		countryLabel: 'Bulgaria',
		countryValue: 'BG'
	},
	{
		value: '9',
		label: 'Kardzhali',
		countryLabel: 'Bulgaria',
		countryValue: 'BG'
	},
	{
		value: '10',
		label: 'Kyustendil',
		countryLabel: 'Bulgaria',
		countryValue: 'BG'
	},
	{
		value: '11',
		label: 'Lovech',
		countryLabel: 'Bulgaria',
		countryValue: 'BG'
	},
	{
		value: '12',
		label: 'Montana',
		countryLabel: 'Bulgaria',
		countryValue: 'BG'
	},
	{
		value: '13',
		label: 'Pazardzhik',
		countryLabel: 'Bulgaria',
		countryValue: 'BG'
	},
	{
		value: '14',
		label: 'Pernik',
		countryLabel: 'Bulgaria',
		countryValue: 'BG'
	},
	{
		value: '15',
		label: 'Pleven',
		countryLabel: 'Bulgaria',
		countryValue: 'BG'
	},
	{
		value: '16',
		label: 'Plovdiv',
		countryLabel: 'Bulgaria',
		countryValue: 'BG'
	},
	{
		value: '17',
		label: 'Razgrad',
		countryLabel: 'Bulgaria',
		countryValue: 'BG'
	},
	{
		value: '18',
		label: 'Ruse',
		countryLabel: 'Bulgaria',
		countryValue: 'BG'
	},
	{
		value: '19',
		label: 'Silistra',
		countryLabel: 'Bulgaria',
		countryValue: 'BG'
	},
	{
		value: '20',
		label: 'Sliven',
		countryLabel: 'Bulgaria',
		countryValue: 'BG'
	},
	{
		value: '21',
		label: 'Smolyan',
		countryLabel: 'Bulgaria',
		countryValue: 'BG'
	},
	{
		value: '22',
		label: 'Sofia (stolitsa)',
		countryLabel: 'Bulgaria',
		countryValue: 'BG'
	},
	{
		value: '23',
		label: 'Sofia',
		countryLabel: 'Bulgaria',
		countryValue: 'BG'
	},
	{
		value: '24',
		label: 'Stara Zagora',
		countryLabel: 'Bulgaria',
		countryValue: 'BG'
	},
	{
		value: '25',
		label: 'Targovishte',
		countryLabel: 'Bulgaria',
		countryValue: 'BG'
	},
	{
		value: '26',
		label: 'Haskovo',
		countryLabel: 'Bulgaria',
		countryValue: 'BG'
	},
	{
		value: '27',
		label: 'Shumen',
		countryLabel: 'Bulgaria',
		countryValue: 'BG'
	},
	{
		value: '28',
		label: 'Yambol',
		countryLabel: 'Bulgaria',
		countryValue: 'BG'
	},
	{
		value: 'BAM',
		label: 'Bam',
		countryLabel: 'Burkina Faso',
		countryValue: 'BF'
	},
	{
		value: 'BAZ',
		label: 'Bazega',
		countryLabel: 'Burkina Faso',
		countryValue: 'BF'
	},
	{
		value: 'BLG',
		label: 'Boulgou',
		countryLabel: 'Burkina Faso',
		countryValue: 'BF'
	},
	{
		value: 'BLK',
		label: 'Boulkiemde',
		countryLabel: 'Burkina Faso',
		countryValue: 'BF'
	},
	{
		value: 'GAN',
		label: 'Ganzourgou',
		countryLabel: 'Burkina Faso',
		countryValue: 'BF'
	},
	{
		value: 'GNA',
		label: 'Gnagna',
		countryLabel: 'Burkina Faso',
		countryValue: 'BF'
	},
	{
		value: 'GOU',
		label: 'Gourma',
		countryLabel: 'Burkina Faso',
		countryValue: 'BF'
	},
	{
		value: 'HOU',
		label: 'Houet',
		countryLabel: 'Burkina Faso',
		countryValue: 'BF'
	},
	{
		value: 'IOB',
		label: 'Ioba',
		countryLabel: 'Burkina Faso',
		countryValue: 'BF'
	},
	{
		value: 'KAD',
		label: 'Kadiogo',
		countryLabel: 'Burkina Faso',
		countryValue: 'BF'
	},
	{
		value: 'KMD',
		label: 'Komondjari',
		countryLabel: 'Burkina Faso',
		countryValue: 'BF'
	},
	{
		value: 'KMP',
		label: 'Kompienga',
		countryLabel: 'Burkina Faso',
		countryValue: 'BF'
	},
	{
		value: 'KOP',
		label: 'Koulpelogo',
		countryLabel: 'Burkina Faso',
		countryValue: 'BF'
	},
	{
		value: 'KOT',
		label: 'Kouritenga',
		countryLabel: 'Burkina Faso',
		countryValue: 'BF'
	},
	{
		value: 'KOW',
		label: 'Kourweogo',
		countryLabel: 'Burkina Faso',
		countryValue: 'BF'
	},
	{
		value: 'LER',
		label: 'Leraba',
		countryLabel: 'Burkina Faso',
		countryValue: 'BF'
	},
	{
		value: 'LOR',
		label: 'Loroum',
		countryLabel: 'Burkina Faso',
		countryValue: 'BF'
	},
	{
		value: 'MOU',
		label: 'Mouhoun',
		countryLabel: 'Burkina Faso',
		countryValue: 'BF'
	},
	{
		value: 'NAM',
		label: 'Namentenga',
		countryLabel: 'Burkina Faso',
		countryValue: 'BF'
	},
	{
		value: 'NAO',
		label: 'Nahouri',
		countryLabel: 'Burkina Faso',
		countryValue: 'BF'
	},
	{
		value: 'NAY',
		label: 'Nayala',
		countryLabel: 'Burkina Faso',
		countryValue: 'BF'
	},
	{
		value: 'OUB',
		label: 'Oubritenga',
		countryLabel: 'Burkina Faso',
		countryValue: 'BF'
	},
	{
		value: 'OUD',
		label: 'Oudalan',
		countryLabel: 'Burkina Faso',
		countryValue: 'BF'
	},
	{
		value: 'PAS',
		label: 'Passore',
		countryLabel: 'Burkina Faso',
		countryValue: 'BF'
	},
	{
		value: 'PON',
		label: 'Poni',
		countryLabel: 'Burkina Faso',
		countryValue: 'BF'
	},
	{
		value: 'SEN',
		label: 'Seno',
		countryLabel: 'Burkina Faso',
		countryValue: 'BF'
	},
	{
		value: 'SIS',
		label: 'Sissili',
		countryLabel: 'Burkina Faso',
		countryValue: 'BF'
	},
	{
		value: 'SMT',
		label: 'Sanmatenga',
		countryLabel: 'Burkina Faso',
		countryValue: 'BF'
	},
	{
		value: 'SOM',
		label: 'Soum',
		countryLabel: 'Burkina Faso',
		countryValue: 'BF'
	},
	{
		value: 'SOR',
		label: 'Sourou',
		countryLabel: 'Burkina Faso',
		countryValue: 'BF'
	},
	{
		value: 'TAP',
		label: 'Tapoa',
		countryLabel: 'Burkina Faso',
		countryValue: 'BF'
	},
	{
		value: 'TUI',
		label: 'Tuy',
		countryLabel: 'Burkina Faso',
		countryValue: 'BF'
	},
	{
		value: 'YAT',
		label: 'Yatenga',
		countryLabel: 'Burkina Faso',
		countryValue: 'BF'
	},
	{
		value: 'ZIR',
		label: 'Ziro',
		countryLabel: 'Burkina Faso',
		countryValue: 'BF'
	},
	{
		value: 'ZON',
		label: 'Zondoma',
		countryLabel: 'Burkina Faso',
		countryValue: 'BF'
	},
	{
		value: 'ZOU',
		label: 'Zoundweogo',
		countryLabel: 'Burkina Faso',
		countryValue: 'BF'
	},
	{
		value: 'BM',
		label: 'Bujumbura Mairie',
		countryLabel: 'Burundi',
		countryValue: 'BI'
	},
	{
		value: 'CI',
		label: 'Cibitoke',
		countryLabel: 'Burundi',
		countryValue: 'BI'
	},
	{
		value: 'GI',
		label: 'Gitega',
		countryLabel: 'Burundi',
		countryValue: 'BI'
	},
	{
		value: 'KI',
		label: 'Kirundo',
		countryLabel: 'Burundi',
		countryValue: 'BI'
	},
	{
		value: 'MW',
		label: 'Mwaro',
		countryLabel: 'Burundi',
		countryValue: 'BI'
	},
	{
		value: 'NG',
		label: 'Ngozi',
		countryLabel: 'Burundi',
		countryValue: 'BI'
	},
	{
		value: 'RM',
		label: 'Rumonge',
		countryLabel: 'Burundi',
		countryValue: 'BI'
	},
	{
		value: 'RT',
		label: 'Rutana',
		countryLabel: 'Burundi',
		countryValue: 'BI'
	},
	{
		value: 'RY',
		label: 'Ruyigi',
		countryLabel: 'Burundi',
		countryValue: 'BI'
	},
	{
		value: '1',
		label: 'Banteay Mean Choay',
		countryLabel: 'Cambodia',
		countryValue: 'KH'
	},
	{
		value: '2',
		label: 'Baat Dambang',
		countryLabel: 'Cambodia',
		countryValue: 'KH'
	},
	{
		value: '3',
		label: 'Kampong Chaam',
		countryLabel: 'Cambodia',
		countryValue: 'KH'
	},
	{
		value: '4',
		label: 'Kampong Chhnang',
		countryLabel: 'Cambodia',
		countryValue: 'KH'
	},
	{
		value: '5',
		label: 'Kampong Spueu',
		countryLabel: 'Cambodia',
		countryValue: 'KH'
	},
	{
		value: '6',
		label: 'Kampong Thum',
		countryLabel: 'Cambodia',
		countryValue: 'KH'
	},
	{
		value: '7',
		label: 'Kampot',
		countryLabel: 'Cambodia',
		countryValue: 'KH'
	},
	{
		value: '8',
		label: 'Kandaal',
		countryLabel: 'Cambodia',
		countryValue: 'KH'
	},
	{
		value: '9',
		label: 'Kaoh Kong',
		countryLabel: 'Cambodia',
		countryValue: 'KH'
	},
	{
		value: '10',
		label: 'Kracheh',
		countryLabel: 'Cambodia',
		countryValue: 'KH'
	},
	{
		value: '11',
		label: 'Mondol Kiri',
		countryLabel: 'Cambodia',
		countryValue: 'KH'
	},
	{
		value: '12',
		label: 'Phnom Penh',
		countryLabel: 'Cambodia',
		countryValue: 'KH'
	},
	{
		value: '14',
		label: 'Prey Veaeng',
		countryLabel: 'Cambodia',
		countryValue: 'KH'
	},
	{
		value: '15',
		label: 'Pousaat',
		countryLabel: 'Cambodia',
		countryValue: 'KH'
	},
	{
		value: '16',
		label: 'Rotanak Kiri',
		countryLabel: 'Cambodia',
		countryValue: 'KH'
	},
	{
		value: '17',
		label: 'Siem Reab',
		countryLabel: 'Cambodia',
		countryValue: 'KH'
	},
	{
		value: '18',
		label: 'Preah Sihanouk',
		countryLabel: 'Cambodia',
		countryValue: 'KH'
	},
	{
		value: '19',
		label: 'Stueng Traeng',
		countryLabel: 'Cambodia',
		countryValue: 'KH'
	},
	{
		value: '20',
		label: 'Svaay Rieng',
		countryLabel: 'Cambodia',
		countryValue: 'KH'
	},
	{
		value: '21',
		label: 'Taakaev',
		countryLabel: 'Cambodia',
		countryValue: 'KH'
	},
	{
		value: '22',
		label: 'Otdar Mean Chey',
		countryLabel: 'Cambodia',
		countryValue: 'KH'
	},
	{
		value: '23',
		label: 'Krong Kaeb',
		countryLabel: 'Cambodia',
		countryValue: 'KH'
	},
	{
		value: '24',
		label: 'Krong Pailin',
		countryLabel: 'Cambodia',
		countryValue: 'KH'
	},
	{
		value: 'AD',
		label: 'Adamaoua',
		countryLabel: 'Cameroon',
		countryValue: 'CM'
	},
	{
		value: 'CE',
		label: 'Centre',
		countryLabel: 'Cameroon',
		countryValue: 'CM'
	},
	{
		value: 'EN',
		label: 'Extreme-Nord',
		countryLabel: 'Cameroon',
		countryValue: 'CM'
	},
	{
		value: 'ES',
		label: 'Est',
		countryLabel: 'Cameroon',
		countryValue: 'CM'
	},
	{
		value: 'LT',
		label: 'Littoral',
		countryLabel: 'Cameroon',
		countryValue: 'CM'
	},
	{
		value: 'NO',
		label: 'Nord',
		countryLabel: 'Cameroon',
		countryValue: 'CM'
	},
	{
		value: 'NW',
		label: 'Nord-Ouest',
		countryLabel: 'Cameroon',
		countryValue: 'CM'
	},
	{
		value: 'OU',
		label: 'Ouest',
		countryLabel: 'Cameroon',
		countryValue: 'CM'
	},
	{
		value: 'SU',
		label: 'Sud',
		countryLabel: 'Cameroon',
		countryValue: 'CM'
	},
	{
		value: 'SW',
		label: 'Sud-Ouest',
		countryLabel: 'Cameroon',
		countryValue: 'CM'
	},
	{
		value: 'AB',
		label: 'Alberta',
		countryLabel: 'Canada',
		countryValue: 'CA'
	},
	{
		value: 'BC',
		label: 'British Columbia',
		countryLabel: 'Canada',
		countryValue: 'CA'
	},
	{
		value: 'MB',
		label: 'Manitoba',
		countryLabel: 'Canada',
		countryValue: 'CA'
	},
	{
		value: 'NB',
		label: 'New Brunswick',
		countryLabel: 'Canada',
		countryValue: 'CA'
	},
	{
		value: 'NF',
		label: 'Newfoundland',
		countryLabel: 'Canada',
		countryValue: 'CA'
	},
	{
		value: 'NL',
		label: 'Newfoundland and Labrador',
		countryLabel: 'Canada',
		countryValue: 'CA'
	},
	{
		value: 'NS',
		label: 'Nova Scotia',
		countryLabel: 'Canada',
		countryValue: 'CA'
	},
	{
		value: 'NT',
		label: 'Northwest Territorie',
		countryLabel: 'Canada',
		countryValue: 'CA'
	},
	{
		value: 'NU',
		label: 'Nunavut',
		countryLabel: 'Canada',
		countryValue: 'CA'
	},
	{
		value: 'ON',
		label: 'Ontario',
		countryLabel: 'Canada',
		countryValue: 'CA'
	},
	{
		value: 'PE',
		label: 'Prince Edward Island',
		countryLabel: 'Canada',
		countryValue: 'CA'
	},
	{
		value: 'QC',
		label: 'Quebec',
		countryLabel: 'Canada',
		countryValue: 'CA'
	},
	{
		value: 'SK',
		label: 'Saskatchewan',
		countryLabel: 'Canada',
		countryValue: 'CA'
	},
	{
		value: 'YT',
		label: 'Yukon Territory',
		countryLabel: 'Canada',
		countryValue: 'CA'
	},
	{
		value: 'BR',
		label: 'Brava',
		countryLabel: 'Cape verde',
		countryValue: 'CV'
	},
	{
		value: 'BV',
		label: 'Boa Vista',
		countryLabel: 'Cape verde',
		countryValue: 'CV'
	},
	{
		value: 'CR',
		label: 'Santa Cruz',
		countryLabel: 'Cape verde',
		countryValue: 'CV'
	},
	{
		value: 'PN',
		label: 'Porto Novo',
		countryLabel: 'Cape verde',
		countryValue: 'CV'
	},
	{
		value: 'PR',
		label: 'Praia',
		countryLabel: 'Cape verde',
		countryValue: 'CV'
	},
	{
		value: 'RS',
		label: 'Ribeira Grande de Santiago',
		countryLabel: 'Cape verde',
		countryValue: 'CV'
	},
	{
		value: 'SL',
		label: 'Sal',
		countryLabel: 'Cape verde',
		countryValue: 'CV'
	},
	{
		value: 'SV',
		label: 'Sao Vicente',
		countryLabel: 'Cape verde',
		countryValue: 'CV'
	},
	{
		value: 'TA',
		label: 'Tarrafal',
		countryLabel: 'Cape verde',
		countryValue: 'CV'
	},
	{
		value: 'AC',
		label: 'Ouham',
		countryLabel: 'Central african republic',
		countryValue: 'CF'
	},
	{
		value: 'BB',
		label: 'Bamingui-Bangoran',
		countryLabel: 'Central african republic',
		countryValue: 'CF'
	},
	{
		value: 'BGF',
		label: 'Bangui',
		countryLabel: 'Central african republic',
		countryValue: 'CF'
	},
	{
		value: 'KB',
		label: 'Gribingui',
		countryLabel: 'Central african republic',
		countryValue: 'CF'
	},
	{
		value: 'KG',
		label: 'Kemo-Gribingui',
		countryLabel: 'Central african republic',
		countryValue: 'CF'
	},
	{
		value: 'NM',
		label: 'Nana-Mambere',
		countryLabel: 'Central african republic',
		countryValue: 'CF'
	},
	{
		value: 'OP',
		label: 'Ouham-Pende',
		countryLabel: 'Central african republic',
		countryValue: 'CF'
	},
	{
		value: 'GR',
		label: 'Guera',
		countryLabel: 'Chad',
		countryValue: 'TD'
	},
	{
		value: 'LO',
		label: 'Logone-Occidental',
		countryLabel: 'Chad',
		countryValue: 'TD'
	},
	{
		value: 'LR',
		label: 'Logone-Oriental',
		countryLabel: 'Chad',
		countryValue: 'TD'
	},
	{
		value: 'ME',
		label: 'Mayo-Kebbi-Est',
		countryLabel: 'Chad',
		countryValue: 'TD'
	},
	{
		value: 'ND',
		label: 'Ville de Ndjamena',
		countryLabel: 'Chad',
		countryValue: 'TD'
	},
	{
		value: 'OD',
		label: 'Ouaddai',
		countryLabel: 'Chad',
		countryValue: 'TD'
	},
	{
		value: 'TA',
		label: 'Tandjile',
		countryLabel: 'Chad',
		countryValue: 'TD'
	},
	{
		value: 'AI',
		label: 'Aisen del General Carlos Ibanez del Campo',
		countryLabel: 'Chile',
		countryValue: 'CL'
	},
	{
		value: 'AN',
		label: 'Antofagasta',
		countryLabel: 'Chile',
		countryValue: 'CL'
	},
	{
		value: 'AP',
		label: 'Arica y Parinacota',
		countryLabel: 'Chile',
		countryValue: 'CL'
	},
	{
		value: 'AR',
		label: 'La Araucania',
		countryLabel: 'Chile',
		countryValue: 'CL'
	},
	{
		value: 'AT',
		label: 'Atacama',
		countryLabel: 'Chile',
		countryValue: 'CL'
	},
	{
		value: 'BI',
		label: 'Biobio',
		countryLabel: 'Chile',
		countryValue: 'CL'
	},
	{
		value: 'CO',
		label: 'Coquimbo',
		countryLabel: 'Chile',
		countryValue: 'CL'
	},
	{
		value: 'LI',
		label: "Libertador General Bernardo O'Higgins",
		countryLabel: 'Chile',
		countryValue: 'CL'
	},
	{
		value: 'LL',
		label: 'Los Lagos',
		countryLabel: 'Chile',
		countryValue: 'CL'
	},
	{
		value: 'LR',
		label: 'Los Rios',
		countryLabel: 'Chile',
		countryValue: 'CL'
	},
	{
		value: 'MA',
		label: 'Magallanes',
		countryLabel: 'Chile',
		countryValue: 'CL'
	},
	{
		value: 'ML',
		label: 'Maule',
		countryLabel: 'Chile',
		countryValue: 'CL'
	},
	{
		value: 'NB',
		label: 'Nuble',
		countryLabel: 'Chile',
		countryValue: 'CL'
	},
	{
		value: 'RM',
		label: 'Region Metropolitana de Santiago',
		countryLabel: 'Chile',
		countryValue: 'CL'
	},
	{
		value: 'TA',
		label: 'Tarapaca',
		countryLabel: 'Chile',
		countryValue: 'CL'
	},
	{
		value: 'VS',
		label: 'Valparaiso',
		countryLabel: 'Chile',
		countryValue: 'CL'
	},
	{
		value: 'AH',
		label: 'Anhui',
		countryLabel: 'China',
		countryValue: 'CN'
	},
	{
		value: 'BJ',
		label: 'Beijing',
		countryLabel: 'China',
		countryValue: 'CN'
	},
	{
		value: 'CQ',
		label: 'Chongqing',
		countryLabel: 'China',
		countryValue: 'CN'
	},
	{
		value: 'FJ',
		label: 'Fujian',
		countryLabel: 'China',
		countryValue: 'CN'
	},
	{
		value: 'GD',
		label: 'Guangdong',
		countryLabel: 'China',
		countryValue: 'CN'
	},
	{
		value: 'GS',
		label: 'Gansu',
		countryLabel: 'China',
		countryValue: 'CN'
	},
	{
		value: 'GX',
		label: 'Guangxi',
		countryLabel: 'China',
		countryValue: 'CN'
	},
	{
		value: 'GZ',
		label: 'Guizhou',
		countryLabel: 'China',
		countryValue: 'CN'
	},
	{
		value: 'HA',
		label: 'Henan',
		countryLabel: 'China',
		countryValue: 'CN'
	},
	{
		value: 'HB',
		label: 'Hubei',
		countryLabel: 'China',
		countryValue: 'CN'
	},
	{
		value: 'HE',
		label: 'Hebei',
		countryLabel: 'China',
		countryValue: 'CN'
	},
	{
		value: 'HI',
		label: 'Hainan',
		countryLabel: 'China',
		countryValue: 'CN'
	},
	{
		value: 'HL',
		label: 'Heilongjiang',
		countryLabel: 'China',
		countryValue: 'CN'
	},
	{
		value: 'HN',
		label: 'Hunan',
		countryLabel: 'China',
		countryValue: 'CN'
	},
	{
		value: 'JL',
		label: 'Jilin',
		countryLabel: 'China',
		countryValue: 'CN'
	},
	{
		value: 'JS',
		label: 'Jiangsu',
		countryLabel: 'China',
		countryValue: 'CN'
	},
	{
		value: 'JX',
		label: 'Jiangxi',
		countryLabel: 'China',
		countryValue: 'CN'
	},
	{
		value: 'LN',
		label: 'Liaoning',
		countryLabel: 'China',
		countryValue: 'CN'
	},
	{
		value: 'NM',
		label: 'Nei Mongol',
		countryLabel: 'China',
		countryValue: 'CN'
	},
	{
		value: 'NX',
		label: 'Ningxia',
		countryLabel: 'China',
		countryValue: 'CN'
	},
	{
		value: 'QH',
		label: 'Qinghai',
		countryLabel: 'China',
		countryValue: 'CN'
	},
	{
		value: 'SC',
		label: 'Sichuan',
		countryLabel: 'China',
		countryValue: 'CN'
	},
	{
		value: 'SD',
		label: 'Shandong',
		countryLabel: 'China',
		countryValue: 'CN'
	},
	{
		value: 'SH',
		label: 'Shanghai',
		countryLabel: 'China',
		countryValue: 'CN'
	},
	{
		value: 'SN',
		label: 'Shaanxi',
		countryLabel: 'China',
		countryValue: 'CN'
	},
	{
		value: 'SX',
		label: 'Shanxi',
		countryLabel: 'China',
		countryValue: 'CN'
	},
	{
		value: 'TJ',
		label: 'Tianjin',
		countryLabel: 'China',
		countryValue: 'CN'
	},
	{
		value: 'XJ',
		label: 'Xinjiang',
		countryLabel: 'China',
		countryValue: 'CN'
	},
	{
		value: 'XZ',
		label: 'Xizang',
		countryLabel: 'China',
		countryValue: 'CN'
	},
	{
		value: 'YN',
		label: 'Yunnan',
		countryLabel: 'China',
		countryValue: 'CN'
	},
	{
		value: 'ZJ',
		label: 'Zhejiang',
		countryLabel: 'China',
		countryValue: 'CN'
	},
	{
		value: 'AMA',
		label: 'Amazonas',
		countryLabel: 'Colombia',
		countryValue: 'CO'
	},
	{
		value: 'ANT',
		label: 'Antioquia',
		countryLabel: 'Colombia',
		countryValue: 'CO'
	},
	{
		value: 'ARA',
		label: 'Arauca',
		countryLabel: 'Colombia',
		countryValue: 'CO'
	},
	{
		value: 'ATL',
		label: 'Atlantico',
		countryLabel: 'Colombia',
		countryValue: 'CO'
	},
	{
		value: 'BOL',
		label: 'Bolivar',
		countryLabel: 'Colombia',
		countryValue: 'CO'
	},
	{
		value: 'BOY',
		label: 'Boyaca',
		countryLabel: 'Colombia',
		countryValue: 'CO'
	},
	{
		value: 'CAL',
		label: 'Caldas',
		countryLabel: 'Colombia',
		countryValue: 'CO'
	},
	{
		value: 'CAQ',
		label: 'Caqueta',
		countryLabel: 'Colombia',
		countryValue: 'CO'
	},
	{
		value: 'CAS',
		label: 'Casanare',
		countryLabel: 'Colombia',
		countryValue: 'CO'
	},
	{
		value: 'CAU',
		label: 'Cauca',
		countryLabel: 'Colombia',
		countryValue: 'CO'
	},
	{
		value: 'CES',
		label: 'Cesar',
		countryLabel: 'Colombia',
		countryValue: 'CO'
	},
	{
		value: 'CHO',
		label: 'Choco',
		countryLabel: 'Colombia',
		countryValue: 'CO'
	},
	{
		value: 'COR',
		label: 'Cordoba',
		countryLabel: 'Colombia',
		countryValue: 'CO'
	},
	{
		value: 'CUN',
		label: 'Cundinamarca',
		countryLabel: 'Colombia',
		countryValue: 'CO'
	},
	{
		value: 'DC',
		label: 'Distrito Capital de Bogota',
		countryLabel: 'Colombia',
		countryValue: 'CO'
	},
	{
		value: 'GUA',
		label: 'Guainia',
		countryLabel: 'Colombia',
		countryValue: 'CO'
	},
	{
		value: 'GUV',
		label: 'Guaviare',
		countryLabel: 'Colombia',
		countryValue: 'CO'
	},
	{
		value: 'HUI',
		label: 'Huila',
		countryLabel: 'Colombia',
		countryValue: 'CO'
	},
	{
		value: 'LAG',
		label: 'La Guajira',
		countryLabel: 'Colombia',
		countryValue: 'CO'
	},
	{
		value: 'MAG',
		label: 'Magdalena',
		countryLabel: 'Colombia',
		countryValue: 'CO'
	},
	{
		value: 'MET',
		label: 'Meta',
		countryLabel: 'Colombia',
		countryValue: 'CO'
	},
	{
		value: 'NAR',
		label: 'Narino',
		countryLabel: 'Colombia',
		countryValue: 'CO'
	},
	{
		value: 'NSA',
		label: 'Norte de Santander',
		countryLabel: 'Colombia',
		countryValue: 'CO'
	},
	{
		value: 'PUT',
		label: 'Putumayo',
		countryLabel: 'Colombia',
		countryValue: 'CO'
	},
	{
		value: 'QUI',
		label: 'Quindio',
		countryLabel: 'Colombia',
		countryValue: 'CO'
	},
	{
		value: 'RIS',
		label: 'Risaralda',
		countryLabel: 'Colombia',
		countryValue: 'CO'
	},
	{
		value: 'SAN',
		label: 'Santander',
		countryLabel: 'Colombia',
		countryValue: 'CO'
	},
	{
		value: 'SAP',
		label: 'San Andres',
		countryLabel: 'Colombia',
		countryValue: 'CO'
	},
	{
		value: 'SUC',
		label: 'Sucre',
		countryLabel: 'Colombia',
		countryValue: 'CO'
	},
	{
		value: 'TOL',
		label: 'Tolima',
		countryLabel: 'Colombia',
		countryValue: 'CO'
	},
	{
		value: 'VAC',
		label: 'Valle del Cauca',
		countryLabel: 'Colombia',
		countryValue: 'CO'
	},
	{
		value: 'VID',
		label: 'Vichada',
		countryLabel: 'Colombia',
		countryValue: 'CO'
	},
	{
		value: 'G',
		label: 'Grande Comore',
		countryLabel: 'Comoros',
		countryValue: 'KM'
	},
	{
		value: 'EQ',
		label: 'Equateur',
		countryLabel: 'Congo, DRC',
		countryValue: 'CD'
	},
	{
		value: 'HK',
		label: 'Haut-Katanga',
		countryLabel: 'Congo, DRC',
		countryValue: 'CD'
	},
	{
		value: 'HL',
		label: 'Haut-Lomami',
		countryLabel: 'Congo, DRC',
		countryValue: 'CD'
	},
	{
		value: 'IT',
		label: 'Ituri',
		countryLabel: 'Congo, DRC',
		countryValue: 'CD'
	},
	{
		value: 'KC',
		label: 'Kasai Central',
		countryLabel: 'Congo, DRC',
		countryValue: 'CD'
	},
	{
		value: 'KE',
		label: 'Kasai Oriental',
		countryLabel: 'Congo, DRC',
		countryValue: 'CD'
	},
	{
		value: 'KG',
		label: 'Kwango',
		countryLabel: 'Congo, DRC',
		countryValue: 'CD'
	},
	{
		value: 'KL',
		label: 'Kwilu',
		countryLabel: 'Congo, DRC',
		countryValue: 'CD'
	},
	{
		value: 'KN',
		label: 'Kinshasa',
		countryLabel: 'Congo, DRC',
		countryValue: 'CD'
	},
	{
		value: 'LU',
		label: 'Lualaba',
		countryLabel: 'Congo, DRC',
		countryValue: 'CD'
	},
	{
		value: 'MA',
		label: 'Maniema',
		countryLabel: 'Congo, DRC',
		countryValue: 'CD'
	},
	{
		value: 'NK',
		label: 'Nord-Kivu',
		countryLabel: 'Congo, DRC',
		countryValue: 'CD'
	},
	{
		value: 'SA',
		label: 'Sankuru',
		countryLabel: 'Congo, DRC',
		countryValue: 'CD'
	},
	{
		value: 'SK',
		label: 'Sud-Kivu',
		countryLabel: 'Congo, DRC',
		countryValue: 'CD'
	},
	{
		value: 'TA',
		label: 'Tanganyika',
		countryLabel: 'Congo, DRC',
		countryValue: 'CD'
	},
	{
		value: 'TO',
		label: 'Tshopo',
		countryLabel: 'Congo, DRC',
		countryValue: 'CD'
	},
	{
		value: '8',
		label: 'Cuvette',
		countryLabel: 'Congo, ROC',
		countryValue: 'CG'
	},
	{
		value: '13',
		label: 'Sangha',
		countryLabel: 'Congo, ROC',
		countryValue: 'CG'
	},
	{
		value: '16',
		label: 'Pointe-Noire',
		countryLabel: 'Congo, ROC',
		countryValue: 'CG'
	},
	{
		value: 'BZV',
		label: 'Brazzaville',
		countryLabel: 'Congo, ROC',
		countryValue: 'CG'
	},
	{
		value: 'A',
		label: 'Alajuela',
		countryLabel: 'Costa Rica',
		countryValue: 'CR'
	},
	{
		value: 'C',
		label: 'Cartago',
		countryLabel: 'Costa Rica',
		countryValue: 'CR'
	},
	{
		value: 'G',
		label: 'Guanacaste',
		countryLabel: 'Costa Rica',
		countryValue: 'CR'
	},
	{
		value: 'H',
		label: 'Heredia',
		countryLabel: 'Costa Rica',
		countryValue: 'CR'
	},
	{
		value: 'L',
		label: 'Limon',
		countryLabel: 'Costa Rica',
		countryValue: 'CR'
	},
	{
		value: 'P',
		label: 'Puntarenas',
		countryLabel: 'Costa Rica',
		countryValue: 'CR'
	},
	{
		value: 'SJ',
		label: 'San Jose',
		countryLabel: 'Costa Rica',
		countryValue: 'CR'
	},
	{
		value: '1',
		label: 'Zagrebacka zupanija',
		countryLabel: 'Croatia',
		countryValue: 'HR'
	},
	{
		value: '2',
		label: 'Krapinsko-zagorska zupanija',
		countryLabel: 'Croatia',
		countryValue: 'HR'
	},
	{
		value: '3',
		label: 'Sisacko-moslavacka zupanija',
		countryLabel: 'Croatia',
		countryValue: 'HR'
	},
	{
		value: '4',
		label: 'Karlovacka zupanija',
		countryLabel: 'Croatia',
		countryValue: 'HR'
	},
	{
		value: '5',
		label: 'Varazdinska zupanija',
		countryLabel: 'Croatia',
		countryValue: 'HR'
	},
	{
		value: '6',
		label: 'Koprivnicko-krizevacka zupanija',
		countryLabel: 'Croatia',
		countryValue: 'HR'
	},
	{
		value: '7',
		label: 'Bjelovarsko-bilogorska zupanija',
		countryLabel: 'Croatia',
		countryValue: 'HR'
	},
	{
		value: '8',
		label: 'Primorsko-goranska zupanija',
		countryLabel: 'Croatia',
		countryValue: 'HR'
	},
	{
		value: '9',
		label: 'Licko-senjska zupanija',
		countryLabel: 'Croatia',
		countryValue: 'HR'
	},
	{
		value: '10',
		label: 'Viroviticko-podravska zupanija',
		countryLabel: 'Croatia',
		countryValue: 'HR'
	},
	{
		value: '11',
		label: 'Pozesko-slavonska zupanija',
		countryLabel: 'Croatia',
		countryValue: 'HR'
	},
	{
		value: '12',
		label: 'Brodsko-posavska zupanija',
		countryLabel: 'Croatia',
		countryValue: 'HR'
	},
	{
		value: '13',
		label: 'Zadarska zupanija',
		countryLabel: 'Croatia',
		countryValue: 'HR'
	},
	{
		value: '14',
		label: 'Osjecko-baranjska zupanija',
		countryLabel: 'Croatia',
		countryValue: 'HR'
	},
	{
		value: '15',
		label: 'Sibensko-kninska zupanija',
		countryLabel: 'Croatia',
		countryValue: 'HR'
	},
	{
		value: '16',
		label: 'Vukovarsko-srijemska zupanija',
		countryLabel: 'Croatia',
		countryValue: 'HR'
	},
	{
		value: '17',
		label: 'Splitsko-dalmatinska zupanija',
		countryLabel: 'Croatia',
		countryValue: 'HR'
	},
	{
		value: '18',
		label: 'Istarska zupanija',
		countryLabel: 'Croatia',
		countryValue: 'HR'
	},
	{
		value: '19',
		label: 'Dubrovacko-neretvanska zupanija',
		countryLabel: 'Croatia',
		countryValue: 'HR'
	},
	{
		value: '20',
		label: 'Medimurska zupanija',
		countryLabel: 'Croatia',
		countryValue: 'HR'
	},
	{
		value: '21',
		label: 'Grad Zagreb',
		countryLabel: 'Croatia',
		countryValue: 'HR'
	},
	{
		value: '1',
		label: 'Pinar del Rio',
		countryLabel: 'Cuba',
		countryValue: 'CU'
	},
	{
		value: '3',
		label: 'La Habana',
		countryLabel: 'Cuba',
		countryValue: 'CU'
	},
	{
		value: '4',
		label: 'Matanzas',
		countryLabel: 'Cuba',
		countryValue: 'CU'
	},
	{
		value: '5',
		label: 'Villa Clara',
		countryLabel: 'Cuba',
		countryValue: 'CU'
	},
	{
		value: '6',
		label: 'Cienfuegos',
		countryLabel: 'Cuba',
		countryValue: 'CU'
	},
	{
		value: '7',
		label: 'Sancti Spiritus',
		countryLabel: 'Cuba',
		countryValue: 'CU'
	},
	{
		value: '8',
		label: 'Ciego de Avila',
		countryLabel: 'Cuba',
		countryValue: 'CU'
	},
	{
		value: '9',
		label: 'Camaguey',
		countryLabel: 'Cuba',
		countryValue: 'CU'
	},
	{
		value: '10',
		label: 'Las Tunas',
		countryLabel: 'Cuba',
		countryValue: 'CU'
	},
	{
		value: '11',
		label: 'Holguin',
		countryLabel: 'Cuba',
		countryValue: 'CU'
	},
	{
		value: '12',
		label: 'Granma',
		countryLabel: 'Cuba',
		countryValue: 'CU'
	},
	{
		value: '13',
		label: 'Santiago de Cuba',
		countryLabel: 'Cuba',
		countryValue: 'CU'
	},
	{
		value: '14',
		label: 'Guantanamo',
		countryLabel: 'Cuba',
		countryValue: 'CU'
	},
	{
		value: '15',
		label: 'Artemisa',
		countryLabel: 'Cuba',
		countryValue: 'CU'
	},
	{
		value: '16',
		label: 'Mayabeque',
		countryLabel: 'Cuba',
		countryValue: 'CU'
	},
	{
		value: '1',
		label: 'Lefkosia',
		countryLabel: 'Cyprus',
		countryValue: 'CY'
	},
	{
		value: '2',
		label: 'Lemesos',
		countryLabel: 'Cyprus',
		countryValue: 'CY'
	},
	{
		value: '3',
		label: 'Larnaka',
		countryLabel: 'Cyprus',
		countryValue: 'CY'
	},
	{
		value: '4',
		label: 'Ammochostos',
		countryLabel: 'Cyprus',
		countryValue: 'CY'
	},
	{
		value: '5',
		label: 'Pafos',
		countryLabel: 'Cyprus',
		countryValue: 'CY'
	},
	{
		value: '6',
		label: 'Keryneia',
		countryLabel: 'Cyprus',
		countryValue: 'CY'
	},
	{
		value: '10',
		label: 'Praha',
		countryLabel: 'Czech Republic',
		countryValue: 'CZ'
	},
	{
		value: '20',
		label: 'Stredocesky kraj',
		countryLabel: 'Czech Republic',
		countryValue: 'CZ'
	},
	{
		value: '31',
		label: 'Jihocesky kraj',
		countryLabel: 'Czech Republic',
		countryValue: 'CZ'
	},
	{
		value: '32',
		label: 'Plzensky kraj',
		countryLabel: 'Czech Republic',
		countryValue: 'CZ'
	},
	{
		value: '41',
		label: 'Karlovarsky kraj',
		countryLabel: 'Czech Republic',
		countryValue: 'CZ'
	},
	{
		value: '42',
		label: 'Ustecky kraj',
		countryLabel: 'Czech Republic',
		countryValue: 'CZ'
	},
	{
		value: '51',
		label: 'Liberecky kraj',
		countryLabel: 'Czech Republic',
		countryValue: 'CZ'
	},
	{
		value: '52',
		label: 'Kralovehradecky kraj',
		countryLabel: 'Czech Republic',
		countryValue: 'CZ'
	},
	{
		value: '53',
		label: 'Pardubicky kraj',
		countryLabel: 'Czech Republic',
		countryValue: 'CZ'
	},
	{
		value: '63',
		label: 'Kraj Vysocina',
		countryLabel: 'Czech Republic',
		countryValue: 'CZ'
	},
	{
		value: '64',
		label: 'Jihomoravsky kraj',
		countryLabel: 'Czech Republic',
		countryValue: 'CZ'
	},
	{
		value: '71',
		label: 'Olomoucky kraj',
		countryLabel: 'Czech Republic',
		countryValue: 'CZ'
	},
	{
		value: '72',
		label: 'Zlinsky kraj',
		countryLabel: 'Czech Republic',
		countryValue: 'CZ'
	},
	{
		value: '80',
		label: 'Moravskoslezsky kraj',
		countryLabel: 'Czech Republic',
		countryValue: 'CZ'
	},
	{
		value: '81',
		label: 'Nordjylland',
		countryLabel: 'Denmark',
		countryValue: 'DK'
	},
	{
		value: '82',
		label: 'Midtjylland',
		countryLabel: 'Denmark',
		countryValue: 'DK'
	},
	{
		value: '83',
		label: 'Syddanmark',
		countryLabel: 'Denmark',
		countryValue: 'DK'
	},
	{
		value: '84',
		label: 'Hovedstaden',
		countryLabel: 'Denmark',
		countryValue: 'DK'
	},
	{
		value: '85',
		label: 'Sjaelland',
		countryLabel: 'Denmark',
		countryValue: 'DK'
	},
	{
		value: 'AR',
		label: 'Arta',
		countryLabel: 'Djibouti',
		countryValue: 'DJ'
	},
	{
		value: 'DJ',
		label: 'Djibouti',
		countryLabel: 'Djibouti',
		countryValue: 'DJ'
	},
	{
		value: '2',
		label: 'Saint Andrew',
		countryLabel: 'Dominica',
		countryValue: 'DM'
	},
	{
		value: '4',
		label: 'Saint George',
		countryLabel: 'Dominica',
		countryValue: 'DM'
	},
	{
		value: '5',
		label: 'Saint John',
		countryLabel: 'Dominica',
		countryValue: 'DM'
	},
	{
		value: '9',
		label: 'Saint Patrick',
		countryLabel: 'Dominica',
		countryValue: 'DM'
	},
	{
		value: '10',
		label: 'Saint Paul',
		countryLabel: 'Dominica',
		countryValue: 'DM'
	},
	{
		value: '1',
		label: 'Distrito Nacional (Santo Domingo)',
		countryLabel: 'Dominican Republic',
		countryValue: 'DO'
	},
	{
		value: '2',
		label: 'Azua',
		countryLabel: 'Dominican Republic',
		countryValue: 'DO'
	},
	{
		value: '3',
		label: 'Baoruco',
		countryLabel: 'Dominican Republic',
		countryValue: 'DO'
	},
	{
		value: '4',
		label: 'Barahona',
		countryLabel: 'Dominican Republic',
		countryValue: 'DO'
	},
	{
		value: '5',
		label: 'Dajabon',
		countryLabel: 'Dominican Republic',
		countryValue: 'DO'
	},
	{
		value: '6',
		label: 'Duarte',
		countryLabel: 'Dominican Republic',
		countryValue: 'DO'
	},
	{
		value: '7',
		label: 'Elias Pina',
		countryLabel: 'Dominican Republic',
		countryValue: 'DO'
	},
	{
		value: '8',
		label: 'El Seibo',
		countryLabel: 'Dominican Republic',
		countryValue: 'DO'
	},
	{
		value: '9',
		label: 'Espaillat',
		countryLabel: 'Dominican Republic',
		countryValue: 'DO'
	},
	{
		value: '10',
		label: 'Independencia',
		countryLabel: 'Dominican Republic',
		countryValue: 'DO'
	},
	{
		value: '11',
		label: 'La Altagracia',
		countryLabel: 'Dominican Republic',
		countryValue: 'DO'
	},
	{
		value: '12',
		label: 'La Romana',
		countryLabel: 'Dominican Republic',
		countryValue: 'DO'
	},
	{
		value: '13',
		label: 'La Vega',
		countryLabel: 'Dominican Republic',
		countryValue: 'DO'
	},
	{
		value: '14',
		label: 'Maria Trinidad Sanchez',
		countryLabel: 'Dominican Republic',
		countryValue: 'DO'
	},
	{
		value: '15',
		label: 'Monte Cristi',
		countryLabel: 'Dominican Republic',
		countryValue: 'DO'
	},
	{
		value: '16',
		label: 'Pedernales',
		countryLabel: 'Dominican Republic',
		countryValue: 'DO'
	},
	{
		value: '17',
		label: 'Peravia',
		countryLabel: 'Dominican Republic',
		countryValue: 'DO'
	},
	{
		value: '18',
		label: 'Puerto Plata',
		countryLabel: 'Dominican Republic',
		countryValue: 'DO'
	},
	{
		value: '19',
		label: 'Hermanas Mirabal',
		countryLabel: 'Dominican Republic',
		countryValue: 'DO'
	},
	{
		value: '20',
		label: 'Samana',
		countryLabel: 'Dominican Republic',
		countryValue: 'DO'
	},
	{
		value: '21',
		label: 'San Cristobal',
		countryLabel: 'Dominican Republic',
		countryValue: 'DO'
	},
	{
		value: '22',
		label: 'San Juan',
		countryLabel: 'Dominican Republic',
		countryValue: 'DO'
	},
	{
		value: '23',
		label: 'San Pedro de Macoris',
		countryLabel: 'Dominican Republic',
		countryValue: 'DO'
	},
	{
		value: '24',
		label: 'Sanchez Ramirez',
		countryLabel: 'Dominican Republic',
		countryValue: 'DO'
	},
	{
		value: '25',
		label: 'Santiago',
		countryLabel: 'Dominican Republic',
		countryValue: 'DO'
	},
	{
		value: '26',
		label: 'Santiago Rodriguez',
		countryLabel: 'Dominican Republic',
		countryValue: 'DO'
	},
	{
		value: '27',
		label: 'Valverde',
		countryLabel: 'Dominican Republic',
		countryValue: 'DO'
	},
	{
		value: '28',
		label: 'Monsenor Nouel',
		countryLabel: 'Dominican Republic',
		countryValue: 'DO'
	},
	{
		value: '29',
		label: 'Monte Plata',
		countryLabel: 'Dominican Republic',
		countryValue: 'DO'
	},
	{
		value: '30',
		label: 'Hato Mayor',
		countryLabel: 'Dominican Republic',
		countryValue: 'DO'
	},
	{
		value: '31',
		label: 'San Jose de Ocoa',
		countryLabel: 'Dominican Republic',
		countryValue: 'DO'
	},
	{
		value: 'A',
		label: 'Azuay',
		countryLabel: 'Ecuador',
		countryValue: 'EC'
	},
	{
		value: 'B',
		label: 'Bolivar',
		countryLabel: 'Ecuador',
		countryValue: 'EC'
	},
	{
		value: 'C',
		label: 'Carchi',
		countryLabel: 'Ecuador',
		countryValue: 'EC'
	},
	{
		value: 'D',
		label: 'Orellana',
		countryLabel: 'Ecuador',
		countryValue: 'EC'
	},
	{
		value: 'E',
		label: 'Esmeraldas',
		countryLabel: 'Ecuador',
		countryValue: 'EC'
	},
	{
		value: 'F',
		label: 'Canar',
		countryLabel: 'Ecuador',
		countryValue: 'EC'
	},
	{
		value: 'G',
		label: 'Guayas',
		countryLabel: 'Ecuador',
		countryValue: 'EC'
	},
	{
		value: 'H',
		label: 'Chimborazo',
		countryLabel: 'Ecuador',
		countryValue: 'EC'
	},
	{
		value: 'I',
		label: 'Imbabura',
		countryLabel: 'Ecuador',
		countryValue: 'EC'
	},
	{
		value: 'L',
		label: 'Loja',
		countryLabel: 'Ecuador',
		countryValue: 'EC'
	},
	{
		value: 'M',
		label: 'Manabi',
		countryLabel: 'Ecuador',
		countryValue: 'EC'
	},
	{
		value: 'N',
		label: 'Napo',
		countryLabel: 'Ecuador',
		countryValue: 'EC'
	},
	{
		value: 'O',
		label: 'El Oro',
		countryLabel: 'Ecuador',
		countryValue: 'EC'
	},
	{
		value: 'P',
		label: 'Pichincha',
		countryLabel: 'Ecuador',
		countryValue: 'EC'
	},
	{
		value: 'R',
		label: 'Los Rios',
		countryLabel: 'Ecuador',
		countryValue: 'EC'
	},
	{
		value: 'S',
		label: 'Morona Santiago',
		countryLabel: 'Ecuador',
		countryValue: 'EC'
	},
	{
		value: 'SD',
		label: 'Santo Domingo de los Tsachilas',
		countryLabel: 'Ecuador',
		countryValue: 'EC'
	},
	{
		value: 'SE',
		label: 'Santa Elena',
		countryLabel: 'Ecuador',
		countryValue: 'EC'
	},
	{
		value: 'T',
		label: 'Tungurahua',
		countryLabel: 'Ecuador',
		countryValue: 'EC'
	},
	{
		value: 'U',
		label: 'Sucumbios',
		countryLabel: 'Ecuador',
		countryValue: 'EC'
	},
	{
		value: 'W',
		label: 'Galapagos',
		countryLabel: 'Ecuador',
		countryValue: 'EC'
	},
	{
		value: 'X',
		label: 'Cotopaxi',
		countryLabel: 'Ecuador',
		countryValue: 'EC'
	},
	{
		value: 'Y',
		label: 'Pastaza',
		countryLabel: 'Ecuador',
		countryValue: 'EC'
	},
	{
		value: 'Z',
		label: 'Zamora Chinchipe',
		countryLabel: 'Ecuador',
		countryValue: 'EC'
	},
	{
		value: 'ALX',
		label: 'Al Iskandariyah',
		countryLabel: 'Egypt',
		countryValue: 'EG'
	},
	{
		value: 'ASN',
		label: 'Aswan',
		countryLabel: 'Egypt',
		countryValue: 'EG'
	},
	{
		value: 'AST',
		label: 'Asyut',
		countryLabel: 'Egypt',
		countryValue: 'EG'
	},
	{
		value: 'BA',
		label: 'Al Bahr al Ahmar',
		countryLabel: 'Egypt',
		countryValue: 'EG'
	},
	{
		value: 'BH',
		label: 'Al Buhayrah',
		countryLabel: 'Egypt',
		countryValue: 'EG'
	},
	{
		value: 'BNS',
		label: 'Bani Suwayf',
		countryLabel: 'Egypt',
		countryValue: 'EG'
	},
	{
		value: 'C',
		label: 'Al Qahirah',
		countryLabel: 'Egypt',
		countryValue: 'EG'
	},
	{
		value: 'DK',
		label: 'Ad Daqahliyah',
		countryLabel: 'Egypt',
		countryValue: 'EG'
	},
	{
		value: 'DT',
		label: 'Dumyat',
		countryLabel: 'Egypt',
		countryValue: 'EG'
	},
	{
		value: 'FYM',
		label: 'Al Fayyum',
		countryLabel: 'Egypt',
		countryValue: 'EG'
	},
	{
		value: 'GH',
		label: 'Al Gharbiyah',
		countryLabel: 'Egypt',
		countryValue: 'EG'
	},
	{
		value: 'GZ',
		label: 'Al Jizah',
		countryLabel: 'Egypt',
		countryValue: 'EG'
	},
	{
		value: 'IS',
		label: "Al Isma'iliyah",
		countryLabel: 'Egypt',
		countryValue: 'EG'
	},
	{
		value: 'JS',
		label: "Janub Sina'",
		countryLabel: 'Egypt',
		countryValue: 'EG'
	},
	{
		value: 'KB',
		label: 'Al Qalyubiyah',
		countryLabel: 'Egypt',
		countryValue: 'EG'
	},
	{
		value: 'KFS',
		label: 'Kafr ash Shaykh',
		countryLabel: 'Egypt',
		countryValue: 'EG'
	},
	{
		value: 'KN',
		label: 'Qina',
		countryLabel: 'Egypt',
		countryValue: 'EG'
	},
	{
		value: 'LX',
		label: 'Al Uqsur',
		countryLabel: 'Egypt',
		countryValue: 'EG'
	},
	{
		value: 'MN',
		label: 'Al Minya',
		countryLabel: 'Egypt',
		countryValue: 'EG'
	},
	{
		value: 'MNF',
		label: 'Al Minufiyah',
		countryLabel: 'Egypt',
		countryValue: 'EG'
	},
	{
		value: 'MT',
		label: 'Matruh',
		countryLabel: 'Egypt',
		countryValue: 'EG'
	},
	{
		value: 'PTS',
		label: "Bur Sa'id",
		countryLabel: 'Egypt',
		countryValue: 'EG'
	},
	{
		value: 'SHG',
		label: 'Suhaj',
		countryLabel: 'Egypt',
		countryValue: 'EG'
	},
	{
		value: 'SHR',
		label: 'Ash Sharqiyah',
		countryLabel: 'Egypt',
		countryValue: 'EG'
	},
	{
		value: 'SIN',
		label: "Shamal Sina'",
		countryLabel: 'Egypt',
		countryValue: 'EG'
	},
	{
		value: 'SUZ',
		label: 'As Suways',
		countryLabel: 'Egypt',
		countryValue: 'EG'
	},
	{
		value: 'WAD',
		label: 'Al Wadi al Jadid',
		countryLabel: 'Egypt',
		countryValue: 'EG'
	},
	{
		value: 'AH',
		label: 'Ahuachapan',
		countryLabel: 'El Salvador',
		countryValue: 'SV'
	},
	{
		value: 'CA',
		label: 'Cabanas',
		countryLabel: 'El Salvador',
		countryValue: 'SV'
	},
	{
		value: 'CH',
		label: 'Chalatenango',
		countryLabel: 'El Salvador',
		countryValue: 'SV'
	},
	{
		value: 'CU',
		label: 'Cuscatlan',
		countryLabel: 'El Salvador',
		countryValue: 'SV'
	},
	{
		value: 'LI',
		label: 'La Libertad',
		countryLabel: 'El Salvador',
		countryValue: 'SV'
	},
	{
		value: 'MO',
		label: 'Morazan',
		countryLabel: 'El Salvador',
		countryValue: 'SV'
	},
	{
		value: 'PA',
		label: 'La Paz',
		countryLabel: 'El Salvador',
		countryValue: 'SV'
	},
	{
		value: 'SA',
		label: 'Santa Ana',
		countryLabel: 'El Salvador',
		countryValue: 'SV'
	},
	{
		value: 'SM',
		label: 'San Miguel',
		countryLabel: 'El Salvador',
		countryValue: 'SV'
	},
	{
		value: 'SO',
		label: 'Sonsonate',
		countryLabel: 'El Salvador',
		countryValue: 'SV'
	},
	{
		value: 'SS',
		label: 'San Salvador',
		countryLabel: 'El Salvador',
		countryValue: 'SV'
	},
	{
		value: 'SV',
		label: 'San Vicente',
		countryLabel: 'El Salvador',
		countryValue: 'SV'
	},
	{
		value: 'UN',
		label: 'La Union',
		countryLabel: 'El Salvador',
		countryValue: 'SV'
	},
	{
		value: 'US',
		label: 'Usulutan',
		countryLabel: 'El Salvador',
		countryValue: 'SV'
	},
	{
		value: 'BN',
		label: 'Bioko Norte',
		countryLabel: 'Equatorial Guinea',
		countryValue: 'GQ'
	},
	{
		value: 'KN',
		label: 'Kie-Ntem',
		countryLabel: 'Equatorial Guinea',
		countryValue: 'GQ'
	},
	{
		value: 'LI',
		label: 'Litoral',
		countryLabel: 'Equatorial Guinea',
		countryValue: 'GQ'
	},
	{
		value: 'WN',
		label: 'Wele-Nzas',
		countryLabel: 'Equatorial Guinea',
		countryValue: 'GQ'
	},
	{
		value: 'MA',
		label: 'Al Awsat',
		countryLabel: 'Eritrea',
		countryValue: 'ER'
	},
	{
		value: '37',
		label: 'Harjumaa',
		countryLabel: 'Estonia',
		countryValue: 'EE'
	},
	{
		value: '39',
		label: 'Hiiumaa',
		countryLabel: 'Estonia',
		countryValue: 'EE'
	},
	{
		value: '45',
		label: 'Ida-Virumaa',
		countryLabel: 'Estonia',
		countryValue: 'EE'
	},
	{
		value: '50',
		label: 'Jogevamaa',
		countryLabel: 'Estonia',
		countryValue: 'EE'
	},
	{
		value: '52',
		label: 'Jarvamaa',
		countryLabel: 'Estonia',
		countryValue: 'EE'
	},
	{
		value: '56',
		label: 'Laanemaa',
		countryLabel: 'Estonia',
		countryValue: 'EE'
	},
	{
		value: '60',
		label: 'Laane-Virumaa',
		countryLabel: 'Estonia',
		countryValue: 'EE'
	},
	{
		value: '64',
		label: 'Polvamaa',
		countryLabel: 'Estonia',
		countryValue: 'EE'
	},
	{
		value: '68',
		label: 'Parnumaa',
		countryLabel: 'Estonia',
		countryValue: 'EE'
	},
	{
		value: '71',
		label: 'Raplamaa',
		countryLabel: 'Estonia',
		countryValue: 'EE'
	},
	{
		value: '74',
		label: 'Saaremaa',
		countryLabel: 'Estonia',
		countryValue: 'EE'
	},
	{
		value: '79',
		label: 'Tartumaa',
		countryLabel: 'Estonia',
		countryValue: 'EE'
	},
	{
		value: '81',
		label: 'Valgamaa',
		countryLabel: 'Estonia',
		countryValue: 'EE'
	},
	{
		value: '84',
		label: 'Viljandimaa',
		countryLabel: 'Estonia',
		countryValue: 'EE'
	},
	{
		value: '87',
		label: 'Vorumaa',
		countryLabel: 'Estonia',
		countryValue: 'EE'
	},
	{
		value: 'AA',
		label: 'Adis Abeba',
		countryLabel: 'Ethiopia',
		countryValue: 'ET'
	},
	{
		value: 'AF',
		label: 'Afar',
		countryLabel: 'Ethiopia',
		countryValue: 'ET'
	},
	{
		value: 'AM',
		label: 'Amara',
		countryLabel: 'Ethiopia',
		countryValue: 'ET'
	},
	{
		value: 'BE',
		label: 'Binshangul Gumuz',
		countryLabel: 'Ethiopia',
		countryValue: 'ET'
	},
	{
		value: 'DD',
		label: 'Dire Dawa',
		countryLabel: 'Ethiopia',
		countryValue: 'ET'
	},
	{
		value: 'HA',
		label: 'Hareri Hizb',
		countryLabel: 'Ethiopia',
		countryValue: 'ET'
	},
	{
		value: 'OR',
		label: 'Oromiya',
		countryLabel: 'Ethiopia',
		countryValue: 'ET'
	},
	{
		value: 'SN',
		label: 'YeDebub Biheroch Bihereseboch na Hizboch',
		countryLabel: 'Ethiopia',
		countryValue: 'ET'
	},
	{
		value: 'SO',
		label: 'Sumale',
		countryLabel: 'Ethiopia',
		countryValue: 'ET'
	},
	{
		value: 'TI',
		label: 'Tigray',
		countryLabel: 'Ethiopia',
		countryValue: 'ET'
	},
	{
		value: '101',
		label: 'Veles',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '102',
		label: 'Gradsko',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '103',
		label: 'Demir Kapija',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '104',
		label: 'Kavadarci',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '105',
		label: 'Lozovo',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '106',
		label: 'Negotino',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '107',
		label: 'Rosoman',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '108',
		label: 'Sveti Nikole',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '109',
		label: 'Caska',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '201',
		label: 'Berovo',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '202',
		label: 'Vinica',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '203',
		label: 'Delcevo',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '205',
		label: 'Karbinci',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '206',
		label: 'Kocani',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '207',
		label: 'Makedonska Kamenica',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '208',
		label: 'Pehcevo',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '209',
		label: 'Probistip',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '210',
		label: 'Cesinovo-Oblesevo',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '211',
		label: 'Stip',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '301',
		label: 'Vevcani',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '303',
		label: 'Debar',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '307',
		label: 'Kicevo',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '308',
		label: 'Makedonski Brod',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '310',
		label: 'Ohrid',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '311',
		label: 'Plasnica',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '312',
		label: 'Struga',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '313',
		label: 'Centar Zupa',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '401',
		label: 'Bogdanci',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '402',
		label: 'Bosilovo',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '403',
		label: 'Valandovo',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '404',
		label: 'Vasilevo',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '405',
		label: 'Gevgelija',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '406',
		label: 'Dojran',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '408',
		label: 'Novo Selo',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '409',
		label: 'Radovis',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '410',
		label: 'Strumica',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '501',
		label: 'Bitola',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '502',
		label: 'Demir Hisar',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '503',
		label: 'Dolneni',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '505',
		label: 'Krusevo',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '506',
		label: 'Mogila',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '507',
		label: 'Novaci',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '508',
		label: 'Prilep',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '509',
		label: 'Resen',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '601',
		label: 'Bogovinje',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '602',
		label: 'Brvenica',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '603',
		label: 'Vrapciste',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '604',
		label: 'Gostivar',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '605',
		label: 'Zelino',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '606',
		label: 'Jegunovce',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '607',
		label: 'Mavrovo i Rostusa',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '608',
		label: 'Tearce',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '609',
		label: 'Tetovo',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '701',
		label: 'Kratovo',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '702',
		label: 'Kriva Palanka',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '703',
		label: 'Kumanovo',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '704',
		label: 'Lipkovo',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '705',
		label: 'Rankovce',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '706',
		label: 'Staro Nagoricane',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '802',
		label: 'Aracinovo',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '803',
		label: 'Butel',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '804',
		label: 'Gazi Baba',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '806',
		label: 'Zelenikovo',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '807',
		label: 'Ilinden',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '809',
		label: 'Kisela Voda',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '810',
		label: 'Petrovec',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '811',
		label: 'Saraj',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '812',
		label: 'Sopiste',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '813',
		label: 'Studenicani',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '814',
		label: 'Centar',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: '817',
		label: 'Suto Orizari',
		countryLabel: 'F.Y.R. of Macedonia',
		countryValue: 'MK'
	},
	{
		value: 'C',
		label: 'Central',
		countryLabel: 'Fiji Islands',
		countryValue: 'FJ'
	},
	{
		value: 'E',
		label: 'Eastern',
		countryLabel: 'Fiji Islands',
		countryValue: 'FJ'
	},
	{
		value: 'N',
		label: 'Northern',
		countryLabel: 'Fiji Islands',
		countryValue: 'FJ'
	},
	{
		value: 'R',
		label: 'Rotuma',
		countryLabel: 'Fiji Islands',
		countryValue: 'FJ'
	},
	{
		value: 'W',
		label: 'Western',
		countryLabel: 'Fiji Islands',
		countryValue: 'FJ'
	},
	{
		value: '2',
		label: 'Etela-Karjala',
		countryLabel: 'Finland',
		countryValue: 'FI'
	},
	{
		value: '3',
		label: 'Etela-Pohjanmaa',
		countryLabel: 'Finland',
		countryValue: 'FI'
	},
	{
		value: '4',
		label: 'Etela-Savo',
		countryLabel: 'Finland',
		countryValue: 'FI'
	},
	{
		value: '5',
		label: 'Kainuu',
		countryLabel: 'Finland',
		countryValue: 'FI'
	},
	{
		value: '6',
		label: 'Kanta-Hame',
		countryLabel: 'Finland',
		countryValue: 'FI'
	},
	{
		value: '7',
		label: 'Keski-Pohjanmaa',
		countryLabel: 'Finland',
		countryValue: 'FI'
	},
	{
		value: '8',
		label: 'Keski-Suomi',
		countryLabel: 'Finland',
		countryValue: 'FI'
	},
	{
		value: '9',
		label: 'Kymenlaakso',
		countryLabel: 'Finland',
		countryValue: 'FI'
	},
	{
		value: '10',
		label: 'Lappi',
		countryLabel: 'Finland',
		countryValue: 'FI'
	},
	{
		value: '11',
		label: 'Pirkanmaa',
		countryLabel: 'Finland',
		countryValue: 'FI'
	},
	{
		value: '12',
		label: 'Pohjanmaa',
		countryLabel: 'Finland',
		countryValue: 'FI'
	},
	{
		value: '13',
		label: 'Pohjois-Karjala',
		countryLabel: 'Finland',
		countryValue: 'FI'
	},
	{
		value: '14',
		label: 'Pohjois-Pohjanmaa',
		countryLabel: 'Finland',
		countryValue: 'FI'
	},
	{
		value: '15',
		label: 'Pohjois-Savo',
		countryLabel: 'Finland',
		countryValue: 'FI'
	},
	{
		value: '16',
		label: 'Paijat-Hame',
		countryLabel: 'Finland',
		countryValue: 'FI'
	},
	{
		value: '17',
		label: 'Satakunta',
		countryLabel: 'Finland',
		countryValue: 'FI'
	},
	{
		value: '18',
		label: 'Uusimaa',
		countryLabel: 'Finland',
		countryValue: 'FI'
	},
	{
		value: '19',
		label: 'Varsinais-Suomi',
		countryLabel: 'Finland',
		countryValue: 'FI'
	},
	{
		value: '20R',
		label: 'Corse',
		countryLabel: 'France',
		countryValue: 'FR'
	},
	{
		value: 'ARA',
		label: 'Auvergne-Rhone-Alpes',
		countryLabel: 'France',
		countryValue: 'FR'
	},
	{
		value: 'BFC',
		label: 'Bourgogne-Franche-Comte',
		countryLabel: 'France',
		countryValue: 'FR'
	},
	{
		value: 'BRE',
		label: 'Bretagne',
		countryLabel: 'France',
		countryValue: 'FR'
	},
	{
		value: 'CVL',
		label: 'Centre-Val de Loire',
		countryLabel: 'France',
		countryValue: 'FR'
	},
	{
		value: 'GES',
		label: 'Grand-Est',
		countryLabel: 'France',
		countryValue: 'FR'
	},
	{
		value: 'HDF',
		label: 'Hauts-de-France',
		countryLabel: 'France',
		countryValue: 'FR'
	},
	{
		value: 'IDF',
		label: 'Ile-de-France',
		countryLabel: 'France',
		countryValue: 'FR'
	},
	{
		value: 'NAQ',
		label: 'Nouvelle-Aquitaine',
		countryLabel: 'France',
		countryValue: 'FR'
	},
	{
		value: 'NOR',
		label: 'Normandie',
		countryLabel: 'France',
		countryValue: 'FR'
	},
	{
		value: 'OCC',
		label: 'Occitanie',
		countryLabel: 'France',
		countryValue: 'FR'
	},
	{
		value: 'PAC',
		label: "Provence-Alpes-Cote-d'Azur",
		countryLabel: 'France',
		countryValue: 'FR'
	},
	{
		value: 'PDL',
		label: 'Pays-de-la-Loire',
		countryLabel: 'France',
		countryValue: 'FR'
	},
	{
		value: '1',
		label: 'Estuaire',
		countryLabel: 'Gabon',
		countryValue: 'GA'
	},
	{
		value: '2',
		label: 'Haut-Ogooue',
		countryLabel: 'Gabon',
		countryValue: 'GA'
	},
	{
		value: '4',
		label: 'Ngounie',
		countryLabel: 'Gabon',
		countryValue: 'GA'
	},
	{
		value: '8',
		label: 'Ogooue-Maritime',
		countryLabel: 'Gabon',
		countryValue: 'GA'
	},
	{
		value: '9',
		label: 'Woleu-Ntem',
		countryLabel: 'Gabon',
		countryValue: 'GA'
	},
	{
		value: 'B',
		label: 'Banjul',
		countryLabel: 'Gambia',
		countryValue: 'GM'
	},
	{
		value: 'L',
		label: 'Lower River',
		countryLabel: 'Gambia',
		countryValue: 'GM'
	},
	{
		value: 'M',
		label: 'Central River',
		countryLabel: 'Gambia',
		countryValue: 'GM'
	},
	{
		value: 'N',
		label: 'North Bank',
		countryLabel: 'Gambia',
		countryValue: 'GM'
	},
	{
		value: 'U',
		label: 'Upper River',
		countryLabel: 'Gambia',
		countryValue: 'GM'
	},
	{
		value: 'W',
		label: 'Western',
		countryLabel: 'Gambia',
		countryValue: 'GM'
	},
	{
		value: 'AB',
		label: 'Abkhazia',
		countryLabel: 'Georgia',
		countryValue: 'GE'
	},
	{
		value: 'AJ',
		label: 'Ajaria',
		countryLabel: 'Georgia',
		countryValue: 'GE'
	},
	{
		value: 'GU',
		label: 'Guria',
		countryLabel: 'Georgia',
		countryValue: 'GE'
	},
	{
		value: 'IM',
		label: 'Imereti',
		countryLabel: 'Georgia',
		countryValue: 'GE'
	},
	{
		value: 'KA',
		label: "K'akheti",
		countryLabel: 'Georgia',
		countryValue: 'GE'
	},
	{
		value: 'KK',
		label: 'Kvemo Kartli',
		countryLabel: 'Georgia',
		countryValue: 'GE'
	},
	{
		value: 'MM',
		label: 'Mtskheta-Mtianeti',
		countryLabel: 'Georgia',
		countryValue: 'GE'
	},
	{
		value: 'RL',
		label: "Rach'a-Lechkhumi-Kvemo Svaneti",
		countryLabel: 'Georgia',
		countryValue: 'GE'
	},
	{
		value: 'SJ',
		label: 'Samtskhe-Javakheti',
		countryLabel: 'Georgia',
		countryValue: 'GE'
	},
	{
		value: 'SK',
		label: 'Shida Kartli',
		countryLabel: 'Georgia',
		countryValue: 'GE'
	},
	{
		value: 'SZ',
		label: 'Samegrelo-Zemo Svaneti',
		countryLabel: 'Georgia',
		countryValue: 'GE'
	},
	{
		value: 'TB',
		label: 'Tbilisi',
		countryLabel: 'Georgia',
		countryValue: 'GE'
	},
	{
		value: 'BB',
		label: 'Brandenburg',
		countryLabel: 'Germany',
		countryValue: 'DE'
	},
	{
		value: 'BE',
		label: 'Berlin',
		countryLabel: 'Germany',
		countryValue: 'DE'
	},
	{
		value: 'BW',
		label: 'Baden-Wurttemberg',
		countryLabel: 'Germany',
		countryValue: 'DE'
	},
	{
		value: 'BY',
		label: 'Bayern',
		countryLabel: 'Germany',
		countryValue: 'DE'
	},
	{
		value: 'HB',
		label: 'Bremen',
		countryLabel: 'Germany',
		countryValue: 'DE'
	},
	{
		value: 'HE',
		label: 'Hessen',
		countryLabel: 'Germany',
		countryValue: 'DE'
	},
	{
		value: 'HH',
		label: 'Hamburg',
		countryLabel: 'Germany',
		countryValue: 'DE'
	},
	{
		value: 'MV',
		label: 'Mecklenburg-Vorpommern',
		countryLabel: 'Germany',
		countryValue: 'DE'
	},
	{
		value: 'NI',
		label: 'Niedersachsen',
		countryLabel: 'Germany',
		countryValue: 'DE'
	},
	{
		value: 'NW',
		label: 'Nordrhein-Westfalen',
		countryLabel: 'Germany',
		countryValue: 'DE'
	},
	{
		value: 'RP',
		label: 'Rheinland-Pfalz',
		countryLabel: 'Germany',
		countryValue: 'DE'
	},
	{
		value: 'SH',
		label: 'Schleswig-Holstein',
		countryLabel: 'Germany',
		countryValue: 'DE'
	},
	{
		value: 'SL',
		label: 'Saarland',
		countryLabel: 'Germany',
		countryValue: 'DE'
	},
	{
		value: 'SN',
		label: 'Sachsen',
		countryLabel: 'Germany',
		countryValue: 'DE'
	},
	{
		value: 'ST',
		label: 'Sachsen-Anhalt',
		countryLabel: 'Germany',
		countryValue: 'DE'
	},
	{
		value: 'TH',
		label: 'Thuringen',
		countryLabel: 'Germany',
		countryValue: 'DE'
	},
	{
		value: 'AA',
		label: 'Greater Accra',
		countryLabel: 'Ghana',
		countryValue: 'GH'
	},
	{
		value: 'AF',
		label: 'Ahafo',
		countryLabel: 'Ghana',
		countryValue: 'GH'
	},
	{
		value: 'AH',
		label: 'Ashanti',
		countryLabel: 'Ghana',
		countryValue: 'GH'
	},
	{
		value: 'BE',
		label: 'Bono East',
		countryLabel: 'Ghana',
		countryValue: 'GH'
	},
	{
		value: 'BO',
		label: 'Bono',
		countryLabel: 'Ghana',
		countryValue: 'GH'
	},
	{
		value: 'CP',
		label: 'Central',
		countryLabel: 'Ghana',
		countryValue: 'GH'
	},
	{
		value: 'EP',
		label: 'Eastern',
		countryLabel: 'Ghana',
		countryValue: 'GH'
	},
	{
		value: 'NP',
		label: 'Northern',
		countryLabel: 'Ghana',
		countryValue: 'GH'
	},
	{
		value: 'TV',
		label: 'Volta',
		countryLabel: 'Ghana',
		countryValue: 'GH'
	},
	{
		value: 'UE',
		label: 'Upper East',
		countryLabel: 'Ghana',
		countryValue: 'GH'
	},
	{
		value: 'WP',
		label: 'Western',
		countryLabel: 'Ghana',
		countryValue: 'GH'
	},
	{
		value: 'A',
		label: 'Anatoliki Makedonia kai Thraki',
		countryLabel: 'Greece',
		countryValue: 'GR'
	},
	{
		value: 'B',
		label: 'Kentriki Makedonia',
		countryLabel: 'Greece',
		countryValue: 'GR'
	},
	{
		value: 'C',
		label: 'Dytiki Makedonia',
		countryLabel: 'Greece',
		countryValue: 'GR'
	},
	{
		value: 'D',
		label: 'Ipeiros',
		countryLabel: 'Greece',
		countryValue: 'GR'
	},
	{
		value: 'E',
		label: 'Thessalia',
		countryLabel: 'Greece',
		countryValue: 'GR'
	},
	{
		value: 'F',
		label: 'Ionia Nisia',
		countryLabel: 'Greece',
		countryValue: 'GR'
	},
	{
		value: 'G',
		label: 'Dytiki Ellada',
		countryLabel: 'Greece',
		countryValue: 'GR'
	},
	{
		value: 'H',
		label: 'Sterea Ellada',
		countryLabel: 'Greece',
		countryValue: 'GR'
	},
	{
		value: 'I',
		label: 'Attiki',
		countryLabel: 'Greece',
		countryValue: 'GR'
	},
	{
		value: 'J',
		label: 'Peloponnisos',
		countryLabel: 'Greece',
		countryValue: 'GR'
	},
	{
		value: 'K',
		label: 'Voreio Aigaio',
		countryLabel: 'Greece',
		countryValue: 'GR'
	},
	{
		value: 'L',
		label: 'Notio Aigaio',
		countryLabel: 'Greece',
		countryValue: 'GR'
	},
	{
		value: 'M',
		label: 'Kriti',
		countryLabel: 'Greece',
		countryValue: 'GR'
	},
	{
		value: 'AV',
		label: 'Avannaata Kommunia',
		countryLabel: 'Greenland',
		countryValue: 'GL'
	},
	{
		value: 'KU',
		label: 'Kommune Kujalleq',
		countryLabel: 'Greenland',
		countryValue: 'GL'
	},
	{
		value: 'QE',
		label: 'Qeqqata Kommunia',
		countryLabel: 'Greenland',
		countryValue: 'GL'
	},
	{
		value: 'QT',
		label: 'Kommune Qeqertalik',
		countryLabel: 'Greenland',
		countryValue: 'GL'
	},
	{
		value: 'SM',
		label: 'Kommuneqarfik Sermersooq',
		countryLabel: 'Greenland',
		countryValue: 'GL'
	},
	{
		value: '1',
		label: 'Saint Andrew',
		countryLabel: 'Grenada',
		countryValue: 'GD'
	},
	{
		value: '2',
		label: 'Saint David',
		countryLabel: 'Grenada',
		countryValue: 'GD'
	},
	{
		value: '3',
		label: 'Saint George',
		countryLabel: 'Grenada',
		countryValue: 'GD'
	},
	{
		value: '4',
		label: 'Saint John',
		countryLabel: 'Grenada',
		countryValue: 'GD'
	},
	{
		value: '5',
		label: 'Saint Mark',
		countryLabel: 'Grenada',
		countryValue: 'GD'
	},
	{
		value: '6',
		label: 'Saint Patrick',
		countryLabel: 'Grenada',
		countryValue: 'GD'
	},
	{
		value: '10',
		label: 'Southern Grenadine Islands',
		countryLabel: 'Grenada',
		countryValue: 'GD'
	},
	{
		value: 'AV',
		label: 'Alta Verapaz',
		countryLabel: 'Guatemala',
		countryValue: 'GT'
	},
	{
		value: 'BV',
		label: 'Baja Verapaz',
		countryLabel: 'Guatemala',
		countryValue: 'GT'
	},
	{
		value: 'CM',
		label: 'Chimaltenango',
		countryLabel: 'Guatemala',
		countryValue: 'GT'
	},
	{
		value: 'CQ',
		label: 'Chiquimula',
		countryLabel: 'Guatemala',
		countryValue: 'GT'
	},
	{
		value: 'ES',
		label: 'Escuintla',
		countryLabel: 'Guatemala',
		countryValue: 'GT'
	},
	{
		value: 'GU',
		label: 'Guatemala',
		countryLabel: 'Guatemala',
		countryValue: 'GT'
	},
	{
		value: 'HU',
		label: 'Huehuetenango',
		countryLabel: 'Guatemala',
		countryValue: 'GT'
	},
	{
		value: 'IZ',
		label: 'Izabal',
		countryLabel: 'Guatemala',
		countryValue: 'GT'
	},
	{
		value: 'JA',
		label: 'Jalapa',
		countryLabel: 'Guatemala',
		countryValue: 'GT'
	},
	{
		value: 'JU',
		label: 'Jutiapa',
		countryLabel: 'Guatemala',
		countryValue: 'GT'
	},
	{
		value: 'PE',
		label: 'Peten',
		countryLabel: 'Guatemala',
		countryValue: 'GT'
	},
	{
		value: 'PR',
		label: 'El Progreso',
		countryLabel: 'Guatemala',
		countryValue: 'GT'
	},
	{
		value: 'QC',
		label: 'Quiche',
		countryLabel: 'Guatemala',
		countryValue: 'GT'
	},
	{
		value: 'QZ',
		label: 'Quetzaltenango',
		countryLabel: 'Guatemala',
		countryValue: 'GT'
	},
	{
		value: 'RE',
		label: 'Retalhuleu',
		countryLabel: 'Guatemala',
		countryValue: 'GT'
	},
	{
		value: 'SA',
		label: 'Sacatepequez',
		countryLabel: 'Guatemala',
		countryValue: 'GT'
	},
	{
		value: 'SM',
		label: 'San Marcos',
		countryLabel: 'Guatemala',
		countryValue: 'GT'
	},
	{
		value: 'SO',
		label: 'Solola',
		countryLabel: 'Guatemala',
		countryValue: 'GT'
	},
	{
		value: 'SR',
		label: 'Santa Rosa',
		countryLabel: 'Guatemala',
		countryValue: 'GT'
	},
	{
		value: 'SU',
		label: 'Suchitepequez',
		countryLabel: 'Guatemala',
		countryValue: 'GT'
	},
	{
		value: 'TO',
		label: 'Totonicapan',
		countryLabel: 'Guatemala',
		countryValue: 'GT'
	},
	{
		value: 'ZA',
		label: 'Zacapa',
		countryLabel: 'Guatemala',
		countryValue: 'GT'
	},
	{
		value: 'B',
		label: 'Boke',
		countryLabel: 'Guinea',
		countryValue: 'GN'
	},
	{
		value: 'BF',
		label: 'Boffa',
		countryLabel: 'Guinea',
		countryValue: 'GN'
	},
	{
		value: 'C',
		label: 'Conakry',
		countryLabel: 'Guinea',
		countryValue: 'GN'
	},
	{
		value: 'CO',
		label: 'Coyah',
		countryLabel: 'Guinea',
		countryValue: 'GN'
	},
	{
		value: 'DB',
		label: 'Dabola',
		countryLabel: 'Guinea',
		countryValue: 'GN'
	},
	{
		value: 'K',
		label: 'Kankan',
		countryLabel: 'Guinea',
		countryValue: 'GN'
	},
	{
		value: 'N',
		label: 'Nzerekore',
		countryLabel: 'Guinea',
		countryValue: 'GN'
	},
	{
		value: 'SI',
		label: 'Siguiri',
		countryLabel: 'Guinea',
		countryValue: 'GN'
	},
	{
		value: 'BS',
		label: 'Bissau',
		countryLabel: 'Guinea-Bissau',
		countryValue: 'GW'
	},
	{
		value: 'GA',
		label: 'Gabu',
		countryLabel: 'Guinea-Bissau',
		countryValue: 'GW'
	},
	{
		value: 'BA',
		label: 'Barima-Waini',
		countryLabel: 'Guyana',
		countryValue: 'GY'
	},
	{
		value: 'CU',
		label: 'Cuyuni-Mazaruni',
		countryLabel: 'Guyana',
		countryValue: 'GY'
	},
	{
		value: 'DE',
		label: 'Demerara-Mahaica',
		countryLabel: 'Guyana',
		countryValue: 'GY'
	},
	{
		value: 'EB',
		label: 'East Berbice-Corentyne',
		countryLabel: 'Guyana',
		countryValue: 'GY'
	},
	{
		value: 'ES',
		label: 'Essequibo Islands-West Demerara',
		countryLabel: 'Guyana',
		countryValue: 'GY'
	},
	{
		value: 'MA',
		label: 'Mahaica-Berbice',
		countryLabel: 'Guyana',
		countryValue: 'GY'
	},
	{
		value: 'PT',
		label: 'Potaro-Siparuni',
		countryLabel: 'Guyana',
		countryValue: 'GY'
	},
	{
		value: 'UD',
		label: 'Upper Demerara-Berbice',
		countryLabel: 'Guyana',
		countryValue: 'GY'
	},
	{
		value: 'AR',
		label: 'Artibonite',
		countryLabel: 'Haiti',
		countryValue: 'HT'
	},
	{
		value: 'CE',
		label: 'Centre',
		countryLabel: 'Haiti',
		countryValue: 'HT'
	},
	{
		value: 'GA',
		label: "Grande'Anse",
		countryLabel: 'Haiti',
		countryValue: 'HT'
	},
	{
		value: 'ND',
		label: 'Nord',
		countryLabel: 'Haiti',
		countryValue: 'HT'
	},
	{
		value: 'NI',
		label: 'Nippes',
		countryLabel: 'Haiti',
		countryValue: 'HT'
	},
	{
		value: 'OU',
		label: 'Ouest',
		countryLabel: 'Haiti',
		countryValue: 'HT'
	},
	{
		value: 'SD',
		label: 'Sud',
		countryLabel: 'Haiti',
		countryValue: 'HT'
	},
	{
		value: 'SE',
		label: 'Sud-Est',
		countryLabel: 'Haiti',
		countryValue: 'HT'
	},
	{
		value: 'AT',
		label: 'Atlantida',
		countryLabel: 'Honduras',
		countryValue: 'HN'
	},
	{
		value: 'CH',
		label: 'Choluteca',
		countryLabel: 'Honduras',
		countryValue: 'HN'
	},
	{
		value: 'CL',
		label: 'Colon',
		countryLabel: 'Honduras',
		countryValue: 'HN'
	},
	{
		value: 'CM',
		label: 'Comayagua',
		countryLabel: 'Honduras',
		countryValue: 'HN'
	},
	{
		value: 'CP',
		label: 'Copan',
		countryLabel: 'Honduras',
		countryValue: 'HN'
	},
	{
		value: 'CR',
		label: 'Cortes',
		countryLabel: 'Honduras',
		countryValue: 'HN'
	},
	{
		value: 'EP',
		label: 'El Paraiso',
		countryLabel: 'Honduras',
		countryValue: 'HN'
	},
	{
		value: 'FM',
		label: 'Francisco Morazan',
		countryLabel: 'Honduras',
		countryValue: 'HN'
	},
	{
		value: 'GD',
		label: 'Gracias a Dios',
		countryLabel: 'Honduras',
		countryValue: 'HN'
	},
	{
		value: 'IB',
		label: 'Islas de la Bahia',
		countryLabel: 'Honduras',
		countryValue: 'HN'
	},
	{
		value: 'IN',
		label: 'Intibuca',
		countryLabel: 'Honduras',
		countryValue: 'HN'
	},
	{
		value: 'LE',
		label: 'Lempira',
		countryLabel: 'Honduras',
		countryValue: 'HN'
	},
	{
		value: 'LP',
		label: 'La Paz',
		countryLabel: 'Honduras',
		countryValue: 'HN'
	},
	{
		value: 'OC',
		label: 'Ocotepeque',
		countryLabel: 'Honduras',
		countryValue: 'HN'
	},
	{
		value: 'OL',
		label: 'Olancho',
		countryLabel: 'Honduras',
		countryValue: 'HN'
	},
	{
		value: 'SB',
		label: 'Santa Barbara',
		countryLabel: 'Honduras',
		countryValue: 'HN'
	},
	{
		value: 'VA',
		label: 'Valle',
		countryLabel: 'Honduras',
		countryValue: 'HN'
	},
	{
		value: 'YO',
		label: 'Yoro',
		countryLabel: 'Honduras',
		countryValue: 'HN'
	},
	{
		value: 'BA',
		label: 'Baranya',
		countryLabel: 'Hungary',
		countryValue: 'HU'
	},
	{
		value: 'BE',
		label: 'Bekes',
		countryLabel: 'Hungary',
		countryValue: 'HU'
	},
	{
		value: 'BK',
		label: 'Bacs-Kiskun',
		countryLabel: 'Hungary',
		countryValue: 'HU'
	},
	{
		value: 'BU',
		label: 'Budapest',
		countryLabel: 'Hungary',
		countryValue: 'HU'
	},
	{
		value: 'BZ',
		label: 'Borsod-Abauj-Zemplen',
		countryLabel: 'Hungary',
		countryValue: 'HU'
	},
	{
		value: 'CS',
		label: 'Csongrad',
		countryLabel: 'Hungary',
		countryValue: 'HU'
	},
	{
		value: 'FE',
		label: 'Fejer',
		countryLabel: 'Hungary',
		countryValue: 'HU'
	},
	{
		value: 'GS',
		label: 'Gyor-Moson-Sopron',
		countryLabel: 'Hungary',
		countryValue: 'HU'
	},
	{
		value: 'HB',
		label: 'Hajdu-Bihar',
		countryLabel: 'Hungary',
		countryValue: 'HU'
	},
	{
		value: 'HE',
		label: 'Heves',
		countryLabel: 'Hungary',
		countryValue: 'HU'
	},
	{
		value: 'JN',
		label: 'Jasz-Nagykun-Szolnok',
		countryLabel: 'Hungary',
		countryValue: 'HU'
	},
	{
		value: 'KE',
		label: 'Komarom-Esztergom',
		countryLabel: 'Hungary',
		countryValue: 'HU'
	},
	{
		value: 'NO',
		label: 'Nograd',
		countryLabel: 'Hungary',
		countryValue: 'HU'
	},
	{
		value: 'PE',
		label: 'Pest',
		countryLabel: 'Hungary',
		countryValue: 'HU'
	},
	{
		value: 'SO',
		label: 'Somogy',
		countryLabel: 'Hungary',
		countryValue: 'HU'
	},
	{
		value: 'SZ',
		label: 'Szabolcs-Szatmar-Bereg',
		countryLabel: 'Hungary',
		countryValue: 'HU'
	},
	{
		value: 'TO',
		label: 'Tolna',
		countryLabel: 'Hungary',
		countryValue: 'HU'
	},
	{
		value: 'VA',
		label: 'Vas',
		countryLabel: 'Hungary',
		countryValue: 'HU'
	},
	{
		value: 'VE',
		label: 'Veszprem',
		countryLabel: 'Hungary',
		countryValue: 'HU'
	},
	{
		value: 'ZA',
		label: 'Zala',
		countryLabel: 'Hungary',
		countryValue: 'HU'
	},
	{
		value: '1',
		label: 'Hofudborgarsvaedi',
		countryLabel: 'Iceland',
		countryValue: 'IS'
	},
	{
		value: '2',
		label: 'Sudurnes',
		countryLabel: 'Iceland',
		countryValue: 'IS'
	},
	{
		value: '3',
		label: 'Vesturland',
		countryLabel: 'Iceland',
		countryValue: 'IS'
	},
	{
		value: '4',
		label: 'Vestfirdir',
		countryLabel: 'Iceland',
		countryValue: 'IS'
	},
	{
		value: '5',
		label: 'Nordurland vestra',
		countryLabel: 'Iceland',
		countryValue: 'IS'
	},
	{
		value: '6',
		label: 'Nordurland eystra',
		countryLabel: 'Iceland',
		countryValue: 'IS'
	},
	{
		value: '7',
		label: 'Austurland',
		countryLabel: 'Iceland',
		countryValue: 'IS'
	},
	{
		value: '8',
		label: 'Sudurland',
		countryLabel: 'Iceland',
		countryValue: 'IS'
	},
	{
		value: 'AN',
		label: 'Andaman and Nicobar Islands',
		countryLabel: 'India',
		countryValue: 'IN'
	},
	{
		value: 'AP',
		label: 'Andhra Pradesh',
		countryLabel: 'India',
		countryValue: 'IN'
	},
	{
		value: 'AR',
		label: 'Arunachal Pradesh',
		countryLabel: 'India',
		countryValue: 'IN'
	},
	{
		value: 'AS',
		label: 'Assam',
		countryLabel: 'India',
		countryValue: 'IN'
	},
	{
		value: 'BR',
		label: 'Bihar',
		countryLabel: 'India',
		countryValue: 'IN'
	},
	{
		value: 'CH',
		label: 'Chandigarh',
		countryLabel: 'India',
		countryValue: 'IN'
	},
	{
		value: 'CT',
		label: 'Chhattisgarh',
		countryLabel: 'India',
		countryValue: 'IN'
	},
	{
		value: 'DD',
		label: 'Daman and Diu',
		countryLabel: 'India',
		countryValue: 'IN'
	},
	{
		value: 'DH',
		label: 'Dadra and Nagar Haveli and Daman and Diu',
		countryLabel: 'India',
		countryValue: 'IN'
	},
	{
		value: 'DL',
		label: 'Delhi',
		countryLabel: 'India',
		countryValue: 'IN'
	},
	{
		value: 'DN',
		label: 'Dadra and Nagar Haveli',
		countryLabel: 'India',
		countryValue: 'IN'
	},
	{
		value: 'GA',
		label: 'Goa',
		countryLabel: 'India',
		countryValue: 'IN'
	},
	{
		value: 'GJ',
		label: 'Gujarat',
		countryLabel: 'India',
		countryValue: 'IN'
	},
	{
		value: 'HP',
		label: 'Himachal Pradesh',
		countryLabel: 'India',
		countryValue: 'IN'
	},
	{
		value: 'HR',
		label: 'Haryana',
		countryLabel: 'India',
		countryValue: 'IN'
	},
	{
		value: 'JH',
		label: 'Jharkhand',
		countryLabel: 'India',
		countryValue: 'IN'
	},
	{
		value: 'JK',
		label: 'Jammu and Kashmir',
		countryLabel: 'India',
		countryValue: 'IN'
	},
	{
		value: 'KA',
		label: 'Karnataka',
		countryLabel: 'India',
		countryValue: 'IN'
	},
	{
		value: 'KL',
		label: 'Kerala',
		countryLabel: 'India',
		countryValue: 'IN'
	},
	{
		value: 'LD',
		label: 'Lakshadweep',
		countryLabel: 'India',
		countryValue: 'IN'
	},
	{
		value: 'MH',
		label: 'Maharashtra',
		countryLabel: 'India',
		countryValue: 'IN'
	},
	{
		value: 'ML',
		label: 'Meghalaya',
		countryLabel: 'India',
		countryValue: 'IN'
	},
	{
		value: 'MN',
		label: 'Manipur',
		countryLabel: 'India',
		countryValue: 'IN'
	},
	{
		value: 'MP',
		label: 'Madhya Pradesh',
		countryLabel: 'India',
		countryValue: 'IN'
	},
	{
		value: 'MZ',
		label: 'Mizoram',
		countryLabel: 'India',
		countryValue: 'IN'
	},
	{
		value: 'NL',
		label: 'Nagaland',
		countryLabel: 'India',
		countryValue: 'IN'
	},
	{
		value: 'OR',
		label: 'Odisha',
		countryLabel: 'India',
		countryValue: 'IN'
	},
	{
		value: 'PB',
		label: 'Punjab',
		countryLabel: 'India',
		countryValue: 'IN'
	},
	{
		value: 'PY',
		label: 'Puducherry',
		countryLabel: 'India',
		countryValue: 'IN'
	},
	{
		value: 'RJ',
		label: 'Rajasthan',
		countryLabel: 'India',
		countryValue: 'IN'
	},
	{
		value: 'SK',
		label: 'Sikkim',
		countryLabel: 'India',
		countryValue: 'IN'
	},
	{
		value: 'TG',
		label: 'Telangana',
		countryLabel: 'India',
		countryValue: 'IN'
	},
	{
		value: 'TN',
		label: 'Tamil Nadu',
		countryLabel: 'India',
		countryValue: 'IN'
	},
	{
		value: 'TR',
		label: 'Tripura',
		countryLabel: 'India',
		countryValue: 'IN'
	},
	{
		value: 'UP',
		label: 'Uttar Pradesh',
		countryLabel: 'India',
		countryValue: 'IN'
	},
	{
		value: 'UT',
		label: 'Uttarakhand',
		countryLabel: 'India',
		countryValue: 'IN'
	},
	{
		value: 'WB',
		label: 'West Bengal',
		countryLabel: 'India',
		countryValue: 'IN'
	},
	{
		value: 'AC',
		label: 'Aceh',
		countryLabel: 'Indonesia',
		countryValue: 'ID'
	},
	{
		value: 'BA',
		label: 'Bali',
		countryLabel: 'Indonesia',
		countryValue: 'ID'
	},
	{
		value: 'BB',
		label: 'Kepulauan Bangka Belitung',
		countryLabel: 'Indonesia',
		countryValue: 'ID'
	},
	{
		value: 'BE',
		label: 'Bengkulu',
		countryLabel: 'Indonesia',
		countryValue: 'ID'
	},
	{
		value: 'BT',
		label: 'Banten',
		countryLabel: 'Indonesia',
		countryValue: 'ID'
	},
	{
		value: 'GO',
		label: 'Gorontalo',
		countryLabel: 'Indonesia',
		countryValue: 'ID'
	},
	{
		value: 'JA',
		label: 'Jambi',
		countryLabel: 'Indonesia',
		countryValue: 'ID'
	},
	{
		value: 'JB',
		label: 'Jawa Barat',
		countryLabel: 'Indonesia',
		countryValue: 'ID'
	},
	{
		value: 'JI',
		label: 'Jawa Timur',
		countryLabel: 'Indonesia',
		countryValue: 'ID'
	},
	{
		value: 'JK',
		label: 'Jakarta Raya',
		countryLabel: 'Indonesia',
		countryValue: 'ID'
	},
	{
		value: 'JT',
		label: 'Jawa Tengah',
		countryLabel: 'Indonesia',
		countryValue: 'ID'
	},
	{
		value: 'KB',
		label: 'Kalimantan Barat',
		countryLabel: 'Indonesia',
		countryValue: 'ID'
	},
	{
		value: 'KI',
		label: 'Kalimantan Timur',
		countryLabel: 'Indonesia',
		countryValue: 'ID'
	},
	{
		value: 'KR',
		label: 'Kepulauan Riau',
		countryLabel: 'Indonesia',
		countryValue: 'ID'
	},
	{
		value: 'KS',
		label: 'Kalimantan Selatan',
		countryLabel: 'Indonesia',
		countryValue: 'ID'
	},
	{
		value: 'KT',
		label: 'Kalimantan Tengah',
		countryLabel: 'Indonesia',
		countryValue: 'ID'
	},
	{
		value: 'KU',
		label: 'Kalimantan Utara',
		countryLabel: 'Indonesia',
		countryValue: 'ID'
	},
	{
		value: 'LA',
		label: 'Lampung',
		countryLabel: 'Indonesia',
		countryValue: 'ID'
	},
	{
		value: 'ML',
		label: 'Maluku',
		countryLabel: 'Indonesia',
		countryValue: 'ID'
	},
	{
		value: 'MU',
		label: 'Maluku Utara',
		countryLabel: 'Indonesia',
		countryValue: 'ID'
	},
	{
		value: 'NB',
		label: 'Nusa Tenggara Barat',
		countryLabel: 'Indonesia',
		countryValue: 'ID'
	},
	{
		value: 'NT',
		label: 'Nusa Tenggara Timur',
		countryLabel: 'Indonesia',
		countryValue: 'ID'
	},
	{
		value: 'PB',
		label: 'Papua Barat',
		countryLabel: 'Indonesia',
		countryValue: 'ID'
	},
	{
		value: 'PP',
		label: 'Papua',
		countryLabel: 'Indonesia',
		countryValue: 'ID'
	},
	{
		value: 'RI',
		label: 'Riau',
		countryLabel: 'Indonesia',
		countryValue: 'ID'
	},
	{
		value: 'SA',
		label: 'Sulawesi Utara',
		countryLabel: 'Indonesia',
		countryValue: 'ID'
	},
	{
		value: 'SB',
		label: 'Sumatera Barat',
		countryLabel: 'Indonesia',
		countryValue: 'ID'
	},
	{
		value: 'SG',
		label: 'Sulawesi Tenggara',
		countryLabel: 'Indonesia',
		countryValue: 'ID'
	},
	{
		value: 'SN',
		label: 'Sulawesi Selatan',
		countryLabel: 'Indonesia',
		countryValue: 'ID'
	},
	{
		value: 'SR',
		label: 'Sulawesi Barat',
		countryLabel: 'Indonesia',
		countryValue: 'ID'
	},
	{
		value: 'SS',
		label: 'Sumatera Selatan',
		countryLabel: 'Indonesia',
		countryValue: 'ID'
	},
	{
		value: 'ST',
		label: 'Sulawesi Tengah',
		countryLabel: 'Indonesia',
		countryValue: 'ID'
	},
	{
		value: 'SU',
		label: 'Sumatera Utara',
		countryLabel: 'Indonesia',
		countryValue: 'ID'
	},
	{
		value: 'YO',
		label: 'Yogyakarta',
		countryLabel: 'Indonesia',
		countryValue: 'ID'
	},
	{
		value: '0',
		label: 'Markazi',
		countryLabel: 'Iran',
		countryValue: 'IR'
	},
	{
		value: '1',
		label: 'Gilan',
		countryLabel: 'Iran',
		countryValue: 'IR'
	},
	{
		value: '2',
		label: 'Mazandaran',
		countryLabel: 'Iran',
		countryValue: 'IR'
	},
	{
		value: '3',
		label: 'Azarbayjan-e Sharqi',
		countryLabel: 'Iran',
		countryValue: 'IR'
	},
	{
		value: '4',
		label: 'Azarbayjan-e Gharbi',
		countryLabel: 'Iran',
		countryValue: 'IR'
	},
	{
		value: '5',
		label: 'Kermanshah',
		countryLabel: 'Iran',
		countryValue: 'IR'
	},
	{
		value: '6',
		label: 'Khuzestan',
		countryLabel: 'Iran',
		countryValue: 'IR'
	},
	{
		value: '7',
		label: 'Fars',
		countryLabel: 'Iran',
		countryValue: 'IR'
	},
	{
		value: '8',
		label: 'Kerman',
		countryLabel: 'Iran',
		countryValue: 'IR'
	},
	{
		value: '9',
		label: 'Khorasan-e Razavi',
		countryLabel: 'Iran',
		countryValue: 'IR'
	},
	{
		value: '10',
		label: 'Esfahan',
		countryLabel: 'Iran',
		countryValue: 'IR'
	},
	{
		value: '11',
		label: 'Sistan va Baluchestan',
		countryLabel: 'Iran',
		countryValue: 'IR'
	},
	{
		value: '12',
		label: 'Kordestan',
		countryLabel: 'Iran',
		countryValue: 'IR'
	},
	{
		value: '13',
		label: 'Hamadan',
		countryLabel: 'Iran',
		countryValue: 'IR'
	},
	{
		value: '14',
		label: 'Chahar Mahal va Bakhtiari',
		countryLabel: 'Iran',
		countryValue: 'IR'
	},
	{
		value: '15',
		label: 'Lorestan',
		countryLabel: 'Iran',
		countryValue: 'IR'
	},
	{
		value: '16',
		label: 'Ilam',
		countryLabel: 'Iran',
		countryValue: 'IR'
	},
	{
		value: '17',
		label: 'Kohgiluyeh va Bowyer Ahmad',
		countryLabel: 'Iran',
		countryValue: 'IR'
	},
	{
		value: '18',
		label: 'Bushehr',
		countryLabel: 'Iran',
		countryValue: 'IR'
	},
	{
		value: '19',
		label: 'Zanjan',
		countryLabel: 'Iran',
		countryValue: 'IR'
	},
	{
		value: '20',
		label: 'Semnan',
		countryLabel: 'Iran',
		countryValue: 'IR'
	},
	{
		value: '21',
		label: 'Yazd',
		countryLabel: 'Iran',
		countryValue: 'IR'
	},
	{
		value: '22',
		label: 'Hormozgan',
		countryLabel: 'Iran',
		countryValue: 'IR'
	},
	{
		value: '23',
		label: 'Tehran',
		countryLabel: 'Iran',
		countryValue: 'IR'
	},
	{
		value: '24',
		label: 'Ardabil',
		countryLabel: 'Iran',
		countryValue: 'IR'
	},
	{
		value: '25',
		label: 'Qom',
		countryLabel: 'Iran',
		countryValue: 'IR'
	},
	{
		value: '26',
		label: 'Qazvin',
		countryLabel: 'Iran',
		countryValue: 'IR'
	},
	{
		value: '27',
		label: 'Golestan',
		countryLabel: 'Iran',
		countryValue: 'IR'
	},
	{
		value: '28',
		label: 'Khorasan-e Shomali',
		countryLabel: 'Iran',
		countryValue: 'IR'
	},
	{
		value: '29',
		label: 'Khorasan-e Jonubi',
		countryLabel: 'Iran',
		countryValue: 'IR'
	},
	{
		value: '30',
		label: 'Alborz',
		countryLabel: 'Iran',
		countryValue: 'IR'
	},
	{
		value: 'AN',
		label: 'Al Anbar',
		countryLabel: 'Iraq',
		countryValue: 'IQ'
	},
	{
		value: 'AR',
		label: 'Arbil',
		countryLabel: 'Iraq',
		countryValue: 'IQ'
	},
	{
		value: 'BA',
		label: 'Al Basrah',
		countryLabel: 'Iraq',
		countryValue: 'IQ'
	},
	{
		value: 'BB',
		label: 'Babil',
		countryLabel: 'Iraq',
		countryValue: 'IQ'
	},
	{
		value: 'BG',
		label: 'Baghdad',
		countryLabel: 'Iraq',
		countryValue: 'IQ'
	},
	{
		value: 'DA',
		label: 'Dahuk',
		countryLabel: 'Iraq',
		countryValue: 'IQ'
	},
	{
		value: 'DI',
		label: 'Diyala',
		countryLabel: 'Iraq',
		countryValue: 'IQ'
	},
	{
		value: 'DQ',
		label: 'Dhi Qar',
		countryLabel: 'Iraq',
		countryValue: 'IQ'
	},
	{
		value: 'KA',
		label: "Karbala'",
		countryLabel: 'Iraq',
		countryValue: 'IQ'
	},
	{
		value: 'KI',
		label: 'Kirkuk',
		countryLabel: 'Iraq',
		countryValue: 'IQ'
	},
	{
		value: 'MA',
		label: 'Maysan',
		countryLabel: 'Iraq',
		countryValue: 'IQ'
	},
	{
		value: 'MU',
		label: 'Al Muthanna',
		countryLabel: 'Iraq',
		countryValue: 'IQ'
	},
	{
		value: 'NA',
		label: 'An Najaf',
		countryLabel: 'Iraq',
		countryValue: 'IQ'
	},
	{
		value: 'NI',
		label: 'Ninawa',
		countryLabel: 'Iraq',
		countryValue: 'IQ'
	},
	{
		value: 'QA',
		label: 'Al Qadisiyah',
		countryLabel: 'Iraq',
		countryValue: 'IQ'
	},
	{
		value: 'SD',
		label: 'Salah ad Din',
		countryLabel: 'Iraq',
		countryValue: 'IQ'
	},
	{
		value: 'SU',
		label: 'As Sulaymaniyah',
		countryLabel: 'Iraq',
		countryValue: 'IQ'
	},
	{
		value: 'WA',
		label: 'Wasit',
		countryLabel: 'Iraq',
		countryValue: 'IQ'
	},
	{
		value: 'CE',
		label: 'Clare',
		countryLabel: 'Ireland',
		countryValue: 'IE'
	},
	{
		value: 'CN',
		label: 'Cavan',
		countryLabel: 'Ireland',
		countryValue: 'IE'
	},
	{
		value: 'CO',
		label: 'Cork',
		countryLabel: 'Ireland',
		countryValue: 'IE'
	},
	{
		value: 'CW',
		label: 'Carlow',
		countryLabel: 'Ireland',
		countryValue: 'IE'
	},
	{
		value: 'D',
		label: 'Dublin',
		countryLabel: 'Ireland',
		countryValue: 'IE'
	},
	{
		value: 'DL',
		label: 'Donegal',
		countryLabel: 'Ireland',
		countryValue: 'IE'
	},
	{
		value: 'G',
		label: 'Galway',
		countryLabel: 'Ireland',
		countryValue: 'IE'
	},
	{
		value: 'KE',
		label: 'Kildare',
		countryLabel: 'Ireland',
		countryValue: 'IE'
	},
	{
		value: 'KK',
		label: 'Kilkenny',
		countryLabel: 'Ireland',
		countryValue: 'IE'
	},
	{
		value: 'KY',
		label: 'Kerry',
		countryLabel: 'Ireland',
		countryValue: 'IE'
	},
	{
		value: 'LD',
		label: 'Longford',
		countryLabel: 'Ireland',
		countryValue: 'IE'
	},
	{
		value: 'LH',
		label: 'Louth',
		countryLabel: 'Ireland',
		countryValue: 'IE'
	},
	{
		value: 'LK',
		label: 'Limerick',
		countryLabel: 'Ireland',
		countryValue: 'IE'
	},
	{
		value: 'LM',
		label: 'Leitrim',
		countryLabel: 'Ireland',
		countryValue: 'IE'
	},
	{
		value: 'LS',
		label: 'Laois',
		countryLabel: 'Ireland',
		countryValue: 'IE'
	},
	{
		value: 'MH',
		label: 'Meath',
		countryLabel: 'Ireland',
		countryValue: 'IE'
	},
	{
		value: 'MN',
		label: 'Monaghan',
		countryLabel: 'Ireland',
		countryValue: 'IE'
	},
	{
		value: 'MO',
		label: 'Mayo',
		countryLabel: 'Ireland',
		countryValue: 'IE'
	},
	{
		value: 'OY',
		label: 'Offaly',
		countryLabel: 'Ireland',
		countryValue: 'IE'
	},
	{
		value: 'RN',
		label: 'Roscommon',
		countryLabel: 'Ireland',
		countryValue: 'IE'
	},
	{
		value: 'SO',
		label: 'Sligo',
		countryLabel: 'Ireland',
		countryValue: 'IE'
	},
	{
		value: 'TA',
		label: 'Tipperary',
		countryLabel: 'Ireland',
		countryValue: 'IE'
	},
	{
		value: 'WD',
		label: 'Waterford',
		countryLabel: 'Ireland',
		countryValue: 'IE'
	},
	{
		value: 'WH',
		label: 'Westmeath',
		countryLabel: 'Ireland',
		countryValue: 'IE'
	},
	{
		value: 'WW',
		label: 'Wicklow',
		countryLabel: 'Ireland',
		countryValue: 'IE'
	},
	{
		value: 'WX',
		label: 'Wexford',
		countryLabel: 'Ireland',
		countryValue: 'IE'
	},
	{
		value: 'D',
		label: 'HaDarom',
		countryLabel: 'Israel',
		countryValue: 'IL'
	},
	{
		value: 'HA',
		label: 'Hefa',
		countryLabel: 'Israel',
		countryValue: 'IL'
	},
	{
		value: 'JM',
		label: 'Yerushalayim',
		countryLabel: 'Israel',
		countryValue: 'IL'
	},
	{
		value: 'M',
		label: 'HaMerkaz',
		countryLabel: 'Israel',
		countryValue: 'IL'
	},
	{
		value: 'TA',
		label: 'Tel Aviv',
		countryLabel: 'Israel',
		countryValue: 'IL'
	},
	{
		value: 'Z',
		label: 'HaTsafon',
		countryLabel: 'Israel',
		countryValue: 'IL'
	},
	{
		value: '21',
		label: 'Piemonte',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: '23',
		label: "Valle d'Aosta",
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: '25',
		label: 'Lombardia',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: '32',
		label: 'Trentino-Alto Adige',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: '34',
		label: 'Veneto',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: '36',
		label: 'Friuli-Venezia Giulia',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: '42',
		label: 'Liguria',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: '45',
		label: 'Emilia-Romagna',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: '52',
		label: 'Toscana',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: '55',
		label: 'Umbria',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: '57',
		label: 'Marche',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: '62',
		label: 'Lazio',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: '65',
		label: 'Abruzzo',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: '67',
		label: 'Molise',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: '72',
		label: 'Campania',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: '75',
		label: 'Puglia',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: '77',
		label: 'Basilicata',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: '78',
		label: 'Calabria',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: '82',
		label: 'Sicilia',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: '88',
		label: 'Sardegna',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'AG',
		label: 'Agrigento',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'AL',
		label: 'Alessandria',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'AN',
		label: 'Ancona',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'AO',
		label: 'Aosta',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'AP',
		label: 'Ascoli Piceno',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'AQ',
		label: 'LAquila',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'AR',
		label: 'Arezzo',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'AT',
		label: 'Asti',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'AV',
		label: 'Avellino',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'BA',
		label: 'Bari',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'BG',
		label: 'Bergamo',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'BI',
		label: 'Biella',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'BL',
		label: 'Belluno',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'BN',
		label: 'Benevento',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'BO',
		label: 'Bologna',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'BR',
		label: 'Brindisi',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'BS',
		label: 'Brescia',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'BT',
		label: 'Barletta-Andria-Trani',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'BZ',
		label: 'Bolzano',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'CA',
		label: 'Cagliari',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'CB',
		label: 'Campobasso',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'CE',
		label: 'Caserta',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'CH',
		label: 'Chieti',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'CI',
		label: 'Carbonia-Iglesias',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'CL',
		label: 'Caltanissetta',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'CN',
		label: 'Cuneo',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'CO',
		label: 'Como',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'CR',
		label: 'Cremona',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'CS',
		label: 'Cosenza',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'CT',
		label: 'Catania',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'CZ',
		label: 'Catanzaro',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'EN',
		label: 'Enna',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'FC',
		label: 'Forli-Cesena',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'FE',
		label: 'Ferrara',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'FG',
		label: 'Foggia',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'FI',
		label: 'Florence',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'FM',
		label: 'Fermo',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'FR',
		label: 'Frosinone',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'GE',
		label: 'Genoa',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'GO',
		label: 'Gorizia',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'GR',
		label: 'Grosseto',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'IM',
		label: 'Imperia',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'IS',
		label: 'Isernia',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'KR',
		label: 'Crotone',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'LC',
		label: 'Lecco',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'LE',
		label: 'Lecce',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'LI',
		label: 'Livorno',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'LO',
		label: 'Lodi',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'LT',
		label: 'Latina',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'LU',
		label: 'Lucca',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'MB',
		label: 'Monza and Brianza',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'MC',
		label: 'Macerata',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'ME',
		label: 'Messina',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'MI',
		label: 'Milan',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'MN',
		label: 'Mantua',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'MO',
		label: 'Modena',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'MS',
		label: 'Massa and Carrara',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'MT',
		label: 'Matera',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'NA',
		label: 'Naples',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'NO',
		label: 'Novara',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'NU',
		label: 'Nuoro',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'OG',
		label: 'Ogliastra',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'OR',
		label: 'Oristano',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'OT',
		label: 'Olbia-Tempio',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'PA',
		label: 'Palermo',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'PC',
		label: 'Piacenza',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'PD',
		label: 'Padua',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'PE',
		label: 'Pescara',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'PG',
		label: 'Perugia',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'PI',
		label: 'Pisa',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'PN',
		label: 'Pordenone',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'PO',
		label: 'Prato',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'PR',
		label: 'Parma',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'PT',
		label: 'Pistoia',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'PU',
		label: 'Pesaro and Urbino',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'PV',
		label: 'Pavia',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'PZ',
		label: 'Potenza',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'RA',
		label: 'Ravenna',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'RC',
		label: 'Reggio Calabria',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'RE',
		label: 'Reggio Emilia',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'RG',
		label: 'Ragusa',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'RI',
		label: 'Rieti',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'RM',
		label: 'Rome',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'RN',
		label: 'Rimini',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'RO',
		label: 'Rovigo',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'SA',
		label: 'Salerno',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'SI',
		label: 'Siena',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'SO',
		label: 'Sondrio',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'SP',
		label: 'La Spezia',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'SR',
		label: 'Syracuse',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'SS',
		label: 'Sassari',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'SV',
		label: 'Savona',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'TA',
		label: 'Taranto',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'TE',
		label: 'Teramo',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'TN',
		label: 'Trento',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'TO',
		label: 'Turin',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'TP',
		label: 'Trapani',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'TR',
		label: 'Terni',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'TS',
		label: 'Trieste',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'TV',
		label: 'Treviso',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'UD',
		label: 'Udine',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'VA',
		label: 'Varese',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'VB',
		label: 'Verbano-Cusio-Ossola',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'VC',
		label: 'Vercelli',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'VE',
		label: 'Venice',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'VI',
		label: 'Vicenza',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'VR',
		label: 'Verona',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'VS',
		label: 'Medio Campidano',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'VT',
		label: 'Viterbo',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'VV',
		label: 'Vibo Valentia',
		countryLabel: 'Italy',
		countryValue: 'IT'
	},
	{
		value: 'AB',
		label: 'Abidjan',
		countryLabel: 'Ivory Coast',
		countryValue: 'CI'
	},
	{
		value: 'BS',
		label: 'Bas-Sassandra',
		countryLabel: 'Ivory Coast',
		countryValue: 'CI'
	},
	{
		value: 'CM',
		label: 'Comoe',
		countryLabel: 'Ivory Coast',
		countryValue: 'CI'
	},
	{
		value: 'DN',
		label: 'Denguele',
		countryLabel: 'Ivory Coast',
		countryValue: 'CI'
	},
	{
		value: 'GD',
		label: 'Goh-Djiboua',
		countryLabel: 'Ivory Coast',
		countryValue: 'CI'
	},
	{
		value: 'LC',
		label: 'Lacs',
		countryLabel: 'Ivory Coast',
		countryValue: 'CI'
	},
	{
		value: 'LG',
		label: 'Lagunes',
		countryLabel: 'Ivory Coast',
		countryValue: 'CI'
	},
	{
		value: 'MG',
		label: 'Montagnes',
		countryLabel: 'Ivory Coast',
		countryValue: 'CI'
	},
	{
		value: 'SM',
		label: 'Sassandra-Marahoue',
		countryLabel: 'Ivory Coast',
		countryValue: 'CI'
	},
	{
		value: 'SV',
		label: 'Savanes',
		countryLabel: 'Ivory Coast',
		countryValue: 'CI'
	},
	{
		value: 'VB',
		label: 'Vallee du Bandama',
		countryLabel: 'Ivory Coast',
		countryValue: 'CI'
	},
	{
		value: 'WR',
		label: 'Woroba',
		countryLabel: 'Ivory Coast',
		countryValue: 'CI'
	},
	{
		value: 'YM',
		label: 'Yamoussoukro',
		countryLabel: 'Ivory Coast',
		countryValue: 'CI'
	},
	{
		value: 'ZZ',
		label: 'Zanzan',
		countryLabel: 'Ivory Coast',
		countryValue: 'CI'
	},
	{
		value: '1',
		label: 'Kingston',
		countryLabel: 'Jamaica',
		countryValue: 'JM'
	},
	{
		value: '2',
		label: 'Saint Andrew',
		countryLabel: 'Jamaica',
		countryValue: 'JM'
	},
	{
		value: '3',
		label: 'Saint Thomas',
		countryLabel: 'Jamaica',
		countryValue: 'JM'
	},
	{
		value: '4',
		label: 'Portland',
		countryLabel: 'Jamaica',
		countryValue: 'JM'
	},
	{
		value: '5',
		label: 'Saint Mary',
		countryLabel: 'Jamaica',
		countryValue: 'JM'
	},
	{
		value: '6',
		label: 'Saint Ann',
		countryLabel: 'Jamaica',
		countryValue: 'JM'
	},
	{
		value: '7',
		label: 'Trelawny',
		countryLabel: 'Jamaica',
		countryValue: 'JM'
	},
	{
		value: '8',
		label: 'Saint James',
		countryLabel: 'Jamaica',
		countryValue: 'JM'
	},
	{
		value: '9',
		label: 'Hanover',
		countryLabel: 'Jamaica',
		countryValue: 'JM'
	},
	{
		value: '10',
		label: 'Westmoreland',
		countryLabel: 'Jamaica',
		countryValue: 'JM'
	},
	{
		value: '11',
		label: 'Saint Elizabeth',
		countryLabel: 'Jamaica',
		countryValue: 'JM'
	},
	{
		value: '12',
		label: 'Manchester',
		countryLabel: 'Jamaica',
		countryValue: 'JM'
	},
	{
		value: '13',
		label: 'Clarendon',
		countryLabel: 'Jamaica',
		countryValue: 'JM'
	},
	{
		value: '14',
		label: 'Saint Catherine',
		countryLabel: 'Jamaica',
		countryValue: 'JM'
	},
	{
		value: '1',
		label: 'Hokkaido',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '2',
		label: 'Aomori',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '3',
		label: 'Iwate',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '4',
		label: 'Miyagi',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '5',
		label: 'Akita',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '6',
		label: 'Yamagata',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '7',
		label: 'Fukushima',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '8',
		label: 'Ibaraki',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '9',
		label: 'Tochigi',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '10',
		label: 'Gunma',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '11',
		label: 'Saitama',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '12',
		label: 'Chiba',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '13',
		label: 'Tokyo',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '14',
		label: 'Kanagawa',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '15',
		label: 'Niigata',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '16',
		label: 'Toyama',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '17',
		label: 'Ishikawa',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '18',
		label: 'Fukui',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '19',
		label: 'Yamanashi',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '20',
		label: 'Nagano',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '21',
		label: 'Gifu',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '22',
		label: 'Shizuoka',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '23',
		label: 'Aichi',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '24',
		label: 'Mie',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '25',
		label: 'Shiga',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '26',
		label: 'Kyoto',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '27',
		label: 'Osaka',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '28',
		label: 'Hyogo',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '29',
		label: 'Nara',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '30',
		label: 'Wakayama',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '31',
		label: 'Tottori',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '32',
		label: 'Shimane',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '33',
		label: 'Okayama',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '34',
		label: 'Hiroshima',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '35',
		label: 'Yamaguchi',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '36',
		label: 'Tokushima',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '37',
		label: 'Kagawa',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '38',
		label: 'Ehime',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '39',
		label: 'Kochi',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '40',
		label: 'Fukuoka',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '41',
		label: 'Saga',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '42',
		label: 'Nagasaki',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '43',
		label: 'Kumamoto',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '44',
		label: 'Oita',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '45',
		label: 'Miyazaki',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '46',
		label: 'Kagoshima',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: '47',
		label: 'Okinawa',
		countryLabel: 'Japan',
		countryValue: 'JP'
	},
	{
		value: 'AJ',
		label: 'Ajlun',
		countryLabel: 'Jordan',
		countryValue: 'JO'
	},
	{
		value: 'AM',
		label: "Al 'Asimah",
		countryLabel: 'Jordan',
		countryValue: 'JO'
	},
	{
		value: 'AQ',
		label: "Al 'Aqabah",
		countryLabel: 'Jordan',
		countryValue: 'JO'
	},
	{
		value: 'AT',
		label: 'At Tafilah',
		countryLabel: 'Jordan',
		countryValue: 'JO'
	},
	{
		value: 'AZ',
		label: "Az Zarqa'",
		countryLabel: 'Jordan',
		countryValue: 'JO'
	},
	{
		value: 'BA',
		label: "Al Balqa'",
		countryLabel: 'Jordan',
		countryValue: 'JO'
	},
	{
		value: 'IR',
		label: 'Irbid',
		countryLabel: 'Jordan',
		countryValue: 'JO'
	},
	{
		value: 'JA',
		label: 'Jarash',
		countryLabel: 'Jordan',
		countryValue: 'JO'
	},
	{
		value: 'KA',
		label: 'Al Karak',
		countryLabel: 'Jordan',
		countryValue: 'JO'
	},
	{
		value: 'MA',
		label: 'Al Mafraq',
		countryLabel: 'Jordan',
		countryValue: 'JO'
	},
	{
		value: 'MD',
		label: 'Madaba',
		countryLabel: 'Jordan',
		countryValue: 'JO'
	},
	{
		value: 'MN',
		label: "Ma'an",
		countryLabel: 'Jordan',
		countryValue: 'JO'
	},
	{
		value: 'AKM',
		label: 'Aqmola oblysy',
		countryLabel: 'Kazakhstan',
		countryValue: 'KZ'
	},
	{
		value: 'AKT',
		label: 'Aqtobe oblysy',
		countryLabel: 'Kazakhstan',
		countryValue: 'KZ'
	},
	{
		value: 'ALA',
		label: 'Almaty',
		countryLabel: 'Kazakhstan',
		countryValue: 'KZ'
	},
	{
		value: 'ALM',
		label: 'Almaty oblysy',
		countryLabel: 'Kazakhstan',
		countryValue: 'KZ'
	},
	{
		value: 'AST',
		label: 'Nur-Sultan',
		countryLabel: 'Kazakhstan',
		countryValue: 'KZ'
	},
	{
		value: 'ATY',
		label: 'Atyrau oblysy',
		countryLabel: 'Kazakhstan',
		countryValue: 'KZ'
	},
	{
		value: 'KAR',
		label: 'Qaraghandy oblysy',
		countryLabel: 'Kazakhstan',
		countryValue: 'KZ'
	},
	{
		value: 'KUS',
		label: 'Qostanay oblysy',
		countryLabel: 'Kazakhstan',
		countryValue: 'KZ'
	},
	{
		value: 'KZY',
		label: 'Qyzylorda oblysy',
		countryLabel: 'Kazakhstan',
		countryValue: 'KZ'
	},
	{
		value: 'MAN',
		label: 'Mangghystau oblysy',
		countryLabel: 'Kazakhstan',
		countryValue: 'KZ'
	},
	{
		value: 'PAV',
		label: 'Pavlodar oblysy',
		countryLabel: 'Kazakhstan',
		countryValue: 'KZ'
	},
	{
		value: 'SEV',
		label: 'Soltustik Qazaqstan oblysy',
		countryLabel: 'Kazakhstan',
		countryValue: 'KZ'
	},
	{
		value: 'SHY',
		label: 'Shymkent',
		countryLabel: 'Kazakhstan',
		countryValue: 'KZ'
	},
	{
		value: 'VOS',
		label: 'Shyghys Qazaqstan oblysy',
		countryLabel: 'Kazakhstan',
		countryValue: 'KZ'
	},
	{
		value: 'YUZ',
		label: 'Ongtustik Qazaqstan oblysy',
		countryLabel: 'Kazakhstan',
		countryValue: 'KZ'
	},
	{
		value: 'ZAP',
		label: 'Batys Qazaqstan oblysy',
		countryLabel: 'Kazakhstan',
		countryValue: 'KZ'
	},
	{
		value: 'ZHA',
		label: 'Zhambyl oblysy',
		countryLabel: 'Kazakhstan',
		countryValue: 'KZ'
	},
	{
		value: '1',
		label: 'Baringo',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '2',
		label: 'Bomet',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '3',
		label: 'Bungoma',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '4',
		label: 'Busia',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '5',
		label: 'Elgeyo/Marakwet',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '6',
		label: 'Embu',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '7',
		label: 'Garissa',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '8',
		label: 'Homa Bay',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '9',
		label: 'Isiolo',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '10',
		label: 'Kajiado',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '11',
		label: 'Kakamega',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '12',
		label: 'Kericho',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '13',
		label: 'Kiambu',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '14',
		label: 'Kilifi',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '15',
		label: 'Kirinyaga',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '16',
		label: 'Kisii',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '17',
		label: 'Kisumu',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '18',
		label: 'Kitui',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '19',
		label: 'Kwale',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '20',
		label: 'Laikipia',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '21',
		label: 'Lamu',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '22',
		label: 'Machakos',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '23',
		label: 'Makueni',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '24',
		label: 'Mandera',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '25',
		label: 'Marsabit',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '26',
		label: 'Meru',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '27',
		label: 'Migori',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '28',
		label: 'Mombasa',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '29',
		label: "Murang'a",
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '30',
		label: 'Nairobi City',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '31',
		label: 'Nakuru',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '32',
		label: 'Nandi',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '33',
		label: 'Narok',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '34',
		label: 'Nyamira',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '35',
		label: 'Nyandarua',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '36',
		label: 'Nyeri',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '37',
		label: 'Samburu',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '38',
		label: 'Siaya',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '39',
		label: 'Taita/Taveta',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '40',
		label: 'Tana River',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '41',
		label: 'Tharaka-Nithi',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '42',
		label: 'Trans Nzoia',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '43',
		label: 'Turkana',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '44',
		label: 'Uasin Gishu',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '45',
		label: 'Vihiga',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '46',
		label: 'Wajir',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: '47',
		label: 'West Pokot',
		countryLabel: 'Kenya',
		countryValue: 'KE'
	},
	{
		value: 'G',
		label: 'Gilbert Islands',
		countryLabel: 'Kiribati',
		countryValue: 'KI'
	},
	{
		value: 'L',
		label: 'Line Islands',
		countryLabel: 'Kiribati',
		countryValue: 'KI'
	},
	{
		value: '11',
		label: 'Seoul-teukbyeolsi',
		countryLabel: 'Korea',
		countryValue: 'KR'
	},
	{
		value: '26',
		label: 'Busan-gwangyeoksi',
		countryLabel: 'Korea',
		countryValue: 'KR'
	},
	{
		value: '27',
		label: 'Daegu-gwangyeoksi',
		countryLabel: 'Korea',
		countryValue: 'KR'
	},
	{
		value: '28',
		label: 'Incheon-gwangyeoksi',
		countryLabel: 'Korea',
		countryValue: 'KR'
	},
	{
		value: '29',
		label: 'Gwangju-gwangyeoksi',
		countryLabel: 'Korea',
		countryValue: 'KR'
	},
	{
		value: '30',
		label: 'Daejeon-gwangyeoksi',
		countryLabel: 'Korea',
		countryValue: 'KR'
	},
	{
		value: '31',
		label: 'Ulsan-gwangyeoksi',
		countryLabel: 'Korea',
		countryValue: 'KR'
	},
	{
		value: '41',
		label: 'Gyeonggi-do',
		countryLabel: 'Korea',
		countryValue: 'KR'
	},
	{
		value: '42',
		label: 'Gangwon-do',
		countryLabel: 'Korea',
		countryValue: 'KR'
	},
	{
		value: '43',
		label: 'Chungcheongbuk-do',
		countryLabel: 'Korea',
		countryValue: 'KR'
	},
	{
		value: '44',
		label: 'Chungcheongnam-do',
		countryLabel: 'Korea',
		countryValue: 'KR'
	},
	{
		value: '45',
		label: 'Jeollabuk-do',
		countryLabel: 'Korea',
		countryValue: 'KR'
	},
	{
		value: '46',
		label: 'Jeollanam-do',
		countryLabel: 'Korea',
		countryValue: 'KR'
	},
	{
		value: '47',
		label: 'Gyeongsangbuk-do',
		countryLabel: 'Korea',
		countryValue: 'KR'
	},
	{
		value: '48',
		label: 'Gyeongsangnam-do',
		countryLabel: 'Korea',
		countryValue: 'KR'
	},
	{
		value: '49',
		label: 'Jeju-teukbyeoljachido',
		countryLabel: 'Korea',
		countryValue: 'KR'
	},
	{
		value: '1',
		label: "P'yongyang",
		countryLabel: 'Korea (North)',
		countryValue: 'KP'
	},
	{
		value: 'AH',
		label: 'Al Ahmadi',
		countryLabel: 'Kuwait',
		countryValue: 'KW'
	},
	{
		value: 'FA',
		label: 'Al Farwaniyah',
		countryLabel: 'Kuwait',
		countryValue: 'KW'
	},
	{
		value: 'HA',
		label: 'Hawalli',
		countryLabel: 'Kuwait',
		countryValue: 'KW'
	},
	{
		value: 'JA',
		label: "Al Jahra'",
		countryLabel: 'Kuwait',
		countryValue: 'KW'
	},
	{
		value: 'KU',
		label: "Al 'Asimah",
		countryLabel: 'Kuwait',
		countryValue: 'KW'
	},
	{
		value: 'MU',
		label: 'Mubarak al Kabir',
		countryLabel: 'Kuwait',
		countryValue: 'KW'
	},
	{
		value: 'B',
		label: 'Batken',
		countryLabel: 'Kyrgyz Republic',
		countryValue: 'KG'
	},
	{
		value: 'C',
		label: 'Chuy',
		countryLabel: 'Kyrgyz Republic',
		countryValue: 'KG'
	},
	{
		value: 'GB',
		label: 'Bishkek Shaary',
		countryLabel: 'Kyrgyz Republic',
		countryValue: 'KG'
	},
	{
		value: 'GO',
		label: 'Osh Shaary',
		countryLabel: 'Kyrgyz Republic',
		countryValue: 'KG'
	},
	{
		value: 'J',
		label: 'Jalal-Abad',
		countryLabel: 'Kyrgyz Republic',
		countryValue: 'KG'
	},
	{
		value: 'N',
		label: 'Naryn',
		countryLabel: 'Kyrgyz Republic',
		countryValue: 'KG'
	},
	{
		value: 'T',
		label: 'Talas',
		countryLabel: 'Kyrgyz Republic',
		countryValue: 'KG'
	},
	{
		value: 'Y',
		label: 'Ysyk-Kol',
		countryLabel: 'Kyrgyz Republic',
		countryValue: 'KG'
	},
	{
		value: 'AT',
		label: 'Attapu',
		countryLabel: 'Lao, PDR',
		countryValue: 'LA'
	},
	{
		value: 'BL',
		label: 'Bolikhamxai',
		countryLabel: 'Lao, PDR',
		countryValue: 'LA'
	},
	{
		value: 'CH',
		label: 'Champasak',
		countryLabel: 'Lao, PDR',
		countryValue: 'LA'
	},
	{
		value: 'KH',
		label: 'Khammouan',
		countryLabel: 'Lao, PDR',
		countryValue: 'LA'
	},
	{
		value: 'LM',
		label: 'Louang Namtha',
		countryLabel: 'Lao, PDR',
		countryValue: 'LA'
	},
	{
		value: 'LP',
		label: 'Louangphabang',
		countryLabel: 'Lao, PDR',
		countryValue: 'LA'
	},
	{
		value: 'OU',
		label: 'Oudomxai',
		countryLabel: 'Lao, PDR',
		countryValue: 'LA'
	},
	{
		value: 'PH',
		label: 'Phongsali',
		countryLabel: 'Lao, PDR',
		countryValue: 'LA'
	},
	{
		value: 'SV',
		label: 'Savannakhet',
		countryLabel: 'Lao, PDR',
		countryValue: 'LA'
	},
	{
		value: 'VI',
		label: 'Viangchan',
		countryLabel: 'Lao, PDR',
		countryValue: 'LA'
	},
	{
		value: 'XA',
		label: 'Xaignabouli',
		countryLabel: 'Lao, PDR',
		countryValue: 'LA'
	},
	{
		value: 'XE',
		label: 'Xekong',
		countryLabel: 'Lao, PDR',
		countryValue: 'LA'
	},
	{
		value: 'XI',
		label: 'Xiangkhouang',
		countryLabel: 'Lao, PDR',
		countryValue: 'LA'
	},
	{
		value: '1',
		label: 'Aglonas novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '2',
		label: 'Aizkraukles novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '3',
		label: 'Aizputes novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '5',
		label: 'Alojas novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '7',
		label: 'Aluksnes novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '9',
		label: 'Apes novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '10',
		label: 'Auces novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '11',
		label: 'Adazu novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '12',
		label: 'Babites novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '13',
		label: 'Baldones novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '14',
		label: 'Baltinavas novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '15',
		label: 'Balvu novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '16',
		label: 'Bauskas novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '17',
		label: 'Beverinas novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '18',
		label: 'Brocenu novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '20',
		label: 'Carnikavas novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '21',
		label: 'Cesvaines novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '22',
		label: 'Cesu novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '24',
		label: 'Dagdas novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '25',
		label: 'Daugavpils novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '26',
		label: 'Dobeles novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '27',
		label: 'Dundagas novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '29',
		label: 'Engures novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '30',
		label: 'Erglu novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '33',
		label: 'Gulbenes novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '34',
		label: 'Iecavas novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '35',
		label: 'Ikskiles novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '37',
		label: 'Incukalna novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '38',
		label: 'Jaunjelgavas novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '39',
		label: 'Jaunpiebalgas novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '40',
		label: 'Jaunpils novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '41',
		label: 'Jelgavas novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '42',
		label: 'Jekabpils novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '43',
		label: 'Kandavas novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '46',
		label: 'Kokneses novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '47',
		label: 'Kraslavas novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '50',
		label: 'Kuldigas novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '52',
		label: 'Kekavas novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '53',
		label: 'Lielvardes novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '54',
		label: 'Limbazu novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '56',
		label: 'Livanu novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '57',
		label: 'Lubanas novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '58',
		label: 'Ludzas novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '59',
		label: 'Madonas novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '61',
		label: 'Malpils novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '64',
		label: 'Nauksenu novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '67',
		label: 'Ogres novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '68',
		label: 'Olaines novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '69',
		label: 'Ozolnieku novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '73',
		label: 'Preilu novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '75',
		label: 'Priekules novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '77',
		label: 'Rezeknes novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '78',
		label: 'Riebinu novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '83',
		label: 'Rundales novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '87',
		label: 'Salaspils novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '88',
		label: 'Saldus novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '89',
		label: 'Saulkrastu novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '90',
		label: 'Sejas novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '91',
		label: 'Siguldas novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '93',
		label: 'Skrundas novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '94',
		label: 'Smiltenes novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '95',
		label: 'Stopinu novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '97',
		label: 'Talsu novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '98',
		label: 'Tervetes novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '99',
		label: 'Tukuma novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '100',
		label: 'Vainodes novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '101',
		label: 'Valkas novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '102',
		label: 'Varaklanu novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '103',
		label: 'Varkavas novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '105',
		label: 'Vecumnieku novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '106',
		label: 'Ventspils novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '108',
		label: 'Vilakas novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: '110',
		label: 'Zilupes novads',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: 'JEL',
		label: 'Jelgava',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: 'JUR',
		label: 'Jurmala',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: 'LPX',
		label: 'Liepaja',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: 'RIX',
		label: 'Riga',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: 'VMR',
		label: 'Valmiera',
		countryLabel: 'Latvia',
		countryValue: 'LV'
	},
	{
		value: 'AK',
		label: 'Aakkar',
		countryLabel: 'Lebanon',
		countryValue: 'LB'
	},
	{
		value: 'AS',
		label: 'Liban-Nord',
		countryLabel: 'Lebanon',
		countryValue: 'LB'
	},
	{
		value: 'BA',
		label: 'Beyrouth',
		countryLabel: 'Lebanon',
		countryValue: 'LB'
	},
	{
		value: 'BH',
		label: 'Baalbek-Hermel',
		countryLabel: 'Lebanon',
		countryValue: 'LB'
	},
	{
		value: 'BI',
		label: 'Beqaa',
		countryLabel: 'Lebanon',
		countryValue: 'LB'
	},
	{
		value: 'JA',
		label: 'Liban-Sud',
		countryLabel: 'Lebanon',
		countryValue: 'LB'
	},
	{
		value: 'JL',
		label: 'Mont-Liban',
		countryLabel: 'Lebanon',
		countryValue: 'LB'
	},
	{
		value: 'NA',
		label: 'Nabatiye',
		countryLabel: 'Lebanon',
		countryValue: 'LB'
	},
	{
		value: 'A',
		label: 'Maseru',
		countryLabel: 'Lesotho',
		countryValue: 'LS'
	},
	{
		value: 'C',
		label: 'Leribe',
		countryLabel: 'Lesotho',
		countryValue: 'LS'
	},
	{
		value: 'D',
		label: 'Berea',
		countryLabel: 'Lesotho',
		countryValue: 'LS'
	},
	{
		value: 'E',
		label: 'Mafeteng',
		countryLabel: 'Lesotho',
		countryValue: 'LS'
	},
	{
		value: 'G',
		label: 'Quthing',
		countryLabel: 'Lesotho',
		countryValue: 'LS'
	},
	{
		value: 'J',
		label: 'Mokhotlong',
		countryLabel: 'Lesotho',
		countryValue: 'LS'
	},
	{
		value: 'K',
		label: 'Thaba-Tseka',
		countryLabel: 'Lesotho',
		countryValue: 'LS'
	},
	{
		value: 'BM',
		label: 'Bomi',
		countryLabel: 'Liberia',
		countryValue: 'LR'
	},
	{
		value: 'GB',
		label: 'Grand Bassa',
		countryLabel: 'Liberia',
		countryValue: 'LR'
	},
	{
		value: 'GG',
		label: 'Grand Gedeh',
		countryLabel: 'Liberia',
		countryValue: 'LR'
	},
	{
		value: 'MG',
		label: 'Margibi',
		countryLabel: 'Liberia',
		countryValue: 'LR'
	},
	{
		value: 'MO',
		label: 'Montserrado',
		countryLabel: 'Liberia',
		countryValue: 'LR'
	},
	{
		value: 'SI',
		label: 'Sinoe',
		countryLabel: 'Liberia',
		countryValue: 'LR'
	},
	{
		value: 'BA',
		label: 'Banghazi',
		countryLabel: 'Libya',
		countryValue: 'LY'
	},
	{
		value: 'BU',
		label: 'Al Butnan',
		countryLabel: 'Libya',
		countryValue: 'LY'
	},
	{
		value: 'DR',
		label: 'Darnah',
		countryLabel: 'Libya',
		countryValue: 'LY'
	},
	{
		value: 'JA',
		label: 'Al Jabal al Akhdar',
		countryLabel: 'Libya',
		countryValue: 'LY'
	},
	{
		value: 'JG',
		label: 'Al Jabal al Gharbi',
		countryLabel: 'Libya',
		countryValue: 'LY'
	},
	{
		value: 'JI',
		label: 'Al Jafarah',
		countryLabel: 'Libya',
		countryValue: 'LY'
	},
	{
		value: 'JU',
		label: 'Al Jufrah',
		countryLabel: 'Libya',
		countryValue: 'LY'
	},
	{
		value: 'MB',
		label: 'Al Marqab',
		countryLabel: 'Libya',
		countryValue: 'LY'
	},
	{
		value: 'MI',
		label: 'Misratah',
		countryLabel: 'Libya',
		countryValue: 'LY'
	},
	{
		value: 'MJ',
		label: 'Al Marj',
		countryLabel: 'Libya',
		countryValue: 'LY'
	},
	{
		value: 'MQ',
		label: 'Murzuq',
		countryLabel: 'Libya',
		countryValue: 'LY'
	},
	{
		value: 'NL',
		label: 'Nalut',
		countryLabel: 'Libya',
		countryValue: 'LY'
	},
	{
		value: 'NQ',
		label: 'An Nuqat al Khams',
		countryLabel: 'Libya',
		countryValue: 'LY'
	},
	{
		value: 'SB',
		label: 'Sabha',
		countryLabel: 'Libya',
		countryValue: 'LY'
	},
	{
		value: 'SR',
		label: 'Surt',
		countryLabel: 'Libya',
		countryValue: 'LY'
	},
	{
		value: 'TB',
		label: 'Tarabulus',
		countryLabel: 'Libya',
		countryValue: 'LY'
	},
	{
		value: 'WA',
		label: 'Al Wahat',
		countryLabel: 'Libya',
		countryValue: 'LY'
	},
	{
		value: 'ZA',
		label: 'Az Zawiyah',
		countryLabel: 'Libya',
		countryValue: 'LY'
	},
	{
		value: '1',
		label: 'Balzers',
		countryLabel: 'Liechtenstein',
		countryValue: 'LI'
	},
	{
		value: '2',
		label: 'Eschen',
		countryLabel: 'Liechtenstein',
		countryValue: 'LI'
	},
	{
		value: '3',
		label: 'Gamprin',
		countryLabel: 'Liechtenstein',
		countryValue: 'LI'
	},
	{
		value: '4',
		label: 'Mauren',
		countryLabel: 'Liechtenstein',
		countryValue: 'LI'
	},
	{
		value: '5',
		label: 'Planken',
		countryLabel: 'Liechtenstein',
		countryValue: 'LI'
	},
	{
		value: '6',
		label: 'Ruggell',
		countryLabel: 'Liechtenstein',
		countryValue: 'LI'
	},
	{
		value: '7',
		label: 'Schaan',
		countryLabel: 'Liechtenstein',
		countryValue: 'LI'
	},
	{
		value: '9',
		label: 'Triesen',
		countryLabel: 'Liechtenstein',
		countryValue: 'LI'
	},
	{
		value: '10',
		label: 'Triesenberg',
		countryLabel: 'Liechtenstein',
		countryValue: 'LI'
	},
	{
		value: '11',
		label: 'Vaduz',
		countryLabel: 'Liechtenstein',
		countryValue: 'LI'
	},
	{
		value: 'AL',
		label: 'Alytaus apskritis',
		countryLabel: 'Lithuania',
		countryValue: 'LT'
	},
	{
		value: 'KL',
		label: 'Klaipedos apskritis',
		countryLabel: 'Lithuania',
		countryValue: 'LT'
	},
	{
		value: 'KU',
		label: 'Kauno apskritis',
		countryLabel: 'Lithuania',
		countryValue: 'LT'
	},
	{
		value: 'MR',
		label: 'Marijampoles apskritis',
		countryLabel: 'Lithuania',
		countryValue: 'LT'
	},
	{
		value: 'PN',
		label: 'Panevezio apskritis',
		countryLabel: 'Lithuania',
		countryValue: 'LT'
	},
	{
		value: 'SA',
		label: 'Siauliu apskritis',
		countryLabel: 'Lithuania',
		countryValue: 'LT'
	},
	{
		value: 'TA',
		label: 'Taurages apskritis',
		countryLabel: 'Lithuania',
		countryValue: 'LT'
	},
	{
		value: 'TE',
		label: 'Telsiu apskritis',
		countryLabel: 'Lithuania',
		countryValue: 'LT'
	},
	{
		value: 'UT',
		label: 'Utenos apskritis',
		countryLabel: 'Lithuania',
		countryValue: 'LT'
	},
	{
		value: 'VL',
		label: 'Vilniaus apskritis',
		countryLabel: 'Lithuania',
		countryValue: 'LT'
	},
	{
		value: 'CA',
		label: 'Capellen',
		countryLabel: 'Luxembourg',
		countryValue: 'LU'
	},
	{
		value: 'CL',
		label: 'Clervaux',
		countryLabel: 'Luxembourg',
		countryValue: 'LU'
	},
	{
		value: 'DI',
		label: 'Diekirch',
		countryLabel: 'Luxembourg',
		countryValue: 'LU'
	},
	{
		value: 'EC',
		label: 'Echternach',
		countryLabel: 'Luxembourg',
		countryValue: 'LU'
	},
	{
		value: 'ES',
		label: 'Esch-sur-Alzette',
		countryLabel: 'Luxembourg',
		countryValue: 'LU'
	},
	{
		value: 'GR',
		label: 'Grevenmacher',
		countryLabel: 'Luxembourg',
		countryValue: 'LU'
	},
	{
		value: 'LU',
		label: 'Luxembourg',
		countryLabel: 'Luxembourg',
		countryValue: 'LU'
	},
	{
		value: 'ME',
		label: 'Mersch',
		countryLabel: 'Luxembourg',
		countryValue: 'LU'
	},
	{
		value: 'RD',
		label: 'Redange',
		countryLabel: 'Luxembourg',
		countryValue: 'LU'
	},
	{
		value: 'RM',
		label: 'Remich',
		countryLabel: 'Luxembourg',
		countryValue: 'LU'
	},
	{
		value: 'VD',
		label: 'Vianden',
		countryLabel: 'Luxembourg',
		countryValue: 'LU'
	},
	{
		value: 'WI',
		label: 'Wiltz',
		countryLabel: 'Luxembourg',
		countryValue: 'LU'
	},
	{
		value: 'A',
		label: 'Toamasina',
		countryLabel: 'Madagascar',
		countryValue: 'MG'
	},
	{
		value: 'D',
		label: 'Antsiranana',
		countryLabel: 'Madagascar',
		countryValue: 'MG'
	},
	{
		value: 'F',
		label: 'Fianarantsoa',
		countryLabel: 'Madagascar',
		countryValue: 'MG'
	},
	{
		value: 'M',
		label: 'Mahajanga',
		countryLabel: 'Madagascar',
		countryValue: 'MG'
	},
	{
		value: 'T',
		label: 'Antananarivo',
		countryLabel: 'Madagascar',
		countryValue: 'MG'
	},
	{
		value: 'U',
		label: 'Toliara',
		countryLabel: 'Madagascar',
		countryValue: 'MG'
	},
	{
		value: 'BA',
		label: 'Balaka',
		countryLabel: 'Malawi',
		countryValue: 'MW'
	},
	{
		value: 'BL',
		label: 'Blantyre',
		countryLabel: 'Malawi',
		countryValue: 'MW'
	},
	{
		value: 'CR',
		label: 'Chiradzulu',
		countryLabel: 'Malawi',
		countryValue: 'MW'
	},
	{
		value: 'DE',
		label: 'Dedza',
		countryLabel: 'Malawi',
		countryValue: 'MW'
	},
	{
		value: 'DO',
		label: 'Dowa',
		countryLabel: 'Malawi',
		countryValue: 'MW'
	},
	{
		value: 'KR',
		label: 'Karonga',
		countryLabel: 'Malawi',
		countryValue: 'MW'
	},
	{
		value: 'LI',
		label: 'Lilongwe',
		countryLabel: 'Malawi',
		countryValue: 'MW'
	},
	{
		value: 'MG',
		label: 'Mangochi',
		countryLabel: 'Malawi',
		countryValue: 'MW'
	},
	{
		value: 'MH',
		label: 'Machinga',
		countryLabel: 'Malawi',
		countryValue: 'MW'
	},
	{
		value: 'MZ',
		label: 'Mzimba',
		countryLabel: 'Malawi',
		countryValue: 'MW'
	},
	{
		value: 'NK',
		label: 'Nkhotakota',
		countryLabel: 'Malawi',
		countryValue: 'MW'
	},
	{
		value: 'SA',
		label: 'Salima',
		countryLabel: 'Malawi',
		countryValue: 'MW'
	},
	{
		value: 'ZO',
		label: 'Zomba',
		countryLabel: 'Malawi',
		countryValue: 'MW'
	},
	{
		value: '1',
		label: 'Johor',
		countryLabel: 'Malaysia',
		countryValue: 'MY'
	},
	{
		value: '2',
		label: 'Kedah',
		countryLabel: 'Malaysia',
		countryValue: 'MY'
	},
	{
		value: '3',
		label: 'Kelantan',
		countryLabel: 'Malaysia',
		countryValue: 'MY'
	},
	{
		value: '4',
		label: 'Melaka',
		countryLabel: 'Malaysia',
		countryValue: 'MY'
	},
	{
		value: '5',
		label: 'Negeri Sembilan',
		countryLabel: 'Malaysia',
		countryValue: 'MY'
	},
	{
		value: '6',
		label: 'Pahang',
		countryLabel: 'Malaysia',
		countryValue: 'MY'
	},
	{
		value: '7',
		label: 'Pulau Pinang',
		countryLabel: 'Malaysia',
		countryValue: 'MY'
	},
	{
		value: '8',
		label: 'Perak',
		countryLabel: 'Malaysia',
		countryValue: 'MY'
	},
	{
		value: '9',
		label: 'Perlis',
		countryLabel: 'Malaysia',
		countryValue: 'MY'
	},
	{
		value: '10',
		label: 'Selangor',
		countryLabel: 'Malaysia',
		countryValue: 'MY'
	},
	{
		value: '11',
		label: 'Terengganu',
		countryLabel: 'Malaysia',
		countryValue: 'MY'
	},
	{
		value: '12',
		label: 'Sabah',
		countryLabel: 'Malaysia',
		countryValue: 'MY'
	},
	{
		value: '13',
		label: 'Sarawak',
		countryLabel: 'Malaysia',
		countryValue: 'MY'
	},
	{
		value: '14',
		label: 'Wilayah Persekutuan Kuala Lumpur',
		countryLabel: 'Malaysia',
		countryValue: 'MY'
	},
	{
		value: '15',
		label: 'Wilayah Persekutuan Labuan',
		countryLabel: 'Malaysia',
		countryValue: 'MY'
	},
	{
		value: '16',
		label: 'Wilayah Persekutuan Putrajaya',
		countryLabel: 'Malaysia',
		countryValue: 'MY'
	},
	{
		value: '0',
		label: 'South Ari Atoll',
		countryLabel: 'Maldives',
		countryValue: 'MV'
	},
	{
		value: '1',
		label: 'Addu City',
		countryLabel: 'Maldives',
		countryValue: 'MV'
	},
	{
		value: '3',
		label: 'Faadhippolhu',
		countryLabel: 'Maldives',
		countryValue: 'MV'
	},
	{
		value: '4',
		label: 'Felidhu Atoll',
		countryLabel: 'Maldives',
		countryValue: 'MV'
	},
	{
		value: '5',
		label: 'Hahdhunmathi',
		countryLabel: 'Maldives',
		countryValue: 'MV'
	},
	{
		value: '12',
		label: 'Mulaku Atoll',
		countryLabel: 'Maldives',
		countryValue: 'MV'
	},
	{
		value: '13',
		label: 'North Maalhosmadulu',
		countryLabel: 'Maldives',
		countryValue: 'MV'
	},
	{
		value: '20',
		label: 'South Maalhosmadulu',
		countryLabel: 'Maldives',
		countryValue: 'MV'
	},
	{
		value: '23',
		label: 'South Thiladhunmathi',
		countryLabel: 'Maldives',
		countryValue: 'MV'
	},
	{
		value: '25',
		label: 'South Miladhunmadulu',
		countryLabel: 'Maldives',
		countryValue: 'MV'
	},
	{
		value: '28',
		label: 'South Huvadhu Atoll',
		countryLabel: 'Maldives',
		countryValue: 'MV'
	},
	{
		value: 'MLE',
		label: 'Male',
		countryLabel: 'Maldives',
		countryValue: 'MV'
	},
	{
		value: '1',
		label: 'Kayes',
		countryLabel: 'Mali',
		countryValue: 'ML'
	},
	{
		value: '2',
		label: 'Koulikoro',
		countryLabel: 'Mali',
		countryValue: 'ML'
	},
	{
		value: '3',
		label: 'Sikasso',
		countryLabel: 'Mali',
		countryValue: 'ML'
	},
	{
		value: '4',
		label: 'Segou',
		countryLabel: 'Mali',
		countryValue: 'ML'
	},
	{
		value: '5',
		label: 'Mopti',
		countryLabel: 'Mali',
		countryValue: 'ML'
	},
	{
		value: '6',
		label: 'Tombouctou',
		countryLabel: 'Mali',
		countryValue: 'ML'
	},
	{
		value: '7',
		label: 'Gao',
		countryLabel: 'Mali',
		countryValue: 'ML'
	},
	{
		value: '8',
		label: 'Kidal',
		countryLabel: 'Mali',
		countryValue: 'ML'
	},
	{
		value: 'BKO',
		label: 'Bamako',
		countryLabel: 'Mali',
		countryValue: 'ML'
	},
	{
		value: '1',
		label: 'Attard',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '2',
		label: 'Balzan',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '3',
		label: 'Birgu',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '4',
		label: 'Birkirkara',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '5',
		label: 'Birzebbuga',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '6',
		label: 'Bormla',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '7',
		label: 'Dingli',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '8',
		label: 'Fgura',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '9',
		label: 'Floriana',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '10',
		label: 'Fontana',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '11',
		label: 'Gudja',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '12',
		label: 'Gzira',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '14',
		label: 'Gharb',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '15',
		label: 'Gharghur',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '16',
		label: 'Ghasri',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '17',
		label: 'Ghaxaq',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '18',
		label: 'Hamrun',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '19',
		label: 'Iklin',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '20',
		label: 'Isla',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '21',
		label: 'Kalkara',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '22',
		label: 'Kercem',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '23',
		label: 'Kirkop',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '24',
		label: 'Lija',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '25',
		label: 'Luqa',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '26',
		label: 'Marsa',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '27',
		label: 'Marsaskala',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '28',
		label: 'Marsaxlokk',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '29',
		label: 'Mdina',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '30',
		label: 'Mellieha',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '31',
		label: 'Mgarr',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '32',
		label: 'Mosta',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '33',
		label: 'Mqabba',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '34',
		label: 'Msida',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '35',
		label: 'Mtarfa',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '36',
		label: 'Munxar',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '37',
		label: 'Nadur',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '38',
		label: 'Naxxar',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '39',
		label: 'Paola',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '40',
		label: 'Pembroke',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '41',
		label: 'Pieta',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '42',
		label: 'Qala',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '43',
		label: 'Qormi',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '45',
		label: 'Rabat Gozo',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '46',
		label: 'Rabat Malta',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '48',
		label: "Saint Julian's",
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '49',
		label: 'Saint John',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '51',
		label: "Saint Paul's Bay",
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '52',
		label: 'Sannat',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '53',
		label: "Saint Lucia's",
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '54',
		label: 'Santa Venera',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '55',
		label: 'Siggiewi',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '56',
		label: 'Sliema',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '57',
		label: 'Swieqi',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '58',
		label: "Ta' Xbiex",
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '59',
		label: 'Tarxien',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '60',
		label: 'Valletta',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '61',
		label: 'Xaghra',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '62',
		label: 'Xewkija',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '63',
		label: 'Xghajra',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '64',
		label: 'Zabbar',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '65',
		label: 'Zebbug Gozo',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '67',
		label: 'Zejtun',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: '68',
		label: 'Zurrieq',
		countryLabel: 'Malta',
		countryValue: 'MT'
	},
	{
		value: 'KIL',
		label: 'Bikini & Kili',
		countryLabel: 'Marshall islands',
		countryValue: 'MH'
	},
	{
		value: 'KWA',
		label: 'Kwajalein',
		countryLabel: 'Marshall islands',
		countryValue: 'MH'
	},
	{
		value: 'MAJ',
		label: 'Majuro',
		countryLabel: 'Marshall islands',
		countryValue: 'MH'
	},
	{
		value: '3',
		label: 'Assaba',
		countryLabel: 'Mauritania',
		countryValue: 'MR'
	},
	{
		value: '4',
		label: 'Gorgol',
		countryLabel: 'Mauritania',
		countryValue: 'MR'
	},
	{
		value: '5',
		label: 'Brakna',
		countryLabel: 'Mauritania',
		countryValue: 'MR'
	},
	{
		value: '6',
		label: 'Trarza',
		countryLabel: 'Mauritania',
		countryValue: 'MR'
	},
	{
		value: '8',
		label: 'Dakhlet Nouadhibou',
		countryLabel: 'Mauritania',
		countryValue: 'MR'
	},
	{
		value: '11',
		label: 'Tiris Zemmour',
		countryLabel: 'Mauritania',
		countryValue: 'MR'
	},
	{
		value: '12',
		label: 'Inchiri',
		countryLabel: 'Mauritania',
		countryValue: 'MR'
	},
	{
		value: '13',
		label: 'Nouakchott Ouest',
		countryLabel: 'Mauritania',
		countryValue: 'MR'
	},
	{
		value: 'BL',
		label: 'Black River',
		countryLabel: 'Mauritius',
		countryValue: 'MU'
	},
	{
		value: 'FL',
		label: 'Flacq',
		countryLabel: 'Mauritius',
		countryValue: 'MU'
	},
	{
		value: 'GP',
		label: 'Grand Port',
		countryLabel: 'Mauritius',
		countryValue: 'MU'
	},
	{
		value: 'MO',
		label: 'Moka',
		countryLabel: 'Mauritius',
		countryValue: 'MU'
	},
	{
		value: 'PA',
		label: 'Pamplemousses',
		countryLabel: 'Mauritius',
		countryValue: 'MU'
	},
	{
		value: 'PL',
		label: 'Port Louis',
		countryLabel: 'Mauritius',
		countryValue: 'MU'
	},
	{
		value: 'PW',
		label: 'Plaines Wilhems',
		countryLabel: 'Mauritius',
		countryValue: 'MU'
	},
	{
		value: 'RO',
		label: 'Rodrigues Islands',
		countryLabel: 'Mauritius',
		countryValue: 'MU'
	},
	{
		value: 'RR',
		label: 'Riviere du Rempart',
		countryLabel: 'Mauritius',
		countryValue: 'MU'
	},
	{
		value: 'SA',
		label: 'Savanne',
		countryLabel: 'Mauritius',
		countryValue: 'MU'
	},
	{
		value: 'AG',
		label: 'Aguascalientes',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'AGU',
		label: 'Aguascalientes',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'BC',
		label: 'Baja California',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'BCN',
		label: 'Baja California',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'BCS',
		label: 'Baja California Sur',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'BS',
		label: 'Baja California Sur',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'CAM',
		label: 'Campeche',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'CH',
		label: 'Chihuahua',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'CHH',
		label: 'Chihuahua',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'CHP',
		label: 'Chiapas',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'CL',
		label: 'Colima',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'CM',
		label: 'Campeche',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'CMX',
		label: 'Ciudad de Mexico',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'CO',
		label: 'Coahuila',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'COA',
		label: 'Coahuila de Zaragoza',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'COL',
		label: 'Colima',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'CS',
		label: 'Chiapas',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'DF',
		label: 'Federal District',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'DG',
		label: 'Durango',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'DUR',
		label: 'Durango',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'GR',
		label: 'Guerrero',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'GRO',
		label: 'Guerrero',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'GT',
		label: 'Guanajuato',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'GUA',
		label: 'Guanajuato',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'HG',
		label: 'Hidalgo',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'HID',
		label: 'Hidalgo',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'JA',
		label: 'Jalisco',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'JAL',
		label: 'Jalisco',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'ME',
		label: 'Mexico State',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'MEX',
		label: 'Mexico',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'MI',
		label: 'Michoacan',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'MIC',
		label: 'Michoacan de Ocampo',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'MO',
		label: 'Morelos',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'MOR',
		label: 'Morelos',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'NA',
		label: 'Nayarit',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'NAY',
		label: 'Nayarit',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'NL',
		label: 'Nuevo Leon',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'NLE',
		label: 'Nuevo Leon',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'OA',
		label: 'Oaxaca',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'OAX',
		label: 'Oaxaca',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'PB',
		label: 'Puebla',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'PUE',
		label: 'Puebla',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'QE',
		label: 'Queretaro',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'QR',
		label: 'Quintana Roo',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'QUE',
		label: 'Queretaro',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'ROO',
		label: 'Quintana Roo',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'SI',
		label: 'Sinaloa',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'SIN',
		label: 'Sinaloa',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'SL',
		label: 'San Luis Potosi',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'SLP',
		label: 'San Luis Potosi',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'SO',
		label: 'Sonora',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'SON',
		label: 'Sonora',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'TAB',
		label: 'Tabasco',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'TAM',
		label: 'Tamaulipas',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'TB',
		label: 'Tabasco',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'TL',
		label: 'Tlaxcala',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'TLA',
		label: 'Tlaxcala',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'TM',
		label: 'Tamaulipas',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'VE',
		label: 'Veracruz',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'VER',
		label: 'Veracruz de Ignacio de la Llave',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'YU',
		label: 'Yucatan',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'YUC',
		label: 'Yucatan',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'ZA',
		label: 'Zacatecas',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'ZAC',
		label: 'Zacatecas',
		countryLabel: 'Mexico',
		countryValue: 'MX'
	},
	{
		value: 'KSA',
		label: 'Kosrae',
		countryLabel: 'Micronesia',
		countryValue: 'FM'
	},
	{
		value: 'PNI',
		label: 'Pohnpei',
		countryLabel: 'Micronesia',
		countryValue: 'FM'
	},
	{
		value: 'TRK',
		label: 'Chuuk',
		countryLabel: 'Micronesia',
		countryValue: 'FM'
	},
	{
		value: 'YAP',
		label: 'Yap',
		countryLabel: 'Micronesia',
		countryValue: 'FM'
	},
	{
		value: 'AN',
		label: 'Anenii Noi',
		countryLabel: 'Moldova',
		countryValue: 'MD'
	},
	{
		value: 'BA',
		label: 'Balti',
		countryLabel: 'Moldova',
		countryValue: 'MD'
	},
	{
		value: 'BD',
		label: 'Bender',
		countryLabel: 'Moldova',
		countryValue: 'MD'
	},
	{
		value: 'BR',
		label: 'Briceni',
		countryLabel: 'Moldova',
		countryValue: 'MD'
	},
	{
		value: 'BS',
		label: 'Basarabeasca',
		countryLabel: 'Moldova',
		countryValue: 'MD'
	},
	{
		value: 'CA',
		label: 'Cahul',
		countryLabel: 'Moldova',
		countryValue: 'MD'
	},
	{
		value: 'CL',
		label: 'Calarasi',
		countryLabel: 'Moldova',
		countryValue: 'MD'
	},
	{
		value: 'CM',
		label: 'Cimislia',
		countryLabel: 'Moldova',
		countryValue: 'MD'
	},
	{
		value: 'CR',
		label: 'Criuleni',
		countryLabel: 'Moldova',
		countryValue: 'MD'
	},
	{
		value: 'CS',
		label: 'Causeni',
		countryLabel: 'Moldova',
		countryValue: 'MD'
	},
	{
		value: 'CT',
		label: 'Cantemir',
		countryLabel: 'Moldova',
		countryValue: 'MD'
	},
	{
		value: 'CU',
		label: 'Chisinau',
		countryLabel: 'Moldova',
		countryValue: 'MD'
	},
	{
		value: 'DO',
		label: 'Donduseni',
		countryLabel: 'Moldova',
		countryValue: 'MD'
	},
	{
		value: 'DR',
		label: 'Drochia',
		countryLabel: 'Moldova',
		countryValue: 'MD'
	},
	{
		value: 'DU',
		label: 'Dubasari',
		countryLabel: 'Moldova',
		countryValue: 'MD'
	},
	{
		value: 'ED',
		label: 'Edinet',
		countryLabel: 'Moldova',
		countryValue: 'MD'
	},
	{
		value: 'FA',
		label: 'Falesti',
		countryLabel: 'Moldova',
		countryValue: 'MD'
	},
	{
		value: 'FL',
		label: 'Floresti',
		countryLabel: 'Moldova',
		countryValue: 'MD'
	},
	{
		value: 'GA',
		label: 'Gagauzia',
		countryLabel: 'Moldova',
		countryValue: 'MD'
	},
	{
		value: 'GL',
		label: 'Glodeni',
		countryLabel: 'Moldova',
		countryValue: 'MD'
	},
	{
		value: 'HI',
		label: 'Hincesti',
		countryLabel: 'Moldova',
		countryValue: 'MD'
	},
	{
		value: 'IA',
		label: 'Ialoveni',
		countryLabel: 'Moldova',
		countryValue: 'MD'
	},
	{
		value: 'LE',
		label: 'Leova',
		countryLabel: 'Moldova',
		countryValue: 'MD'
	},
	{
		value: 'NI',
		label: 'Nisporeni',
		countryLabel: 'Moldova',
		countryValue: 'MD'
	},
	{
		value: 'OC',
		label: 'Ocnita',
		countryLabel: 'Moldova',
		countryValue: 'MD'
	},
	{
		value: 'OR',
		label: 'Orhei',
		countryLabel: 'Moldova',
		countryValue: 'MD'
	},
	{
		value: 'RE',
		label: 'Rezina',
		countryLabel: 'Moldova',
		countryValue: 'MD'
	},
	{
		value: 'RI',
		label: 'Riscani',
		countryLabel: 'Moldova',
		countryValue: 'MD'
	},
	{
		value: 'SD',
		label: 'Soldanesti',
		countryLabel: 'Moldova',
		countryValue: 'MD'
	},
	{
		value: 'SI',
		label: 'Singerei',
		countryLabel: 'Moldova',
		countryValue: 'MD'
	},
	{
		value: 'SO',
		label: 'Soroca',
		countryLabel: 'Moldova',
		countryValue: 'MD'
	},
	{
		value: 'ST',
		label: 'Straseni',
		countryLabel: 'Moldova',
		countryValue: 'MD'
	},
	{
		value: 'SV',
		label: 'Stefan Voda',
		countryLabel: 'Moldova',
		countryValue: 'MD'
	},
	{
		value: 'TA',
		label: 'Taraclia',
		countryLabel: 'Moldova',
		countryValue: 'MD'
	},
	{
		value: 'TE',
		label: 'Telenesti',
		countryLabel: 'Moldova',
		countryValue: 'MD'
	},
	{
		value: 'UN',
		label: 'Ungheni',
		countryLabel: 'Moldova',
		countryValue: 'MD'
	},
	{
		value: 'CO',
		label: 'La Condamine',
		countryLabel: 'Monaco',
		countryValue: 'MC'
	},
	{
		value: 'FO',
		label: 'Fontvieille',
		countryLabel: 'Monaco',
		countryValue: 'MC'
	},
	{
		value: 'MC',
		label: 'Monte-Carlo',
		countryLabel: 'Monaco',
		countryValue: 'MC'
	},
	{
		value: 'MO',
		label: 'Monaco-Ville',
		countryLabel: 'Monaco',
		countryValue: 'MC'
	},
	{
		value: 'SR',
		label: 'Saint-Roman',
		countryLabel: 'Monaco',
		countryValue: 'MC'
	},
	{
		value: '1',
		label: 'Ulaanbaatar',
		countryLabel: 'Mongolia',
		countryValue: 'MN'
	},
	{
		value: '35',
		label: 'Orhon',
		countryLabel: 'Mongolia',
		countryValue: 'MN'
	},
	{
		value: '37',
		label: 'Darhan uul',
		countryLabel: 'Mongolia',
		countryValue: 'MN'
	},
	{
		value: '43',
		label: 'Hovd',
		countryLabel: 'Mongolia',
		countryValue: 'MN'
	},
	{
		value: '47',
		label: 'Tov',
		countryLabel: 'Mongolia',
		countryValue: 'MN'
	},
	{
		value: '49',
		label: 'Selenge',
		countryLabel: 'Mongolia',
		countryValue: 'MN'
	},
	{
		value: '61',
		label: 'Dornod',
		countryLabel: 'Mongolia',
		countryValue: 'MN'
	},
	{
		value: '63',
		label: 'Dornogovi',
		countryLabel: 'Mongolia',
		countryValue: 'MN'
	},
	{
		value: '65',
		label: 'Govi-Altay',
		countryLabel: 'Mongolia',
		countryValue: 'MN'
	},
	{
		value: '71',
		label: 'Bayan-Olgiy',
		countryLabel: 'Mongolia',
		countryValue: 'MN'
	},
	{
		value: '1',
		label: 'Andrijevica',
		countryLabel: 'Montenegro',
		countryValue: 'ME'
	},
	{
		value: '2',
		label: 'Bar',
		countryLabel: 'Montenegro',
		countryValue: 'ME'
	},
	{
		value: '3',
		label: 'Berane',
		countryLabel: 'Montenegro',
		countryValue: 'ME'
	},
	{
		value: '4',
		label: 'Bijelo Polje',
		countryLabel: 'Montenegro',
		countryValue: 'ME'
	},
	{
		value: '5',
		label: 'Budva',
		countryLabel: 'Montenegro',
		countryValue: 'ME'
	},
	{
		value: '6',
		label: 'Cetinje',
		countryLabel: 'Montenegro',
		countryValue: 'ME'
	},
	{
		value: '7',
		label: 'Danilovgrad',
		countryLabel: 'Montenegro',
		countryValue: 'ME'
	},
	{
		value: '8',
		label: 'Herceg-Novi',
		countryLabel: 'Montenegro',
		countryValue: 'ME'
	},
	{
		value: '10',
		label: 'Kotor',
		countryLabel: 'Montenegro',
		countryValue: 'ME'
	},
	{
		value: '11',
		label: 'Mojkovac',
		countryLabel: 'Montenegro',
		countryValue: 'ME'
	},
	{
		value: '12',
		label: 'Niksic',
		countryLabel: 'Montenegro',
		countryValue: 'ME'
	},
	{
		value: '13',
		label: 'Plav',
		countryLabel: 'Montenegro',
		countryValue: 'ME'
	},
	{
		value: '14',
		label: 'Pljevlja',
		countryLabel: 'Montenegro',
		countryValue: 'ME'
	},
	{
		value: '15',
		label: 'Pluzine',
		countryLabel: 'Montenegro',
		countryValue: 'ME'
	},
	{
		value: '16',
		label: 'Podgorica',
		countryLabel: 'Montenegro',
		countryValue: 'ME'
	},
	{
		value: '17',
		label: 'Rozaje',
		countryLabel: 'Montenegro',
		countryValue: 'ME'
	},
	{
		value: '19',
		label: 'Tivat',
		countryLabel: 'Montenegro',
		countryValue: 'ME'
	},
	{
		value: '20',
		label: 'Ulcinj',
		countryLabel: 'Montenegro',
		countryValue: 'ME'
	},
	{
		value: '21',
		label: 'Zabljak',
		countryLabel: 'Montenegro',
		countryValue: 'ME'
	},
	{
		value: '24',
		label: 'Tuzi',
		countryLabel: 'Montenegro',
		countryValue: 'ME'
	},
	{
		value: '1',
		label: 'Tanger-Tetouan-Al Hoceima',
		countryLabel: 'Morocco',
		countryValue: 'MA'
	},
	{
		value: '2',
		label: "L'Oriental",
		countryLabel: 'Morocco',
		countryValue: 'MA'
	},
	{
		value: '3',
		label: 'Fes- Meknes',
		countryLabel: 'Morocco',
		countryValue: 'MA'
	},
	{
		value: '4',
		label: 'Rabat-Sale-Kenitra',
		countryLabel: 'Morocco',
		countryValue: 'MA'
	},
	{
		value: '5',
		label: 'Beni-Mellal-Khenifra',
		countryLabel: 'Morocco',
		countryValue: 'MA'
	},
	{
		value: '6',
		label: 'Casablanca-Settat',
		countryLabel: 'Morocco',
		countryValue: 'MA'
	},
	{
		value: '7',
		label: 'Marrakech-Safi',
		countryLabel: 'Morocco',
		countryValue: 'MA'
	},
	{
		value: '8',
		label: 'Draa-Tafilalet',
		countryLabel: 'Morocco',
		countryValue: 'MA'
	},
	{
		value: '9',
		label: 'Souss-Massa',
		countryLabel: 'Morocco',
		countryValue: 'MA'
	},
	{
		value: '10',
		label: 'Guelmim-Oued Noun (EH-partial)',
		countryLabel: 'Morocco',
		countryValue: 'MA'
	},
	{
		value: '11',
		label: 'Laayoune-Sakia El Hamra (EH-partial)',
		countryLabel: 'Morocco',
		countryValue: 'MA'
	},
	{
		value: 'A',
		label: 'Niassa',
		countryLabel: 'Mozambique',
		countryValue: 'MZ'
	},
	{
		value: 'B',
		label: 'Manica',
		countryLabel: 'Mozambique',
		countryValue: 'MZ'
	},
	{
		value: 'G',
		label: 'Gaza',
		countryLabel: 'Mozambique',
		countryValue: 'MZ'
	},
	{
		value: 'I',
		label: 'Inhambane',
		countryLabel: 'Mozambique',
		countryValue: 'MZ'
	},
	{
		value: 'L',
		label: 'Maputo',
		countryLabel: 'Mozambique',
		countryValue: 'MZ'
	},
	{
		value: 'N',
		label: 'Nampula',
		countryLabel: 'Mozambique',
		countryValue: 'MZ'
	},
	{
		value: 'P',
		label: 'Cabo Delgado',
		countryLabel: 'Mozambique',
		countryValue: 'MZ'
	},
	{
		value: 'Q',
		label: 'Zambezia',
		countryLabel: 'Mozambique',
		countryValue: 'MZ'
	},
	{
		value: 'S',
		label: 'Sofala',
		countryLabel: 'Mozambique',
		countryValue: 'MZ'
	},
	{
		value: 'T',
		label: 'Tete',
		countryLabel: 'Mozambique',
		countryValue: 'MZ'
	},
	{
		value: '1',
		label: 'Sagaing',
		countryLabel: 'Myanmar',
		countryValue: 'MM'
	},
	{
		value: '2',
		label: 'Bago',
		countryLabel: 'Myanmar',
		countryValue: 'MM'
	},
	{
		value: '3',
		label: 'Magway',
		countryLabel: 'Myanmar',
		countryValue: 'MM'
	},
	{
		value: '4',
		label: 'Mandalay',
		countryLabel: 'Myanmar',
		countryValue: 'MM'
	},
	{
		value: '5',
		label: 'Tanintharyi',
		countryLabel: 'Myanmar',
		countryValue: 'MM'
	},
	{
		value: '6',
		label: 'Yangon',
		countryLabel: 'Myanmar',
		countryValue: 'MM'
	},
	{
		value: '7',
		label: 'Ayeyarwady',
		countryLabel: 'Myanmar',
		countryValue: 'MM'
	},
	{
		value: '11',
		label: 'Kachin',
		countryLabel: 'Myanmar',
		countryValue: 'MM'
	},
	{
		value: '12',
		label: 'Kayah',
		countryLabel: 'Myanmar',
		countryValue: 'MM'
	},
	{
		value: '13',
		label: 'Kayin',
		countryLabel: 'Myanmar',
		countryValue: 'MM'
	},
	{
		value: '14',
		label: 'Chin',
		countryLabel: 'Myanmar',
		countryValue: 'MM'
	},
	{
		value: '15',
		label: 'Mon',
		countryLabel: 'Myanmar',
		countryValue: 'MM'
	},
	{
		value: '16',
		label: 'Rakhine',
		countryLabel: 'Myanmar',
		countryValue: 'MM'
	},
	{
		value: '17',
		label: 'Shan',
		countryLabel: 'Myanmar',
		countryValue: 'MM'
	},
	{
		value: '18',
		label: 'Nay Pyi Taw',
		countryLabel: 'Myanmar',
		countryValue: 'MM'
	},
	{
		value: 'CA',
		label: 'Zambezi',
		countryLabel: 'Namibia',
		countryValue: 'NA'
	},
	{
		value: 'ER',
		label: 'Erongo',
		countryLabel: 'Namibia',
		countryValue: 'NA'
	},
	{
		value: 'HA',
		label: 'Hardap',
		countryLabel: 'Namibia',
		countryValue: 'NA'
	},
	{
		value: 'KA',
		label: 'Karas',
		countryLabel: 'Namibia',
		countryValue: 'NA'
	},
	{
		value: 'KE',
		label: 'Kavango East',
		countryLabel: 'Namibia',
		countryValue: 'NA'
	},
	{
		value: 'KH',
		label: 'Khomas',
		countryLabel: 'Namibia',
		countryValue: 'NA'
	},
	{
		value: 'KU',
		label: 'Kunene',
		countryLabel: 'Namibia',
		countryValue: 'NA'
	},
	{
		value: 'OD',
		label: 'Otjozondjupa',
		countryLabel: 'Namibia',
		countryValue: 'NA'
	},
	{
		value: 'OH',
		label: 'Omaheke',
		countryLabel: 'Namibia',
		countryValue: 'NA'
	},
	{
		value: 'ON',
		label: 'Oshana',
		countryLabel: 'Namibia',
		countryValue: 'NA'
	},
	{
		value: 'OS',
		label: 'Omusati',
		countryLabel: 'Namibia',
		countryValue: 'NA'
	},
	{
		value: 'OT',
		label: 'Oshikoto',
		countryLabel: 'Namibia',
		countryValue: 'NA'
	},
	{
		value: 'OW',
		label: 'Ohangwena',
		countryLabel: 'Namibia',
		countryValue: 'NA'
	},
	{
		value: '1',
		label: 'Aiwo',
		countryLabel: 'Nauru',
		countryValue: 'NR'
	},
	{
		value: '3',
		label: 'Anetan',
		countryLabel: 'Nauru',
		countryValue: 'NR'
	},
	{
		value: '14',
		label: 'Yaren',
		countryLabel: 'Nauru',
		countryValue: 'NR'
	},
	{
		value: 'BA',
		label: 'Bagmati',
		countryLabel: 'Nepal',
		countryValue: 'NP'
	},
	{
		value: 'BH',
		label: 'Bheri',
		countryLabel: 'Nepal',
		countryValue: 'NP'
	},
	{
		value: 'DH',
		label: 'Dhawalagiri',
		countryLabel: 'Nepal',
		countryValue: 'NP'
	},
	{
		value: 'GA',
		label: 'Gandaki',
		countryLabel: 'Nepal',
		countryValue: 'NP'
	},
	{
		value: 'JA',
		label: 'Janakpur',
		countryLabel: 'Nepal',
		countryValue: 'NP'
	},
	{
		value: 'KA',
		label: 'Karnali',
		countryLabel: 'Nepal',
		countryValue: 'NP'
	},
	{
		value: 'KO',
		label: 'Kosi',
		countryLabel: 'Nepal',
		countryValue: 'NP'
	},
	{
		value: 'LU',
		label: 'Lumbini',
		countryLabel: 'Nepal',
		countryValue: 'NP'
	},
	{
		value: 'MA',
		label: 'Mahakali',
		countryLabel: 'Nepal',
		countryValue: 'NP'
	},
	{
		value: 'ME',
		label: 'Mechi',
		countryLabel: 'Nepal',
		countryValue: 'NP'
	},
	{
		value: 'NA',
		label: 'Narayani',
		countryLabel: 'Nepal',
		countryValue: 'NP'
	},
	{
		value: 'RA',
		label: 'Rapti',
		countryLabel: 'Nepal',
		countryValue: 'NP'
	},
	{
		value: 'SA',
		label: 'Sagarmatha',
		countryLabel: 'Nepal',
		countryValue: 'NP'
	},
	{
		value: 'SE',
		label: 'Seti',
		countryLabel: 'Nepal',
		countryValue: 'NP'
	},
	{
		value: 'DR',
		label: 'Drenthe',
		countryLabel: 'Netherlands',
		countryValue: 'NL'
	},
	{
		value: 'FL',
		label: 'Flevoland',
		countryLabel: 'Netherlands',
		countryValue: 'NL'
	},
	{
		value: 'FR',
		label: 'Fryslan',
		countryLabel: 'Netherlands',
		countryValue: 'NL'
	},
	{
		value: 'GE',
		label: 'Gelderland',
		countryLabel: 'Netherlands',
		countryValue: 'NL'
	},
	{
		value: 'GR',
		label: 'Groningen',
		countryLabel: 'Netherlands',
		countryValue: 'NL'
	},
	{
		value: 'LI',
		label: 'Limburg',
		countryLabel: 'Netherlands',
		countryValue: 'NL'
	},
	{
		value: 'NB',
		label: 'Noord-Brabant',
		countryLabel: 'Netherlands',
		countryValue: 'NL'
	},
	{
		value: 'NH',
		label: 'Noord-Holland',
		countryLabel: 'Netherlands',
		countryValue: 'NL'
	},
	{
		value: 'OV',
		label: 'Overijssel',
		countryLabel: 'Netherlands',
		countryValue: 'NL'
	},
	{
		value: 'UT',
		label: 'Utrecht',
		countryLabel: 'Netherlands',
		countryValue: 'NL'
	},
	{
		value: 'ZE',
		label: 'Zeeland',
		countryLabel: 'Netherlands',
		countryValue: 'NL'
	},
	{
		value: 'ZH',
		label: 'Zuid-Holland',
		countryLabel: 'Netherlands',
		countryValue: 'NL'
	},
	{
		value: 'AUK',
		label: 'Auckland',
		countryLabel: 'New Zealand',
		countryValue: 'NZ'
	},
	{
		value: 'BOP',
		label: 'Bay of Plenty',
		countryLabel: 'New Zealand',
		countryValue: 'NZ'
	},
	{
		value: 'CAN',
		label: 'Canterbury',
		countryLabel: 'New Zealand',
		countryValue: 'NZ'
	},
	{
		value: 'CIT',
		label: 'Chatham Islands Territory',
		countryLabel: 'New Zealand',
		countryValue: 'NZ'
	},
	{
		value: 'GIS',
		label: 'Gisborne',
		countryLabel: 'New Zealand',
		countryValue: 'NZ'
	},
	{
		value: 'HKB',
		label: "Hawke's Bay",
		countryLabel: 'New Zealand',
		countryValue: 'NZ'
	},
	{
		value: 'MBH',
		label: 'Marlborough',
		countryLabel: 'New Zealand',
		countryValue: 'NZ'
	},
	{
		value: 'MWT',
		label: 'Manawatu-Wanganui',
		countryLabel: 'New Zealand',
		countryValue: 'NZ'
	},
	{
		value: 'NSN',
		label: 'Nelson',
		countryLabel: 'New Zealand',
		countryValue: 'NZ'
	},
	{
		value: 'NTL',
		label: 'Northland',
		countryLabel: 'New Zealand',
		countryValue: 'NZ'
	},
	{
		value: 'OTA',
		label: 'Otago',
		countryLabel: 'New Zealand',
		countryValue: 'NZ'
	},
	{
		value: 'STL',
		label: 'Southland',
		countryLabel: 'New Zealand',
		countryValue: 'NZ'
	},
	{
		value: 'TAS',
		label: 'Tasman',
		countryLabel: 'New Zealand',
		countryValue: 'NZ'
	},
	{
		value: 'TKI',
		label: 'Taranaki',
		countryLabel: 'New Zealand',
		countryValue: 'NZ'
	},
	{
		value: 'WGN',
		label: 'Wellington',
		countryLabel: 'New Zealand',
		countryValue: 'NZ'
	},
	{
		value: 'WKO',
		label: 'Waikato',
		countryLabel: 'New Zealand',
		countryValue: 'NZ'
	},
	{
		value: 'WTC',
		label: 'West Coast',
		countryLabel: 'New Zealand',
		countryValue: 'NZ'
	},
	{
		value: 'AN',
		label: 'Costa Caribe Norte',
		countryLabel: 'Nicaragua',
		countryValue: 'NI'
	},
	{
		value: 'AS',
		label: 'Costa Caribe Sur',
		countryLabel: 'Nicaragua',
		countryValue: 'NI'
	},
	{
		value: 'BO',
		label: 'Boaco',
		countryLabel: 'Nicaragua',
		countryValue: 'NI'
	},
	{
		value: 'CA',
		label: 'Carazo',
		countryLabel: 'Nicaragua',
		countryValue: 'NI'
	},
	{
		value: 'CI',
		label: 'Chinandega',
		countryLabel: 'Nicaragua',
		countryValue: 'NI'
	},
	{
		value: 'CO',
		label: 'Chontales',
		countryLabel: 'Nicaragua',
		countryValue: 'NI'
	},
	{
		value: 'ES',
		label: 'Esteli',
		countryLabel: 'Nicaragua',
		countryValue: 'NI'
	},
	{
		value: 'GR',
		label: 'Granada',
		countryLabel: 'Nicaragua',
		countryValue: 'NI'
	},
	{
		value: 'JI',
		label: 'Jinotega',
		countryLabel: 'Nicaragua',
		countryValue: 'NI'
	},
	{
		value: 'LE',
		label: 'Leon',
		countryLabel: 'Nicaragua',
		countryValue: 'NI'
	},
	{
		value: 'MD',
		label: 'Madriz',
		countryLabel: 'Nicaragua',
		countryValue: 'NI'
	},
	{
		value: 'MN',
		label: 'Managua',
		countryLabel: 'Nicaragua',
		countryValue: 'NI'
	},
	{
		value: 'MS',
		label: 'Masaya',
		countryLabel: 'Nicaragua',
		countryValue: 'NI'
	},
	{
		value: 'MT',
		label: 'Matagalpa',
		countryLabel: 'Nicaragua',
		countryValue: 'NI'
	},
	{
		value: 'NS',
		label: 'Nueva Segovia',
		countryLabel: 'Nicaragua',
		countryValue: 'NI'
	},
	{
		value: 'RI',
		label: 'Rivas',
		countryLabel: 'Nicaragua',
		countryValue: 'NI'
	},
	{
		value: 'SJ',
		label: 'Rio San Juan',
		countryLabel: 'Nicaragua',
		countryValue: 'NI'
	},
	{
		value: '1',
		label: 'Agadez',
		countryLabel: 'Niger',
		countryValue: 'NE'
	},
	{
		value: '2',
		label: 'Diffa',
		countryLabel: 'Niger',
		countryValue: 'NE'
	},
	{
		value: '3',
		label: 'Dosso',
		countryLabel: 'Niger',
		countryValue: 'NE'
	},
	{
		value: '5',
		label: 'Tahoua',
		countryLabel: 'Niger',
		countryValue: 'NE'
	},
	{
		value: '6',
		label: 'Tillaberi',
		countryLabel: 'Niger',
		countryValue: 'NE'
	},
	{
		value: '7',
		label: 'Zinder',
		countryLabel: 'Niger',
		countryValue: 'NE'
	},
	{
		value: '8',
		label: 'Niamey',
		countryLabel: 'Niger',
		countryValue: 'NE'
	},
	{
		value: 'AB',
		label: 'Abia',
		countryLabel: 'Nigeria',
		countryValue: 'NG'
	},
	{
		value: 'AD',
		label: 'Adamawa',
		countryLabel: 'Nigeria',
		countryValue: 'NG'
	},
	{
		value: 'AK',
		label: 'Akwa Ibom',
		countryLabel: 'Nigeria',
		countryValue: 'NG'
	},
	{
		value: 'AN',
		label: 'Anambra',
		countryLabel: 'Nigeria',
		countryValue: 'NG'
	},
	{
		value: 'BA',
		label: 'Bauchi',
		countryLabel: 'Nigeria',
		countryValue: 'NG'
	},
	{
		value: 'BE',
		label: 'Benue',
		countryLabel: 'Nigeria',
		countryValue: 'NG'
	},
	{
		value: 'BO',
		label: 'Borno',
		countryLabel: 'Nigeria',
		countryValue: 'NG'
	},
	{
		value: 'BY',
		label: 'Bayelsa',
		countryLabel: 'Nigeria',
		countryValue: 'NG'
	},
	{
		value: 'CR',
		label: 'Cross River',
		countryLabel: 'Nigeria',
		countryValue: 'NG'
	},
	{
		value: 'DE',
		label: 'Delta',
		countryLabel: 'Nigeria',
		countryValue: 'NG'
	},
	{
		value: 'EB',
		label: 'Ebonyi',
		countryLabel: 'Nigeria',
		countryValue: 'NG'
	},
	{
		value: 'ED',
		label: 'Edo',
		countryLabel: 'Nigeria',
		countryValue: 'NG'
	},
	{
		value: 'EK',
		label: 'Ekiti',
		countryLabel: 'Nigeria',
		countryValue: 'NG'
	},
	{
		value: 'EN',
		label: 'Enugu',
		countryLabel: 'Nigeria',
		countryValue: 'NG'
	},
	{
		value: 'FC',
		label: 'Abuja Federal Capital Territory',
		countryLabel: 'Nigeria',
		countryValue: 'NG'
	},
	{
		value: 'GO',
		label: 'Gombe',
		countryLabel: 'Nigeria',
		countryValue: 'NG'
	},
	{
		value: 'IM',
		label: 'Imo',
		countryLabel: 'Nigeria',
		countryValue: 'NG'
	},
	{
		value: 'JI',
		label: 'Jigawa',
		countryLabel: 'Nigeria',
		countryValue: 'NG'
	},
	{
		value: 'KD',
		label: 'Kaduna',
		countryLabel: 'Nigeria',
		countryValue: 'NG'
	},
	{
		value: 'KE',
		label: 'Kebbi',
		countryLabel: 'Nigeria',
		countryValue: 'NG'
	},
	{
		value: 'KN',
		label: 'Kano',
		countryLabel: 'Nigeria',
		countryValue: 'NG'
	},
	{
		value: 'KO',
		label: 'Kogi',
		countryLabel: 'Nigeria',
		countryValue: 'NG'
	},
	{
		value: 'KT',
		label: 'Katsina',
		countryLabel: 'Nigeria',
		countryValue: 'NG'
	},
	{
		value: 'KW',
		label: 'Kwara',
		countryLabel: 'Nigeria',
		countryValue: 'NG'
	},
	{
		value: 'LA',
		label: 'Lagos',
		countryLabel: 'Nigeria',
		countryValue: 'NG'
	},
	{
		value: 'NA',
		label: 'Nasarawa',
		countryLabel: 'Nigeria',
		countryValue: 'NG'
	},
	{
		value: 'NI',
		label: 'Niger',
		countryLabel: 'Nigeria',
		countryValue: 'NG'
	},
	{
		value: 'OG',
		label: 'Ogun',
		countryLabel: 'Nigeria',
		countryValue: 'NG'
	},
	{
		value: 'ON',
		label: 'Ondo',
		countryLabel: 'Nigeria',
		countryValue: 'NG'
	},
	{
		value: 'OS',
		label: 'Osun',
		countryLabel: 'Nigeria',
		countryValue: 'NG'
	},
	{
		value: 'OY',
		label: 'Oyo',
		countryLabel: 'Nigeria',
		countryValue: 'NG'
	},
	{
		value: 'PL',
		label: 'Plateau',
		countryLabel: 'Nigeria',
		countryValue: 'NG'
	},
	{
		value: 'RI',
		label: 'Rivers',
		countryLabel: 'Nigeria',
		countryValue: 'NG'
	},
	{
		value: 'SO',
		label: 'Sokoto',
		countryLabel: 'Nigeria',
		countryValue: 'NG'
	},
	{
		value: 'TA',
		label: 'Taraba',
		countryLabel: 'Nigeria',
		countryValue: 'NG'
	},
	{
		value: 'YO',
		label: 'Yobe',
		countryLabel: 'Nigeria',
		countryValue: 'NG'
	},
	{
		value: 'ZA',
		label: 'Zamfara',
		countryLabel: 'Nigeria',
		countryValue: 'NG'
	},
	{
		value: '3',
		label: 'Oslo',
		countryLabel: 'Norway',
		countryValue: 'NO'
	},
	{
		value: '11',
		label: 'Rogaland',
		countryLabel: 'Norway',
		countryValue: 'NO'
	},
	{
		value: '15',
		label: 'More og Romsdal',
		countryLabel: 'Norway',
		countryValue: 'NO'
	},
	{
		value: '18',
		label: 'Nordland',
		countryLabel: 'Norway',
		countryValue: 'NO'
	},
	{
		value: '30',
		label: 'Viken',
		countryLabel: 'Norway',
		countryValue: 'NO'
	},
	{
		value: '34',
		label: 'Innlandet',
		countryLabel: 'Norway',
		countryValue: 'NO'
	},
	{
		value: '38',
		label: 'Vestfold og Telemark',
		countryLabel: 'Norway',
		countryValue: 'NO'
	},
	{
		value: '42',
		label: 'Agder',
		countryLabel: 'Norway',
		countryValue: 'NO'
	},
	{
		value: '46',
		label: 'Vestland',
		countryLabel: 'Norway',
		countryValue: 'NO'
	},
	{
		value: '50',
		label: 'Trondelag',
		countryLabel: 'Norway',
		countryValue: 'NO'
	},
	{
		value: '54',
		label: 'Troms og Finnmark',
		countryLabel: 'Norway',
		countryValue: 'NO'
	},
	{
		value: 'BJ',
		label: 'Janub al Batinah',
		countryLabel: 'Oman',
		countryValue: 'OM'
	},
	{
		value: 'BS',
		label: 'Shamal al Batinah',
		countryLabel: 'Oman',
		countryValue: 'OM'
	},
	{
		value: 'BU',
		label: 'Al Buraymi',
		countryLabel: 'Oman',
		countryValue: 'OM'
	},
	{
		value: 'DA',
		label: 'Ad Dakhiliyah',
		countryLabel: 'Oman',
		countryValue: 'OM'
	},
	{
		value: 'MA',
		label: 'Masqat',
		countryLabel: 'Oman',
		countryValue: 'OM'
	},
	{
		value: 'MU',
		label: 'Musandam',
		countryLabel: 'Oman',
		countryValue: 'OM'
	},
	{
		value: 'SJ',
		label: 'Janub ash Sharqiyah',
		countryLabel: 'Oman',
		countryValue: 'OM'
	},
	{
		value: 'SS',
		label: 'Shamal ash Sharqiyah',
		countryLabel: 'Oman',
		countryValue: 'OM'
	},
	{
		value: 'WU',
		label: 'Al Wusta',
		countryLabel: 'Oman',
		countryValue: 'OM'
	},
	{
		value: 'ZA',
		label: 'Az Zahirah',
		countryLabel: 'Oman',
		countryValue: 'OM'
	},
	{
		value: 'ZU',
		label: 'Zufar',
		countryLabel: 'Oman',
		countryValue: 'OM'
	},
	{
		value: 'BA',
		label: 'Balochistan',
		countryLabel: 'Pakistan',
		countryValue: 'PK'
	},
	{
		value: 'GB',
		label: 'Gilgit-Baltistan',
		countryLabel: 'Pakistan',
		countryValue: 'PK'
	},
	{
		value: 'IS',
		label: 'Islamabad',
		countryLabel: 'Pakistan',
		countryValue: 'PK'
	},
	{
		value: 'JK',
		label: 'Azad Jammu and Kashmir',
		countryLabel: 'Pakistan',
		countryValue: 'PK'
	},
	{
		value: 'KP',
		label: 'Khyber Pakhtunkhwa',
		countryLabel: 'Pakistan',
		countryValue: 'PK'
	},
	{
		value: 'PB',
		label: 'Punjab',
		countryLabel: 'Pakistan',
		countryValue: 'PK'
	},
	{
		value: 'SD',
		label: 'Sindh',
		countryLabel: 'Pakistan',
		countryValue: 'PK'
	},
	{
		value: '4',
		label: 'Airai',
		countryLabel: 'Palau',
		countryValue: 'PW'
	},
	{
		value: '100',
		label: 'Kayangel',
		countryLabel: 'Palau',
		countryValue: 'PW'
	},
	{
		value: '150',
		label: 'Koror',
		countryLabel: 'Palau',
		countryValue: 'PW'
	},
	{
		value: '212',
		label: 'Melekeok',
		countryLabel: 'Palau',
		countryValue: 'PW'
	},
	{
		value: '214',
		label: 'Ngaraard',
		countryLabel: 'Palau',
		countryValue: 'PW'
	},
	{
		value: '222',
		label: 'Ngardmau',
		countryLabel: 'Palau',
		countryValue: 'PW'
	},
	{
		value: 'BTH',
		label: 'Bethlehem',
		countryLabel: 'Palestinian territory',
		countryValue: 'PS'
	},
	{
		value: 'DEB',
		label: 'Deir El Balah',
		countryLabel: 'Palestinian territory',
		countryValue: 'PS'
	},
	{
		value: 'GZA',
		label: 'Gaza',
		countryLabel: 'Palestinian territory',
		countryValue: 'PS'
	},
	{
		value: 'HBN',
		label: 'Hebron',
		countryLabel: 'Palestinian territory',
		countryValue: 'PS'
	},
	{
		value: 'JEM',
		label: 'Jerusalem',
		countryLabel: 'Palestinian territory',
		countryValue: 'PS'
	},
	{
		value: 'JEN',
		label: 'Jenin',
		countryLabel: 'Palestinian territory',
		countryValue: 'PS'
	},
	{
		value: 'JRH',
		label: 'Jericho and Al Aghwar',
		countryLabel: 'Palestinian territory',
		countryValue: 'PS'
	},
	{
		value: 'KYS',
		label: 'Khan Yunis',
		countryLabel: 'Palestinian territory',
		countryValue: 'PS'
	},
	{
		value: 'NBS',
		label: 'Nablus',
		countryLabel: 'Palestinian territory',
		countryValue: 'PS'
	},
	{
		value: 'QQA',
		label: 'Qalqilya',
		countryLabel: 'Palestinian territory',
		countryValue: 'PS'
	},
	{
		value: 'RBH',
		label: 'Ramallah',
		countryLabel: 'Palestinian territory',
		countryValue: 'PS'
	},
	{
		value: 'RFH',
		label: 'Rafah',
		countryLabel: 'Palestinian territory',
		countryValue: 'PS'
	},
	{
		value: 'SLT',
		label: 'Salfit',
		countryLabel: 'Palestinian territory',
		countryValue: 'PS'
	},
	{
		value: 'TBS',
		label: 'Tubas',
		countryLabel: 'Palestinian territory',
		countryValue: 'PS'
	},
	{
		value: 'TKM',
		label: 'Tulkarm',
		countryLabel: 'Palestinian territory',
		countryValue: 'PS'
	},
	{
		value: '1',
		label: 'Bocas del Toro',
		countryLabel: 'Panama',
		countryValue: 'PA'
	},
	{
		value: '2',
		label: 'Cocle',
		countryLabel: 'Panama',
		countryValue: 'PA'
	},
	{
		value: '3',
		label: 'Colon',
		countryLabel: 'Panama',
		countryValue: 'PA'
	},
	{
		value: '4',
		label: 'Chiriqui',
		countryLabel: 'Panama',
		countryValue: 'PA'
	},
	{
		value: '5',
		label: 'Darien',
		countryLabel: 'Panama',
		countryValue: 'PA'
	},
	{
		value: '6',
		label: 'Herrera',
		countryLabel: 'Panama',
		countryValue: 'PA'
	},
	{
		value: '7',
		label: 'Los Santos',
		countryLabel: 'Panama',
		countryValue: 'PA'
	},
	{
		value: '8',
		label: 'Panama',
		countryLabel: 'Panama',
		countryValue: 'PA'
	},
	{
		value: '9',
		label: 'Veraguas',
		countryLabel: 'Panama',
		countryValue: 'PA'
	},
	{
		value: 'NB',
		label: 'Ngobe-Bugle',
		countryLabel: 'Panama',
		countryValue: 'PA'
	},
	{
		value: 'CPM',
		label: 'Central',
		countryLabel: 'Papua New Guinea',
		countryValue: 'PG'
	},
	{
		value: 'EBR',
		label: 'East New Britain',
		countryLabel: 'Papua New Guinea',
		countryValue: 'PG'
	},
	{
		value: 'EHG',
		label: 'Eastern Highlands',
		countryLabel: 'Papua New Guinea',
		countryValue: 'PG'
	},
	{
		value: 'ESW',
		label: 'East Sepik',
		countryLabel: 'Papua New Guinea',
		countryValue: 'PG'
	},
	{
		value: 'MBA',
		label: 'Milne Bay',
		countryLabel: 'Papua New Guinea',
		countryValue: 'PG'
	},
	{
		value: 'MPL',
		label: 'Morobe',
		countryLabel: 'Papua New Guinea',
		countryValue: 'PG'
	},
	{
		value: 'MPM',
		label: 'Madang',
		countryLabel: 'Papua New Guinea',
		countryValue: 'PG'
	},
	{
		value: 'MRL',
		label: 'Manus',
		countryLabel: 'Papua New Guinea',
		countryValue: 'PG'
	},
	{
		value: 'NCD',
		label: 'National Capital District (Port Moresby)',
		countryLabel: 'Papua New Guinea',
		countryValue: 'PG'
	},
	{
		value: 'NSB',
		label: 'Bougainville',
		countryLabel: 'Papua New Guinea',
		countryValue: 'PG'
	},
	{
		value: 'SAN',
		label: 'West Sepik',
		countryLabel: 'Papua New Guinea',
		countryValue: 'PG'
	},
	{
		value: 'SHM',
		label: 'Southern Highlands',
		countryLabel: 'Papua New Guinea',
		countryValue: 'PG'
	},
	{
		value: 'WBK',
		label: 'West New Britain',
		countryLabel: 'Papua New Guinea',
		countryValue: 'PG'
	},
	{
		value: 'WHM',
		label: 'Western Highlands',
		countryLabel: 'Papua New Guinea',
		countryValue: 'PG'
	},
	{
		value: 'WPD',
		label: 'Western',
		countryLabel: 'Papua New Guinea',
		countryValue: 'PG'
	},
	{
		value: '1',
		label: 'Concepcion',
		countryLabel: 'Paraguay',
		countryValue: 'PY'
	},
	{
		value: '2',
		label: 'San Pedro',
		countryLabel: 'Paraguay',
		countryValue: 'PY'
	},
	{
		value: '3',
		label: 'Cordillera',
		countryLabel: 'Paraguay',
		countryValue: 'PY'
	},
	{
		value: '4',
		label: 'Guaira',
		countryLabel: 'Paraguay',
		countryValue: 'PY'
	},
	{
		value: '5',
		label: 'Caaguazu',
		countryLabel: 'Paraguay',
		countryValue: 'PY'
	},
	{
		value: '6',
		label: 'Caazapa',
		countryLabel: 'Paraguay',
		countryValue: 'PY'
	},
	{
		value: '7',
		label: 'Itapua',
		countryLabel: 'Paraguay',
		countryValue: 'PY'
	},
	{
		value: '8',
		label: 'Misiones',
		countryLabel: 'Paraguay',
		countryValue: 'PY'
	},
	{
		value: '9',
		label: 'Paraguari',
		countryLabel: 'Paraguay',
		countryValue: 'PY'
	},
	{
		value: '10',
		label: 'Alto Parana',
		countryLabel: 'Paraguay',
		countryValue: 'PY'
	},
	{
		value: '11',
		label: 'Central',
		countryLabel: 'Paraguay',
		countryValue: 'PY'
	},
	{
		value: '12',
		label: 'Neembucu',
		countryLabel: 'Paraguay',
		countryValue: 'PY'
	},
	{
		value: '13',
		label: 'Amambay',
		countryLabel: 'Paraguay',
		countryValue: 'PY'
	},
	{
		value: '14',
		label: 'Canindeyu',
		countryLabel: 'Paraguay',
		countryValue: 'PY'
	},
	{
		value: '15',
		label: 'Presidente Hayes',
		countryLabel: 'Paraguay',
		countryValue: 'PY'
	},
	{
		value: '19',
		label: 'Boqueron',
		countryLabel: 'Paraguay',
		countryValue: 'PY'
	},
	{
		value: 'ASU',
		label: 'Asuncion',
		countryLabel: 'Paraguay',
		countryValue: 'PY'
	},
	{
		value: 'AMA',
		label: 'Amazonas',
		countryLabel: 'Peru',
		countryValue: 'PE'
	},
	{
		value: 'ANC',
		label: 'Ancash',
		countryLabel: 'Peru',
		countryValue: 'PE'
	},
	{
		value: 'APU',
		label: 'Apurimac',
		countryLabel: 'Peru',
		countryValue: 'PE'
	},
	{
		value: 'ARE',
		label: 'Arequipa',
		countryLabel: 'Peru',
		countryValue: 'PE'
	},
	{
		value: 'AYA',
		label: 'Ayacucho',
		countryLabel: 'Peru',
		countryValue: 'PE'
	},
	{
		value: 'CAJ',
		label: 'Cajamarca',
		countryLabel: 'Peru',
		countryValue: 'PE'
	},
	{
		value: 'CAL',
		label: 'El Callao',
		countryLabel: 'Peru',
		countryValue: 'PE'
	},
	{
		value: 'CUS',
		label: 'Cusco',
		countryLabel: 'Peru',
		countryValue: 'PE'
	},
	{
		value: 'HUC',
		label: 'Huanuco',
		countryLabel: 'Peru',
		countryValue: 'PE'
	},
	{
		value: 'HUV',
		label: 'Huancavelica',
		countryLabel: 'Peru',
		countryValue: 'PE'
	},
	{
		value: 'ICA',
		label: 'Ica',
		countryLabel: 'Peru',
		countryValue: 'PE'
	},
	{
		value: 'JUN',
		label: 'Junin',
		countryLabel: 'Peru',
		countryValue: 'PE'
	},
	{
		value: 'LAL',
		label: 'La Libertad',
		countryLabel: 'Peru',
		countryValue: 'PE'
	},
	{
		value: 'LAM',
		label: 'Lambayeque',
		countryLabel: 'Peru',
		countryValue: 'PE'
	},
	{
		value: 'LIM',
		label: 'Lima',
		countryLabel: 'Peru',
		countryValue: 'PE'
	},
	{
		value: 'LOR',
		label: 'Loreto',
		countryLabel: 'Peru',
		countryValue: 'PE'
	},
	{
		value: 'MDD',
		label: 'Madre de Dios',
		countryLabel: 'Peru',
		countryValue: 'PE'
	},
	{
		value: 'MOQ',
		label: 'Moquegua',
		countryLabel: 'Peru',
		countryValue: 'PE'
	},
	{
		value: 'PAS',
		label: 'Pasco',
		countryLabel: 'Peru',
		countryValue: 'PE'
	},
	{
		value: 'PIU',
		label: 'Piura',
		countryLabel: 'Peru',
		countryValue: 'PE'
	},
	{
		value: 'PUN',
		label: 'Puno',
		countryLabel: 'Peru',
		countryValue: 'PE'
	},
	{
		value: 'SAM',
		label: 'San Martin',
		countryLabel: 'Peru',
		countryValue: 'PE'
	},
	{
		value: 'TAC',
		label: 'Tacna',
		countryLabel: 'Peru',
		countryValue: 'PE'
	},
	{
		value: 'TUM',
		label: 'Tumbes',
		countryLabel: 'Peru',
		countryValue: 'PE'
	},
	{
		value: 'UCA',
		label: 'Ucayali',
		countryLabel: 'Peru',
		countryValue: 'PE'
	},
	{
		value: '0',
		label: 'National Capital Region',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'ABR',
		label: 'Abra',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'AGN',
		label: 'Agusan del Norte',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'AGS',
		label: 'Agusan del Sur',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'AKL',
		label: 'Aklan',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'ALB',
		label: 'Albay',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'ANT',
		label: 'Antique',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'APA',
		label: 'Apayao',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'AUR',
		label: 'Aurora',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'BAN',
		label: 'Bataan',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'BAS',
		label: 'Basilan',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'BEN',
		label: 'Benguet',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'BIL',
		label: 'Biliran',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'BOH',
		label: 'Bohol',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'BTG',
		label: 'Batangas',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'BTN',
		label: 'Batanes',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'BUK',
		label: 'Bukidnon',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'BUL',
		label: 'Bulacan',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'CAG',
		label: 'Cagayan',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'CAM',
		label: 'Camiguin',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'CAN',
		label: 'Camarines Norte',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'CAP',
		label: 'Capiz',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'CAS',
		label: 'Camarines Sur',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'CAT',
		label: 'Catanduanes',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'CAV',
		label: 'Cavite',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'CEB',
		label: 'Cebu',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'COM',
		label: 'Davao de Oro',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'DAO',
		label: 'Davao Oriental',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'DAS',
		label: 'Davao del Sur',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'DAV',
		label: 'Davao del Norte',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'DIN',
		label: 'Dinagat Islands',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'EAS',
		label: 'Eastern Samar',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'GUI',
		label: 'Guimaras',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'IFU',
		label: 'Ifugao',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'ILI',
		label: 'Iloilo',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'ILN',
		label: 'Ilocos Norte',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'ILS',
		label: 'Ilocos Sur',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'ISA',
		label: 'Isabela',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'KAL',
		label: 'Kalinga',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'LAG',
		label: 'Laguna',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'LAN',
		label: 'Lanao del Norte',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'LAS',
		label: 'Lanao del Sur',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'LEY',
		label: 'Leyte',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'LUN',
		label: 'La Union',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'MAD',
		label: 'Marinduque',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'MAG',
		label: 'Maguindanao',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'MAS',
		label: 'Masbate',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'MDC',
		label: 'Mindoro Occidental',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'MDR',
		label: 'Mindoro Oriental',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'MOU',
		label: 'Mountain Province',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'MSC',
		label: 'Misamis Occidental',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'MSR',
		label: 'Misamis Oriental',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'NCO',
		label: 'Cotabato',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'NEC',
		label: 'Negros Occidental',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'NER',
		label: 'Negros Oriental',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'NSA',
		label: 'Northern Samar',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'NUE',
		label: 'Nueva Ecija',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'NUV',
		label: 'Nueva Vizcaya',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'PAM',
		label: 'Pampanga',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'PAN',
		label: 'Pangasinan',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'PLW',
		label: 'Palawan',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'QUE',
		label: 'Quezon',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'QUI',
		label: 'Quirino',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'RIZ',
		label: 'Rizal',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'ROM',
		label: 'Romblon',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'SAR',
		label: 'Sarangani',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'SCO',
		label: 'South Cotabato',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'SIG',
		label: 'Siquijor',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'SLE',
		label: 'Southern Leyte',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'SLU',
		label: 'Sulu',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'SOR',
		label: 'Sorsogon',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'SUK',
		label: 'Sultan Kudarat',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'SUN',
		label: 'Surigao del Norte',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'SUR',
		label: 'Surigao del Sur',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'TAR',
		label: 'Tarlac',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'TAW',
		label: 'Tawi-Tawi',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'WSA',
		label: 'Samar',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'ZAN',
		label: 'Zamboanga del Norte',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'ZAS',
		label: 'Zamboanga del Sur',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'ZMB',
		label: 'Zambales',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: 'ZSI',
		label: 'Zamboanga Sibugay',
		countryLabel: 'Philippines',
		countryValue: 'PH'
	},
	{
		value: '2',
		label: 'Dolnoslaskie',
		countryLabel: 'Poland',
		countryValue: 'PL'
	},
	{
		value: '4',
		label: 'Kujawsko-pomorskie',
		countryLabel: 'Poland',
		countryValue: 'PL'
	},
	{
		value: '6',
		label: 'Lubelskie',
		countryLabel: 'Poland',
		countryValue: 'PL'
	},
	{
		value: '8',
		label: 'Lubuskie',
		countryLabel: 'Poland',
		countryValue: 'PL'
	},
	{
		value: '10',
		label: 'Lodzkie',
		countryLabel: 'Poland',
		countryValue: 'PL'
	},
	{
		value: '12',
		label: 'Malopolskie',
		countryLabel: 'Poland',
		countryValue: 'PL'
	},
	{
		value: '14',
		label: 'Mazowieckie',
		countryLabel: 'Poland',
		countryValue: 'PL'
	},
	{
		value: '16',
		label: 'Opolskie',
		countryLabel: 'Poland',
		countryValue: 'PL'
	},
	{
		value: '18',
		label: 'Podkarpackie',
		countryLabel: 'Poland',
		countryValue: 'PL'
	},
	{
		value: '20',
		label: 'Podlaskie',
		countryLabel: 'Poland',
		countryValue: 'PL'
	},
	{
		value: '22',
		label: 'Pomorskie',
		countryLabel: 'Poland',
		countryValue: 'PL'
	},
	{
		value: '24',
		label: 'Slaskie',
		countryLabel: 'Poland',
		countryValue: 'PL'
	},
	{
		value: '26',
		label: 'Swietokrzyskie',
		countryLabel: 'Poland',
		countryValue: 'PL'
	},
	{
		value: '28',
		label: 'Warminsko-mazurskie',
		countryLabel: 'Poland',
		countryValue: 'PL'
	},
	{
		value: '30',
		label: 'Wielkopolskie',
		countryLabel: 'Poland',
		countryValue: 'PL'
	},
	{
		value: '32',
		label: 'Zachodniopomorskie',
		countryLabel: 'Poland',
		countryValue: 'PL'
	},
	{
		value: '1',
		label: 'Aveiro',
		countryLabel: 'Portugal',
		countryValue: 'PT'
	},
	{
		value: '2',
		label: 'Beja',
		countryLabel: 'Portugal',
		countryValue: 'PT'
	},
	{
		value: '3',
		label: 'Braga',
		countryLabel: 'Portugal',
		countryValue: 'PT'
	},
	{
		value: '4',
		label: 'Braganca',
		countryLabel: 'Portugal',
		countryValue: 'PT'
	},
	{
		value: '5',
		label: 'Castelo Branco',
		countryLabel: 'Portugal',
		countryValue: 'PT'
	},
	{
		value: '6',
		label: 'Coimbra',
		countryLabel: 'Portugal',
		countryValue: 'PT'
	},
	{
		value: '7',
		label: 'Evora',
		countryLabel: 'Portugal',
		countryValue: 'PT'
	},
	{
		value: '8',
		label: 'Faro',
		countryLabel: 'Portugal',
		countryValue: 'PT'
	},
	{
		value: '9',
		label: 'Guarda',
		countryLabel: 'Portugal',
		countryValue: 'PT'
	},
	{
		value: '10',
		label: 'Leiria',
		countryLabel: 'Portugal',
		countryValue: 'PT'
	},
	{
		value: '11',
		label: 'Lisboa',
		countryLabel: 'Portugal',
		countryValue: 'PT'
	},
	{
		value: '12',
		label: 'Portalegre',
		countryLabel: 'Portugal',
		countryValue: 'PT'
	},
	{
		value: '13',
		label: 'Porto',
		countryLabel: 'Portugal',
		countryValue: 'PT'
	},
	{
		value: '14',
		label: 'Santarem',
		countryLabel: 'Portugal',
		countryValue: 'PT'
	},
	{
		value: '15',
		label: 'Setubal',
		countryLabel: 'Portugal',
		countryValue: 'PT'
	},
	{
		value: '16',
		label: 'Viana do Castelo',
		countryLabel: 'Portugal',
		countryValue: 'PT'
	},
	{
		value: '17',
		label: 'Vila Real',
		countryLabel: 'Portugal',
		countryValue: 'PT'
	},
	{
		value: '18',
		label: 'Viseu',
		countryLabel: 'Portugal',
		countryValue: 'PT'
	},
	{
		value: '20',
		label: 'Regiao Autonoma dos Acores',
		countryLabel: 'Portugal',
		countryValue: 'PT'
	},
	{
		value: '30',
		label: 'Regiao Autonoma da Madeira',
		countryLabel: 'Portugal',
		countryValue: 'PT'
	},
	{
		value: 'DA',
		label: 'Ad Dawhah',
		countryLabel: 'Qatar',
		countryValue: 'QA'
	},
	{
		value: 'KH',
		label: 'Al Khawr wa adh Dhakhirah',
		countryLabel: 'Qatar',
		countryValue: 'QA'
	},
	{
		value: 'MS',
		label: 'Ash Shamal',
		countryLabel: 'Qatar',
		countryValue: 'QA'
	},
	{
		value: 'RA',
		label: 'Ar Rayyan',
		countryLabel: 'Qatar',
		countryValue: 'QA'
	},
	{
		value: 'US',
		label: 'Umm Salal',
		countryLabel: 'Qatar',
		countryValue: 'QA'
	},
	{
		value: 'WA',
		label: 'Al Wakrah',
		countryLabel: 'Qatar',
		countryValue: 'QA'
	},
	{
		value: 'ZA',
		label: "Az Za'ayin",
		countryLabel: 'Qatar',
		countryValue: 'QA'
	},
	{
		value: 'AB',
		label: 'Alba',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'AG',
		label: 'Arges',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'AR',
		label: 'Arad',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'B',
		label: 'Bucuresti',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'BC',
		label: 'Bacau',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'BH',
		label: 'Bihor',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'BN',
		label: 'Bistrita-Nasaud',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'BR',
		label: 'Braila',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'BT',
		label: 'Botosani',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'BV',
		label: 'Brasov',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'BZ',
		label: 'Buzau',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'CJ',
		label: 'Cluj',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'CL',
		label: 'Calarasi',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'CS',
		label: 'Caras-Severin',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'CT',
		label: 'Constanta',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'CV',
		label: 'Covasna',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'DB',
		label: 'Dambovita',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'DJ',
		label: 'Dolj',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'GJ',
		label: 'Gorj',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'GL',
		label: 'Galati',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'GR',
		label: 'Giurgiu',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'HD',
		label: 'Hunedoara',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'HR',
		label: 'Harghita',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'IF',
		label: 'Ilfov',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'IL',
		label: 'Ialomita',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'IS',
		label: 'Iasi',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'MH',
		label: 'Mehedinti',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'MM',
		label: 'Maramures',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'MS',
		label: 'Mures',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'NT',
		label: 'Neamt',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'OT',
		label: 'Olt',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'PH',
		label: 'Prahova',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'SB',
		label: 'Sibiu',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'SJ',
		label: 'Salaj',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'SM',
		label: 'Satu Mare',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'SV',
		label: 'Suceava',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'TL',
		label: 'Tulcea',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'TM',
		label: 'Timis',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'TR',
		label: 'Teleorman',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'VL',
		label: 'Valcea',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'VN',
		label: 'Vrancea',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'VS',
		label: 'Vaslui',
		countryLabel: 'Romania',
		countryValue: 'RO'
	},
	{
		value: 'ALT',
		label: 'Altayskiy kray',
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'AMU',
		label: "Amurskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'ARK',
		label: "Arkhangel'skaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'AST',
		label: "Astrakhanskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'BEL',
		label: "Belgorodskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'BRY',
		label: "Bryanskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'CE',
		label: 'Chechenskaya Respublika',
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'CHE',
		label: "Chelyabinskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'CHU',
		label: 'Chukotskiy avtonomnyy okrug',
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'CU',
		label: 'Chuvashskaya Respublika',
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'IRK',
		label: "Irkutskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'IVA',
		label: "Ivanovskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'KAM',
		label: 'Kamchatskiy kray',
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'KB',
		label: 'Kabardino-Balkarskaya Respublika',
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'KC',
		label: 'Karachayevo-Cherkesskaya Respublika',
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'KDA',
		label: 'Krasnodarskiy kray',
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'KEM',
		label: "Kemerovskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'KGD',
		label: "Kaliningradskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'KGN',
		label: "Kurganskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'KHA',
		label: 'Khabarovskiy kray',
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'KHM',
		label: 'Khanty-Mansiyskiy avtonomnyy okrug',
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'KIR',
		label: "Kirovskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'KLU',
		label: "Kaluzhskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'KOS',
		label: "Kostromskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'KRS',
		label: "Kurskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'KYA',
		label: 'Krasnoyarskiy kray',
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'LEN',
		label: "Leningradskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'LIP',
		label: "Lipetskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'MAG',
		label: "Magadanskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'MOS',
		label: "Moskovskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'MOW',
		label: 'Moskva',
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'MUR',
		label: "Murmanskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'NEN',
		label: 'Nenetskiy avtonomnyy okrug',
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'NGR',
		label: "Novgorodskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'NIZ',
		label: "Nizhegorodskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'NVS',
		label: "Novosibirskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'OMS',
		label: "Omskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'ORE',
		label: "Orenburgskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'ORL',
		label: "Orlovskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'PER',
		label: 'Permskiy kray',
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'PNZ',
		label: "Penzenskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'PRI',
		label: 'Primorskiy kray',
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'PSK',
		label: "Pskovskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'ROS',
		label: "Rostovskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'RYA',
		label: "Ryazanskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'SAK',
		label: "Sakhalinskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'SAM',
		label: "Samarskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'SAR',
		label: "Saratovskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'SMO',
		label: "Smolenskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'SPE',
		label: 'Sankt-Peterburg',
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'STA',
		label: "Stavropol'skiy kray",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'SVE',
		label: "Sverdlovskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'TAM',
		label: "Tambovskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'TOM',
		label: "Tomskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'TUL',
		label: "Tul'skaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'TVE',
		label: "Tverskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'TYU',
		label: "Tyumenskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'UD',
		label: 'Udmurtskaya Respublika',
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'ULY',
		label: "Ul'yanovskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'VGG',
		label: "Volgogradskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'VLA',
		label: "Vladimirskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'VLG',
		label: "Vologodskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'VOR',
		label: "Voronezhskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'YAN',
		label: 'Yamalo-Nenetskiy avtonomnyy okrug',
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'YAR',
		label: "Yaroslavskaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'YEV',
		label: "Yevreyskaya avtonomnaya oblast'",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'ZAB',
		label: "Zabaykal'skiy kray",
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: 'AD',
		label: 'Adygeya',
		countryLabel: 'Russia',
		countryValue: 'RU'
	},
	{
		value: '1',
		label: 'Ville de Kigali',
		countryLabel: 'Rwanda',
		countryValue: 'RW'
	},
	{
		value: '2',
		label: 'Est',
		countryLabel: 'Rwanda',
		countryValue: 'RW'
	},
	{
		value: '3',
		label: 'Nord',
		countryLabel: 'Rwanda',
		countryValue: 'RW'
	},
	{
		value: '4',
		label: 'Ouest',
		countryLabel: 'Rwanda',
		countryValue: 'RW'
	},
	{
		value: '5',
		label: 'Sud',
		countryLabel: 'Rwanda',
		countryValue: 'RW'
	},
	{
		value: 'HL',
		label: 'Saint Helena',
		countryLabel: 'Saint helena',
		countryValue: 'SH'
	},
	{
		value: '1',
		label: 'Christ Church Nichola Town',
		countryLabel: 'Saint kitts and nevis',
		countryValue: 'KN'
	},
	{
		value: '2',
		label: 'Saint Anne Sandy Point',
		countryLabel: 'Saint kitts and nevis',
		countryValue: 'KN'
	},
	{
		value: '3',
		label: 'Saint George Basseterre',
		countryLabel: 'Saint kitts and nevis',
		countryValue: 'KN'
	},
	{
		value: '5',
		label: 'Saint James Windward',
		countryLabel: 'Saint kitts and nevis',
		countryValue: 'KN'
	},
	{
		value: '6',
		label: 'Saint John Capisterre',
		countryLabel: 'Saint kitts and nevis',
		countryValue: 'KN'
	},
	{
		value: '7',
		label: 'Saint John Figtree',
		countryLabel: 'Saint kitts and nevis',
		countryValue: 'KN'
	},
	{
		value: '8',
		label: 'Saint Mary Cayon',
		countryLabel: 'Saint kitts and nevis',
		countryValue: 'KN'
	},
	{
		value: '9',
		label: 'Saint Paul Capisterre',
		countryLabel: 'Saint kitts and nevis',
		countryValue: 'KN'
	},
	{
		value: '10',
		label: 'Saint Paul Charlestown',
		countryLabel: 'Saint kitts and nevis',
		countryValue: 'KN'
	},
	{
		value: '11',
		label: 'Saint Peter Basseterre',
		countryLabel: 'Saint kitts and nevis',
		countryValue: 'KN'
	},
	{
		value: '12',
		label: 'Saint Thomas Lowland',
		countryLabel: 'Saint kitts and nevis',
		countryValue: 'KN'
	},
	{
		value: '13',
		label: 'Saint Thomas Middle Island',
		countryLabel: 'Saint kitts and nevis',
		countryValue: 'KN'
	},
	{
		value: '15',
		label: 'Trinity Palmetto Point',
		countryLabel: 'Saint kitts and nevis',
		countryValue: 'KN'
	},
	{
		value: '1',
		label: 'Anse la Raye',
		countryLabel: 'Saint lucia',
		countryValue: 'LC'
	},
	{
		value: '2',
		label: 'Castries',
		countryLabel: 'Saint lucia',
		countryValue: 'LC'
	},
	{
		value: '3',
		label: 'Choiseul',
		countryLabel: 'Saint lucia',
		countryValue: 'LC'
	},
	{
		value: '5',
		label: 'Dennery',
		countryLabel: 'Saint lucia',
		countryValue: 'LC'
	},
	{
		value: '6',
		label: 'Gros Islet',
		countryLabel: 'Saint lucia',
		countryValue: 'LC'
	},
	{
		value: '7',
		label: 'Laborie',
		countryLabel: 'Saint lucia',
		countryValue: 'LC'
	},
	{
		value: '8',
		label: 'Micoud',
		countryLabel: 'Saint lucia',
		countryValue: 'LC'
	},
	{
		value: '10',
		label: 'Soufriere',
		countryLabel: 'Saint lucia',
		countryValue: 'LC'
	},
	{
		value: '11',
		label: 'Vieux Fort',
		countryLabel: 'Saint lucia',
		countryValue: 'LC'
	},
	{
		value: '1',
		label: 'Charlotte',
		countryLabel: 'Saint vincent & the grenadines',
		countryValue: 'VC'
	},
	{
		value: '4',
		label: 'Saint George',
		countryLabel: 'Saint vincent & the grenadines',
		countryValue: 'VC'
	},
	{
		value: '5',
		label: 'Saint Patrick',
		countryLabel: 'Saint vincent & the grenadines',
		countryValue: 'VC'
	},
	{
		value: '6',
		label: 'Grenadines',
		countryLabel: 'Saint vincent & the grenadines',
		countryValue: 'VC'
	},
	{
		value: '3',
		label: 'Domagnano',
		countryLabel: 'San Marino',
		countryValue: 'SM'
	},
	{
		value: '4',
		label: 'Faetano',
		countryLabel: 'San Marino',
		countryValue: 'SM'
	},
	{
		value: '7',
		label: 'Citta di San Marino',
		countryLabel: 'San Marino',
		countryValue: 'SM'
	},
	{
		value: '9',
		label: 'Serravalle',
		countryLabel: 'San Marino',
		countryValue: 'SM'
	},
	{
		value: '1',
		label: 'Agua Grande',
		countryLabel: 'Sao tome and principe',
		countryValue: 'ST'
	},
	{
		value: '1',
		label: 'Ar Riyad',
		countryLabel: 'Saudi Arabia',
		countryValue: 'SA'
	},
	{
		value: '2',
		label: 'Makkah al Mukarramah',
		countryLabel: 'Saudi Arabia',
		countryValue: 'SA'
	},
	{
		value: '3',
		label: 'Al Madinah al Munawwarah',
		countryLabel: 'Saudi Arabia',
		countryValue: 'SA'
	},
	{
		value: '4',
		label: 'Ash Sharqiyah',
		countryLabel: 'Saudi Arabia',
		countryValue: 'SA'
	},
	{
		value: '5',
		label: 'Al Qasim',
		countryLabel: 'Saudi Arabia',
		countryValue: 'SA'
	},
	{
		value: '6',
		label: "Ha'il",
		countryLabel: 'Saudi Arabia',
		countryValue: 'SA'
	},
	{
		value: '7',
		label: 'Tabuk',
		countryLabel: 'Saudi Arabia',
		countryValue: 'SA'
	},
	{
		value: '8',
		label: 'Al Hudud ash Shamaliyah',
		countryLabel: 'Saudi Arabia',
		countryValue: 'SA'
	},
	{
		value: '9',
		label: 'Jazan',
		countryLabel: 'Saudi Arabia',
		countryValue: 'SA'
	},
	{
		value: '10',
		label: 'Najran',
		countryLabel: 'Saudi Arabia',
		countryValue: 'SA'
	},
	{
		value: '11',
		label: 'Al Bahah',
		countryLabel: 'Saudi Arabia',
		countryValue: 'SA'
	},
	{
		value: '12',
		label: 'Al Jawf',
		countryLabel: 'Saudi Arabia',
		countryValue: 'SA'
	},
	{
		value: '14',
		label: 'Asir',
		countryLabel: 'Saudi Arabia',
		countryValue: 'SA'
	},
	{
		value: 'DB',
		label: 'Diourbel',
		countryLabel: 'Senegal',
		countryValue: 'SN'
	},
	{
		value: 'DK',
		label: 'Dakar',
		countryLabel: 'Senegal',
		countryValue: 'SN'
	},
	{
		value: 'FK',
		label: 'Fatick',
		countryLabel: 'Senegal',
		countryValue: 'SN'
	},
	{
		value: 'KA',
		label: 'Kaffrine',
		countryLabel: 'Senegal',
		countryValue: 'SN'
	},
	{
		value: 'KD',
		label: 'Kolda',
		countryLabel: 'Senegal',
		countryValue: 'SN'
	},
	{
		value: 'KL',
		label: 'Kaolack',
		countryLabel: 'Senegal',
		countryValue: 'SN'
	},
	{
		value: 'LG',
		label: 'Louga',
		countryLabel: 'Senegal',
		countryValue: 'SN'
	},
	{
		value: 'MT',
		label: 'Matam',
		countryLabel: 'Senegal',
		countryValue: 'SN'
	},
	{
		value: 'SE',
		label: 'Sedhiou',
		countryLabel: 'Senegal',
		countryValue: 'SN'
	},
	{
		value: 'SL',
		label: 'Saint-Louis',
		countryLabel: 'Senegal',
		countryValue: 'SN'
	},
	{
		value: 'TC',
		label: 'Tambacounda',
		countryLabel: 'Senegal',
		countryValue: 'SN'
	},
	{
		value: 'TH',
		label: 'Thies',
		countryLabel: 'Senegal',
		countryValue: 'SN'
	},
	{
		value: 'ZG',
		label: 'Ziguinchor',
		countryLabel: 'Senegal',
		countryValue: 'SN'
	},
	{
		value: '0',
		label: 'Beograd',
		countryLabel: 'Serbia',
		countryValue: 'RS'
	},
	{
		value: '1',
		label: 'Severnobacki okrug',
		countryLabel: 'Serbia',
		countryValue: 'RS'
	},
	{
		value: '2',
		label: 'Srednjebanatski okrug',
		countryLabel: 'Serbia',
		countryValue: 'RS'
	},
	{
		value: '3',
		label: 'Severnobanatski okrug',
		countryLabel: 'Serbia',
		countryValue: 'RS'
	},
	{
		value: '4',
		label: 'Juznobanatski okrug',
		countryLabel: 'Serbia',
		countryValue: 'RS'
	},
	{
		value: '5',
		label: 'Zapadnobacki okrug',
		countryLabel: 'Serbia',
		countryValue: 'RS'
	},
	{
		value: '6',
		label: 'Juznobacki okrug',
		countryLabel: 'Serbia',
		countryValue: 'RS'
	},
	{
		value: '7',
		label: 'Sremski okrug',
		countryLabel: 'Serbia',
		countryValue: 'RS'
	},
	{
		value: '8',
		label: 'Macvanski okrug',
		countryLabel: 'Serbia',
		countryValue: 'RS'
	},
	{
		value: '9',
		label: 'Kolubarski okrug',
		countryLabel: 'Serbia',
		countryValue: 'RS'
	},
	{
		value: '10',
		label: 'Podunavski okrug',
		countryLabel: 'Serbia',
		countryValue: 'RS'
	},
	{
		value: '11',
		label: 'Branicevski okrug',
		countryLabel: 'Serbia',
		countryValue: 'RS'
	},
	{
		value: '12',
		label: 'Sumadijski okrug',
		countryLabel: 'Serbia',
		countryValue: 'RS'
	},
	{
		value: '13',
		label: 'Pomoravski okrug',
		countryLabel: 'Serbia',
		countryValue: 'RS'
	},
	{
		value: '14',
		label: 'Borski okrug',
		countryLabel: 'Serbia',
		countryValue: 'RS'
	},
	{
		value: '15',
		label: 'Zajecarski okrug',
		countryLabel: 'Serbia',
		countryValue: 'RS'
	},
	{
		value: '16',
		label: 'Zlatiborski okrug',
		countryLabel: 'Serbia',
		countryValue: 'RS'
	},
	{
		value: '17',
		label: 'Moravicki okrug',
		countryLabel: 'Serbia',
		countryValue: 'RS'
	},
	{
		value: '18',
		label: 'Raski okrug',
		countryLabel: 'Serbia',
		countryValue: 'RS'
	},
	{
		value: '19',
		label: 'Rasinski okrug',
		countryLabel: 'Serbia',
		countryValue: 'RS'
	},
	{
		value: '20',
		label: 'Nisavski okrug',
		countryLabel: 'Serbia',
		countryValue: 'RS'
	},
	{
		value: '21',
		label: 'Toplicki okrug',
		countryLabel: 'Serbia',
		countryValue: 'RS'
	},
	{
		value: '22',
		label: 'Pirotski okrug',
		countryLabel: 'Serbia',
		countryValue: 'RS'
	},
	{
		value: '23',
		label: 'Jablanicki okrug',
		countryLabel: 'Serbia',
		countryValue: 'RS'
	},
	{
		value: '24',
		label: 'Pcinjski okrug',
		countryLabel: 'Serbia',
		countryValue: 'RS'
	},
	{
		value: '26',
		label: 'Pecki okrug',
		countryLabel: 'Serbia',
		countryValue: 'RS'
	},
	{
		value: '27',
		label: 'Prizrenski okrug',
		countryLabel: 'Serbia',
		countryValue: 'RS'
	},
	{
		value: '28',
		label: 'Kosovsko-Mitrovacki okrug',
		countryLabel: 'Serbia',
		countryValue: 'RS'
	},
	{
		value: '1',
		label: 'Anse aux Pins',
		countryLabel: 'Seychelle Islands',
		countryValue: 'SC'
	},
	{
		value: '2',
		label: 'Anse Boileau',
		countryLabel: 'Seychelle Islands',
		countryValue: 'SC'
	},
	{
		value: '5',
		label: 'Anse Royale',
		countryLabel: 'Seychelle Islands',
		countryValue: 'SC'
	},
	{
		value: '6',
		label: 'Baie Lazare',
		countryLabel: 'Seychelle Islands',
		countryValue: 'SC'
	},
	{
		value: '7',
		label: 'Baie Sainte Anne',
		countryLabel: 'Seychelle Islands',
		countryValue: 'SC'
	},
	{
		value: '8',
		label: 'Beau Vallon',
		countryLabel: 'Seychelle Islands',
		countryValue: 'SC'
	},
	{
		value: '10',
		label: 'Bel Ombre',
		countryLabel: 'Seychelle Islands',
		countryValue: 'SC'
	},
	{
		value: '11',
		label: 'Cascade',
		countryLabel: 'Seychelle Islands',
		countryValue: 'SC'
	},
	{
		value: '13',
		label: 'Grand Anse Mahe',
		countryLabel: 'Seychelle Islands',
		countryValue: 'SC'
	},
	{
		value: '14',
		label: 'Grand Anse Praslin',
		countryLabel: 'Seychelle Islands',
		countryValue: 'SC'
	},
	{
		value: '15',
		label: 'La Digue',
		countryLabel: 'Seychelle Islands',
		countryValue: 'SC'
	},
	{
		value: '16',
		label: 'English River',
		countryLabel: 'Seychelle Islands',
		countryValue: 'SC'
	},
	{
		value: '20',
		label: 'Pointe Larue',
		countryLabel: 'Seychelle Islands',
		countryValue: 'SC'
	},
	{
		value: '23',
		label: 'Takamaka',
		countryLabel: 'Seychelle Islands',
		countryValue: 'SC'
	},
	{
		value: 'E',
		label: 'Eastern',
		countryLabel: 'Sierra Leone',
		countryValue: 'SL'
	},
	{
		value: 'N',
		label: 'Northern',
		countryLabel: 'Sierra Leone',
		countryValue: 'SL'
	},
	{
		value: 'S',
		label: 'Southern',
		countryLabel: 'Sierra Leone',
		countryValue: 'SL'
	},
	{
		value: 'W',
		label: 'Western Area',
		countryLabel: 'Sierra Leone',
		countryValue: 'SL'
	},
	{
		value: 'BC',
		label: 'Banskobystricky kraj',
		countryLabel: 'Slovak Republic',
		countryValue: 'SK'
	},
	{
		value: 'BL',
		label: 'Bratislavsky kraj',
		countryLabel: 'Slovak Republic',
		countryValue: 'SK'
	},
	{
		value: 'KI',
		label: 'Kosicky kraj',
		countryLabel: 'Slovak Republic',
		countryValue: 'SK'
	},
	{
		value: 'NI',
		label: 'Nitriansky kraj',
		countryLabel: 'Slovak Republic',
		countryValue: 'SK'
	},
	{
		value: 'PV',
		label: 'Presovsky kraj',
		countryLabel: 'Slovak Republic',
		countryValue: 'SK'
	},
	{
		value: 'TA',
		label: 'Trnavsky kraj',
		countryLabel: 'Slovak Republic',
		countryValue: 'SK'
	},
	{
		value: 'TC',
		label: 'Trenciansky kraj',
		countryLabel: 'Slovak Republic',
		countryValue: 'SK'
	},
	{
		value: 'ZI',
		label: 'Zilinsky kraj',
		countryLabel: 'Slovak Republic',
		countryValue: 'SK'
	},
	{
		value: '1',
		label: 'Ajdovscina',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '2',
		label: 'Beltinci',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '3',
		label: 'Bled',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '4',
		label: 'Bohinj',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '5',
		label: 'Borovnica',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '6',
		label: 'Bovec',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '7',
		label: 'Brda',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '8',
		label: 'Brezovica',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '9',
		label: 'Brezice',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '10',
		label: 'Tisina',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '11',
		label: 'Celje',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '12',
		label: 'Cerklje na Gorenjskem',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '13',
		label: 'Cerknica',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '14',
		label: 'Cerkno',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '15',
		label: 'Crensovci',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '17',
		label: 'Crnomelj',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '18',
		label: 'Destrnik',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '19',
		label: 'Divaca',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '20',
		label: 'Dobrepolje',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '21',
		label: 'Dobrova-Polhov Gradec',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '23',
		label: 'Domzale',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '24',
		label: 'Dornava',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '25',
		label: 'Dravograd',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '26',
		label: 'Duplek',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '29',
		label: 'Gornja Radgona',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '31',
		label: 'Gornji Petrovci',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '32',
		label: 'Grosuplje',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '33',
		label: 'Salovci',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '34',
		label: 'Hrastnik',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '35',
		label: 'Hrpelje-Kozina',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '36',
		label: 'Idrija',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '37',
		label: 'Ig',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '38',
		label: 'Ilirska Bistrica',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '39',
		label: 'Ivancna Gorica',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '40',
		label: 'Izola',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '41',
		label: 'Jesenice',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '42',
		label: 'Jursinci',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '43',
		label: 'Kamnik',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '44',
		label: 'Kanal',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '45',
		label: 'Kidricevo',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '46',
		label: 'Kobarid',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '47',
		label: 'Kobilje',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '48',
		label: 'Kocevje',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '49',
		label: 'Komen',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '50',
		label: 'Koper',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '52',
		label: 'Kranj',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '53',
		label: 'Kranjska Gora',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '54',
		label: 'Krsko',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '55',
		label: 'Kungota',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '56',
		label: 'Kuzma',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '57',
		label: 'Lasko',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '58',
		label: 'Lenart',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '59',
		label: 'Lendava',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '60',
		label: 'Litija',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '61',
		label: 'Ljubljana',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '63',
		label: 'Ljutomer',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '64',
		label: 'Logatec',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '65',
		label: 'Loska dolina',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '66',
		label: 'Loski Potok',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '67',
		label: 'Luce',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '68',
		label: 'Lukovica',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '69',
		label: 'Majsperk',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '70',
		label: 'Maribor',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '71',
		label: 'Medvode',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '72',
		label: 'Menges',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '73',
		label: 'Metlika',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '74',
		label: 'Mezica',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '75',
		label: 'Miren-Kostanjevica',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '76',
		label: 'Mislinja',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '77',
		label: 'Moravce',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '79',
		label: 'Mozirje',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '80',
		label: 'Murska Sobota',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '81',
		label: 'Muta',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '82',
		label: 'Naklo',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '83',
		label: 'Nazarje',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '84',
		label: 'Nova Gorica',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '85',
		label: 'Novo Mesto',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '86',
		label: 'Odranci',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '87',
		label: 'Ormoz',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '90',
		label: 'Piran',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '91',
		label: 'Pivka',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '92',
		label: 'Podcetrtek',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '94',
		label: 'Postojna',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '95',
		label: 'Preddvor',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '96',
		label: 'Ptuj',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '97',
		label: 'Puconci',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '98',
		label: 'Race-Fram',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '99',
		label: 'Radece',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '100',
		label: 'Radenci',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '101',
		label: 'Radlje ob Dravi',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '102',
		label: 'Radovljica',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '103',
		label: 'Ravne na Koroskem',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '104',
		label: 'Ribnica',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '105',
		label: 'Rogasovci',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '106',
		label: 'Rogaska Slatina',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '108',
		label: 'Ruse',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '109',
		label: 'Semic',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '110',
		label: 'Sevnica',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '111',
		label: 'Sezana',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '112',
		label: 'Slovenj Gradec',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '113',
		label: 'Slovenska Bistrica',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '114',
		label: 'Slovenske Konjice',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '115',
		label: 'Starse',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '116',
		label: 'Sveti Jurij ob Scavnici',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '117',
		label: 'Sencur',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '118',
		label: 'Sentilj',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '119',
		label: 'Sentjernej',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '120',
		label: 'Sentjur',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '121',
		label: 'Skocjan',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '122',
		label: 'Skofja Loka',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '123',
		label: 'Skofljica',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '124',
		label: 'Smarje pri Jelsah',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '125',
		label: 'Smartno ob Paki',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '126',
		label: 'Sostanj',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '127',
		label: 'Store',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '128',
		label: 'Tolmin',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '129',
		label: 'Trbovlje',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '130',
		label: 'Trebnje',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '131',
		label: 'Trzic',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '132',
		label: 'Turnisce',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '133',
		label: 'Velenje',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '134',
		label: 'Velike Lasce',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '135',
		label: 'Videm',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '136',
		label: 'Vipava',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '137',
		label: 'Vitanje',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '138',
		label: 'Vodice',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '139',
		label: 'Vojnik',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '140',
		label: 'Vrhnika',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '141',
		label: 'Vuzenica',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '142',
		label: 'Zagorje ob Savi',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '143',
		label: 'Zavrc',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '144',
		label: 'Zrece',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '146',
		label: 'Zelezniki',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '147',
		label: 'Ziri',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '148',
		label: 'Benedikt',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '149',
		label: 'Bistrica ob Sotli',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '150',
		label: 'Bloke',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '151',
		label: 'Braslovce',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '152',
		label: 'Cankova',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '154',
		label: 'Dobje',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '155',
		label: 'Dobrna',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '156',
		label: 'Dobrovnik',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '158',
		label: 'Grad',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '159',
		label: 'Hajdina',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '160',
		label: 'Hoce-Slivnica',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '161',
		label: 'Hodos',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '162',
		label: 'Horjul',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '164',
		label: 'Komenda',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '165',
		label: 'Kostel',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '166',
		label: 'Krizevci',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '167',
		label: 'Lovrenc na Pohorju',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '168',
		label: 'Markovci',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '169',
		label: 'Miklavz na Dravskem polju',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '170',
		label: 'Mirna Pec',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '171',
		label: 'Oplotnica',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '172',
		label: 'Podlehnik',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '173',
		label: 'Polzela',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '174',
		label: 'Prebold',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '175',
		label: 'Prevalje',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '176',
		label: 'Razkrizje',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '179',
		label: 'Sodrazica',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '180',
		label: 'Solcava',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '182',
		label: 'Sveti Andraz v Slovenskih Goricah',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '183',
		label: 'Sempeter-Vrtojba',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '184',
		label: 'Tabor',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '185',
		label: 'Trnovska Vas',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '186',
		label: 'Trzin',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '187',
		label: 'Velika Polana',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '188',
		label: 'Verzej',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '189',
		label: 'Vransko',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '190',
		label: 'Zalec',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '191',
		label: 'Zetale',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '193',
		label: 'Zuzemberk',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '194',
		label: 'Smartno pri Litiji',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '195',
		label: 'Apace',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '196',
		label: 'Cirkulane',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '197',
		label: 'Kosanjevica na Krki',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '198',
		label: 'Makole',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '199',
		label: 'Mokronog-Trebelno',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '200',
		label: 'Poljcane',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '201',
		label: 'Rence-Vogrsko',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '203',
		label: 'Straza',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '204',
		label: 'Sveta Trojica v Slovenskih goricah',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '205',
		label: 'Sveti Tomaz',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '206',
		label: 'Smarjeske Toplice',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '207',
		label: 'Gorje',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '208',
		label: 'Log-Dragomer',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '209',
		label: 'Recica ob Savinji',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '210',
		label: 'Sveti Jurij v Slovenskih goricah',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '211',
		label: 'Sentrupert',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '212',
		label: 'Mirna',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: '213',
		label: 'Ankaran',
		countryLabel: 'Slovenia',
		countryValue: 'SI'
	},
	{
		value: 'CH',
		label: 'Choiseul',
		countryLabel: 'Solomon islands',
		countryValue: 'SB'
	},
	{
		value: 'GU',
		label: 'Guadalcanal',
		countryLabel: 'Solomon islands',
		countryValue: 'SB'
	},
	{
		value: 'WE',
		label: 'Western',
		countryLabel: 'Solomon islands',
		countryValue: 'SB'
	},
	{
		value: 'AW',
		label: 'Awdal',
		countryLabel: 'Somalia',
		countryValue: 'SO'
	},
	{
		value: 'BN',
		label: 'Banaadir',
		countryLabel: 'Somalia',
		countryValue: 'SO'
	},
	{
		value: 'HI',
		label: 'Hiiraan',
		countryLabel: 'Somalia',
		countryValue: 'SO'
	},
	{
		value: 'MU',
		label: 'Mudug',
		countryLabel: 'Somalia',
		countryValue: 'SO'
	},
	{
		value: 'NU',
		label: 'Nugaal',
		countryLabel: 'Somalia',
		countryValue: 'SO'
	},
	{
		value: 'SH',
		label: 'Shabeellaha Hoose',
		countryLabel: 'Somalia',
		countryValue: 'SO'
	},
	{
		value: 'TO',
		label: 'Togdheer',
		countryLabel: 'Somalia',
		countryValue: 'SO'
	},
	{
		value: 'WO',
		label: 'Woqooyi Galbeed',
		countryLabel: 'Somalia',
		countryValue: 'SO'
	},
	{
		value: 'EC',
		label: 'Eastern Cape',
		countryLabel: 'South Africa',
		countryValue: 'ZA'
	},
	{
		value: 'FS',
		label: 'Free State',
		countryLabel: 'South Africa',
		countryValue: 'ZA'
	},
	{
		value: 'GP',
		label: 'Gauteng',
		countryLabel: 'South Africa',
		countryValue: 'ZA'
	},
	{
		value: 'KZN',
		label: 'KwaZulu-Natal',
		countryLabel: 'South Africa',
		countryValue: 'ZA'
	},
	{
		value: 'LP',
		label: 'Limpopo',
		countryLabel: 'South Africa',
		countryValue: 'ZA'
	},
	{
		value: 'MP',
		label: 'Mpumalanga',
		countryLabel: 'South Africa',
		countryValue: 'ZA'
	},
	{
		value: 'NC',
		label: 'Northern Cape',
		countryLabel: 'South Africa',
		countryValue: 'ZA'
	},
	{
		value: 'NW',
		label: 'North-West',
		countryLabel: 'South Africa',
		countryValue: 'ZA'
	},
	{
		value: 'WC',
		label: 'Western Cape',
		countryLabel: 'South Africa',
		countryValue: 'ZA'
	},
	{
		value: 'BN',
		label: 'Northern Bahr el Ghazal',
		countryLabel: 'South Sudan',
		countryValue: 'SS'
	},
	{
		value: 'EC',
		label: 'Central Equatoria',
		countryLabel: 'South Sudan',
		countryValue: 'SS'
	},
	{
		value: 'EE',
		label: 'Eastern Equatoria',
		countryLabel: 'South Sudan',
		countryValue: 'SS'
	},
	{
		value: 'EW',
		label: 'Western Equatoria',
		countryLabel: 'South Sudan',
		countryValue: 'SS'
	},
	{
		value: 'NU',
		label: 'Upper Nile',
		countryLabel: 'South Sudan',
		countryValue: 'SS'
	},
	{
		value: 'UY',
		label: 'Unity',
		countryLabel: 'South Sudan',
		countryValue: 'SS'
	},
	{
		value: 'AN',
		label: 'Andalucia',
		countryLabel: 'Spain',
		countryValue: 'ES'
	},
	{
		value: 'AR',
		label: 'Aragon',
		countryLabel: 'Spain',
		countryValue: 'ES'
	},
	{
		value: 'CB',
		label: 'Cantabria',
		countryLabel: 'Spain',
		countryValue: 'ES'
	},
	{
		value: 'CE',
		label: 'Ceuta',
		countryLabel: 'Spain',
		countryValue: 'ES'
	},
	{
		value: 'CL',
		label: 'Castilla y Leon',
		countryLabel: 'Spain',
		countryValue: 'ES'
	},
	{
		value: 'CM',
		label: 'Castilla-La Mancha',
		countryLabel: 'Spain',
		countryValue: 'ES'
	},
	{
		value: 'CN',
		label: 'Canarias',
		countryLabel: 'Spain',
		countryValue: 'ES'
	},
	{
		value: 'CT',
		label: 'Catalunya',
		countryLabel: 'Spain',
		countryValue: 'ES'
	},
	{
		value: 'EX',
		label: 'Extremadura',
		countryLabel: 'Spain',
		countryValue: 'ES'
	},
	{
		value: 'GA',
		label: 'Galicia',
		countryLabel: 'Spain',
		countryValue: 'ES'
	},
	{
		value: 'IB',
		label: 'Illes Balears',
		countryLabel: 'Spain',
		countryValue: 'ES'
	},
	{
		value: 'ML',
		label: 'Melilla',
		countryLabel: 'Spain',
		countryValue: 'ES'
	},
	{
		value: 'PV',
		label: 'Pais Vasco',
		countryLabel: 'Spain',
		countryValue: 'ES'
	},
	{
		value: 'RI',
		label: 'La Rioja',
		countryLabel: 'Spain',
		countryValue: 'ES'
	},
	{
		value: 'AS',
		label: 'Asturias',
		countryLabel: 'Spain',
		countryValue: 'ES'
	},
	{
		value: '1',
		label: 'Western Province',
		countryLabel: 'SriLanka',
		countryValue: 'LK'
	},
	{
		value: '2',
		label: 'Central Province',
		countryLabel: 'SriLanka',
		countryValue: 'LK'
	},
	{
		value: '3',
		label: 'Southern Province',
		countryLabel: 'SriLanka',
		countryValue: 'LK'
	},
	{
		value: '4',
		label: 'Northern Province',
		countryLabel: 'SriLanka',
		countryValue: 'LK'
	},
	{
		value: '5',
		label: 'Eastern Province',
		countryLabel: 'SriLanka',
		countryValue: 'LK'
	},
	{
		value: '6',
		label: 'North Western Province',
		countryLabel: 'SriLanka',
		countryValue: 'LK'
	},
	{
		value: '7',
		label: 'North Central Province',
		countryLabel: 'SriLanka',
		countryValue: 'LK'
	},
	{
		value: '8',
		label: 'Uva Province',
		countryLabel: 'SriLanka',
		countryValue: 'LK'
	},
	{
		value: '9',
		label: 'Sabaragamuwa Province',
		countryLabel: 'SriLanka',
		countryValue: 'LK'
	},
	{
		value: 'DC',
		label: 'Central Darfur',
		countryLabel: 'Sudan',
		countryValue: 'SD'
	},
	{
		value: 'DN',
		label: 'North Darfur',
		countryLabel: 'Sudan',
		countryValue: 'SD'
	},
	{
		value: 'DS',
		label: 'South Darfur',
		countryLabel: 'Sudan',
		countryValue: 'SD'
	},
	{
		value: 'DW',
		label: 'West Darfur',
		countryLabel: 'Sudan',
		countryValue: 'SD'
	},
	{
		value: 'GD',
		label: 'Gedaref',
		countryLabel: 'Sudan',
		countryValue: 'SD'
	},
	{
		value: 'GK',
		label: 'West Kordofan',
		countryLabel: 'Sudan',
		countryValue: 'SD'
	},
	{
		value: 'GZ',
		label: 'Gezira',
		countryLabel: 'Sudan',
		countryValue: 'SD'
	},
	{
		value: 'KA',
		label: 'Kassala',
		countryLabel: 'Sudan',
		countryValue: 'SD'
	},
	{
		value: 'KH',
		label: 'Khartoum',
		countryLabel: 'Sudan',
		countryValue: 'SD'
	},
	{
		value: 'KN',
		label: 'North Kordofan',
		countryLabel: 'Sudan',
		countryValue: 'SD'
	},
	{
		value: 'KS',
		label: 'South Kordofan',
		countryLabel: 'Sudan',
		countryValue: 'SD'
	},
	{
		value: 'NB',
		label: 'Blue Nile',
		countryLabel: 'Sudan',
		countryValue: 'SD'
	},
	{
		value: 'NO',
		label: 'Northern',
		countryLabel: 'Sudan',
		countryValue: 'SD'
	},
	{
		value: 'NR',
		label: 'River Nile',
		countryLabel: 'Sudan',
		countryValue: 'SD'
	},
	{
		value: 'NW',
		label: 'White Nile',
		countryLabel: 'Sudan',
		countryValue: 'SD'
	},
	{
		value: 'RS',
		label: 'Red Sea',
		countryLabel: 'Sudan',
		countryValue: 'SD'
	},
	{
		value: 'SI',
		label: 'Sennar',
		countryLabel: 'Sudan',
		countryValue: 'SD'
	},
	{
		value: 'CM',
		label: 'Commewijne',
		countryLabel: 'Suriname',
		countryValue: 'SR'
	},
	{
		value: 'NI',
		label: 'Nickerie',
		countryLabel: 'Suriname',
		countryValue: 'SR'
	},
	{
		value: 'PM',
		label: 'Paramaribo',
		countryLabel: 'Suriname',
		countryValue: 'SR'
	},
	{
		value: 'PR',
		label: 'Para',
		countryLabel: 'Suriname',
		countryValue: 'SR'
	},
	{
		value: 'SI',
		label: 'Sipaliwini',
		countryLabel: 'Suriname',
		countryValue: 'SR'
	},
	{
		value: 'WA',
		label: 'Wanica',
		countryLabel: 'Suriname',
		countryValue: 'SR'
	},
	{
		value: 'HH',
		label: 'Hhohho',
		countryLabel: 'Swaziland',
		countryValue: 'SZ'
	},
	{
		value: 'LU',
		label: 'Lubombo',
		countryLabel: 'Swaziland',
		countryValue: 'SZ'
	},
	{
		value: 'MA',
		label: 'Manzini',
		countryLabel: 'Swaziland',
		countryValue: 'SZ'
	},
	{
		value: 'AB',
		label: 'Stockholms lan',
		countryLabel: 'Sweden',
		countryValue: 'SE'
	},
	{
		value: 'AC',
		label: 'Vasterbottens lan',
		countryLabel: 'Sweden',
		countryValue: 'SE'
	},
	{
		value: 'BD',
		label: 'Norrbottens lan',
		countryLabel: 'Sweden',
		countryValue: 'SE'
	},
	{
		value: 'C',
		label: 'Uppsala lan',
		countryLabel: 'Sweden',
		countryValue: 'SE'
	},
	{
		value: 'D',
		label: 'Sodermanlands lan',
		countryLabel: 'Sweden',
		countryValue: 'SE'
	},
	{
		value: 'E',
		label: 'Ostergotlands lan',
		countryLabel: 'Sweden',
		countryValue: 'SE'
	},
	{
		value: 'F',
		label: 'Jonkopings lan',
		countryLabel: 'Sweden',
		countryValue: 'SE'
	},
	{
		value: 'G',
		label: 'Kronobergs lan',
		countryLabel: 'Sweden',
		countryValue: 'SE'
	},
	{
		value: 'H',
		label: 'Kalmar lan',
		countryLabel: 'Sweden',
		countryValue: 'SE'
	},
	{
		value: 'I',
		label: 'Gotlands lan',
		countryLabel: 'Sweden',
		countryValue: 'SE'
	},
	{
		value: 'K',
		label: 'Blekinge lan',
		countryLabel: 'Sweden',
		countryValue: 'SE'
	},
	{
		value: 'M',
		label: 'Skane lan',
		countryLabel: 'Sweden',
		countryValue: 'SE'
	},
	{
		value: 'N',
		label: 'Hallands lan',
		countryLabel: 'Sweden',
		countryValue: 'SE'
	},
	{
		value: 'O',
		label: 'Vastra Gotalands lan',
		countryLabel: 'Sweden',
		countryValue: 'SE'
	},
	{
		value: 'S',
		label: 'Varmlands lan',
		countryLabel: 'Sweden',
		countryValue: 'SE'
	},
	{
		value: 'T',
		label: 'Orebro lan',
		countryLabel: 'Sweden',
		countryValue: 'SE'
	},
	{
		value: 'U',
		label: 'Vastmanlands lan',
		countryLabel: 'Sweden',
		countryValue: 'SE'
	},
	{
		value: 'W',
		label: 'Dalarnas lan',
		countryLabel: 'Sweden',
		countryValue: 'SE'
	},
	{
		value: 'X',
		label: 'Gavleborgs lan',
		countryLabel: 'Sweden',
		countryValue: 'SE'
	},
	{
		value: 'Y',
		label: 'Vasternorrlands lan',
		countryLabel: 'Sweden',
		countryValue: 'SE'
	},
	{
		value: 'Z',
		label: 'Jamtlands lan',
		countryLabel: 'Sweden',
		countryValue: 'SE'
	},
	{
		value: 'AG',
		label: 'Aargau',
		countryLabel: 'Switzerland',
		countryValue: 'CH'
	},
	{
		value: 'AI',
		label: 'Appenzell Innerrhoden',
		countryLabel: 'Switzerland',
		countryValue: 'CH'
	},
	{
		value: 'AR',
		label: 'Appenzell Ausserrhoden',
		countryLabel: 'Switzerland',
		countryValue: 'CH'
	},
	{
		value: 'BE',
		label: 'Bern',
		countryLabel: 'Switzerland',
		countryValue: 'CH'
	},
	{
		value: 'BL',
		label: 'Basel-Landschaft',
		countryLabel: 'Switzerland',
		countryValue: 'CH'
	},
	{
		value: 'BS',
		label: 'Basel-Stadt',
		countryLabel: 'Switzerland',
		countryValue: 'CH'
	},
	{
		value: 'FR',
		label: 'Fribourg',
		countryLabel: 'Switzerland',
		countryValue: 'CH'
	},
	{
		value: 'GE',
		label: 'Geneve',
		countryLabel: 'Switzerland',
		countryValue: 'CH'
	},
	{
		value: 'GL',
		label: 'Glarus',
		countryLabel: 'Switzerland',
		countryValue: 'CH'
	},
	{
		value: 'GR',
		label: 'Graubunden',
		countryLabel: 'Switzerland',
		countryValue: 'CH'
	},
	{
		value: 'JU',
		label: 'Jura',
		countryLabel: 'Switzerland',
		countryValue: 'CH'
	},
	{
		value: 'LU',
		label: 'Luzern',
		countryLabel: 'Switzerland',
		countryValue: 'CH'
	},
	{
		value: 'NE',
		label: 'Neuchatel',
		countryLabel: 'Switzerland',
		countryValue: 'CH'
	},
	{
		value: 'NW',
		label: 'Nidwalden',
		countryLabel: 'Switzerland',
		countryValue: 'CH'
	},
	{
		value: 'OW',
		label: 'Obwalden',
		countryLabel: 'Switzerland',
		countryValue: 'CH'
	},
	{
		value: 'SG',
		label: 'Sankt Gallen',
		countryLabel: 'Switzerland',
		countryValue: 'CH'
	},
	{
		value: 'SH',
		label: 'Schaffhausen',
		countryLabel: 'Switzerland',
		countryValue: 'CH'
	},
	{
		value: 'SO',
		label: 'Solothurn',
		countryLabel: 'Switzerland',
		countryValue: 'CH'
	},
	{
		value: 'SZ',
		label: 'Schwyz',
		countryLabel: 'Switzerland',
		countryValue: 'CH'
	},
	{
		value: 'TG',
		label: 'Thurgau',
		countryLabel: 'Switzerland',
		countryValue: 'CH'
	},
	{
		value: 'TI',
		label: 'Ticino',
		countryLabel: 'Switzerland',
		countryValue: 'CH'
	},
	{
		value: 'UR',
		label: 'Uri',
		countryLabel: 'Switzerland',
		countryValue: 'CH'
	},
	{
		value: 'VD',
		label: 'Vaud',
		countryLabel: 'Switzerland',
		countryValue: 'CH'
	},
	{
		value: 'VS',
		label: 'Valais',
		countryLabel: 'Switzerland',
		countryValue: 'CH'
	},
	{
		value: 'ZG',
		label: 'Zug',
		countryLabel: 'Switzerland',
		countryValue: 'CH'
	},
	{
		value: 'ZH',
		label: 'Zurich',
		countryLabel: 'Switzerland',
		countryValue: 'CH'
	},
	{
		value: 'DI',
		label: 'Dimashq',
		countryLabel: 'Syria',
		countryValue: 'SY'
	},
	{
		value: 'DR',
		label: "Dar'a",
		countryLabel: 'Syria',
		countryValue: 'SY'
	},
	{
		value: 'DY',
		label: 'Dayr az Zawr',
		countryLabel: 'Syria',
		countryValue: 'SY'
	},
	{
		value: 'HA',
		label: 'Al Hasakah',
		countryLabel: 'Syria',
		countryValue: 'SY'
	},
	{
		value: 'HI',
		label: 'Hims',
		countryLabel: 'Syria',
		countryValue: 'SY'
	},
	{
		value: 'HL',
		label: 'Halab',
		countryLabel: 'Syria',
		countryValue: 'SY'
	},
	{
		value: 'HM',
		label: 'Hamah',
		countryLabel: 'Syria',
		countryValue: 'SY'
	},
	{
		value: 'ID',
		label: 'Idlib',
		countryLabel: 'Syria',
		countryValue: 'SY'
	},
	{
		value: 'LA',
		label: 'Al Ladhiqiyah',
		countryLabel: 'Syria',
		countryValue: 'SY'
	},
	{
		value: 'QU',
		label: 'Al Qunaytirah',
		countryLabel: 'Syria',
		countryValue: 'SY'
	},
	{
		value: 'RA',
		label: 'Ar Raqqah',
		countryLabel: 'Syria',
		countryValue: 'SY'
	},
	{
		value: 'RD',
		label: 'Rif Dimashq',
		countryLabel: 'Syria',
		countryValue: 'SY'
	},
	{
		value: 'SU',
		label: "As Suwayda'",
		countryLabel: 'Syria',
		countryValue: 'SY'
	},
	{
		value: 'TA',
		label: 'Tartus',
		countryLabel: 'Syria',
		countryValue: 'SY'
	},
	{
		value: 'CHA',
		label: 'Changhua',
		countryLabel: 'Taiwan',
		countryValue: 'TW'
	},
	{
		value: 'CYQ',
		label: 'Chiayi',
		countryLabel: 'Taiwan',
		countryValue: 'TW'
	},
	{
		value: 'HSQ',
		label: 'Hsinchu',
		countryLabel: 'Taiwan',
		countryValue: 'TW'
	},
	{
		value: 'HUA',
		label: 'Hualien',
		countryLabel: 'Taiwan',
		countryValue: 'TW'
	},
	{
		value: 'ILA',
		label: 'Yilan',
		countryLabel: 'Taiwan',
		countryValue: 'TW'
	},
	{
		value: 'KEE',
		label: 'Keelung',
		countryLabel: 'Taiwan',
		countryValue: 'TW'
	},
	{
		value: 'KHH',
		label: 'Kaohsiung',
		countryLabel: 'Taiwan',
		countryValue: 'TW'
	},
	{
		value: 'KIN',
		label: 'Kinmen',
		countryLabel: 'Taiwan',
		countryValue: 'TW'
	},
	{
		value: 'LIE',
		label: 'Lienchiang',
		countryLabel: 'Taiwan',
		countryValue: 'TW'
	},
	{
		value: 'MIA',
		label: 'Miaoli',
		countryLabel: 'Taiwan',
		countryValue: 'TW'
	},
	{
		value: 'NAN',
		label: 'Nantou',
		countryLabel: 'Taiwan',
		countryValue: 'TW'
	},
	{
		value: 'NWT',
		label: 'New Taipei',
		countryLabel: 'Taiwan',
		countryValue: 'TW'
	},
	{
		value: 'PEN',
		label: 'Penghu',
		countryLabel: 'Taiwan',
		countryValue: 'TW'
	},
	{
		value: 'PIF',
		label: 'Pingtung',
		countryLabel: 'Taiwan',
		countryValue: 'TW'
	},
	{
		value: 'TAO',
		label: 'Taoyuan',
		countryLabel: 'Taiwan',
		countryValue: 'TW'
	},
	{
		value: 'TNN',
		label: 'Tainan',
		countryLabel: 'Taiwan',
		countryValue: 'TW'
	},
	{
		value: 'TPE',
		label: 'Taipei',
		countryLabel: 'Taiwan',
		countryValue: 'TW'
	},
	{
		value: 'TTT',
		label: 'Taitung',
		countryLabel: 'Taiwan',
		countryValue: 'TW'
	},
	{
		value: 'TXG',
		label: 'Taichung',
		countryLabel: 'Taiwan',
		countryValue: 'TW'
	},
	{
		value: 'YUN',
		label: 'Yunlin',
		countryLabel: 'Taiwan',
		countryValue: 'TW'
	},
	{
		value: 'DU',
		label: 'Dushanbe',
		countryLabel: 'Tajikistan',
		countryValue: 'TJ'
	},
	{
		value: 'GB',
		label: 'Kuhistoni Badakhshon',
		countryLabel: 'Tajikistan',
		countryValue: 'TJ'
	},
	{
		value: 'KT',
		label: 'Khatlon',
		countryLabel: 'Tajikistan',
		countryValue: 'TJ'
	},
	{
		value: 'RA',
		label: 'Nohiyahoi Tobei Jumhuri',
		countryLabel: 'Tajikistan',
		countryValue: 'TJ'
	},
	{
		value: 'SU',
		label: 'Sughd',
		countryLabel: 'Tajikistan',
		countryValue: 'TJ'
	},
	{
		value: '1',
		label: 'Arusha',
		countryLabel: 'Tanzania',
		countryValue: 'TZ'
	},
	{
		value: '2',
		label: 'Dar es Salaam',
		countryLabel: 'Tanzania',
		countryValue: 'TZ'
	},
	{
		value: '3',
		label: 'Dodoma',
		countryLabel: 'Tanzania',
		countryValue: 'TZ'
	},
	{
		value: '4',
		label: 'Iringa',
		countryLabel: 'Tanzania',
		countryValue: 'TZ'
	},
	{
		value: '5',
		label: 'Kagera',
		countryLabel: 'Tanzania',
		countryValue: 'TZ'
	},
	{
		value: '7',
		label: 'Kaskazini Unguja',
		countryLabel: 'Tanzania',
		countryValue: 'TZ'
	},
	{
		value: '8',
		label: 'Kigoma',
		countryLabel: 'Tanzania',
		countryValue: 'TZ'
	},
	{
		value: '9',
		label: 'Kilimanjaro',
		countryLabel: 'Tanzania',
		countryValue: 'TZ'
	},
	{
		value: '11',
		label: 'Kusini Unguja',
		countryLabel: 'Tanzania',
		countryValue: 'TZ'
	},
	{
		value: '12',
		label: 'Lindi',
		countryLabel: 'Tanzania',
		countryValue: 'TZ'
	},
	{
		value: '13',
		label: 'Mara',
		countryLabel: 'Tanzania',
		countryValue: 'TZ'
	},
	{
		value: '14',
		label: 'Mbeya',
		countryLabel: 'Tanzania',
		countryValue: 'TZ'
	},
	{
		value: '15',
		label: 'Mjini Magharibi',
		countryLabel: 'Tanzania',
		countryValue: 'TZ'
	},
	{
		value: '16',
		label: 'Morogoro',
		countryLabel: 'Tanzania',
		countryValue: 'TZ'
	},
	{
		value: '17',
		label: 'Mtwara',
		countryLabel: 'Tanzania',
		countryValue: 'TZ'
	},
	{
		value: '18',
		label: 'Mwanza',
		countryLabel: 'Tanzania',
		countryValue: 'TZ'
	},
	{
		value: '19',
		label: 'Pwani',
		countryLabel: 'Tanzania',
		countryValue: 'TZ'
	},
	{
		value: '21',
		label: 'Ruvuma',
		countryLabel: 'Tanzania',
		countryValue: 'TZ'
	},
	{
		value: '22',
		label: 'Shinyanga',
		countryLabel: 'Tanzania',
		countryValue: 'TZ'
	},
	{
		value: '23',
		label: 'Singida',
		countryLabel: 'Tanzania',
		countryValue: 'TZ'
	},
	{
		value: '24',
		label: 'Tabora',
		countryLabel: 'Tanzania',
		countryValue: 'TZ'
	},
	{
		value: '25',
		label: 'Tanga',
		countryLabel: 'Tanzania',
		countryValue: 'TZ'
	},
	{
		value: '26',
		label: 'Manyara',
		countryLabel: 'Tanzania',
		countryValue: 'TZ'
	},
	{
		value: '27',
		label: 'Geita',
		countryLabel: 'Tanzania',
		countryValue: 'TZ'
	},
	{
		value: '28',
		label: 'Katavi',
		countryLabel: 'Tanzania',
		countryValue: 'TZ'
	},
	{
		value: '29',
		label: 'Njombe',
		countryLabel: 'Tanzania',
		countryValue: 'TZ'
	},
	{
		value: '30',
		label: 'Simiyu',
		countryLabel: 'Tanzania',
		countryValue: 'TZ'
	},
	{
		value: '31',
		label: 'Songwe',
		countryLabel: 'Tanzania',
		countryValue: 'TZ'
	},
	{
		value: '10',
		label: 'Krung Thep Maha Nakhon',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '11',
		label: 'Samut Prakan',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '12',
		label: 'Nonthaburi',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '13',
		label: 'Pathum Thani',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '14',
		label: 'Phra Nakhon Si Ayutthaya',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '15',
		label: 'Ang Thong',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '16',
		label: 'Lop Buri',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '17',
		label: 'Sing Buri',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '18',
		label: 'Chai Nat',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '19',
		label: 'Saraburi',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '20',
		label: 'Chon Buri',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '21',
		label: 'Rayong',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '22',
		label: 'Chanthaburi',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '23',
		label: 'Trat',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '24',
		label: 'Chachoengsao',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '25',
		label: 'Prachin Buri',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '26',
		label: 'Nakhon Nayok',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '27',
		label: 'Sa Kaeo',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '30',
		label: 'Nakhon Ratchasima',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '31',
		label: 'Buri Ram',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '32',
		label: 'Surin',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '33',
		label: 'Si Sa Ket',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '34',
		label: 'Ubon Ratchathani',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '35',
		label: 'Yasothon',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '36',
		label: 'Chaiyaphum',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '37',
		label: 'Amnat Charoen',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '38',
		label: 'Bueng Kan',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '39',
		label: 'Nong Bua Lam Phu',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '40',
		label: 'Khon Kaen',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '41',
		label: 'Udon Thani',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '42',
		label: 'Loei',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '43',
		label: 'Nong Khai',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '44',
		label: 'Maha Sarakham',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '45',
		label: 'Roi Et',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '46',
		label: 'Kalasin',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '47',
		label: 'Sakon Nakhon',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '48',
		label: 'Nakhon Phanom',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '49',
		label: 'Mukdahan',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '50',
		label: 'Chiang Mai',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '51',
		label: 'Lamphun',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '52',
		label: 'Lampang',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '53',
		label: 'Uttaradit',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '54',
		label: 'Phrae',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '55',
		label: 'Nan',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '56',
		label: 'Phayao',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '57',
		label: 'Chiang Rai',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '58',
		label: 'Mae Hong Son',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '60',
		label: 'Nakhon Sawan',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '61',
		label: 'Uthai Thani',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '62',
		label: 'Kamphaeng Phet',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '63',
		label: 'Tak',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '64',
		label: 'Sukhothai',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '65',
		label: 'Phitsanulok',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '66',
		label: 'Phichit',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '67',
		label: 'Phetchabun',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '70',
		label: 'Ratchaburi',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '71',
		label: 'Kanchanaburi',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '72',
		label: 'Suphan Buri',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '73',
		label: 'Nakhon Pathom',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '74',
		label: 'Samut Sakhon',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '75',
		label: 'Samut Songkhram',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '76',
		label: 'Phetchaburi',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '77',
		label: 'Prachuap Khiri Khan',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '80',
		label: 'Nakhon Si Thammarat',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '81',
		label: 'Krabi',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '82',
		label: 'Phangnga',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '83',
		label: 'Phuket',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '84',
		label: 'Surat Thani',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '85',
		label: 'Ranong',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '86',
		label: 'Chumphon',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '90',
		label: 'Songkhla',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '91',
		label: 'Satun',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '92',
		label: 'Trang',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '93',
		label: 'Phatthalung',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '94',
		label: 'Pattani',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '95',
		label: 'Yala',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: '96',
		label: 'Narathiwat',
		countryLabel: 'Thailand',
		countryValue: 'TH'
	},
	{
		value: 'AN',
		label: 'Ainaro',
		countryLabel: 'Timor-leste',
		countryValue: 'TL'
	},
	{
		value: 'BO',
		label: 'Bobonaro',
		countryLabel: 'Timor-leste',
		countryValue: 'TL'
	},
	{
		value: 'CO',
		label: 'Cova Lima',
		countryLabel: 'Timor-leste',
		countryValue: 'TL'
	},
	{
		value: 'DI',
		label: 'Dili',
		countryLabel: 'Timor-leste',
		countryValue: 'TL'
	},
	{
		value: 'C',
		label: 'Centrale',
		countryLabel: 'Togo',
		countryValue: 'TG'
	},
	{
		value: 'K',
		label: 'Kara',
		countryLabel: 'Togo',
		countryValue: 'TG'
	},
	{
		value: 'M',
		label: 'Maritime',
		countryLabel: 'Togo',
		countryValue: 'TG'
	},
	{
		value: 'P',
		label: 'Plateaux',
		countryLabel: 'Togo',
		countryValue: 'TG'
	},
	{
		value: 'S',
		label: 'Savanes',
		countryLabel: 'Togo',
		countryValue: 'TG'
	},
	{
		value: '2',
		label: "Ha'apai",
		countryLabel: 'Tonga',
		countryValue: 'TO'
	},
	{
		value: '3',
		label: 'Niuas',
		countryLabel: 'Tonga',
		countryValue: 'TO'
	},
	{
		value: '4',
		label: 'Tongatapu',
		countryLabel: 'Tonga',
		countryValue: 'TO'
	},
	{
		value: 'ARI',
		label: 'Arima',
		countryLabel: 'Trinidad and Tobago',
		countryValue: 'TT'
	},
	{
		value: 'CHA',
		label: 'Chaguanas',
		countryLabel: 'Trinidad and Tobago',
		countryValue: 'TT'
	},
	{
		value: 'CTT',
		label: 'Couva-Tabaquite-Talparo',
		countryLabel: 'Trinidad and Tobago',
		countryValue: 'TT'
	},
	{
		value: 'DMN',
		label: 'Diego Martin',
		countryLabel: 'Trinidad and Tobago',
		countryValue: 'TT'
	},
	{
		value: 'MRC',
		label: 'Mayaro-Rio Claro',
		countryLabel: 'Trinidad and Tobago',
		countryValue: 'TT'
	},
	{
		value: 'PED',
		label: 'Penal-Debe',
		countryLabel: 'Trinidad and Tobago',
		countryValue: 'TT'
	},
	{
		value: 'POS',
		label: 'Port of Spain',
		countryLabel: 'Trinidad and Tobago',
		countryValue: 'TT'
	},
	{
		value: 'PRT',
		label: 'Princes Town',
		countryLabel: 'Trinidad and Tobago',
		countryValue: 'TT'
	},
	{
		value: 'PTF',
		label: 'Point Fortin',
		countryLabel: 'Trinidad and Tobago',
		countryValue: 'TT'
	},
	{
		value: 'SFO',
		label: 'San Fernando',
		countryLabel: 'Trinidad and Tobago',
		countryValue: 'TT'
	},
	{
		value: 'SGE',
		label: 'Sangre Grande',
		countryLabel: 'Trinidad and Tobago',
		countryValue: 'TT'
	},
	{
		value: 'SIP',
		label: 'Siparia',
		countryLabel: 'Trinidad and Tobago',
		countryValue: 'TT'
	},
	{
		value: 'SJL',
		label: 'San Juan-Laventille',
		countryLabel: 'Trinidad and Tobago',
		countryValue: 'TT'
	},
	{
		value: 'TOB',
		label: 'Tobago',
		countryLabel: 'Trinidad and Tobago',
		countryValue: 'TT'
	},
	{
		value: 'TUP',
		label: 'Tunapuna-Piarco',
		countryLabel: 'Trinidad and Tobago',
		countryValue: 'TT'
	},
	{
		value: '11',
		label: 'Tunis',
		countryLabel: 'Tunisia',
		countryValue: 'TN'
	},
	{
		value: '12',
		label: "L'Ariana",
		countryLabel: 'Tunisia',
		countryValue: 'TN'
	},
	{
		value: '13',
		label: 'Ben Arous',
		countryLabel: 'Tunisia',
		countryValue: 'TN'
	},
	{
		value: '14',
		label: 'La Manouba',
		countryLabel: 'Tunisia',
		countryValue: 'TN'
	},
	{
		value: '21',
		label: 'Nabeul',
		countryLabel: 'Tunisia',
		countryValue: 'TN'
	},
	{
		value: '22',
		label: 'Zaghouan',
		countryLabel: 'Tunisia',
		countryValue: 'TN'
	},
	{
		value: '23',
		label: 'Bizerte',
		countryLabel: 'Tunisia',
		countryValue: 'TN'
	},
	{
		value: '31',
		label: 'Beja',
		countryLabel: 'Tunisia',
		countryValue: 'TN'
	},
	{
		value: '32',
		label: 'Jendouba',
		countryLabel: 'Tunisia',
		countryValue: 'TN'
	},
	{
		value: '33',
		label: 'Le Kef',
		countryLabel: 'Tunisia',
		countryValue: 'TN'
	},
	{
		value: '34',
		label: 'Siliana',
		countryLabel: 'Tunisia',
		countryValue: 'TN'
	},
	{
		value: '41',
		label: 'Kairouan',
		countryLabel: 'Tunisia',
		countryValue: 'TN'
	},
	{
		value: '42',
		label: 'Kasserine',
		countryLabel: 'Tunisia',
		countryValue: 'TN'
	},
	{
		value: '43',
		label: 'Sidi Bouzid',
		countryLabel: 'Tunisia',
		countryValue: 'TN'
	},
	{
		value: '51',
		label: 'Sousse',
		countryLabel: 'Tunisia',
		countryValue: 'TN'
	},
	{
		value: '52',
		label: 'Monastir',
		countryLabel: 'Tunisia',
		countryValue: 'TN'
	},
	{
		value: '53',
		label: 'Mahdia',
		countryLabel: 'Tunisia',
		countryValue: 'TN'
	},
	{
		value: '61',
		label: 'Sfax',
		countryLabel: 'Tunisia',
		countryValue: 'TN'
	},
	{
		value: '71',
		label: 'Gafsa',
		countryLabel: 'Tunisia',
		countryValue: 'TN'
	},
	{
		value: '72',
		label: 'Tozeur',
		countryLabel: 'Tunisia',
		countryValue: 'TN'
	},
	{
		value: '73',
		label: 'Kebili',
		countryLabel: 'Tunisia',
		countryValue: 'TN'
	},
	{
		value: '81',
		label: 'Gabes',
		countryLabel: 'Tunisia',
		countryValue: 'TN'
	},
	{
		value: '82',
		label: 'Medenine',
		countryLabel: 'Tunisia',
		countryValue: 'TN'
	},
	{
		value: '83',
		label: 'Tataouine',
		countryLabel: 'Tunisia',
		countryValue: 'TN'
	},
	{
		value: '1',
		label: 'Adana',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '2',
		label: 'Adiyaman',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '3',
		label: 'Afyonkarahisar',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '4',
		label: 'Agri',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '5',
		label: 'Amasya',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '6',
		label: 'Ankara',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '7',
		label: 'Antalya',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '8',
		label: 'Artvin',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '9',
		label: 'Aydin',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '10',
		label: 'Balikesir',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '11',
		label: 'Bilecik',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '12',
		label: 'Bingol',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '13',
		label: 'Bitlis',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '14',
		label: 'Bolu',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '15',
		label: 'Burdur',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '16',
		label: 'Bursa',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '17',
		label: 'Canakkale',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '18',
		label: 'Cankiri',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '19',
		label: 'Corum',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '20',
		label: 'Denizli',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '21',
		label: 'Diyarbakir',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '22',
		label: 'Edirne',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '23',
		label: 'Elazig',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '24',
		label: 'Erzincan',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '25',
		label: 'Erzurum',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '26',
		label: 'Eskisehir',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '27',
		label: 'Gaziantep',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '28',
		label: 'Giresun',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '29',
		label: 'Gumushane',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '30',
		label: 'Hakkari',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '31',
		label: 'Hatay',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '32',
		label: 'Isparta',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '33',
		label: 'Mersin',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '34',
		label: 'Istanbul',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '35',
		label: 'Izmir',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '36',
		label: 'Kars',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '37',
		label: 'Kastamonu',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '38',
		label: 'Kayseri',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '39',
		label: 'Kirklareli',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '40',
		label: 'Kirsehir',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '41',
		label: 'Kocaeli',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '42',
		label: 'Konya',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '43',
		label: 'Kutahya',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '44',
		label: 'Malatya',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '45',
		label: 'Manisa',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '46',
		label: 'Kahramanmaras',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '47',
		label: 'Mardin',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '48',
		label: 'Mugla',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '49',
		label: 'Mus',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '50',
		label: 'Nevsehir',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '51',
		label: 'Nigde',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '52',
		label: 'Ordu',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '53',
		label: 'Rize',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '54',
		label: 'Sakarya',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '55',
		label: 'Samsun',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '56',
		label: 'Siirt',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '57',
		label: 'Sinop',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '58',
		label: 'Sivas',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '59',
		label: 'Tekirdag',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '60',
		label: 'Tokat',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '61',
		label: 'Trabzon',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '62',
		label: 'Tunceli',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '63',
		label: 'Sanliurfa',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '64',
		label: 'Usak',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '65',
		label: 'Van',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '66',
		label: 'Yozgat',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '67',
		label: 'Zonguldak',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '68',
		label: 'Aksaray',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '69',
		label: 'Bayburt',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '70',
		label: 'Karaman',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '71',
		label: 'Kirikkale',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '72',
		label: 'Batman',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '73',
		label: 'Sirnak',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '74',
		label: 'Bartin',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '75',
		label: 'Ardahan',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '76',
		label: 'Igdir',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '77',
		label: 'Yalova',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '78',
		label: 'Karabuk',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '79',
		label: 'Kilis',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '80',
		label: 'Osmaniye',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: '81',
		label: 'Duzce',
		countryLabel: 'Turkey',
		countryValue: 'TR'
	},
	{
		value: 'A',
		label: 'Ahal',
		countryLabel: 'Turkmenistan',
		countryValue: 'TM'
	},
	{
		value: 'B',
		label: 'Balkan',
		countryLabel: 'Turkmenistan',
		countryValue: 'TM'
	},
	{
		value: 'D',
		label: 'Dasoguz',
		countryLabel: 'Turkmenistan',
		countryValue: 'TM'
	},
	{
		value: 'L',
		label: 'Lebap',
		countryLabel: 'Turkmenistan',
		countryValue: 'TM'
	},
	{
		value: 'M',
		label: 'Mary',
		countryLabel: 'Turkmenistan',
		countryValue: 'TM'
	},
	{
		value: 'FUN',
		label: 'Funafuti',
		countryLabel: 'Tuvalu',
		countryValue: 'TV'
	},
	{
		value: '101',
		label: 'Kalangala',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '102',
		label: 'Kampala',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '103',
		label: 'Kiboga',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '104',
		label: 'Luwero',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '105',
		label: 'Masaka',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '106',
		label: 'Mpigi',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '107',
		label: 'Mubende',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '108',
		label: 'Mukono',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '109',
		label: 'Nakasongola',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '110',
		label: 'Rakai',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '111',
		label: 'Sembabule',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '112',
		label: 'Kayunga',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '113',
		label: 'Wakiso',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '114',
		label: 'Lyantonde',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '115',
		label: 'Mityana',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '116',
		label: 'Nakaseke',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '117',
		label: 'Buikwe',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '118',
		label: 'Bukomansibi',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '120',
		label: 'Buvuma',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '121',
		label: 'Gomba',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '122',
		label: 'Kalungu',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '123',
		label: 'Kyankwanzi',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '124',
		label: 'Lwengo',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '201',
		label: 'Bugiri',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '202',
		label: 'Busia',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '203',
		label: 'Iganga',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '204',
		label: 'Jinja',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '205',
		label: 'Kamuli',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '206',
		label: 'Kapchorwa',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '207',
		label: 'Katakwi',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '208',
		label: 'Kumi',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '209',
		label: 'Mbale',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '210',
		label: 'Pallisa',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '211',
		label: 'Soroti',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '212',
		label: 'Tororo',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '213',
		label: 'Kaberamaido',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '214',
		label: 'Mayuge',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '215',
		label: 'Sironko',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '216',
		label: 'Amuria',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '217',
		label: 'Budaka',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '218',
		label: 'Bududa',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '219',
		label: 'Bukedea',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '220',
		label: 'Bukwo',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '221',
		label: 'Butaleja',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '222',
		label: 'Kaliro',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '223',
		label: 'Manafwa',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '224',
		label: 'Namutumba',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '225',
		label: 'Bulambuli',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '226',
		label: 'Buyende',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '227',
		label: 'Kibuku',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '228',
		label: 'Kween',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '229',
		label: 'Luuka',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '230',
		label: 'Namayingo',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '231',
		label: 'Ngora',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '232',
		label: 'Serere',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '301',
		label: 'Adjumani',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '302',
		label: 'Apac',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '303',
		label: 'Arua',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '304',
		label: 'Gulu',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '305',
		label: 'Kitgum',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '306',
		label: 'Kotido',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '307',
		label: 'Lira',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '308',
		label: 'Moroto',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '309',
		label: 'Moyo',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '310',
		label: 'Nebbi',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '311',
		label: 'Nakapiripirit',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '312',
		label: 'Pader',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '313',
		label: 'Yumbe',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '314',
		label: 'Abim',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '315',
		label: 'Amolatar',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '316',
		label: 'Amuru',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '317',
		label: 'Dokolo',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '318',
		label: 'Kaabong',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '319',
		label: 'Koboko',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '321',
		label: 'Oyam',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '322',
		label: 'Agago',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '323',
		label: 'Alebtong',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '324',
		label: 'Amudat',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '326',
		label: 'Lamwo',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '327',
		label: 'Napak',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '328',
		label: 'Nwoya',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '330',
		label: 'Zombo',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '401',
		label: 'Bundibugyo',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '402',
		label: 'Bushenyi',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '403',
		label: 'Hoima',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '404',
		label: 'Kabale',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '405',
		label: 'Kabarole',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '406',
		label: 'Kasese',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '407',
		label: 'Kibaale',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '408',
		label: 'Kisoro',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '409',
		label: 'Masindi',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '410',
		label: 'Mbarara',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '411',
		label: 'Ntungamo',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '412',
		label: 'Rukungiri',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '413',
		label: 'Kamwenge',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '415',
		label: 'Kyenjojo',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '417',
		label: 'Ibanda',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '418',
		label: 'Isingiro',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '419',
		label: 'Kiruhura',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '420',
		label: 'Buhweju',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '421',
		label: 'Kiryandongo',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '422',
		label: 'Kyegegwa',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '423',
		label: 'Mitooma',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '425',
		label: 'Rubirizi',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '426',
		label: 'Sheema',
		countryLabel: 'Uganda',
		countryValue: 'UG'
	},
	{
		value: '5',
		label: 'Vinnytska oblast',
		countryLabel: 'Ukraine',
		countryValue: 'UA'
	},
	{
		value: '7',
		label: 'Volynska oblast',
		countryLabel: 'Ukraine',
		countryValue: 'UA'
	},
	{
		value: '9',
		label: 'Luhanska oblast',
		countryLabel: 'Ukraine',
		countryValue: 'UA'
	},
	{
		value: '12',
		label: 'Dnipropetrovska oblast',
		countryLabel: 'Ukraine',
		countryValue: 'UA'
	},
	{
		value: '14',
		label: 'Donetska oblast',
		countryLabel: 'Ukraine',
		countryValue: 'UA'
	},
	{
		value: '18',
		label: 'Zhytomyrska oblast',
		countryLabel: 'Ukraine',
		countryValue: 'UA'
	},
	{
		value: '21',
		label: 'Zakarpatska oblast',
		countryLabel: 'Ukraine',
		countryValue: 'UA'
	},
	{
		value: '23',
		label: 'Zaporizka oblast',
		countryLabel: 'Ukraine',
		countryValue: 'UA'
	},
	{
		value: '26',
		label: 'Ivano-Frankivska oblast',
		countryLabel: 'Ukraine',
		countryValue: 'UA'
	},
	{
		value: '30',
		label: 'Kyiv',
		countryLabel: 'Ukraine',
		countryValue: 'UA'
	},
	{
		value: '32',
		label: 'Kyivska oblast',
		countryLabel: 'Ukraine',
		countryValue: 'UA'
	},
	{
		value: '35',
		label: 'Kirovohradska oblast',
		countryLabel: 'Ukraine',
		countryValue: 'UA'
	},
	{
		value: '40',
		label: 'Sevastopol',
		countryLabel: 'Ukraine',
		countryValue: 'UA'
	},
	{
		value: '43',
		label: 'Avtonomna Respublika Krym',
		countryLabel: 'Ukraine',
		countryValue: 'UA'
	},
	{
		value: '46',
		label: 'Lvivska oblast',
		countryLabel: 'Ukraine',
		countryValue: 'UA'
	},
	{
		value: '48',
		label: 'Mykolaivska oblast',
		countryLabel: 'Ukraine',
		countryValue: 'UA'
	},
	{
		value: '51',
		label: 'Odeska oblast',
		countryLabel: 'Ukraine',
		countryValue: 'UA'
	},
	{
		value: '53',
		label: 'Poltavska oblast',
		countryLabel: 'Ukraine',
		countryValue: 'UA'
	},
	{
		value: '56',
		label: 'Rivnenska oblast',
		countryLabel: 'Ukraine',
		countryValue: 'UA'
	},
	{
		value: '59',
		label: 'Sumska oblast',
		countryLabel: 'Ukraine',
		countryValue: 'UA'
	},
	{
		value: '61',
		label: 'Ternopilska oblast',
		countryLabel: 'Ukraine',
		countryValue: 'UA'
	},
	{
		value: '63',
		label: 'Kharkivska oblast',
		countryLabel: 'Ukraine',
		countryValue: 'UA'
	},
	{
		value: '65',
		label: 'Khersonska oblast',
		countryLabel: 'Ukraine',
		countryValue: 'UA'
	},
	{
		value: '68',
		label: 'Khmelnytska oblast',
		countryLabel: 'Ukraine',
		countryValue: 'UA'
	},
	{
		value: '71',
		label: 'Cherkaska oblast',
		countryLabel: 'Ukraine',
		countryValue: 'UA'
	},
	{
		value: '74',
		label: 'Chernihivska oblast',
		countryLabel: 'Ukraine',
		countryValue: 'UA'
	},
	{
		value: '77',
		label: 'Chernivetska oblast',
		countryLabel: 'Ukraine',
		countryValue: 'UA'
	},
	{
		value: 'AJ',
		label: 'Ajman',
		countryLabel: 'United Arab Emirates',
		countryValue: 'AE'
	},
	{
		value: 'AZ',
		label: 'Abu Zaby',
		countryLabel: 'United Arab Emirates',
		countryValue: 'AE'
	},
	{
		value: 'DU',
		label: 'Dubayy',
		countryLabel: 'United Arab Emirates',
		countryValue: 'AE'
	},
	{
		value: 'FU',
		label: 'Al Fujayrah',
		countryLabel: 'United Arab Emirates',
		countryValue: 'AE'
	},
	{
		value: 'RK',
		label: "Ra's al Khaymah",
		countryLabel: 'United Arab Emirates',
		countryValue: 'AE'
	},
	{
		value: 'SH',
		label: 'Ash Shariqah',
		countryLabel: 'United Arab Emirates',
		countryValue: 'AE'
	},
	{
		value: 'UQ',
		label: 'Umm al Qaywayn',
		countryLabel: 'United Arab Emirates',
		countryValue: 'AE'
	},
	{
		value: 'ENG',
		label: 'England',
		countryLabel: 'United Kingdom',
		countryValue: 'GB'
	},
	{
		value: 'NIR',
		label: 'Northern Ireland',
		countryLabel: 'United Kingdom',
		countryValue: 'GB'
	},
	{
		value: 'SCT',
		label: 'Scotland',
		countryLabel: 'United Kingdom',
		countryValue: 'GB'
	},
	{
		value: 'WLS',
		label: 'Wales',
		countryLabel: 'United Kingdom',
		countryValue: 'GB'
	},
	{
		value: 'AA',
		label: 'Armed Forces Americas',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'AE',
		label: 'Armed Forces Europe, Middle East, Africa and Canada',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'AK',
		label: 'Alaska',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'AL',
		label: 'Alabama',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'AP',
		label: 'Armed Forces Pacific',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'AR',
		label: 'Arkansas',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'AZ',
		label: 'Arizona',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'CA',
		label: 'California',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'CO',
		label: 'Colorado',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'CT',
		label: 'Connecticut',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'DC',
		label: 'District of Columbia',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'DE',
		label: 'Delaware',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'FL',
		label: 'Florida',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'GA',
		label: 'Georgia',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'GU',
		label: 'Guam territory',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'HI',
		label: 'Hawaii',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'IA',
		label: 'Iowa',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'ID',
		label: 'Idaho',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'IL',
		label: 'Illinois',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'IN',
		label: 'Indiana',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'KS',
		label: 'Kansas',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'KY',
		label: 'Kentucky',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'LA',
		label: 'Louisiana',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'MA',
		label: 'Massachusetts',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'MD',
		label: 'Maryland',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'ME',
		label: 'Maine',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'MI',
		label: 'Michigan',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'MN',
		label: 'Minnesota',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'MO',
		label: 'Missouri',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'MS',
		label: 'Mississippi',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'MT',
		label: 'Montana',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'NC',
		label: 'North Carolina',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'ND',
		label: 'North Dakota',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'NE',
		label: 'Nebraska',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'NH',
		label: 'New Hampshire',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'NJ',
		label: 'New Jersey',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'NM',
		label: 'New Mexico',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'NV',
		label: 'Nevada',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'NY',
		label: 'New York',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'OH',
		label: 'Ohio',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'OK',
		label: 'Oklahoma',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'OR',
		label: 'Oregon',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'PA',
		label: 'Pennsylvania',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'RI',
		label: 'Rhode Island',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'SC',
		label: 'South Carolina',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'SD',
		label: 'South Dakota',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'TN',
		label: 'Tennessee',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'TX',
		label: 'Texas',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'UT',
		label: 'Utah',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'VA',
		label: 'Virginia',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'VT',
		label: 'Vermont',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'WA',
		label: 'Washington',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'WI',
		label: 'Wisconsin',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'WV',
		label: 'West Virginia',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'WY',
		label: 'Wyoming',
		countryLabel: 'United States',
		countryValue: 'US'
	},
	{
		value: 'AR',
		label: 'Artigas',
		countryLabel: 'Uruguay',
		countryValue: 'UY'
	},
	{
		value: 'CA',
		label: 'Canelones',
		countryLabel: 'Uruguay',
		countryValue: 'UY'
	},
	{
		value: 'CL',
		label: 'Cerro Largo',
		countryLabel: 'Uruguay',
		countryValue: 'UY'
	},
	{
		value: 'CO',
		label: 'Colonia',
		countryLabel: 'Uruguay',
		countryValue: 'UY'
	},
	{
		value: 'DU',
		label: 'Durazno',
		countryLabel: 'Uruguay',
		countryValue: 'UY'
	},
	{
		value: 'FD',
		label: 'Florida',
		countryLabel: 'Uruguay',
		countryValue: 'UY'
	},
	{
		value: 'FS',
		label: 'Flores',
		countryLabel: 'Uruguay',
		countryValue: 'UY'
	},
	{
		value: 'LA',
		label: 'Lavalleja',
		countryLabel: 'Uruguay',
		countryValue: 'UY'
	},
	{
		value: 'MA',
		label: 'Maldonado',
		countryLabel: 'Uruguay',
		countryValue: 'UY'
	},
	{
		value: 'MO',
		label: 'Montevideo',
		countryLabel: 'Uruguay',
		countryValue: 'UY'
	},
	{
		value: 'PA',
		label: 'Paysandu',
		countryLabel: 'Uruguay',
		countryValue: 'UY'
	},
	{
		value: 'RN',
		label: 'Rio Negro',
		countryLabel: 'Uruguay',
		countryValue: 'UY'
	},
	{
		value: 'RO',
		label: 'Rocha',
		countryLabel: 'Uruguay',
		countryValue: 'UY'
	},
	{
		value: 'RV',
		label: 'Rivera',
		countryLabel: 'Uruguay',
		countryValue: 'UY'
	},
	{
		value: 'SA',
		label: 'Salto',
		countryLabel: 'Uruguay',
		countryValue: 'UY'
	},
	{
		value: 'SJ',
		label: 'San Jose',
		countryLabel: 'Uruguay',
		countryValue: 'UY'
	},
	{
		value: 'SO',
		label: 'Soriano',
		countryLabel: 'Uruguay',
		countryValue: 'UY'
	},
	{
		value: 'TA',
		label: 'Tacuarembo',
		countryLabel: 'Uruguay',
		countryValue: 'UY'
	},
	{
		value: 'TT',
		label: 'Treinta y Tres',
		countryLabel: 'Uruguay',
		countryValue: 'UY'
	},
	{
		value: '95',
		label: 'Palmyra Atoll',
		countryLabel: 'US minor outlying islands',
		countryValue: 'UM'
	},
	{
		value: 'AN',
		label: 'Andijon',
		countryLabel: 'Uzbekistan',
		countryValue: 'UZ'
	},
	{
		value: 'BU',
		label: 'Buxoro',
		countryLabel: 'Uzbekistan',
		countryValue: 'UZ'
	},
	{
		value: 'FA',
		label: "Farg'ona",
		countryLabel: 'Uzbekistan',
		countryValue: 'UZ'
	},
	{
		value: 'JI',
		label: 'Jizzax',
		countryLabel: 'Uzbekistan',
		countryValue: 'UZ'
	},
	{
		value: 'NG',
		label: 'Namangan',
		countryLabel: 'Uzbekistan',
		countryValue: 'UZ'
	},
	{
		value: 'NW',
		label: 'Navoiy',
		countryLabel: 'Uzbekistan',
		countryValue: 'UZ'
	},
	{
		value: 'QA',
		label: 'Qashqadaryo',
		countryLabel: 'Uzbekistan',
		countryValue: 'UZ'
	},
	{
		value: 'QR',
		label: "Qoraqalpog'iston Respublikasi",
		countryLabel: 'Uzbekistan',
		countryValue: 'UZ'
	},
	{
		value: 'SA',
		label: 'Samarqand',
		countryLabel: 'Uzbekistan',
		countryValue: 'UZ'
	},
	{
		value: 'SI',
		label: 'Sirdaryo',
		countryLabel: 'Uzbekistan',
		countryValue: 'UZ'
	},
	{
		value: 'SU',
		label: 'Surxondaryo',
		countryLabel: 'Uzbekistan',
		countryValue: 'UZ'
	},
	{
		value: 'TK',
		label: 'Toshkent',
		countryLabel: 'Uzbekistan',
		countryValue: 'UZ'
	},
	{
		value: 'XO',
		label: 'Xorazm',
		countryLabel: 'Uzbekistan',
		countryValue: 'UZ'
	},
	{
		value: 'SEE',
		label: 'Shefa',
		countryLabel: 'Vanuatu',
		countryValue: 'VU'
	},
	{
		value: 'TAE',
		label: 'Tafea',
		countryLabel: 'Vanuatu',
		countryValue: 'VU'
	},
	{
		value: 'TOB',
		label: 'Torba',
		countryLabel: 'Vanuatu',
		countryValue: 'VU'
	},
	{
		value: 'A',
		label: 'Distrito Capital',
		countryLabel: 'Venezuela',
		countryValue: 'VE'
	},
	{
		value: 'B',
		label: 'Anzoategui',
		countryLabel: 'Venezuela',
		countryValue: 'VE'
	},
	{
		value: 'C',
		label: 'Apure',
		countryLabel: 'Venezuela',
		countryValue: 'VE'
	},
	{
		value: 'D',
		label: 'Aragua',
		countryLabel: 'Venezuela',
		countryValue: 'VE'
	},
	{
		value: 'E',
		label: 'Barinas',
		countryLabel: 'Venezuela',
		countryValue: 'VE'
	},
	{
		value: 'F',
		label: 'Bolivar',
		countryLabel: 'Venezuela',
		countryValue: 'VE'
	},
	{
		value: 'G',
		label: 'Carabobo',
		countryLabel: 'Venezuela',
		countryValue: 'VE'
	},
	{
		value: 'H',
		label: 'Cojedes',
		countryLabel: 'Venezuela',
		countryValue: 'VE'
	},
	{
		value: 'I',
		label: 'Falcon',
		countryLabel: 'Venezuela',
		countryValue: 'VE'
	},
	{
		value: 'J',
		label: 'Guarico',
		countryLabel: 'Venezuela',
		countryValue: 'VE'
	},
	{
		value: 'K',
		label: 'Lara',
		countryLabel: 'Venezuela',
		countryValue: 'VE'
	},
	{
		value: 'L',
		label: 'Merida',
		countryLabel: 'Venezuela',
		countryValue: 'VE'
	},
	{
		value: 'M',
		label: 'Miranda',
		countryLabel: 'Venezuela',
		countryValue: 'VE'
	},
	{
		value: 'N',
		label: 'Monagas',
		countryLabel: 'Venezuela',
		countryValue: 'VE'
	},
	{
		value: 'O',
		label: 'Nueva Esparta',
		countryLabel: 'Venezuela',
		countryValue: 'VE'
	},
	{
		value: 'P',
		label: 'Portuguesa',
		countryLabel: 'Venezuela',
		countryValue: 'VE'
	},
	{
		value: 'R',
		label: 'Sucre',
		countryLabel: 'Venezuela',
		countryValue: 'VE'
	},
	{
		value: 'S',
		label: 'Tachira',
		countryLabel: 'Venezuela',
		countryValue: 'VE'
	},
	{
		value: 'T',
		label: 'Trujillo',
		countryLabel: 'Venezuela',
		countryValue: 'VE'
	},
	{
		value: 'U',
		label: 'Yaracuy',
		countryLabel: 'Venezuela',
		countryValue: 'VE'
	},
	{
		value: 'V',
		label: 'Zulia',
		countryLabel: 'Venezuela',
		countryValue: 'VE'
	},
	{
		value: 'X',
		label: 'La Guaira',
		countryLabel: 'Venezuela',
		countryValue: 'VE'
	},
	{
		value: 'Y',
		label: 'Delta Amacuro',
		countryLabel: 'Venezuela',
		countryValue: 'VE'
	},
	{
		value: 'Z',
		label: 'Amazonas',
		countryLabel: 'Venezuela',
		countryValue: 'VE'
	},
	{
		value: '1',
		label: 'Lai Chau',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '2',
		label: 'Lao Cai',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '3',
		label: 'Ha Giang',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '4',
		label: 'Cao Bang',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '5',
		label: 'Son La',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '6',
		label: 'Yen Bai',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '7',
		label: 'Tuyen Quang',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '9',
		label: 'Lang Son',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '13',
		label: 'Quang Ninh',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '14',
		label: 'Hoa Binh',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '18',
		label: 'Ninh Binh',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '20',
		label: 'Thai Binh',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '21',
		label: 'Thanh Hoa',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '22',
		label: 'Nghe An',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '23',
		label: 'Ha Tinh',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '24',
		label: 'Quang Binh',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '25',
		label: 'Quang Tri',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '26',
		label: 'Thua Thien-Hue',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '27',
		label: 'Quang Nam',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '28',
		label: 'Kon Tum',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '29',
		label: 'Quang Ngai',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '30',
		label: 'Gia Lai',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '31',
		label: 'Binh Dinh',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '32',
		label: 'Phu Yen',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '33',
		label: 'Dak Lak',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '34',
		label: 'Khanh Hoa',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '35',
		label: 'Lam Dong',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '36',
		label: 'Ninh Thuan',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '37',
		label: 'Tay Ninh',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '39',
		label: 'Dong Nai',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '40',
		label: 'Binh Thuan',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '41',
		label: 'Long An',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '43',
		label: 'Ba Ria - Vung Tau',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '44',
		label: 'An Giang',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '45',
		label: 'Dong Thap',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '46',
		label: 'Tien Giang',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '47',
		label: 'Kien Giang',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '49',
		label: 'Vinh Long',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '50',
		label: 'Ben Tre',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '51',
		label: 'Tra Vinh',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '52',
		label: 'Soc Trang',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '53',
		label: 'Bac Kan',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '54',
		label: 'Bac Giang',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '55',
		label: 'Bac Lieu',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '56',
		label: 'Bac Ninh',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '57',
		label: 'Binh Duong',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '58',
		label: 'Binh Phuoc',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '59',
		label: 'Ca Mau',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '61',
		label: 'Hai Duong',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '63',
		label: 'Ha Nam',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '66',
		label: 'Hung Yen',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '67',
		label: 'Nam Dinh',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '68',
		label: 'Phu Tho',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '69',
		label: 'Thai Nguyen',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '70',
		label: 'Vinh Phuc',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '71',
		label: 'Dien Bien',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '72',
		label: 'Dak Nong',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: '73',
		label: 'Hau Giang',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: 'CT',
		label: 'Can Tho',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: 'DN',
		label: 'Da Nang',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: 'HN',
		label: 'Ha Noi',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: 'HP',
		label: 'Hai Phong',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: 'SG',
		label: 'Ho Chi Minh',
		countryLabel: 'Vietnam',
		countryValue: 'VN'
	},
	{
		value: 'SG',
		label: 'Sigave',
		countryLabel: 'Wallis and futuna',
		countryValue: 'WF'
	},
	{
		value: 'UV',
		label: 'Uvea',
		countryLabel: 'Wallis and futuna',
		countryValue: 'WF'
	},
	{
		value: 'AT',
		label: 'Atua',
		countryLabel: 'Western Samoa',
		countryValue: 'WS'
	},
	{
		value: 'FA',
		label: "Fa'asaleleaga",
		countryLabel: 'Western Samoa',
		countryValue: 'WS'
	},
	{
		value: 'TU',
		label: 'Tuamasaga',
		countryLabel: 'Western Samoa',
		countryValue: 'WS'
	},
	{
		value: 'AD',
		label: 'Adan',
		countryLabel: 'Yemen',
		countryValue: 'YE'
	},
	{
		value: 'AM',
		label: 'Amran',
		countryLabel: 'Yemen',
		countryValue: 'YE'
	},
	{
		value: 'BA',
		label: "Al Bayda'",
		countryLabel: 'Yemen',
		countryValue: 'YE'
	},
	{
		value: 'DA',
		label: "Ad Dali'",
		countryLabel: 'Yemen',
		countryValue: 'YE'
	},
	{
		value: 'DH',
		label: 'Dhamar',
		countryLabel: 'Yemen',
		countryValue: 'YE'
	},
	{
		value: 'HD',
		label: 'Hadramawt',
		countryLabel: 'Yemen',
		countryValue: 'YE'
	},
	{
		value: 'HJ',
		label: 'Hajjah',
		countryLabel: 'Yemen',
		countryValue: 'YE'
	},
	{
		value: 'HU',
		label: 'Al Hudaydah',
		countryLabel: 'Yemen',
		countryValue: 'YE'
	},
	{
		value: 'IB',
		label: 'Ibb',
		countryLabel: 'Yemen',
		countryValue: 'YE'
	},
	{
		value: 'LA',
		label: 'Lahij',
		countryLabel: 'Yemen',
		countryValue: 'YE'
	},
	{
		value: 'MA',
		label: "Ma'rib",
		countryLabel: 'Yemen',
		countryValue: 'YE'
	},
	{
		value: 'MR',
		label: 'Al Mahrah',
		countryLabel: 'Yemen',
		countryValue: 'YE'
	},
	{
		value: 'MW',
		label: 'Al Mahwit',
		countryLabel: 'Yemen',
		countryValue: 'YE'
	},
	{
		value: 'SA',
		label: "Amanat al 'Asimah",
		countryLabel: 'Yemen',
		countryValue: 'YE'
	},
	{
		value: 'SD',
		label: "Sa'dah",
		countryLabel: 'Yemen',
		countryValue: 'YE'
	},
	{
		value: 'SH',
		label: 'Shabwah',
		countryLabel: 'Yemen',
		countryValue: 'YE'
	},
	{
		value: 'SN',
		label: "San'a'",
		countryLabel: 'Yemen',
		countryValue: 'YE'
	},
	{
		value: 'TA',
		label: "Ta'izz",
		countryLabel: 'Yemen',
		countryValue: 'YE'
	},
	{
		value: '1',
		label: 'Western',
		countryLabel: 'Zambia',
		countryValue: 'ZM'
	},
	{
		value: '2',
		label: 'Central',
		countryLabel: 'Zambia',
		countryValue: 'ZM'
	},
	{
		value: '3',
		label: 'Eastern',
		countryLabel: 'Zambia',
		countryValue: 'ZM'
	},
	{
		value: '4',
		label: 'Luapula',
		countryLabel: 'Zambia',
		countryValue: 'ZM'
	},
	{
		value: '5',
		label: 'Northern',
		countryLabel: 'Zambia',
		countryValue: 'ZM'
	},
	{
		value: '6',
		label: 'North-Western',
		countryLabel: 'Zambia',
		countryValue: 'ZM'
	},
	{
		value: '7',
		label: 'Southern',
		countryLabel: 'Zambia',
		countryValue: 'ZM'
	},
	{
		value: '8',
		label: 'Copperbelt',
		countryLabel: 'Zambia',
		countryValue: 'ZM'
	},
	{
		value: '9',
		label: 'Lusaka',
		countryLabel: 'Zambia',
		countryValue: 'ZM'
	},
	{
		value: '10',
		label: 'Muchinga',
		countryLabel: 'Zambia',
		countryValue: 'ZM'
	},
	{
		value: 'BU',
		label: 'Bulawayo',
		countryLabel: 'Zimbabwe',
		countryValue: 'ZW'
	},
	{
		value: 'HA',
		label: 'Harare',
		countryLabel: 'Zimbabwe',
		countryValue: 'ZW'
	},
	{
		value: 'MA',
		label: 'Manicaland',
		countryLabel: 'Zimbabwe',
		countryValue: 'ZW'
	},
	{
		value: 'MC',
		label: 'Mashonaland Central',
		countryLabel: 'Zimbabwe',
		countryValue: 'ZW'
	},
	{
		value: 'ME',
		label: 'Mashonaland East',
		countryLabel: 'Zimbabwe',
		countryValue: 'ZW'
	},
	{
		value: 'MI',
		label: 'Midlands',
		countryLabel: 'Zimbabwe',
		countryValue: 'ZW'
	},
	{
		value: 'MN',
		label: 'Matabeleland North',
		countryLabel: 'Zimbabwe',
		countryValue: 'ZW'
	},
	{
		value: 'MS',
		label: 'Matabeleland South',
		countryLabel: 'Zimbabwe',
		countryValue: 'ZW'
	},
	{
		value: 'MV',
		label: 'Masvingo',
		countryLabel: 'Zimbabwe',
		countryValue: 'ZW'
	},
	{
		value: 'MW',
		label: 'Mashonaland West',
		countryLabel: 'Zimbabwe',
		countryValue: 'ZW'
	}
];
