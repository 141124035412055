import React, {ReactNode} from 'react';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';
import {isMobile} from 'src/utils';
import {Typography} from 'src/components/@shared';
import {ReactComponent as BanqIcon} from 'src/assets/svg/banq.svg';
import theme from 'src/theme';

const useStyles = makeStyles(() => ({
	root: () => ({
		display: 'flex',
		flexDirection: 'row',
		padding: '10px 0',
		width: '100%'
	}),
	avatar: {
		display: 'flex',
		alignItems: 'center'
	},
	info: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	verticalSpace: {
		marginTop: 5
	},
	target: {
		textTransform: 'uppercase',
		color: theme.colors.black50
	},
	alignVertically: {
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		marginTop: 0
	},
	title: {marginTop: 5, whiteSpace: 'nowrap'},
	subtitle: {
		marginTop: 5,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center'
	},
	logo: {
		marginRight: 9,
		position: 'relative',
		top: isMobile ? 0 : 2,
		fill: theme.colors.black
	},
	clickable: {
		cursor: 'pointer'
	}
}));

interface Props {
	title: string;
	avatar?: ReactNode;
	accountName?: string;
	accountPayName?: string;
	endAdornment?: ReactNode;
	onClick?: () => void;
}
const BanqCard = ({title, endAdornment, avatar, accountName, accountPayName, onClick}: Props) => {
	const classes = useStyles();

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
		<div className={clsx(classes.root, {[classes.clickable]: Boolean(onClick)})} onClick={onClick}>
			{avatar && <div className={clsx(classes.avatar, classes.verticalSpace)}>{avatar}</div>}
			<div className={classes.info}>
				<div>
					<Typography
						className={clsx(classes.target, classes.verticalSpace, {
							[classes.alignVertically]: !accountName && !accountPayName
						})}
						lineHeight={14}>
						{title}
					</Typography>
					{accountName && (
						<Typography className={clsx(classes.title, classes.verticalSpace)} size={16} weight="semi-bold">
							{accountName}
						</Typography>
					)}
					{accountPayName && (
						<div className={clsx(classes.subtitle, classes.verticalSpace)}>
							<BanqIcon className={classes.logo} />
							<Typography size={16}>{accountPayName}</Typography>
						</div>
					)}
				</div>
				{endAdornment}
			</div>
		</div>
	);
};

export default BanqCard;
