import {produce} from 'immer';

import {Actions} from 'src/store/constants';
import {PaymentStatus} from 'src/api';
import {
	AcceptPaymentErrorAction,
	AcceptPaymentSuccessAction,
	LoadPaymentInfoAction,
	LoadPaymentInfoSuccessAction,
	DeclinePaymentSuccessAction,
	DeclinePaymentErrorAction,
	SetIsExpiredAction
} from './actions';

type PaymentActions =
	| LoadPaymentInfoSuccessAction
	| LoadPaymentInfoAction
	| AcceptPaymentSuccessAction
	| AcceptPaymentErrorAction
	| DeclinePaymentSuccessAction
	| DeclinePaymentErrorAction
	| SetIsExpiredAction;

const initialState = {
	isExpired: null,
	paymentStatus: 'pending'
};

const paymentReducer = produce((state, action: PaymentActions) => {
	switch (action.type) {
		case Actions.LOAD_PAYMENT_INFO_SUCCESS:
			return {
				...state,
				...action.payload,
				paymentStatus: action.payload.status
			};
		case Actions.ACCEPT_PAYMENT_SUCCESS:
			return {
				...state,
				paymentStatus: PaymentStatus.isCompleted
			};
		case Actions.ACCEPT_PAYMENT_ERROR:
			return {
				...state,
				paymentStatus: PaymentStatus.isFailed
			};
		case Actions.DECLINE_PAYMENT_SUCCESS:
			return {
				...state,
				paymentStatus: PaymentStatus.isDeclined
			};
		case Actions.DECLINE_PAYMENT_ERROR:
			return {
				...state,
				paymentStatus: PaymentStatus.isFailed
			};
		case Actions.SET_IS_EXPIRED:
			return {
				...state,
				isExpired: true
			};
	}
}, initialState);

export default paymentReducer;
