export interface PaymentInfoI {
	//TODO: re-write as js types
	id: 0;
	guid: '3fa85f64-5717-4562-b3fc-2c963f66afa6';
	createdAt: '2021-11-24T14:38:06.394Z';
	updatedAt: '2021-11-24T14:38:06.394Z';
	name: 'string';
	status: 'pending';
	createdBy: '3fa85f64-5717-4562-b3fc-2c963f66afa6';
	performedBy: '3fa85f64-5717-4562-b3fc-2c963f66afa6';
	endDate: '2021-11-24';
	startDate: '2021-11-24';
	frequency: 'once';
	flowType: 'default';
	expiresAt: '2021-11-24T14:38:06.394Z';
	type: 'move';
	moveType: 'internal';
	disbursementType: 'notSpecified';
	contributionType: 'wire';
	reference: 'string';
	externalOrderCode: 'string';
	returnUrl: 'string';
	d3RedirectUrl: 'string';
	unitCount: 0;
	assetOperation: 'none';
	asset: 'btc';
	tokenDataId: '3fa85f64-5717-4562-b3fc-2c963f66afa6';
	nftName: 'string';
	nftDescription: 'string';
	nftAmount: 0;
	mediaType: 'string';
	nftOperation: 'none';
	source: {
		accountId: number;
		banq: {
			id: 0;
			banqGuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6';
			custodialNumber: 0;
			banqName: 'string';
			banqNickName: 'string';
			transactionName: 'string';
		};
		externalAccount: {
			id: 0;
			type: 'bank';
			bankName: 'string';
			cardType: 'debit';
			paymentSystem: 'visa';
			last4: 'string';
		};
		avatar: {
			type: 'string';
			guid: '3fa85f64-5717-4562-b3fc-2c963f66afa6';
			status: 'uploadPending';
			level: 'notUsed';
			uploadUrl: 'string';
			imageUrls: {
				additionalProp1: 'string';
				additionalProp2: 'string';
				additionalProp3: 'string';
			};
		};
		merchantName: 'string';
		accountType: 'string';
		type: 'banq';
		externalAccountId: 0;
		banqGuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6';
	};
	destination: {
		accountId: 0;
		banq: {
			id: 0;
			banqGuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6';
			custodialNumber: 0;
			banqName: 'string';
			banqNickName: 'string';
			transactionName: 'string';
		};
		externalAccount: {
			id: 0;
			type: 'bank';
			bankName: 'string';
			cardType: 'debit';
			paymentSystem: 'visa';
			last4: 'string';
		};
		avatar: {
			type: 'string';
			guid: '3fa85f64-5717-4562-b3fc-2c963f66afa6';
			status: 'uploadPending';
			level: 'notUsed';
			uploadUrl: 'string';
			imageUrls: {
				additionalProp1: 'string';
				additionalProp2: 'string';
				additionalProp3: 'string';
			};
		};
		merchantName: 'string';
		accountType: 'string';
		type: 'banq';
		externalAccountId: 0;
		banqGuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6';
	};
	comment: 'string';
	funds: 0;
	tips: 0;
	repeatTip: true;
}

export interface PaymentInfoErrorI {
	isExpired: boolean;
	status: string;
}

export interface PaymentSuccessI {
	isCompleted: boolean;
	status: string;
}

export enum PaymentStatus {
	isPending = 'pending',
	isCompleted = 'completed',
	isDeclined = 'declined',
	isFailed = 'failed',
	isExpired = 'expired',
	isPendingInBlockchainNetwork = 'pendingInBlockchainNetwork'
}
