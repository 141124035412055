import clsx from 'clsx';
import React, {ReactNode} from 'react';
import Measure, {BoundingRect} from 'react-measure';

import {useStyles} from './ScreenLayout.styles';

interface PropsI {
	className?: string;
	children: ReactNode;
}

const resize = (dimensions: BoundingRect | undefined): void => {
	if (!dimensions) return;
	if (!dimensions.width && !dimensions.height) return;
};

export const ScreenLayout = ({className, children}: PropsI) => {
	const classes = useStyles();

	return (
		<Measure bounds onResize={(contentRect) => resize(contentRect.bounds)}>
			{({measureRef}) => (
				<div ref={measureRef} className={clsx(classes.root, className)}>
					<div className={classes.contentWrapper}>{children}</div>
				</div>
			)}
		</Measure>
	);
};
