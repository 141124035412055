import {AssetBalance, Avatar} from './users';

export enum AccountSpaceType {
	merchantAccountSpace = 'merchant-account-space',
	personalAccountSpace = 'personal-account-space'
}

export type BanqAccount = {
	id: number;
	guid: string;
	payId?: number;
	banqGuid: string;
	accountSpaceId: number;
	accountSpaceType?: AccountSpaceType;
	activeInvitesCount?: number;
	adminUsersCount?: number;
	balance?: number;
	displayName: string;
	isNameSearchable?: boolean;
	isPrimary?: boolean;
	nickName?: string;
	payName?: string;
	type?: string;
	useTransactionName?: boolean;
	transactionName?: string;
	avatar?: Avatar;
	assetBalances?: AssetBalance[];
	industryIds: number[];
};
