import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {DialogConfirm} from '..';
import {selectPaymentInfo} from 'src/store/payment/selectors';
import {selectNftMedia} from 'src/store/nft/selectors';
import {createSpaceByBanqIdSelector} from 'src/store/spaces/selectors';
import {Button, NFTimageBox, ScreenLayout, ViewTitle} from 'src/components/@shared';
import {SourceBanqCardContainer, PaymentOptionsPopup} from 'src/components';
import {useStyles} from './TransferView.styles';
import {selectCardFee} from 'src/store/cardFees/selectors';
import {loadPaymentFees} from 'src/store/cardFees/actions';
import {PaymentInfoI, FeeTypes} from 'src/api/types';
import {selectLastAddedExternalAccountId, createChosenExternalAccount} from 'src/store/externalAccounts/selectors';
import {PopupPagesEnum} from 'src/components/addPaymentOptionPopUp';

interface PropsI {
	onSubmit: () => void;
	onDecline: () => void;
}

export const TransferView = ({onSubmit, onDecline}: PropsI) => {
	const dispatch = useDispatch();
	const {source, destination, funds, ...paymentInfo} = useSelector(selectPaymentInfo) as PaymentInfoI;
	const lastAddedExternalAccount = useSelector(selectLastAddedExternalAccountId);
	const spaceId = useSelector(createSpaceByBanqIdSelector(source?.banq?.id))?.id;
	const reselectedExternalAccount = useSelector(createChosenExternalAccount(spaceId)); // 162098
	const selectedExternalAccount = reselectedExternalAccount || lastAddedExternalAccount;
	const [isDeclineModal, setIsDeclineModal] = useState<boolean>(false);
	const [isOptionsPopupOpen, setIsOptionsPopupOpen] = useState<boolean>(false);
	const [optionsPopupPage, setOptionsPopupPage] = useState<PopupPagesEnum>();
	const creditCardFee = useSelector(selectCardFee(FeeTypes.creditCardPayments));
	const debitCardFee = useSelector(selectCardFee(FeeTypes.debitCardPayments));
	const areFeesLoaded = creditCardFee && debitCardFee;
	const {marketplace, transactionFee, gasFee} = useSelector(selectNftMedia);

	const handleOptionsPopupConfig = (isPopupOpen: boolean, popupPage?: PopupPagesEnum) => {
		setIsOptionsPopupOpen(isPopupOpen);
		if (popupPage) {
			setOptionsPopupPage(popupPage);
		}
	};
	const banqId = (paymentInfo as PaymentInfoI)?.source?.banq?.id;
	const classes = useStyles();
	const transactionFeeParsed = transactionFee && transactionFee.lenght > 0 ? transactionFee : 0;
	const gasFeeParsed = gasFee && gasFee.length > 0 ? gasFee : 0;
	const totalFees = transactionFeeParsed + gasFeeParsed;

	useEffect(() => {
		if (totalFees > 0 && !areFeesLoaded) {
			dispatch(loadPaymentFees({spaceId: 162098})); // banq.accountSpaceId
		}
	}, [areFeesLoaded, totalFees, dispatch]);

	return (
		<div className={classes.root}>
			<ViewTitle className={classes.heading}>{(marketplace || '') + ' Transfer'}</ViewTitle>
			<ScreenLayout>
				<div className={classes.content}>
					<NFTimageBox />
					<div className={classes.paragraph}>
						<div>
							<span className={classes.paragraph__title}>Transfer to</span>
							<span className={classes.paragraph__subtitle}>Marketplace</span>
						</div>
						{marketplace && (
							<div className={classes.paragraph__content_box}>
								<span className={classes.paragraph__content_text}>{marketplace}</span>
							</div>
						)}
					</div>
					{destination && totalFees > 0 && (
						<>
							<SourceBanqCardContainer
								sourceBanqId={banqId}
								externalAccountId={selectedExternalAccount}
								onClick={() => {
									handleOptionsPopupConfig(true, PopupPagesEnum.PaymentOptionsPage);
								}}
								onAddDebitCard={() => {
									handleOptionsPopupConfig(true, PopupPagesEnum.PaymentOptionsPage);
								}}
							/>
							<PaymentOptionsPopup
								isOpen={isOptionsPopupOpen}
								page={optionsPopupPage}
								handleOptionsPopupConfig={handleOptionsPopupConfig}
							/>
						</>
					)}
					{destination && (
						<div>
							<div className={classes.total}>
								<span className={classes.paragraph__title}>Transaction Fee</span>
								<span>{transactionFeeParsed || 'Free'}</span>
							</div>
							<div className={classes.total}>
								<span className={classes.paragraph__title}>Estimated Gas Fee</span>
								<span>{gasFeeParsed || 'Free'}</span>
							</div>
							<div className={classes.total}>
								<div>Total</div>
								<span>{totalFees || 'Free'}</span>
							</div>
						</div>
					)}
					<div className={classes.actions}>
						<Button fullWidth type="submit" onClick={onSubmit} disabled={!!funds && !selectedExternalAccount}>
							{totalFees > 0 && 'Pay &'} Transfer
						</Button>
						<Button onClick={() => setIsDeclineModal(true)} gradient="popUpButton" variant="outlinedGradient" fullWidth>
							Decline
						</Button>
					</div>
				</div>
			</ScreenLayout>
			<span className={classes.tip}>
				{`By clicking submit & pay, you are giving ${
					marketplace || ''
				} permission to transfer the NFT above on your behalf.`}
			</span>
			{isDeclineModal && (
				<DialogConfirm
					onClose={() => setIsDeclineModal(false)}
					onConfirm={() => {
						setIsDeclineModal(false);
						onDecline();
					}}
				/>
			)}
		</div>
	);
};
