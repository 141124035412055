import React from 'react';

import {NFTimageBox, ScreenLayout, ViewTitle} from 'src/components/@shared';
import {useStyles} from './TransferSucceed.styles';

export const TransferSucceed = () => {
	const classes = useStyles();
	return (
		<ScreenLayout>
			<div className={classes.content}>
				<NFTimageBox />
				<ViewTitle className={classes.title}>You completed this transfer! Done and done.</ViewTitle>
				<span className={classes.subtitle}>Open the banq app to see transaction.</span>
			</div>
		</ScreenLayout>
	);
};
