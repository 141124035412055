import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {ReactComponent as ArrowIconBlack} from 'src/assets/svg/arrow-left-black.svg';
import {ReactComponent as GradientRightIcon} from 'src/assets/svg/gradient-right-arrow.svg';
import {IconButton} from 'src/components/@shared';

const useStyles = makeStyles(() => ({
	button: {
		cursor: 'pointer'
	}
}));

export interface NavigateIconButtonProps {
	onClick: () => void;
	variant: 'back' | 'forward';
}

export const NavigateIconButton = (props: Omit<NavigateIconButtonProps, 'children'>) => {
	const classes = useStyles();
	const {variant} = props;

	return (
		<IconButton {...props} className={classes.button} data-testid="navigateIconButton">
			{variant === 'back' ? <ArrowIconBlack /> : <GradientRightIcon />}
		</IconButton>
	);
};
