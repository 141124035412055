import React, {useMemo} from 'react';
import {makeStyles} from '@material-ui/styles';
import {countries} from 'country-flags-svg';
import {isMobile} from 'src/utils/isMobileDevice';
import {
	Field,
	Button,
	Form,
	Spinner,
	TextField,
	Typography,
	ExpirationDateField,
	CardNumberField,
	Dropdown
} from 'src/components/@shared';
import * as yup from 'yup';
import {getCardDetails} from 'src/utils';
import theme from 'src/theme';
import {NumberField} from 'src/components/@shared/NumberField/NumberField';
import AFflagIcon from 'src/assets/svg/flags/AF.svg';
import USflagIcon from 'src/assets/svg/flags/US.svg';
import ALflagIcon from 'src/assets/svg/flags/AL.svg';
import GBflagIcon from 'src/assets/svg/flags/GB.svg';
import CAflagIcon from 'src/assets/svg/flags/CA.svg';
import HUflagIcon from 'src/assets/svg/flags/HU.svg';
import {sanctionedCountries, states} from 'src/config';
import {OptionI} from 'src/components/@shared/Dropdown/types';

const dataRequiredMsg = 'This field is required';

const useStyles = makeStyles(() => ({
	form: {
		paddingBottom: 44
	},
	input: {
		margin: 24
	},
	inline: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		gap: 16
	},
	inlineItem: {
		width: 'calc(100% / 2 - 16px)',
		maxHeight: 106
	},
	sectionTitle: {margin: '26px 0 16px 0'},
	btn: {
		marginTop: 42
	},
	disclaimer: {
		color: theme.colors.black70,
		marginTop: 22,
		textAlign: 'center'
	},
	countriesField: {
		margin: '24px 0'
	},
	statesField: {
		marginTop: 10
	},
	statesError: {
		fontSize: 12,
		margin: '12px 0',
		color: theme.colors.red,
		fontFamily: 'Sofia Pro'
	}
}));

const validationSchema = yup.object().shape({
	nameOnCard: yup
		.string()
		.min(2, 'Name on card must be at least 2 characters')
		.max(50, 'Name on card must be at most 50 characters')
		.required(dataRequiredMsg),
	cardNumber: yup.string().max(20, 'Card number must be at most 20 characters').required(dataRequiredMsg),
	email: yup.string().email('Enter valid format').required(dataRequiredMsg),
	expires: yup
		.string()
		.test('invalidDate', 'Invalid expiration month', (date, {createError}) => {
			const year = date?.slice(2) || '';
			const month = date?.slice(0, 2) || '';
			const yearNow = +new Date().getFullYear().toString().slice(2);
			const monthNow = new Date().getMonth() + 1;
			const isYearValid = year.length === 2 && +year - yearNow <= 10;
			const isMonthValid = /0[1-9]|1[0-2]/.test(month || '');
			const isExpired = isMonthValid && isYearValid && (yearNow === +year ? monthNow > +month : yearNow > +year);
			if (isExpired) {
				return createError({message: 'Card is expired'});
			}
			return isYearValid && isMonthValid && !isExpired;
		})
		.required('Invalid expiration month'),
	cvv: yup
		.string()
		.when('cardNumber', (value: any, schema: any) => {
			const {cvvLength, isPopular} = getCardDetails(value);
			if (isPopular) {
				return schema
					.min(cvvLength, `CVV must be exactly ${cvvLength} characters`)
					.max(cvvLength, `CVV must be exactly ${cvvLength} characters`);
			}
			return schema.max(cvvLength, `CVV must be at most ${cvvLength} characters`);
		})
		.required(dataRequiredMsg),
	country: yup
		.object()
		.nullable()
		.shape({
			label: yup.string().nullable(),
			value: yup.string().required(dataRequiredMsg).nullable()
		}),
	street: yup
		.string()
		.min(2, 'Street address must be at least 2 characters')
		.max(100, 'Street address must be at most 100 characters')
		.required(dataRequiredMsg),
	apartment: yup
		.string()
		.min(2, 'Apartment must be at least 2 characters')
		.max(100, 'Apartment must be at most 100 characters'),
	city: yup
		.string()
		.min(2, 'City must be at least 2 characters')
		.max(60, 'City must be at most 60 characters')
		.required(dataRequiredMsg),
	region: yup.string().when('state', {
		is: null,
		then: yup.string().max(60, 'Region must be at most 60 characters'),
		otherwise: yup.string()
	}),
	postalCode: yup
		.string()
		.when('countryValue', {
			is: 'US',
			then: yup.string().max(15, 'Zip code must be at most 15 characters'),
			otherwise: yup.string().max(15, 'Postal code must be at most 15 characters')
		})
		.required(dataRequiredMsg)
});

export type AddCreditCardFormValues = {
	nameOnCard: string;
	cardNumber: string;
	expires: string;
	cvv: string;
	country: OptionI | null;
	city: string;
	street: string;
	apartment: string;
	region: string;
	state: OptionI | null;
	postalCode: string;
	countryValue: string;
	email: string;
};

interface Props {
	isLoading?: boolean;
	onSubmit: (values: AddCreditCardFormValues) => void;
	setStateError: (error: boolean) => void;
	stateError: boolean;
}

interface CountryI {
	[key: string]: string;
}

const getInitialValues = (countriesOptions: OptionI[]) => ({
	nameOnCard: '',
	cardNumber: '',
	email: '',
	expires: '',
	cvv: '',
	country: countriesOptions.find((country: OptionI) => country.value === 'US') as OptionI,
	street: '',
	apartment: '',
	city: '',
	region: '',
	state: null,
	postalCode: '',
	countryValue: 'US'
});

// temporary func
const changeIcons = (countryName: string, countryFlag: string) => {
	switch (countryName) {
		case 'Afghanistan':
			return AFflagIcon;
		case 'United States':
			return USflagIcon;
		case 'United States Minor Outlying Islands':
			return USflagIcon;
		case 'United Kingdom':
			return GBflagIcon;
		case 'Albania':
			return ALflagIcon;
		case 'Canada':
			return CAflagIcon;
		case 'Hungary':
			return HUflagIcon;
		default:
			return countryFlag;
	}
};

export const AddCreditCardForm = ({isLoading, onSubmit, setStateError, stateError}: Props) => {
	const classes = useStyles({isMobileDevice: isMobile});

	const countriesOptions = useMemo(
		() =>
			countries
				.map((country: CountryI) => ({
					value: country.iso2,
					label: country.name,
					// AF, US, GB icons are crushed at the library. TODO: remove library and add all flags as svg at /images
					icon: changeIcons(country.name, country.flag)
				}))
				.filter((country: OptionI) => !sanctionedCountries.includes(country.value)),
		[]
	);

	return (
		<Form
			className={classes.form}
			initialValues={getInitialValues(countriesOptions)}
			validationSchema={validationSchema}
			onSubmit={onSubmit}>
			{({values, setFieldValue}) => {
				const isUSA = (values?.country as unknown as OptionI)?.value === 'US';
				const selectedCountryStates = states.filter(
					(countryData: CountryI) => countryData.countryValue === (values?.country as unknown as OptionI)?.value
				);
				return (
					<>
						<Field
							data-testid="name"
							name="nameOnCard"
							label="Name on Card"
							className={classes.input}
							fullWidth
							component={TextField}
						/>
						<Field
							data-testid="cardNumber"
							name="cardNumber"
							label="Card Number"
							className={classes.input}
							autoCorrect="off"
							fullWidth
							component={CardNumberField}
						/>
						<div className={classes.inline}>
							<div className={classes.inlineItem}>
								<Field
									data-testid="expires"
									name="expires"
									label="Exp. Date (MM/YY)"
									className={classes.input}
									component={ExpirationDateField}
									fullWidth
								/>
							</div>
							<div className={classes.inlineItem}>
								<Field
									data-testid="cvv"
									name="cvv"
									label="CVV"
									className={classes.input}
									format={''.padEnd(getCardDetails(values.cardNumber).cvvLength, '#')}
									component={NumberField}
									fullWidth
								/>
							</div>
						</div>
						<Typography className={classes.sectionTitle} size={20} lineHeight={30} weight="semi-bold">
							Billing Address
						</Typography>
						<Field
							data-testid="country"
							name="country"
							label="Country"
							className={classes.countriesField}
							options={countriesOptions}
							onChange={(country) => {
								setFieldValue('country', country);
								setFieldValue('region', '');
								setFieldValue('state', null);
								setFieldValue('countryValue', (country as OptionI)?.value);
							}}
							component={Dropdown}
						/>
						<Field
							data-testid="street"
							name="street"
							label="Street Address"
							className={classes.input}
							fullWidth
							component={TextField}
						/>
						<Field
							data-testid="apartment"
							name="apartment"
							label="Apartment / Suite (optional)"
							className={classes.input}
							fullWidth
							component={TextField}
						/>
						<Field
							data-testid="city"
							name="city"
							label="City"
							className={classes.input}
							fullWidth
							component={TextField}
						/>
						<div className={classes.inline}>
							<div className={classes.inlineItem}>
								{selectedCountryStates.length > 0 ? (
									<div>
										<Field
											data-testid="state"
											name="state"
											label={isUSA ? 'State' : 'Region'}
											component={Dropdown}
											hasEndAdorment
											onChange={(value) => {
												setStateError(false);
												setFieldValue('state', value);
												setFieldValue('region', '');
											}}
											options={selectedCountryStates}
										/>
										{stateError && <div className={classes.statesError}>{dataRequiredMsg}</div>}
									</div>
								) : (
									<Field
										data-testid="region"
										name="region"
										label="Region"
										className={classes.input}
										onChange={(value) => {
											setFieldValue('region', value);
											setFieldValue('state', null);
										}}
										component={TextField}
										fullWidth
									/>
								)}
							</div>
							<div className={classes.inlineItem}>
								<Field
									data-testid="postalCode"
									name="postalCode"
									label={isUSA ? 'ZIP Code' : 'Postal Code'}
									className={classes.input}
									component={TextField}
									fullWidth
								/>
							</div>
						</div>
						<Field
							data-testid="email"
							name="email"
							label="Email"
							className={classes.input}
							autoCorrect="off"
							autoCapitalize="off"
							fullWidth
							component={TextField}
						/>
						<Button
							className={classes.btn}
							onClick={() => setStateError(!values.state)}
							disabled={isLoading}
							fullWidth
							type="submit">
							{isLoading ? <Spinner color="mercury" size={19} /> : `Add Card`}
						</Button>
						<Typography className={classes.disclaimer} size={16} lineHeight={22}>
							We’ll remember this card for fast checkout in the future.
						</Typography>
					</>
				);
			}}
		</Form>
	);
};
