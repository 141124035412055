// export const SomethingWentWrongErrorsList = [
// 	Errors.loadAuthUserError,
// 	Errors.loadBanqByIdError,
// 	Errors.loadBanqByGuidError,
// 	Errors.parsePaymentLinkError,
// 	Errors.verifyEmailError,
// 	Errors.isLoadSpacesError,
// 	Errors.createSpaceError
// ];

import {State} from '../reducer';

export const selectErrors = (state: State) => state.errors;
export const selectPaymentAcceptError = (state: State) => state.errors.loadPaymentAcceptError;
