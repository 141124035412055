export interface NftMediaI {
	marketplace: string;
	name: string;
	urlImage: string;
	mediaType: string;
	creatorName: string;
	supportUrl: string;
	transactionFee: 0;
	gasFee: 0;
	isExpired?: boolean | null;
}

export enum NftImgSize {
	ExtraSmall = 'extraSmall',
	Small = 'small',
	Medium = 'medium',
	Large = 'large'
}
