import React, {ReactNode, useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';
import {delay} from 'src/utils';
import theme from 'src/theme';
import isString from 'lodash/isString';
import {Typography} from '../Typography';

interface StyleProps {
	display: boolean;
}

const useStyles = makeStyles(() => ({
	root: ({display}: StyleProps) => ({
		display: display ? 'block' : 'none',
		position: 'absolute',
		left: '50%',
		top: 30,
		transform: 'translateX(-50%)',
		padding: '16px 20px',
		color: theme.colors.white,
		borderRadius: 10,
		boxShadow: '0px 10px 22px rgba(118, 34, 169, 0.18)',
		zIndex: 9999
	}),
	successBackground: {
		background: theme.gradients.purpleBanner
	},
	errorBackground: {
		background: theme.gradients.red
	},
	messageContainer: {
		width: 295
	}
}));

interface SnackbarProps {
	className?: string;
	children: ReactNode | string;
	variant?: 'success' | 'error';
	onClose: () => void;
}

export const Snackbar = ({className, children, variant = 'success', onClose}: SnackbarProps) => {
	const [display, setDisplay] = useState<boolean>(true);
	const classes = useStyles({display});

	useEffect(() => {
		let isUnmounted = false;
		delay(5000).then(() => {
			if (isUnmounted) return;
			setDisplay(false);
			onClose();
		});
		return () => {
			isUnmounted = true;
		};
	}, [onClose]);

	return (
		<div
			className={clsx(classes.root, className, {
				[classes.errorBackground]: variant === 'error',
				[classes.successBackground]: variant === 'success'
			})}>
			<div className={classes.messageContainer}>
				{isString(children) ? (
					<Typography size={16} color="white" lineHeight={22}>
						{children}
					</Typography>
				) : (
					children
				)}
			</div>
		</div>
	);
};
