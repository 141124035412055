import React from 'react';
import {useStyles} from './Backdrop.styles';

import {Colors} from 'src/theme';

export const Backdrop = ({
	children,
	background,
	blur
}: {
	children: React.ReactNode;
	background?: keyof Colors;
	blur?: boolean;
}): JSX.Element => {
	const classes = useStyles({background, blur: blur || false});
	return (
		<div data-testid="Backdrop" className={classes.root}>
			{children}
		</div>
	);
};
