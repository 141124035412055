import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {ReactComponent as GradientPlus} from 'src/assets/svg/plus-graddient.svg';
import {ReactComponent as ArrowRight} from 'src/assets/svg/arrow-right-gray.svg';
import {Button, Typography} from '../@shared';

const useStyles = makeStyles(() => ({
	root: {
		borderBottom: 'none',
		padding: '32px 0 0 5px',
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between'
	},
	textWrapper: {
		display: 'flex'
	},
	text: {
		paddingLeft: 10
	},
	arrowRight: {
		paddingRight: 5
	}
}));

export interface AddCreditCardButtonProps {
	onClick?: () => void;
}
export const AddPaymentOptionButton = ({onClick}: AddCreditCardButtonProps) => {
	const classes = useStyles();

	const handleClick = () => onClick?.();
	return (
		// eslint-disable-next-line react/button-has-type
		<Button fullWidth className={classes.root} variant="minimalistic" onClick={handleClick}>
			<div className={classes.textWrapper}>
				<GradientPlus />
				<Typography className={classes.text} size={16} lineHeight={20}>
					Add a Bank or Debit / Credit Card
				</Typography>
			</div>
			<ArrowRight className={classes.arrowRight} />
		</Button>
	);
};
