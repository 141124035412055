import {produce} from 'immer';
import {NftMediaI} from 'src/api';

import {Actions} from 'src/store/constants';
import {LoadNftMediaSuccessAction, LoadNftMediaAction, LoadNftMediaErrorsAction} from './actions';

type NftActions = LoadNftMediaAction | LoadNftMediaSuccessAction | LoadNftMediaErrorsAction;

const initialState: NftMediaI = {
	marketplace: '',
	name: '',
	urlImage: '',
	mediaType: '',
	creatorName: '',
	supportUrl: '',
	transactionFee: 0,
	gasFee: 0,
	isExpired: null
};

const nftReducer = produce((draft, action: NftActions) => {
	switch (action.type) {
		case Actions.LOAD_NFT_MEDIA_SUCCESS:
			return {
				...action.payload
			};
		case Actions.LOAD_NFT_MEDIA_ERROR:
			return {
				isError: true
			};
		default:
			break;
	}
}, initialState);

export default nftReducer;
