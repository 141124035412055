import {Action} from 'redux';
import {Actions} from '../constants';
import {PayloadAction} from '../types';

export type SetSuccessAction = PayloadAction<Actions.SET_SUCCESS, {key: string; message: unknown}>;
export type RemoveSuccessAction = PayloadAction<Actions.REMOVE_SUCCESS, string>;
export type ClearSuccessAction = Action<Actions.CLEAR_SUCCESS>;

export const setSuccess = (key: string, message: unknown = true): SetSuccessAction => ({
	type: Actions.SET_SUCCESS,
	payload: {key, message}
});

export const removeSuccess = (key: string): RemoveSuccessAction => ({
	type: Actions.REMOVE_SUCCESS,
	payload: key
});

export const clearSuccess = (): ClearSuccessAction => ({
	type: Actions.CLEAR_SUCCESS
});
