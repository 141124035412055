import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {ViewTitle, NavigateIconButton} from 'src/components/@shared';
import {removeSuccess} from 'src/store/@success/actions';
import {Success} from 'src/store/constants';
import {selectPaymentInfo} from 'src/store/payment/selectors';
import {createSpaceByBanqIdSelector} from 'src/store/spaces/selectors';
import {ReactComponent as BanqLogo} from 'src/assets/svg/banqLogo.svg';
import {select3dsIframeSrc} from 'src/store/3ds/selectors';
import {selectIsCardAddedSuccessfully, selectIsCardAddLoading} from 'src/store/card/selectors';
import {addCard} from 'src/store/card/action';
import {AddCardBody, PaymentInfoI} from 'src/api';
import {loadExternalAccounts} from 'src/store/externalAccounts/actions';
import {PopupPagesEnum} from 'src/components/addPaymentOptionPopUp';
import {AddCreditCardForm, AddCreditCardFormValues} from './AddCreditCardForm';
import {useStyles} from './AddCreditCardView.styles';

export const mapFormStateToRequestValues = (formValues: AddCreditCardFormValues): AddCardBody => ({
	cardNumber: formValues.cardNumber,
	expirationYear: formValues.expires?.slice(2),
	expirationMonth: formValues.expires?.slice(0, 2),
	cvvTwo: formValues.cvv,
	cardHolderName: formValues?.nameOnCard,
	email: formValues?.email,
	addressOne: formValues?.street,
	addressTwo: formValues?.apartment ?? '',
	city: formValues?.city,
	zipCode: formValues?.postalCode,
	country: formValues?.country?.value as string,
	returnUrl: `${window.location.href.replace(/\?refreshToken=.*$/, '')}?3ds-redirect`,
	state: formValues.state?.value || formValues.region || ''
});

interface AddCreditCardViewProps {
	handleOptionsPopupConfig: (isOpen: boolean, page?: PopupPagesEnum) => void;
}

export const AddCreditCardView = ({handleOptionsPopupConfig}: AddCreditCardViewProps) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const paymentInfo = useSelector(selectPaymentInfo) as PaymentInfoI;
	const banqId = paymentInfo?.source?.banq?.id;
	const spaceId = useSelector(createSpaceByBanqIdSelector(banqId))?.id; // 162098
	const [stateError, setStateError] = useState(false);
	const securityCheckUrl = useSelector(select3dsIframeSrc);
	const isCardAddedSuccessfully = useSelector(selectIsCardAddedSuccessfully);
	const isLoading = useSelector(selectIsCardAddLoading);

	useEffect(() => {
		if (securityCheckUrl) handleOptionsPopupConfig(true, PopupPagesEnum.SecurityPage);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [securityCheckUrl]);

	useEffect(() => {
		if (isCardAddedSuccessfully) {
			if (spaceId) {
				dispatch(loadExternalAccounts(spaceId));
			}
			dispatch(removeSuccess(Success.isCardAddedSuccessfully));
			handleOptionsPopupConfig(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isCardAddedSuccessfully]);

	const handleSubmit = (values: AddCreditCardFormValues): void => {
		if (stateError) return;
		dispatch(addCard(spaceId as number, mapFormStateToRequestValues(values)));
	};

	return (
		<div className={classes.root}>
			<BanqLogo className={classes.logo} />
			<ViewTitle
				className={classes.title}
				startAdornment={
					<NavigateIconButton
						variant="back"
						onClick={() => handleOptionsPopupConfig(true, PopupPagesEnum.PaymentOptionsPage)}
					/>
				}>
				Add a Card
			</ViewTitle>
			<AddCreditCardForm
				onSubmit={handleSubmit}
				setStateError={setStateError}
				stateError={stateError}
				isLoading={isLoading}
			/>
		</div>
	);
};
