import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {Typography, ViewTitle, NavigateIconButton} from 'src/components/@shared';
import {useSelector, useDispatch} from 'react-redux';
import {
	createCreditCardListSelector,
	selectLastAddedExternalAccountId,
	createChosenExternalAccount
} from 'src/store/externalAccounts/selectors';
import {ExternalSpace} from 'src/api/types';
import isEmpty from 'lodash/isEmpty';
import {selectIsSingleBanqWithEmptyBalance} from 'src/store/spaces/selectors';
import {createSpaceByBanqIdSelector} from 'src/store/spaces/selectors';
import {selectPaymentInfo} from 'src/store/payment/selectors';
import {PaymentInfoI, FeeTypes} from 'src/api/types';
import {selectCardFee} from 'src/store/cardFees/selectors';
import {createBanqSelector} from 'src/store/banqs/selectors';
import {setChosenExternalAccount} from 'src/store/externalAccounts/actions';
import theme from 'src/theme';
import {PopupPagesEnum} from 'src/components/addPaymentOptionPopUp';
import {AddPaymentOptionButton} from './AddPaymentOptionButton';
import {CreditCardsList} from './CreditCardsList';
import {AddPaymentOptionAction} from './AddPaymentOptionAction';

const useStyles = makeStyles(() => ({
	root: {
		height: 550
	},
	title: {
		justifyContent: 'flex-start'
	},
	rightMargin: {
		marginRight: 17
	},
	cardsBlockTitle: {
		textTransform: 'uppercase',
		padding: '14px 0 16px 0',
		color: theme.colors.black50
	},
	minAllowedHeight: {
		[theme.breakpoints.up.sm]: {
			minHeight: 476
		}
	},
	btn: {
		marginTop: 94,
		width: '100%'
	},
	btnMobile: {
		position: 'absolute',
		bottom: 50,
		width: '90%'
	},
	logoutButtonWrapper: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: 35
	},
	addPaymentOptionButton: {
		paddingBottom: 48
	}
}));

interface PaymentOptionsProps {
	handleOptionsPopupConfig: (isOpen: boolean, page?: PopupPagesEnum) => void;
}

export const PaymentOptionsView = ({handleOptionsPopupConfig}: PaymentOptionsProps) => {
	const classes = useStyles();
	const {source, destination, funds, ...paymentInfo} = useSelector(selectPaymentInfo) as PaymentInfoI;
	const banqId = (paymentInfo as PaymentInfoI)?.source?.banq?.id;
	const banq = useSelector(createBanqSelector(banqId));
	const dispatch = useDispatch();
	const spaceId = useSelector(createSpaceByBanqIdSelector(source?.banq?.id))?.id;
	const creditCardList = useSelector(createCreditCardListSelector(spaceId)); // 162098
	const reselectedExternalAccount = useSelector(createChosenExternalAccount(spaceId)); // 162098
	const lastAddedExternalAccount = useSelector(selectLastAddedExternalAccountId);
	const chosenExternalAccount = reselectedExternalAccount || lastAddedExternalAccount;
	const lastAddedExternalAccountId = useSelector(selectLastAddedExternalAccountId);
	const isSingleBanqWithEmptyBalance = useSelector(selectIsSingleBanqWithEmptyBalance);
	const creditCardFee = useSelector(selectCardFee(FeeTypes.creditCardPayments));
	const debitCardFee = useSelector(selectCardFee(FeeTypes.debitCardPayments));

	return (
		<div className={classes.root}>
			<ViewTitle
				className={classes.title}
				startAdornment={<NavigateIconButton variant="back" onClick={() => handleOptionsPopupConfig(false)} />}>
				Payment Methods
			</ViewTitle>
			{!isEmpty(creditCardList) && (
				<>
					<Typography className={classes.cardsBlockTitle} size={11} lineHeight={11}>
						Cards
					</Typography>
					<CreditCardsList
						items={creditCardList as ExternalSpace[]}
						selectedExternalAccountId={chosenExternalAccount}
						onSelect={(card) => {
							dispatch(setChosenExternalAccount(card));
							handleOptionsPopupConfig(false);
						}}
						shouldShowDefault={isSingleBanqWithEmptyBalance || banq?.balance === 0}
						creditCardFee={creditCardFee}
						debitCardFee={debitCardFee}
						defaultAccountId={lastAddedExternalAccountId}
						banq={banq}
					/>
				</>
			)}
			<div className={classes.addPaymentOptionButton}>
				<AddPaymentOptionAction
					onAddDebitCard={() => {
						handleOptionsPopupConfig(true, PopupPagesEnum.AddCreditCardPage); // navigate(routes.creditCardWidget(banq.accountSpaceId, banqId), {state: {previousUrl: pathname}});
					}}>
					<AddPaymentOptionButton />
				</AddPaymentOptionAction>
			</div>
		</div>
	);
};
