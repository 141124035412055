import {Actions} from 'src/store/constants';
import {ExternalSpace} from 'src/api';
import keyBy from 'lodash/keyBy';
import {
	LoadExternalAccountAction,
	LoadExternalSpacesSuccessAction,
	SetExternalAccountAction,
	SetChosenExternalAccountAction,
	RelinkCardAction,
	CancelRelinkCardAction
} from './actions';

export type ExternalSpacesAction =
	| LoadExternalAccountAction
	| LoadExternalSpacesSuccessAction
	| SetExternalAccountAction
	| SetChosenExternalAccountAction
	| RelinkCardAction
	| CancelRelinkCardAction;

type ExternalSpacesState = Record<string, ExternalSpace> & {chosenExternalAccount?: ExternalSpace};

const initialState: ExternalSpacesState = {};

const externalSpacesReducer = (state = initialState, action: ExternalSpacesAction) => {
	let cancelRelinkingPayload;
	if (action.type === Actions.CANCEL_RELINK_CARD) {
		// relinking flag was moved by destructurization
		const {relinking, ...payload} = action.payload;
		cancelRelinkingPayload = payload;
	}

	switch (action.type) {
		case Actions.LOAD_EXTERNAL_SPACES_SUCCESS:
			return {
				...keyBy(action.payload, 'externalAccountId')
			};

		case Actions.SET_EXTERNAL_SPACE:
			return {
				...state,
				[action.payload.externalAccountId]: action.payload
			};

		case Actions.SET_CHOSEN_EXTERNAL_SPACE:
			let accounts = {...state};
			Object.keys(state).forEach((key) => {
				accounts[key].chosen = false;
			});
			return {
				...accounts,
				[action.payload.externalAccountId]: {...action.payload, chosen: true}
			};

		case Actions.RELINK_CARD:
			return {
				...state,
				[action.payload.externalAccountId]: {
					...action.payload,
					relinking: true
				}
			};

		case Actions.CANCEL_RELINK_CARD:
			return {
				...state,
				[action.payload.externalAccountId]: cancelRelinkingPayload
			};

		default:
			return state;
	}
};

export default externalSpacesReducer;
