import React, {ReactNode, ReactText} from 'react';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';

import {Typography} from 'src/components/@shared';
import {isMobileDevice} from 'src/utils';
import {S} from 'src/styles';

const useStyles = makeStyles({
	root: {
		...S.flexCentered,
		marginBottom: 26
	}
});

interface Props {
	className?: string;
	startAdornment?: ReactNode;
	children: ReactText;
}

export const ViewTitle = ({children, startAdornment, className}: Props) => {
	const classes = useStyles();
	return (
		<div className={clsx(classes.root, className)}>
			{!isMobileDevice() && startAdornment}
			<Typography size={22} lineHeight={30} weight="semi-bold">
				{children}
			</Typography>
		</div>
	);
};
