import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
	Layout,
	TransferDeclined,
	TransferFailed,
	TransferSucceed,
	TransferView,
	TransferViewExpired
} from 'src/components';
import {Spinner} from 'src/components/@shared';
import {
	selectIsPaymentAcceptLoading,
	selectPaymentInfo,
	selectIsPaymentInfoLoading,
	selectPaymentStatus,
	selectIsPaymentDeclinedLoading
} from 'src/store/payment/selectors';
import {selectNftMedia, selectIsNftMediaLoading} from 'src/store/nft/selectors';
import {loadNftMedia} from 'src/store/nft/actions';
import {createSpaceByBanqIdSelector} from 'src/store/spaces/selectors';
import {loadPaymentInfo, acceptPayment, declinePayment} from 'src/store/payment/actions';
import {selectLastAddedExternalAccountId, createChosenExternalAccount} from 'src/store/externalAccounts/selectors';
import {loadExternalAccounts} from 'src/store/externalAccounts/actions';
import {loadBanqById} from 'src/store/banqs/actions';
import {PaymentInfoErrorI, PaymentStatus, PaymentInfoI} from 'src/api';
import {appConfig} from './api/appConfig';

function App() {
	const isPaymentInfoLoading = useSelector(selectIsPaymentInfoLoading);
	const isPaymentAcceptLoading = useSelector(selectIsPaymentAcceptLoading);
	const isPaymentDeclinedLoading = useSelector(selectIsPaymentDeclinedLoading);
	const payment = useSelector(selectPaymentInfo);
	const paymentStatus = useSelector(selectPaymentStatus);
	const {transactionFee, gasFee} = useSelector(selectNftMedia);
	const accountSpaceId = (payment as PaymentInfoI)?.source?.accountId;
	const banqId = (payment as PaymentInfoI)?.source?.banq?.id;
	const spaceId = useSelector(createSpaceByBanqIdSelector(banqId))?.id;
	const lastAddedExternalAccount = useSelector(selectLastAddedExternalAccountId);
	const reselectedExternalAccount = useSelector(createChosenExternalAccount(spaceId)); // 162098
	const selectedExternalAccount = reselectedExternalAccount || lastAddedExternalAccount;
	const dispatch = useDispatch();
	const nftMediaIsLoaded = useSelector(selectIsNftMediaLoading);

	const checkStatusTimer = useRef(0);

	useEffect(() => {
		!nftMediaIsLoaded && dispatch(loadNftMedia());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		if (paymentStatus === PaymentStatus.isPendingInBlockchainNetwork) {
			checkStatusTimer.current = window.setInterval(() => dispatch(loadPaymentInfo()), 5000);
			return () => {
				clearInterval(checkStatusTimer.current);
				checkStatusTimer.current = 0;
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paymentStatus, dispatch]);

	const transactionFeeParsed = transactionFee && transactionFee.lenght > 0 ? transactionFee : 0;
	const gasFeeParsed = gasFee && gasFee.length > 0 ? gasFee : 0;
	const totalFees = transactionFeeParsed + gasFeeParsed;

	useEffect(() => {
		if (!appConfig.token.length) {
			dispatch(declinePayment());
		} else dispatch(loadPaymentInfo());
	}, [dispatch]);

	useEffect(() => {
		if (totalFees > 0 && accountSpaceId) {
			dispatch(loadExternalAccounts(accountSpaceId)); // 162098
		}
	}, [accountSpaceId, totalFees, dispatch]);

	useEffect(() => {
		if (totalFees > 0 && banqId) {
			dispatch(loadBanqById(banqId)); // 172846
		}
	}, [banqId, totalFees, dispatch]);

	const onSubmit = () => dispatch(acceptPayment(selectedExternalAccount));

	const onDecline = () => dispatch(declinePayment());

	const getView = () => {
		switch (paymentStatus) {
			case PaymentStatus.isCompleted:
				return <TransferSucceed />;
			case PaymentStatus.isDeclined:
				return <TransferDeclined />;
			case PaymentStatus.isFailed:
				return <TransferFailed />;
			case PaymentStatus.isPending:
				return <TransferView onSubmit={onSubmit} onDecline={onDecline} />;
			case PaymentStatus.isPendingInBlockchainNetwork:
				return <Spinner />;
			default:
				return;
		}
	};

	const getPage = () =>
		(payment as PaymentInfoErrorI).isExpired || paymentStatus === PaymentStatus.isExpired ? (
			<TransferViewExpired />
		) : (
			!isPaymentAcceptLoading && !isPaymentInfoLoading && getView()
		);

	return (
		<Layout>
			{isPaymentInfoLoading || isPaymentAcceptLoading || isPaymentDeclinedLoading ? <Spinner /> : getPage()}
		</Layout>
	);
}

export default App;
