import {makeStyles} from '@material-ui/styles';

import {S} from 'src/styles';
import theme from 'src/theme';

export const useStyles = makeStyles(() => ({
	root: {
		width: 544,
		textAlign: 'center'
	},
	heading: {
		marginTop: '2rem'
	},
	content: {
		...S.screenContent,
		'& > div': {
			width: '100%'
		},
		'& button ~ button': {
			marginTop: 20
		}
	},
	paragraph: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '0 0 15px 0',
		margin: '15px 0 0 ',
		borderBottom: `1px solid ${theme.colors.gallery}`
	},
	paragraph__title: {
		fontSize: 14,
		textTransform: 'uppercase',
		color: theme.colors.black50,
		display: 'block'
	},
	paragraph__subtitle: {
		fontSize: 16,
		fontWeight: 400,
		display: 'block',
		marginTop: 5
	},
	paragraph__content_box: {
		display: 'flex',
		alignItems: 'end'
	},
	paragraph__content_text: {
		color: theme.colors.black50,
		display: 'inline-block',
		marginLeft: 9
	},
	paragraph__content_card: {
		cursor: 'pointer',
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'right',
		'& > span:last-child': {
			fontSize: 14,
			color: theme.colors.black50
		},
		'& + svg': {
			cursor: 'pointer',
			alignSelf: 'center'
		}
	},
	total: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: '13px 0',
		'&:not(:last-child) span': {
			fontSize: 14
		},
		'&:last-child': {
			borderTop: `2px solid ${theme.colors.black}`,
			paddingBottom: 0,
			color: theme.colors.black,
			fontSize: 16,
			fontWeight: 600
		}
	},
	actions: {
		marginTop: 52
	},
	tip: {
		fontFamily: 'Sofia Pro',
		marginTop: 32,
		display: 'inline-block',
		marginBottom: '2rem'
	}
}));
