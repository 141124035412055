import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Snackbar} from 'src/components/@shared';
import {removeError} from 'src/store/@errors/actions';
import {Errors, Success} from 'src/store/constants';
import {removeSuccess} from 'src/store/@success/actions';
import {useSetSomethingWentWrongMessage} from 'src/hooks/useSetSomethingWentWrongMessage';
import {selectSnackbarErrorMessage, selectSnackbarSuccessMessage} from './selectors';

export const SnackbarMessage = () => {
	const snackbarErrorMessage = useSelector(selectSnackbarErrorMessage);
	const snackbarSuccessMessage = useSelector(selectSnackbarSuccessMessage);
	const dispatch = useDispatch();

	useSetSomethingWentWrongMessage();

	const handleClose = () => {
		if (snackbarErrorMessage) dispatch(removeError(Errors.snackbar));
		if (snackbarSuccessMessage) dispatch(removeSuccess(Success.snackbar));
	};

	return (
		<>
			{Boolean(snackbarErrorMessage) && (
				<Snackbar variant="error" onClose={handleClose}>
					{snackbarErrorMessage}
				</Snackbar>
			)}
			{Boolean(snackbarSuccessMessage) && (
				<Snackbar variant="success" onClose={handleClose}>
					{snackbarSuccessMessage}
				</Snackbar>
			)}
		</>
	);
};
