import {PaymentSystem} from 'src/api/types';
import visaCardAvatar from 'src/assets/svg/visaAvatar.svg';
import masterCardAvatar from 'src/assets/svg/mastercardAvatar.svg';
import aExpressAvatar from 'src/assets/svg/aExpressAvatar.svg';
import unknownCardProviderAvatar from 'src/assets/svg/unknownCardProviderAvatar.svg';

export const determineCardProvider = (cardNumber: string) => {
	if (/^37\d*/.test(cardNumber)) return PaymentSystem.AmericanExpress;
	if (/^4\d*/.test(cardNumber)) return PaymentSystem.Visa;
	if (/^5\d*/.test(cardNumber)) return PaymentSystem.Mastercard;
	return PaymentSystem.Other;
};

export const getCardDetails = (cardNumber: string) => {
	const result = {cvvLength: 5, isPopular: false};
	if (determineCardProvider(cardNumber) === PaymentSystem.AmericanExpress) {
		result.cvvLength = 4;
		result.isPopular = true;
	}
	if (
		determineCardProvider(cardNumber) === PaymentSystem.Visa ||
		determineCardProvider(cardNumber) === PaymentSystem.Mastercard
	) {
		result.cvvLength = 3;
		result.isPopular = true;
	}
	return result;
};

export const mapPaymentSystemToAvatar = {
	[PaymentSystem.Visa]: visaCardAvatar,
	[PaymentSystem.OldPtVisa]: visaCardAvatar,
	[PaymentSystem.Mastercard]: masterCardAvatar,
	[PaymentSystem.OldPtMastercard]: masterCardAvatar,
	[PaymentSystem.AmericanExpress]: aExpressAvatar,
	[PaymentSystem.Other]: unknownCardProviderAvatar
};

export const mapCardToCardName = {
	[PaymentSystem.Visa]: 'Visa',
	[PaymentSystem.OldPtVisa]: 'Visa',
	[PaymentSystem.Mastercard]: 'MasterCard',
	[PaymentSystem.OldPtMastercard]: 'MasterCard',
	[PaymentSystem.AmericanExpress]: 'American Express',
	[PaymentSystem.Other]: 'Card'
};
