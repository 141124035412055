import {Actions, Errors, Loadings, Success} from 'src/store/constants';
import {LoadAction, PayloadAction} from 'src/store/types';
import api, {GetFeesResponse} from 'src/api';

export type LoadPaymentFeesParams = {spaceId: number};
export type LoadPaymentFeesAction = LoadAction<Actions.LOAD_PAYMENT_FEES>;
export type LoadPaymentFeesSuccessAction = PayloadAction<Actions.LOAD_PAYMENT_FEES_SUCCESS, GetFeesResponse>;

export const loadPaymentFees = (params: LoadPaymentFeesParams): LoadPaymentFeesAction => ({
	type: Actions.LOAD_PAYMENT_FEES,
	load: api.fees.getAccountFees(params.spaceId),
	loading: Loadings.isPaymentFeeLoading,
	error: Errors.loadTipsError,
	success: Success.isPaymentFeeLoadedSuccessful
});
