import {makeStyles} from '@material-ui/styles';

export interface StyleProps {
	size: number;
}

const translateSize = '400%';

export const useStyles = makeStyles(() => ({
	root: ({size}: StyleProps) => ({
		position: 'relative',
		width: size,
		height: size,
		margin: 'auto'
	}),
	spinner: {
		animation: '$ldsSpinnerContainer 1.1s linear infinite',
		position: 'absolute',
		top: '40%',
		left: '44%',
		width: '10%',
		height: '24%',
		borderRadius: '5px',
		'&:nth-child(1)': {
			transform: `rotate(0deg) translate(${translateSize}) rotate(90deg)`,
			animationDelay: '0.0s'
		},
		'&:nth-child(2)': {
			transform: `rotate(30deg) translate(${translateSize}) rotate(90deg)`,
			animationDelay: '0.1s'
		},
		'&:nth-child(3)': {
			transform: `rotate(60deg) translate(${translateSize}) rotate(90deg)`,
			animationDelay: '0.2s'
		},
		'&:nth-child(4)': {
			transform: `rotate(90deg) translate(${translateSize}) rotate(90deg)`,
			animationDelay: '0.3s'
		},
		'&:nth-child(5)': {
			transform: `rotate(120deg) translate(${translateSize}) rotate(90deg)`,
			animationDelay: '0.4s'
		},
		'&:nth-child(6)': {
			transform: `rotate(150deg) translate(${translateSize}) rotate(90deg)`,
			animationDelay: '0.5s'
		},
		'&:nth-child(7)': {
			transform: `rotate(180deg) translate(${translateSize}) rotate(90deg)`,
			animationDelay: '0.6s'
		},
		'&:nth-child(8)': {
			transform: `rotate(210deg) translate(${translateSize}) rotate(90deg)`,
			animationDelay: '0.7s'
		},
		'&:nth-child(9)': {
			transform: `rotate(240deg) translate(${translateSize}) rotate(90deg)`,
			animationDelay: '0.8s'
		},
		'&:nth-child(10)': {
			transform: `rotate(270deg) translate(${translateSize}) rotate(90deg)`,
			animationDelay: '0.9s'
		},
		'&:nth-child(11)': {
			transform: `rotate(300deg) translate(${translateSize}) rotate(90deg)`,
			animationDelay: '0.10s'
		},
		'&:nth-child(12)': {
			transform: `rotate(330deg) translate(${translateSize}) rotate(90deg)`,
			animationDelay: '0.11s'
		}
	},
	'@keyframes ldsSpinnerContainer': {
		'0%': {
			opacity: 1
		},
		'100%': {
			opacity: 0
		}
	}
}));
