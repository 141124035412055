import {Actions} from 'src/store/constants';
import {produce} from 'immer';
import {PaymentFees} from 'src/api';
import {LoadPaymentFeesSuccessAction} from './actions';

export type PaymentActions = LoadPaymentFeesSuccessAction;

export type PaymentState = {
	paymentFees?: PaymentFees[];
};

const initialState: PaymentState = {};

const feesReducer = produce((draft, action: PaymentActions) => {
	if (action.type === Actions.LOAD_PAYMENT_FEES_SUCCESS) {
		draft.paymentFees = action.payload.data;
	}
}, initialState);

export default feesReducer;
