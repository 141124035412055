import {MediaType} from '../types';

//TODO: remove/adjust if backend will provide another realization
export const getType = (mediaType: string = ''): string => {
	const splittedType = mediaType?.split('/');
	if (splittedType?.includes('gif')) return MediaType.GIF;
	else if (splittedType?.includes('audio')) return MediaType.Audio;
	else if (splittedType?.includes('video')) return MediaType.Video;
	else if (splittedType?.includes('image')) return MediaType.Image;
	else return MediaType.Other;
};
