import React, {ButtonHTMLAttributes} from 'react';
import clsx from 'clsx';

import {Colors, Gradients} from 'src/theme';
import {useStyles} from './Button.styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	type?: 'submit' | 'button';
	variant?: 'contained' | 'outlinedGradient' | 'minimalistic' | 'outlined';
	fullWidth?: boolean;
	gradient?: keyof Gradients;
	color?: keyof Colors;
}

export const Button = ({
	className,
	disabled,
	children,
	fullWidth,
	color,
	gradient,
	variant = 'contained',
	...props
}: ButtonProps) => {
	const classes = useStyles({fullWidth, gradient, color});
	return (
		<button
			{...props}
			disabled={disabled}
			className={clsx(classes.root, classes[variant], className, {
				[classes.disabled]: disabled
			})}>
			{children}
		</button>
	);
};
