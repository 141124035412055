import {makeStyles} from '@material-ui/styles';

import theme from 'src/theme';

export const useStyles = makeStyles(() => ({
	root: {
		width: 544,
		position: 'relative',
		backgroundColor: theme.colors.white,
		boxShadow: '0px 0px 26px rgba(0, 0, 0, 0.15)',
		borderRadius: 10,
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'initial',
		margin: 'auto',
		boxSizing: 'border-box'
	},
	contentWrapper: {
		padding: '28px 48px 48px 48px'
	}
}));
