import {createSelector} from 'reselect';
import {FeeTypes} from 'src/api';
import {formatInUSD} from 'src/utils';
import {State} from '../reducer';

export const selectPaymentFees = (state: State) => state.fees?.paymentFees;

export const selectCardFee = (paymentType: FeeTypes) =>
	createSelector(selectPaymentFees, (fees) => {
		if (!fees) return '';
		const feesByCardType = fees.find((fee) => fee.type === paymentType);
		return feesByCardType?.fixedValue
			? formatInUSD(feesByCardType?.fixedValue || 0)
			: `${feesByCardType?.percentageValue}%`;
	});
