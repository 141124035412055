import React, {FC} from 'react';
import NumberFormat from 'react-number-format';
import {TextField} from 'src/components/@shared';
import {CustomInputProps, NumberFieldPropsBase} from 'src/types';

const CustomInput: FC<CustomInputProps> = ({...props}) => <TextField type="number" {...props} />;

export interface NumberFieldProps extends NumberFieldPropsBase {
	onChange?: (phone: string) => void;
}

export const NumberField: FC<NumberFieldProps> = ({
	format,
	value,
	startAdorment,
	prefix,
	onChange,
	size,
	type,
	...props
}) => (
	<NumberFormat
		{...props}
		type={type}
		format={format}
		prefix={prefix}
		customInput={CustomInput}
		className={props?.className}
		defaultValue={value}
		startAdorment={startAdorment}
		onValueChange={(values) => {
			onChange?.(values.value);
		}}
	/>
);
