import omit from 'lodash/omit';

import {Actions} from '../constants';
import {PayloadAction} from '../types';
import {endsWithError, endsWithPending} from '../utils';
import {ClearErrorsAction, RemoveErrorAction, SetErrorAction} from './actions';

type ErrorsActions = PayloadAction<Actions, string> | SetErrorAction | RemoveErrorAction | ClearErrorsAction;

const initialState: Record<string, unknown> = {};

const errorsReducer = (state = initialState, action: ErrorsActions) => {
	switch (action.type) {
		case Actions.SET_ERROR:
			return {
				...state,
				[(action as SetErrorAction).payload.key]: (action as SetErrorAction).payload.error
			};

		case Actions.REMOVE_ERROR:
			return omit(state, action.payload);

		case Actions.CLEAR_ERRORS:
			return initialState;
	}

	if (endsWithPending(action.type) && action.error) {
		return omit(state, action.error);
	}

	if (endsWithError(action.type) && action.error)
		return {
			...state,
			[action.error]: action.payload
		};

	return state;
};

export default errorsReducer;
