import {makeStyles} from '@material-ui/styles';
import theme, {Colors} from 'src/theme';

interface StyleProps {
	background?: keyof Colors;
	blur: Boolean;
}

export const useStyles = makeStyles(() => ({
	root: ({background, blur}: StyleProps) => ({
		backgroundColor: (background && theme.colors[background]) || theme.colors.white,
		backdropFilter: blur && 'blur(8px)',
		position: 'fixed',
		width: '100%',
		height: '100%',
		top: '0px',
		left: '0px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: 100
	})
}));
