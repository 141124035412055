import {makeStyles} from '@material-ui/styles';
import React, {HTMLAttributes, ReactNode} from 'react';
import clsx from 'clsx';

import theme, {Colors, Weights} from 'src/theme';

type Weight = keyof Weights;

type StylesProp = {
	weight: Weight;
	size: number;
	color?: keyof Colors;
	lineHeight: number;
};

const useStyles = makeStyles(() => ({
	root: ({color, weight, size, lineHeight}: StylesProp) => ({
		fontFamily: theme.fonts.primary,
		fontWeight: theme.weights[weight],
		color: color ? theme.colors[color] : theme.colors.black,
		fontSize: size,
		lineHeight: `${lineHeight}px`
	})
}));

export interface TypographyProps extends Omit<HTMLAttributes<HTMLDivElement>, 'color'> {
	children: ReactNode;
	weight?: Weight;
	size?: number;
	color?: keyof Colors;
	lineHeight?: number;
}

export const Typography = ({
	className,
	size = 14,
	color,
	weight = 'regular',
	lineHeight = 16,
	children,
	...props
}: TypographyProps) => {
	const classes = useStyles({size, weight, color, lineHeight});
	return (
		<div {...props} className={clsx(classes.root, className)}>
			{children}
		</div>
	);
};
