import http from './http';
import {Space} from './types';

export type CreateUserRequest = {
	firstName: string;
	lastName: string;
	phone: string;
	email: string;
	password: string;
	verificationCode: string;
	idempotentToken?: string;
};

const users = {
	async verifyEmail(email: string): Promise<boolean> {
		const response = await http.get<unknown, {exists: boolean}>(`/users/verify?email=${email}`);
		return response.exists;
	},

	async loadUserSpaces(userId: number): Promise<Space[]> {
		const {data} = await http.get(`/users/${userId}/account-spaces`);
		return data;
	}
};

export default users;
