import http from './http';

export type GetFeesResponse = {
	data: {
		feeTypeId: number;
		type: string;
		fixedValue: number;
		percentageValue: number;
	}[];
};

const fees = {
	getAccountFees(spaceId: number): Promise<GetFeesResponse> {
		return http.get(`/fees?accountId=${spaceId}`);
	}
};

export default fees;
